import { Dispatch } from 'redux';
import {
  User,
  UserRequest,
  ResetPasswordRequest,
  ConfirmResetPasswordRequest,
  ChangeCredentialsRequest,
  ConfirmEmailRequest,
  Business,
  Account,
  LoggedAccount,
} from '../config/types';
import {
  LOGGING_IN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LoginActionTypes,
  LOGOUT,
  LogoutActionTypes,
  RESETING_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  ResetPasswordActionTypes,
  CONFIRMING_RESET_PASSWORD,
  CONFIRM_RESET_PASSWORD_SUCCESS,
  CONFIRM_RESET_PASSWORD_ERROR,
  ConfirmResetPasswordActionTypes,
  RegisterUserActionTypes,
  REGISTERING_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  ValidateRegisterActionTypes,
  VALIDATING_REGISTER,
  VALIDATE_REGISTER_SUCCESS,
  VALIDATE_REGISTER_ERROR,
  RegisterBusinessActionTypes,
  REGISTERING_BUSINESS,
  REGISTER_BUSINESS_SUCCESS,
  REGISTER_BUSINESS_ERROR,
  ChangeCredentialsActionTypes,
  GetAccountActionTypes,
  GETTING_ACCOUNT,
  CHANGING_CREDENTIALS,
  CHANGE_CREDENTIALS_SUCCESS,
  CHANGE_CREDENTIALS_ERROR,
  UPDATING_ACCOUNT,
  UPDATE_ACCOUNT_SUCCESS,
  UpdateAccountActionTypes,
  UPDATE_ACCOUNT_ERROR,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_ERROR,
  ResendConfirmationTypes,
  RESEND_CONFIRMATION_SUCCESS,
  RESENDING_CONFIRMATION,
  RESEND_CONFIRMATION_FAILED,
} from '../config/ActionTypes';
import { authService } from '../../services/auth.service';
import Cookies from 'universal-cookie';

//#region Login

export const login = (user: User) => (dispatch: Dispatch<LoginActionTypes>) => {
  dispatch(loggingIn());

  return authService.login({ user }).then(
    (response) => {
      const cookies = new Cookies();
      cookies.set('token', response!.token, { path: '/' });

      dispatch(loginSuccess(response!.user));
    },
    (error) => {
      dispatch(loginError(error));
    },
  );
};

const loggingIn = (): LoginActionTypes => ({
  type: LOGGING_IN,
});

const loginSuccess = (account: LoggedAccount): LoginActionTypes => ({
  type: LOGIN_SUCCESS,
  account,
});

const loginError = (error: string): LoginActionTypes => ({
  type: LOGIN_ERROR,
  error,
});

//#endregion

//#region Logout

export const logout = () => (dispatch: Dispatch<LogoutActionTypes>) => {
  return authService.logout().then(
    (response) => {
      if (response?.isSuccess) {
        const cookies = new Cookies();
        cookies.remove('token');
        dispatch(logoutSuccess());
      }
    },
    (error) => {
      console.log(error);
    },
  );
};

export const logoutSuccess = (): LogoutActionTypes => ({
  type: LOGOUT,
});

//#endregion

//#region Reset password

export const resetPassword =
  ({ email }: ResetPasswordRequest) =>
  (dispatch: Dispatch<ResetPasswordActionTypes>) => {
    dispatch(resetingPassword());

    const resetPasswordRequest: ResetPasswordRequest = {
      email,
    };

    return authService.resetPassword(resetPasswordRequest).then(
      (response) => {
        dispatch(resetPasswordSuccess());
      },
      (error) => {
        dispatch(resetPasswordError(error));
      },
    );
  };

const resetingPassword = (): ResetPasswordActionTypes => ({
  type: RESETING_PASSWORD,
});

const resetPasswordSuccess = (): ResetPasswordActionTypes => ({
  type: RESET_PASSWORD_SUCCESS,
});

const resetPasswordError = (error: string): ResetPasswordActionTypes => ({
  type: RESET_PASSWORD_ERROR,
  error,
});

//#endregion

//#region Confirm reset password

export const confirmResetPassword =
  ({ id, token }: ConfirmResetPasswordRequest) =>
  (dispatch: Dispatch<ConfirmResetPasswordActionTypes>) => {
    dispatch(confirmingResetPassword());

    return authService.confirmResetPassword({ id, token }).then(
      (response) => {
        dispatch(confirmResetPasswordSuccess(response!));
      },
      (error) => {
        dispatch(confirmResetPasswordError(error));
      },
    );
  };

const confirmingResetPassword = (): ConfirmResetPasswordActionTypes => ({
  type: CONFIRMING_RESET_PASSWORD,
});

const confirmResetPasswordSuccess = (password: string): ConfirmResetPasswordActionTypes => ({
  type: CONFIRM_RESET_PASSWORD_SUCCESS,
  recoveredPassword: password,
});

const confirmResetPasswordError = (error: string): ConfirmResetPasswordActionTypes => ({
  type: CONFIRM_RESET_PASSWORD_ERROR,
  error,
});

//#endregion

//#region Register user

export const registerUser = (user: UserRequest) => (dispatch: Dispatch<RegisterUserActionTypes>) => {
  dispatch(registeringUser());

  return authService.registerUser({ user }).then(
    (response) => {
      dispatch(registerUserSuccess(response!));
    },
    (error) => {
      dispatch(registerUserError(error));
    },
  );
};

const registeringUser = (): RegisterUserActionTypes => ({
  type: REGISTERING_USER,
});

const registerUserSuccess = (user: User): RegisterUserActionTypes => ({
  type: REGISTER_USER_SUCCESS,
  user,
});

const registerUserError = (error: string): RegisterUserActionTypes => ({
  type: REGISTER_USER_ERROR,
  error,
});

//#endregion

//#region Validate register

export const validateRegister =
  ({ userId, token }: ConfirmEmailRequest) =>
  (dispatch: Dispatch<ValidateRegisterActionTypes>) => {
    dispatch(validatingRegister());

    return authService.validateRegister({ userId, token }).then(
      (response) => {
        if (response) {
          const cookies = new Cookies();
          cookies.set('token', response!.token, { path: '/' });

          dispatch(validateRegisterSuccess());
        } else {
          dispatch(validateRegisterError('Email ya existente.'));
        }
      },
      (error) => {
        dispatch(validateRegisterError(error));
      },
    );
  };

const validatingRegister = (): ValidateRegisterActionTypes => ({
  type: VALIDATING_REGISTER,
});

const validateRegisterSuccess = (): ValidateRegisterActionTypes => ({
  type: VALIDATE_REGISTER_SUCCESS,
});

const validateRegisterError = (error: string): ValidateRegisterActionTypes => ({
  type: VALIDATE_REGISTER_ERROR,
  error,
});

//#endregion

//#region Register business

export const registerBusiness = (business: Business) => (dispatch: Dispatch<RegisterBusinessActionTypes>) => {
  dispatch(registeringBusiness());

  return authService.registerBusiness(business).then(
    (response) => {
      dispatch(registerBusinessSuccess(response!));
    },
    (error) => {
      dispatch(registerBusinessError(error));
    },
  );
};

const registeringBusiness = (): RegisterBusinessActionTypes => ({
  type: REGISTERING_BUSINESS,
});

const registerBusinessSuccess = (business: Business): RegisterBusinessActionTypes => ({
  type: REGISTER_BUSINESS_SUCCESS,
  business,
});

const registerBusinessError = (error: string): RegisterBusinessActionTypes => ({
  type: REGISTER_BUSINESS_ERROR,
  error,
});

//#endregion

//#region Get account

export const getAccount = () => (dispatch: Dispatch<GetAccountActionTypes>) => {
  dispatch(gettingAccount());

  return authService.getAccount().then(
    (response) => {
      dispatch(getAccountSuccess(response!));
    },
    (error) => {
      dispatch(getAccountError(error));
    },
  );
};

const gettingAccount = (): GetAccountActionTypes => ({
  type: GETTING_ACCOUNT,
});

const getAccountSuccess = (account: LoggedAccount): GetAccountActionTypes => ({
  type: GET_ACCOUNT_SUCCESS,
  account,
});

const getAccountError = (error: string): GetAccountActionTypes => ({
  type: GET_ACCOUNT_ERROR,
  error,
});

//#endregion

//#region Update account

export const updateAccount = (account: Account) => (dispatch: Dispatch<UpdateAccountActionTypes>) => {
  dispatch(updatingAccount());

  return authService.updateAccount(account).then(
    (response) => {
      dispatch(updateAccountSuccess(response!));
    },
    (error) => {
      dispatch(updateAccountError(error));
    },
  );
};

const updatingAccount = (): UpdateAccountActionTypes => ({
  type: UPDATING_ACCOUNT,
});

const updateAccountSuccess = (account: LoggedAccount): UpdateAccountActionTypes => ({
  type: UPDATE_ACCOUNT_SUCCESS,
  account,
});

const updateAccountError = (error: string): UpdateAccountActionTypes => ({
  type: UPDATE_ACCOUNT_ERROR,
  error,
});

//#endregion

//#region Change credentials

export const changeCredentials =
  (changeCredentialsRequest: ChangeCredentialsRequest) =>
  (dispatch: Dispatch<ChangeCredentialsActionTypes>) => {
    dispatch(changingCredentials());

    return authService.modifyCredentials(changeCredentialsRequest).then(
      (response) => {
        dispatch(changeCredentialsSuccess());
      },
      (error) => {
        dispatch(changeCredentialsError(error));
      },
    );
  };

const changingCredentials = (): ChangeCredentialsActionTypes => ({
  type: CHANGING_CREDENTIALS,
});

const changeCredentialsSuccess = (): ChangeCredentialsActionTypes => ({
  type: CHANGE_CREDENTIALS_SUCCESS,
});

const changeCredentialsError = (error: string): ChangeCredentialsActionTypes => ({
  type: CHANGE_CREDENTIALS_ERROR,
  error,
});

//#endregion

//#region Resend confirmation

export const resendConfirmation = (email: string) => {
  return (dispatch: Dispatch<ResendConfirmationTypes>) => {
    dispatch(resendingConfirmation());

    return authService.resendConfirmation(email).then(
      (response) => {
        dispatch(resendConfirmationSuccess(response!));
      },
      (error) => {
        dispatch(resendConfirmationError(error));
      },
    );
  };
};

export const resendingConfirmation = (): ResendConfirmationTypes => ({
  type: RESENDING_CONFIRMATION,
});

export const resendConfirmationSuccess = (confirmation: string): ResendConfirmationTypes => ({
  type: RESEND_CONFIRMATION_SUCCESS,
  confirmation,
});

export const resendConfirmationError = (error: string): ResendConfirmationTypes => ({
  type: RESEND_CONFIRMATION_FAILED,
  error,
});

//#endregion
