import React, { useState } from 'react';
import { ScreenTitle } from '../../components/ScreenTitle';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { Divider, Button, Link, Typography, makeStyles, createStyles, Theme, Paper } from '@material-ui/core';
import { FormTextField } from '../../components/forms/FormTextField';
import schemas from '../../data/schemas';
import { ThunkDispatch } from 'redux-thunk';
import { AppActions } from '../../store/config/ActionTypes';
import { connect, ConnectedProps } from 'react-redux';
import { login } from '../../store/action_creators/auth.actions';
import { User, RootState } from '../../store/config/types';
import { CustomSnackbar } from './../../components/CustomSnackbar';
import { Status } from './../../store/config/enums';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      margin: '1rem 0',
    },
    registerText: {
      textAlign: 'center',
    },
  }),
);

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  login: (user: User) => dispatch(login(user)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Values {
  email: string;
  password: string;
}

function Login({ auth, login }: PropsFromRedux) {
  const history = useHistory();
  const [loggingIn, setLoggingIn] = useState<boolean>(false);

  const classes = useStyles();

  const submitLogin = (values: Values, helpers: FormikHelpers<Values>) => {
    const user: User = {
      email: values.email,
      password: values.password,
    };

    setLoggingIn(true);
    login(user);
  };

  const closeSnack = () => {
    setLoggingIn(false);
  };
  return (
    <div className="screen-container profile">
      <Paper elevation={2}>
        <ScreenTitle title="Iniciar sesión" />
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={schemas.LoginSchema}
          onSubmit={submitLogin}
        >
          {({ values }) => (
            <Form className="form">
              <Field
                className="form-row"
                name="email"
                component={FormTextField}
                type="text"
                placeholder="Correo electrónico"
                value={values['email']}
              />
              <Field
                className="form-row"
                name="password"
                component={FormTextField}
                type="password"
                placeholder="Contraseña"
                value={values['password']}
              />
              <div className="many-actions-row">
                <Link onClick={() => history.push('reset-password')}>Reiniciar contraseña</Link>
                <Button type="submit" color="primary" variant="contained">
                  Ingresar
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <Divider className={`${classes.divider} divider`} />
        <Typography className={classes.registerText} variant="subtitle2">
          ¿Aún no tienes una cuenta? <Link href="/register">Regístrate aquí</Link>
        </Typography>
        <CustomSnackbar
          open={loggingIn && auth.logInErrorMessage !== null}
          message={auth.logInErrorMessage!}
          handleClose={closeSnack}
          type={Status.ERROR}
        />
      </Paper>
    </div>
  );
}

export default connector(Login);
