import { createStyles, Divider, Link, makeStyles, Typography } from '@material-ui/core';
import { ScreenTitle } from '../../components/ScreenTitle';

const useStyles = makeStyles(() =>
  createStyles({
    paragraph: {
      margin: '1rem 0',
    },
    item: {
      fontWeight: 'bold',
    },
    mainTitle: {
      margin: '1.5rem 0',
      textDecoration: 'underline',
      textAlign: 'center',
    },
    title: {
      margin: '1.5rem 0',
      textAlign: 'center',
    },
    list: {
      paddingLeft: '1rem',
    },
  }),
);

function TermsAndConditions() {
  const classes = useStyles();

  return (
    <>
      <ScreenTitle title=" CONDICIONES GENERALES Y PARTICULARES DE CONTRATACIÓN PARA EMPRESAS ADHERIDAS AL SISTEMA DE OCA S.A." />
      <Divider className="screen-divider" />

      <Typography className={classes.paragraph}>
        Las presentes condiciones generales y particulares de contratación (en adelante, las “Condiciones”)
        son aplicables a las relaciones entre OCA S.A. (en adelante, “OCA”) en su rol de Adquirente y las
        empresas o comercios adheridos al Sistema de OCA (en adelante, las “Empresas Adheridas”).
      </Typography>
      <Typography className={classes.paragraph}>
        A todos los efectos que correspondan, el término “Empresa Adherida” o “Empresas Adheridas” comprende a
        todas las personas, físicas o jurídicas, que acepten los Medios de Pago Electrónicos (según se define
        más adelante). En caso de que la Empresa Adherida no esté de acuerdo con las presentes Condiciones, la
        Empresa Adherida no podrá operar con OCA ni aceptar como medio de pago los Medios de Pago.
      </Typography>

      <Typography variant="h5" component="h2" className={classes.mainTitle}>
        I. CONDICIONES GENERALES
      </Typography>
      <Typography variant="h6" component="h3" className={classes.paragraph}>
        1 - OBJETO
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>1.1. Objeto:</span> Por medio de la aceptación de las presentes
        Condiciones, las Empresas Adheridas están facultadas para aceptar y recibir pagos de parte de los
        Usuarios de Medios de Pago Electrónicos emitidos por Sellos locales y/o internacionales, o por Medios
        de Pago emergentes de cualquier otro sistema de pagos, con los cuales OCA haya suscrito convenios, los
        que se detallan en el Formulario de Adhesión a que refiere el numeral 1.2, quedando sujeta a las
        siguientes Condiciones. Las Empresas Adheridas solamente aceptarán pagos por parte de los Usuarios
        cuando se cumplan todos los requisitos establecidos en las presentes Condiciones.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>1.2. Formulario de Adhesión:</span> Las Empresas Adheridas que deseen
        contratar con OCA deberán adherir a las presentes Condiciones mediante la suscripción del Formulario
        de Adhesión. El Formulario de Adhesión deberá ser completado por la Empresa Adherida, ingresando todos
        los datos personales y de identificación requeridos por el Formulario de Adhesión, que se adjunta como
        ANEXO I.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>1.3. Comienzo de la adhesión:</span> El carácter de Empresa Adherida
        comenzará a partir de la aceptación de las presentes Condiciones y con la habilitación realizada por
        OCA para que la Empresa Adherida pueda operar bajo su N° de documento (RUT o el que corresponda) con
        el o los códigos de Empresa Adherida que OCA le otorgue al momento de la firma del Formulario de
        Adhesión o los que se agreguen con posterioridad y a solicitud de la Empresa Adherida.
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        2 - REPRESENTANTES
      </Typography>
      <Typography className={classes.paragraph}>
        La Empresa Adherida declara que sus representantes son los denunciados en el Formulario de Adhesión
        (en adelante, los <b>“Representantes”</b>). Los actos que realicen los Representantes obligarán a la
        Empresa Adherida hasta que se notifique a OCA por escrito de la cesación del cargo, su revocación,
        renuncia y/o limitación al mismo.
      </Typography>
      <Typography className={classes.paragraph}>
        Todo cese o cambio de los Representantes, así como la inscripción de los mismos en el Registro
        Nacional de Personas Jurídicas, Sección Comercio (de conformidad con lo dispuesto por el artículo 86
        de la Ley 16.060), así como cualquier cambio en su razón social, nombre de fantasía, domicilio,
        teléfono, conformación de la sociedad y/o rubros en que opera deberá ser notificado al Adquirente
        luego de ocurrida la variación en forma fehaciente, por escrito, en forma electrónica o por los
        canales que OCA establezca para esto. La notificación no se considerará válida si no es recibida por
        parte de OCA mediante acuse de recibo en todas sus formas manual o electrónica o a través del servicio
        disponible especialmente para estos trámites en el sitio Web de OCA al que la Empresa Adherida accede
        mediante su usuario y contraseña. En el caso de que tal modificación no sea notificada, la
        responsabilidad emergente por la falta de ésta y los inconvenientes que acarree será exclusiva de la
        Empresa Adherida.
      </Typography>
      <Typography className={classes.paragraph}>
        Mientras OCA no haya acusado recibo de los cambios de los Representantes, podrá tener por válidos y
        vigentes los Representantes anteriormente denunciados por la Empresa Adherida. En consecuencia, serán
        válidos, eficaces y oponibles a terceros todos los actos celebrados por los Representantes, aunque
        éstos hubieran sido cesados o modificados y dicho cese o modificación se hubiera inscripto. En el caso
        que algún tercero desconozca la existencia, validez o eficacia de actos celebrados en nombre de la
        Empresa Adherida por los Representantes basado en la falta de inscripción antedicha, la Empresa
        Adherida será responsable frente a OCA de las consecuencias que de ello se deriven.
      </Typography>
      <Typography variant="h6" component="h3" className={classes.paragraph}>
        3 - DEFINICIONES
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>Adquirente:</span> Es OCA S.A., entidad debidamente autorizada por los
        Sellos, quien tiene a cargo la adhesión de las Empresas Adheridas y demás prestadores de servicio, que
        se rige por los derechos y facultades establecidos en estas Condiciones.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>Anulación:</span> Es una operación que permite anular operaciones de
        compra y/o de devolución; debe ser realizada antes que la Empresa Adherida efectúe el cierre de lote
        correspondiente a las operaciones de Captura de Datos (Medio de Pago Electrónico), en el que se
        encuentra la Transacción original.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>Autorización:</span> Aprobación por parte de un Emisor de Medios de
        Pagos Electrónicos o de un tercero designado por éste, de las Transacciones de compra de bienes o
        servicios realizadas por Usuarios de Medios de Pago Electrónicos.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>Comisión:</span> Es la suma de dinero que la Empresa Adherida debe a
        OCA por el Servicio de Enrutamiento de Transacciones y servicio de pago de Transacciones en su rol de
        Adquirente y que se establece en la cláusula Séptima de las presentes Condiciones.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>Comprobante de Compra o Cupón:</span> En las Transacciones con Medios
        de Pago Presente, es el comprobante (cupón) que se confecciona en el momento de la venta y que
        contiene datos requeridos por OCA para la Autorización de la Transacción. Puede ser emitido por una
        Terminal de Captura electrónica o en forma manual (en este último caso aplica solo a tarjetas de
        crédito). En transacciones con otras modalidades puede ser un comprobante electrónico donde se
        documenta la Transacción realizada con el Medio de Pago.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>Contracargo:</span> Es cualquier reclamación o impugnación respecto de
        las Transacciones realizadas por los Usuarios de Medios de Pago Electrónicos ante el emisor de Medios
        de Pago Electrónicos.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>Devolución:</span> Es una transacción por la cual se le reembolsan los
        fondos a un Usuario que haya entregado en devolución los bienes o servicios adquiridos en la Empresa
        Adherida. A diferencia de la Anulación la Devolución es considerada como una transacción nueva y no
        está ligada a una Transacción de compra realizada previamente, y debe ser realizada después que la
        Empresa Adherida efectúe el cierre de lote correspondiente a las operaciones de Captura de Datos
        (Medio de Pago Electrónico), en el que se encuentra la Transacción original.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>Emisor o Entidad Emisora:</span> Es quien, bajo su exclusiva
        responsabilidad, otorga un Medio de Pago al Usuario, de quien percibirá los importes correspondientes
        a los consumos realizados en las Empresas Adheridas dentro de los parámetros fijados. A su vez y
        dentro del Sistema, es la que debe proveer los fondos para pagar a la Empresa Adherida el total de los
        consumos efectuados mediante los Medios de Pago por él emitidos.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>Empresa Adherida:</span> Es la que integra el Sistema aceptando los
        Medios de Pago Electrónicos para cancelar las ventas realizadas y/o servicios prestados a los
        Usuarios. Presentará las Transacciones para el procesamiento ante el Adquirente.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>Enrutamiento:</span> Es el pedido de respuesta a OCA de las
        Transacciones recibidas de las Empresas Adheridas, con la finalidad de que OCA capture los pedidos y
        los transmita (enrutamiento) hacia los Sellos, mediante la utilización de redes propias o de terceros,
        a fin de que las Transacciones sean aprobadas o denegadas, incluyendo mediante el uso de links,
        códigos QR u otros medios.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>Internet:</span> Es la red global mundial y otras redes similares (e
        incluye servicios en línea).
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>Liquidación:</span> Es la determinación de la suma a ser abonada a la
        Empresa Adherida consistente en el cálculo de los importes a pagar, de los conceptos impositivos que
        corresponda aplicar y/o reembolsar conforme la normativa aplicable, y de la deducción de la Comisión y
        otros conceptos que se especifican en la cláusula Séptima, así como en la emisión de documentos
        fiscales.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>Medio de Pago o Medio de Pago Electrónico:</span> Es el instrumento que
        contiene las especificaciones y características definidas por el Emisor para ser utilizado por los
        Usuarios, como medio de pago de consumos de bienes o servicios comercializados en las Empresas
        Adheridas. Son las Tarjetas, tarjetas de débito, tarjetas prepagas, monederos electrónicos o medios de
        pago electrónicos inteligentes, los dispositivos para acceso a cuentas, u otros dispositivos, como por
        ejemplo teléfonos celulares, PIX, distintos a los Medios de Pago Electrónicos que operen como medio de
        pago, respecto de los cuales OCA sea adquirente en la actualidad o en el futuro.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>PIX:</span> Es el sistema de pago instantáneo creado por el Banco
        Central de Brasil.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>Requisitos de Seguridad:</span> Son los requisitos establecidos en la
        cláusula 5.16 de las Condiciones Particulares.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>RESONET:</span> Significa Resonet S.A., Nº de RUT 2155 0038 0016 con
        domicilio en Br. Artigas 1243 y con número de teléfono 26144054.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>Sello:</span> Entidad que ofrece la organización, estructura de
        marketing, normas operacionales y de seguridad para el funcionamiento del Sistema. Asimismo, otorga la
        licencia de uso de la marca y los logos para los emisores, adquirentes y empresas adheridas.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>Sistema:</span> Es el que interconecta a las entidades que participan
        del sistema de pagos, permitiendo a los Usuarios de los Medios de Pago que OCA adquiera, realizar
        compras en todas las Empresas Adheridas. Las entidades que participan del mismo son Emisores,
        Adquirente, los Sellos, las Empresas Adheridas y las entidades que presten servicios homologados y
        habilitados por el Adquirente y/o el Sello, o que administren un sistema de pagos de cualquier
        naturaleza.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>Tarifario:</span> Son todas las Comisiones, tarifas, precios, seguros u
        otros importes necesarios para mantener, utilizar, ampliar o modificar los servicios prestados a la
        Empresa Adherida.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>Tarjetas:</span> Son las tarjetas de crédito emitidas por OCA y las
        emitidas por sistemas locales e internacionales con los cuales OCA haya suscrito convenios.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>Terminal de Captura:</span> También denominada "terminal de punto de
        venta" o "POS", es en sentido amplio, todo aparato o dispositivo informático u otra modalidad de
        captura que mediante un programa de procesamiento de información es utilizado por las Empresas
        Adheridas para obtener Autorizaciones en línea y presentar las Transacciones al cobro en forma
        electrónica.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>Transacción Autorizada:</span> Es la Transacción que cuenta con una
        Autorización.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>Transacciones:</span> Son las operaciones de pago generadas por los
        Usuarios de Medios de Pago Electrónicos en las Empresas Adheridas. También incluirá las operaciones de
        CashBack establecidas en estas Condiciones.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>Transacciones con Medios de Pago Electrónicos No Presente:</span>{' '}
        Transacciones generadas en virtud de operaciones realizadas en modalidad de comercio electrónico, o
        cualquier otra modalidad de enrutamiento automático que no requiera la presencia física del Medio de
        Pago Electrónico.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>Transacciones con Medios de Pago Electrónicos Presente:</span>{' '}
        Transacciones generadas en virtud de operaciones realizadas a través de una Terminal POS, con lectura
        de los datos grabados en la banda magnética, el chip o cualquier otro dispositivo con el que cuente el
        Medio de Pago Electrónico.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>Usuario:</span> Es aquella persona física o jurídica, titular o
        adicional de un Medio de Pago, mediante el cual puede realizar compras de bienes o servicios en las
        Empresas Adheridas.
      </Typography>

      <Typography className={classes.paragraph}>
        Para la interpretación de estas Condiciones, serán de aplicación las siguientes reglas, a menos que
        claramente se indique otra cosa:
        <br />
        (i) Las palabras en singular incluyen el plural y viceversa y las palabras en femenino el masculino y
        viceversa;
        <br />
        (ii) Los encabezados son para facilitar la lectura de las Condiciones, pero no deben afectar la
        interpretación de las Condiciones;
        <br />
        (iii) En todas las oportunidades que se hace referencia a que OCA tiene una facultad, poder, derecho,
        autorización, potestad o similar, debe entenderse que OCA tiene un derecho, el cual puede ser ejercido
        por OCA o no, a juicio de OCA y en ningún caso debe interpretarse que OCA tiene una obligación o deber
        (salvo que así lo indicara la normativa aplicable);
        <br />
        (iv) En cualquier situación en que la legislación aplicable prohibiera, declarare nulo o de otra forma
        limitare un derecho que se encuentra contenido en las presentes Condiciones, la cláusula aplicable
        debe ser leída como si fuera precedida por la siguiente frase: “en la medida que fuere permitido por
        la legislación aplicable”;
        <br />
        (v) Las palabras “incluye” e “incluyendo” deben considerarse que se encuentran seguidas de las
        palabras “sin encontrarse limitado(a) a”;
        <br />
        (vi) Las referencias tales como “aquí previstas”, “por el presente” y similares, deben entenderse como
        referencias a todas las Condiciones y no únicamente a una cláusula de las mismas;
        <br />
        (vii) A los efectos de calcular los plazos previstos en el Contrato, siempre que se use la palabra
        “desde” o “a partir” implica que debe contarse el plazo o evento desde e incluyendo dicha fecha,
        cuando se usa la palabra “siguiente” debe contarse el plazo desde el día posterior al evento al que se
        hace referencia, cuando se usa la palabra “hasta” significa hasta e inclusive;
        <br />
        (viii) Si la fecha de vencimiento del cumplimiento de una obligación fuera un día distinto a un día
        hábil, dicha obligación será exigible el primer día hábil siguiente, pero los intereses, costos y
        cargos se continuarán devengando durante el periodo entre el día inhábil y el primer día hábil
        siguiente.
      </Typography>
      <Typography variant="h6" component="h3" className={classes.paragraph}>
        4 - ACEPTACIÓN DE MEDIOS DE PAGO Y PAGOS
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>4.1. Aceptación:</span> Las Empresas Adheridas solamente aceptarán
        pagos por parte de los Usuarios cuando se cumplan todos los requisitos establecidos en las presentes
        Condiciones. La Empresa Adherida se abstendrá de operar con productos distintos de los autorizados en
        estas Condiciones, así como de utilizar el código asignado para formas de operar diferentes de las
        autorizadas. OCA no asume responsabilidad alguna por las Transacciones que no cumplan los requisitos
        de aceptación.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>
          4.2. Características de mercaderías, productos, bienes y/o servicios:
        </span>{' '}
        Las mercaderías y los servicios que adquieran los Usuarios deben ser de libre comercialización en las
        Empresas Adheridas que los expendan o presten, no pudiendo estar afectados por ninguna restricción,
        embargo o indisponibilidad, ni sujeta a condición alguna, ni afectadas a previa conformidad,
        asentimiento o cesión por parte de terceros. La Empresa Adherida deberá suministrar a los Usuarios en
        forma cierta y objetiva, información veraz, detallada, eficaz y suficiente sobre las características
        esenciales de los productos y/o servicios comercializados y, cumplir con todas las disposiciones de la
        legislación vigente. La Empresa Adherida deberá mantener las condiciones de las ofertas realizadas a
        los Usuarios por el término indicado en ellas. Queda entendido que OCA no asume responsabilidad alguna
        por calidad, cantidad, marca, devoluciones, servicios no prestados, o cualquier otro asunto o reclamo
        referido a las mercaderías, productos, bienes y/o servicios adquiridos mediante la utilización del
        Medio de Pago Electrónico.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>
          4.3. Tratamiento en precios de venta. Montos mínimos. Medios de Pago Electrónicos:
        </span>{' '}
        La Empresa Adherida se obliga a no poner mínimos de compra y a mantener los mismos precios que rijan
        para las transacciones de contado (incluso en liquidaciones, ofertas especiales, saldos, etc.) en las
        operaciones que se realicen por medio de este sistema en todos los planes de venta. La Empresa
        Adherida deberá respetar e incluir los descuentos o ventajas que ofrezca al público en general y se
        obliga a no ofrecer descuentos a los Medios de Pago Electrónico ni beneficios de tipo alguno para
        obtener de éstos el pago en efectivo.
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        5 - CONDICIONES DE UTILIZACIÓN DE MEDIOS DE PAGOS
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>5.1. Utilización de Medio de Pago Electrónico:</span> La Empresa
        Adherida deberá en todos los casos dar cumplimiento a las normas establecidas en las presentes
        Condiciones para la utilización de Medio de Pago Electrónicos respecto de la operativa de que se
        trate. En los casos de Transacciones con Medios de Pago Electrónicos Presente, la Empresa Adherida
        deberá controlar que los Medio de Pago Electrónico reúnan las características y sean utilizadas
        conforme lo establecido a continuación.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>5.2. Características de los Medios de Pago Electrónicos:</span> Los
        Medios de Pago contendrán las características y los elementos que los Emisores establezcan según el
        tipo de Medio de Pago de que se trate.
      </Typography>

      <Typography className={classes.paragraph}>
        En el caso de las Tarjetas, constará con caracteres impresos y/o grabados al menos los siguientes
        datos:
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol className={classes.list}>
          <li>(i) el logotipo de la marca,</li>
          <li>(ii) el nombre del Emisor,</li>
          <li>(iii) eventualmente, foto y firma de su titular,</li>
          <li>(iv) el número de tarjeta,</li>
          <li>(v) el nombre del titular,</li>
          <li>(vi) su vigencia,</li>
          <li>(vii) el código de seguridad,</li>
          <li>(viii) una banda magnética, un microchip u otros dispositivos;</li>
          <li>(ix) eventualmente un panel con la firma de su titular.</li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        El Adquirente y/o los Emisores se reservan el derecho de modificar o ampliar estas características de
        las Tarjetas, comunicándolo a las Empresas Adheridas.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>5.3. Condiciones de utilización:</span> La Empresa Adherida deberá dar
        cumplimiento a los siguientes recaudos, salvo cuando fuera dispuesto en forma diferente a lo largo de
        las presentes Condiciones:
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol type="a" className={classes.list}>
          <li>
            <b>Verificación de identidad:</b> Es deber de toda Empresa Adherida verificar la identidad del
            portador del Medio de Pago Electrónico en todos los casos. En caso de Transacciones con Medios de
            Pago Electrónico Presente, si el Medio de Pago Electrónico tuviera una fotografía, el deber de
            identificación incluirá la comparación de la misma con la persona que exhiba el Medio de Pago
            Electrónico. Debe exigir en todos los casos la exhibición de documento de identidad o pasaporte,
            verificando que el nombre y apellido grabados en el Medio de Pago Electrónico concuerden con los
            registrados en el documento exhibido por el usuario.
          </li>
          <li>
            <b>Intransferibilidad:</b> El Medio de Pago Electrónico es intransferible, y sólo es su tenedor
            legítimo la persona a cuyo nombre ha sido otorgada, no pudiendo ser exhibida ni utilizada por
            terceros, ni siquiera por sus representantes legales o mandatarios.
          </li>
          <li>
            <b>Vencimiento del Medio de Pago Electrónico:</b> Es deber de la Empresa Adherida, en los casos de
            Transacciones con Medio de Pago Electrónico Presente, verificar si la fecha de vencimiento grabada
            en el Medio de Pago Electrónico está vencida al día de la Transacción.
          </li>
          <li>
            <b>Autorización:</b> En todos los casos, la Empresa Adherida deberá solicitar autorización previa
            a OCA por todas las Transacciones que se realicen con los Medios de Pago Electrónicos. El código
            de Autorización otorgado, en los casos de Transacciones donde se genere comprobante de compra,
            deberá ser incluido en este comprobante.
            <br />
            Si la Empresa Adherida tuviera alguna sospecha con respecto a una Transacción deberá comunicarse
            con el Adquirente para notificar que se trata de una Transacción sospechosa y aguardar sus
            instrucciones.
            <br />
            Es deber de la Empresa Adherida informar inmediatamente al Adquirente la cancelación de la
            Autorización concedida en todos los casos que, por las razones que fuere, el Usuario no realizare
            la operación, atento a que ello implica la reducción de su límite de compra. La omisión de la
            información responsabilizará a la Empresa Adherida por el daño que el Usuario sufriere en razón de
            la lesión por reducción de su límite de compra.
            <br />
            Cuando lo estime conveniente y especialmente por razones de seguridad, el Adquirente podrá
            requerir a la Empresa Adherida que ponga en contacto al Usuario con el centro de autorizaciones,
            absteniéndose de realizar comentarios negativos sobre el Usuario y/o el Sistema.
            <br />
            La omisión de cumplimiento de este deber por parte de la Empresa Adherida la hará responsable de
            todos los daños y perjuicios que ello ocasione al Usuario.
          </li>
          <li>
            <b>Firma:</b> La Empresa Adherida deberá verificar que la firma que luce en la tarjeta, cuando la
            misma tiene panel de firma, y cuando la Transacción es una Transacción con Medio de Pago
            Electrónico Presente y con firma del comprobante (no aplica para las Transacciones Sin Contacto),
            concuerde con la realizada por el comprador en el Comprobante de Compra. Si el Medio de Pago
            incluye panel de firmas, pero careciera de la firma del Usuario, la Empresa Adherida antes de
            completar la Transacción deberá requerir al Usuario que la firme dando previo cumplimiento al
            deber de identificación con el documento de identidad y absteniéndose de realizar la operación en
            caso contrario.
          </li>
          <li>
            <b>Completar el Cupón:</b> Deberá completar el Cupón manual o electrónico como mínimos con los
            siguientes campos: fecha, moneda, importe, plan de venta. En el caso de Cupones manuales, posible
            sólo para el uso de Tarjetas, se debe incluir también el N° de Tarjeta.
          </li>
          <li>
            <b>Datos fiscales y administrativos:</b> La Empresa Adherida deberá hacer constar en el Cupón, en
            todos los casos, los datos exigidos por las disposiciones tributarias o legales.
          </li>
          <li>
            <b>Otros requisitos:</b> La Empresa Adherida deberá dar cumplimiento a todo otro requisito
            dispuesto a lo largo del presente y/o circulares y/o notas informativas que el Adquirente pueda
            enviarle para cada una de las distintas formas de operar.
          </li>
          <li>
            <b>Lectura obligatoria:</b> La lectura de la banda magnética y/o de chip y/u otros dispositivos
            electrónicos que tenga la Tarjeta es obligatoria, si no fuera posible obtener la lectura de estos
            dispositivos, la Empresa Adherida deberá confeccionar un Cupón en forma manual, siempre que el
            Medio de Pago sea una Tarjeta, y no aplica cuando el Medio de Pago es una tarjeta de débito o
            prepaga.
          </li>
        </ol>
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>5.4. Medio de Pago Electrónico adulterado:</span> La Empresa Adherida
        se abstendrá de operar si el Medio de Pago Electrónico y/o el documento de identidad presentaren
        alteraciones o "prima facie" no correspondieren al titular del Medio de Pago Electrónico.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>5.5. Desdoblamiento de operaciones:</span> La Empresa Adherida deberá
        incluir en un único Cupón el importe total correspondiente al precio de los bienes y/o servicios
        incluidos en la Transacción, sin subdividir la operación de venta en varios Cupones.
      </Typography>

      <Typography className={classes.paragraph}>
        Se exceptúan de lo dispuesto anteriormente a aquellas Empresas Adheridas expresamente autorizadas por
        el Adquirente para operar bajo la modalidad de remisión de mercadería o prestación de servicios con
        posterioridad a su perfeccionamiento. En dichos casos podrán realizarse dos Cupones diferentes, uno
        como depósito y el restante en pago del saldo. En el caso de operaciones realizadas en Terminales de
        Captura, las mercaderías o los servicios que se facturen individualmente a la misma cuenta (por
        ejemplo, pasajes aéreos) no se considerarán una violación de estas Condiciones cuando se procese un
        comprobante dividido, si se obtiene autorización aparte para cada una de las mercancías o servicios.
      </Typography>

      <Typography className={classes.paragraph}>
        En los casos en que se presenten varios Medios de Pago Electrónico para abonar el pago de una sola
        Transacción, no se aplicará la prohibición anterior.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>5.6. Préstamos de dinero:</span> La Empresa Adherida se obliga a no
        recibir pagos de ningún titular de Tarjeta respecto a bienes o servicios que fueran documentados en un
        Cupón de venta a favor de OCA; ni aceptar cambio de cheques, salvo autorización expresa y por escrito
        del Adquirente. Queda exceptuado la entrega de adelantos de efectivo que se realiza al Usuario como
        parte de una Transacción de compra, para los Medios de Pago del tipo Tarjetas de Débito (CashBack).
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>5.7. Refinanciación-Cancelación de operaciones:</span> Tampoco podrá la
        Empresa Adherida aceptar pagos de los Usuarios en concepto de cancelación o refinanciación parcial o
        total de deudas contraídas con alguno de los integrantes del Sistema.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>5.8. Devolución de compra:</span> La Empresa Adherida deberá abstenerse
        de recibir pagos de Usuarios por mercaderías o servicios incluidos en el Cupón, salvo en el caso
        previsto en el párrafo siguiente. Toda devolución o ajuste del importe de Transacciones realizadas con
        Medio de Pago Electrónico, en una Terminal de Captura, deberá ser efectuada únicamente a través de una
        Terminal de Captura, salvo excepciones podrá realizarse con un formulario manual proporcionado por el
        Adquirente para estos efectos, donde se identifique la fecha del Cupón original, así como el monto del
        crédito, con suficiente detalle para que se pueda identificar claramente la Transacción original.
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida toma conocimiento que toda devolución o ajuste realizada será procesada y
        descontada de su Liquidación, asumiendo toda responsabilidad por los daños y perjuicios que ello
        ocasione. Las Devoluciones deberán realizarse y presentarse al cobro en los plazos y condiciones
        vigentes a la fecha de su presentación. En los casos de Devoluciones de compra aplicará las mismas
        condiciones que están vigentes para la compra, los que podrán ser modificados por el Adquirente en
        cualquier momento.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>5.9. Cesión de Cupones:</span> Los cupones respaldatorios de las
        Transacciones efectuadas por los comercios adheridos no podrán ser traspasados ni comercializados a
        terceros, salvo aquellas operaciones que se documenten en contratos de Cesión de Créditos, los cuales
        deberán ser notificados a OCA con las formalidades de estilo y en consonancia con lo dispuesto por los
        artículos 1757 y siguientes del Código Civil, así como artículos 564 y 565 del Código de Comercio.
      </Typography>

      <Typography className={classes.paragraph}>
        También está expresamente prohibido a la Empresa Adherida presentar al cobro operaciones realizadas
        fuera de los límites geográficos del país en el cual se afilió al Sistema o transferir los Cupones
        correspondientes a Transacciones realizadas en dicho país para ser cobradas por otra Empresa Adherida
        fuera del mismo.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>5.10. Moneda:</span> Las Transacciones pueden realizarse en moneda
        local o en dólares estadounidenses o en cualquier otra moneda que sea especialmente habilitada por el
        Adquirente, a excepción del Medio de Pago PIX, que solamente tendrá habilitadas Transacciones en
        dólares estadounidenses. OCA podrá autorizar, a su solo criterio, transacciones en otras monedas para
        el Medio de Pago PIX. La Empresa Adherida deberá abstenerse de realizar conversiones entre dichas
        monedas o cualquier otra diferente de la consignada en el Cupón.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>5.11. Operaciones irregulares o defectuosas:</span> Son causas
        legítimas de rechazo de operaciones, no asumiendo las entidades del Sistema ni el Adquirente
        responsabilidad alguna a su respecto, y sin perjuicio del derecho de la Empresa Adherida con respecto
        a terceros por la venta efectuada, las establecidas a continuación:
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol type="a" className={classes.list}>
          <li>
            No se hubiera verificado la identidad del Usuario cuando se trata de Transacciones con Medios de
            Pago Electrónico Presente y con contacto;
          </li>
          <li>Se hubiere realizado desdoblamiento de operaciones;</li>
          <li>
            Operaciones realizadas con Medio de Pago Electrónico fuera de vigencia. Los Medios de Pago
            Electrónico son válidos desde el primer día del mes de inicio de su vigencia hasta el último día
            del mes de sus respectivos vencimientos, inclusive;
          </li>
          <li>
            Hubieren transcurrido más de quince (15) días corridos entre la fecha de venta consignada en el
            Cupón y la presentación al Adquirente. En casos excepcionales este plazo podrá extenderse hasta 30
            días hábiles contados de la fecha de transacción hasta el día del depósito inclusive, previa
            autorización de OCA;
          </li>
          <li>
            No se hubiera requerido autorización o no coincida la clave de autorización que consta en el
            Comprobante de Compra con la concedida y registrada en el centro de autorizaciones, o difiera la
            operación autorizada con la que figura en el Comprobante de Compra (ya sea en el importe, código
            de Usuario, fecha o cantidad de cuotas);
          </li>
          <li>Faltara informar en el Cupón de los datos exigidos por disposiciones tributarias o legales;</li>
          <li>
            Incumplimiento de las normas operativas detalladas en estas Condiciones u otras que se comuniquen
            a la Empresa Adherida en forma fehaciente o incumplimiento de la legislación vigente;
          </li>
          <li>
            Transacción presentada al cobro bajo un número de Empresa Adherida distinto al de su realización;
          </li>
          <li>Préstamo de dinero no autorizados en Empresas Adheridas;</li>
          <li>
            Cuando en el caso de Transacciones manuales los Comprobantes de Compra utilizados no sean los que
            OCA suministre para dichas Transacciones o los aceptados por OCA para los Sellos que adquiera;
          </li>
          <li>
            Cuando la operación haya sido efectuada por P.O.S. y el ingreso del número del Medio de Pago se
            haya realizado por teclado sin leer la banda magnética, el chip o el dispositivo electrónico que
            tenga la Medio de Pago Electrónico, salvo los casos en que la Empresa Adherida haya acordado con
            OCA operar en esta modalidad;
          </li>
          <li>
            Cuando una operación fuera de línea (off line) no contenga un número de autorización válido para
            OCA;
          </li>
          <li>
            Si el número en la Tarjeta se encuentra incluido en los archivos de seguridad de los sistemas
            locales y/o internacionales con los cuales OCA suscribe convenios, o si se avisara por cualquier
            otro medio que dicha Tarjeta no debe ser aceptada. En caso de presentarse alguna de las
            situaciones antes indicadas, la Empresa Adherida no aceptará la Tarjeta, la retendrá y se pondrá
            de inmediato en contacto con OCA, haciendo sus mayores esfuerzos para recuperar, retener y
            entregar a OCA la Tarjeta si OCA se lo solicita;
          </li>
          <li>
            Si el Medio de Pago no es auténtico, presenta alteraciones o no está en perfecto estado de
            conservación. La Empresa Adherida se obliga a negar la operación ante cualquier duda sobre la
            autenticidad del Medio de Pago o sobre la correspondencia entre el que realiza la compra y el
            titular del Medio de Pago;
          </li>
          <li>
            No poner mínimos de compra y a mantener los mismos precios que rijan para las transacciones de
            contado (incluso en liquidaciones, ofertas especiales, saldos, etc.) en las operaciones que se
            realicen por medio del Sistema en todos los planes de venta. La Empresa Adherida deberá respetar e
            incluir los descuentos o ventajas que ofrezca al público en general y se obliga a no ofrecer
            descuentos a los Usuarios ni beneficios de tipo alguno para obtener de éstos el pago en efectivo;
          </li>
          <li>
            Cuando en Comprobantes de Compra que requieren firma del Usuario, no constare dicha firma, o la
            misma estuviere enmendada o "prima facie" fuere falsa;
          </li>
          <li>
            Cuando no se completare el Cupón en forma clara o integral, o se hallare enmendado o adulterado;
          </li>
          <li>Falta de homologación o adecuación de la Terminal de Captura;</li>
          <li>
            En caso de diferencias entre el duplicado o triplicado del Cupón en poder del Usuario y el
            original presentado por la Empresa Adherida;
          </li>
          <li>
            En caso de operaciones manuales cuando el Cupón presentase irregularidades perceptibles a simple
            vista, tales como la falta de alineación, continuidad o definición, o diferencias en los tipos,
            tamaños, espacios o caracteres de las letras o números correspondientes a los datos consignados en
            relieve o impresos en el Medio de Pago Electrónico del Usuario, o falta de alguno de estos datos;
            o cuando los Comprobantes de Compra utilizados no sean los que OCA suministre o autorice para
            dichas Transacciones;
          </li>
          <li>
            En caso de Transacciones realizadas en Terminales de Captura cuando
            <ol type="i">
              <li>
                Las Transacciones sean realizadas sin que figuren impresos en el Cupón los datos del Medio de
                Pago Electrónico, nombres, vencimiento y nombre y código de la Empresa Adherida o presentando
                éste alguna de las irregularidades detalladas en puntos anteriores.
              </li>
              <li>
                Cuando la Empresa Adherida a la que habiéndosele solicitado los Cupones originales no los
                hubiere presentado al Adquirente dentro de los plazos establecidos.
              </li>
              <li>
                Cuando existan operaciones especiales desconocidas por el Usuario o presentadas fuera del
                plazo autorizado.
              </li>
              <li>
                Cuando haya falta de captura de los últimos 4 dígitos u otros datos adicionales del Medio de
                Pago Electrónico cuando el Adquirente lo solicitara.
              </li>
            </ol>
          </li>
          <li>
            En caso de Transacciones de débito automático y Transacciones con Medios de Pago Electrónico No
            Presente referidas en las Condiciones Particulares
            <ol type="i">
              <li>Impugnación de Transacción por el Usuario.</li>
              <li>Autorización solicitada en forma irregular.</li>
              <li>
                Falta de remisión, total o parcial, de documentación respaldatoria dentro de los 5 días de
                requerida;
              </li>
              <li>
                Ausencia de constancia documentada y suficiente, a criterio del Adquirente, del consentimiento
                del Usuario.
              </li>
              <li>
                Ejercicio por el Usuario del derecho de arrepentimiento establecido en la Ley No. 17.250 o su
                falta de otorgamiento por la Empresa Adherida.
              </li>
              <li>
                Falta de cumplimiento de las formalidades legales para el perfeccionamiento de la Transacción
                o en la documentación respaldatoria.
              </li>
              <li>
                Falta de cumplimiento de las disposiciones de seguridad fijadas por el Adquirente, en
                Transacciones con Medios de Pago Electrónico No Presente.
              </li>
              <li>Operación bajo modalidades no autorizadas.</li>
            </ol>
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        Excepciones: (Venta no Presencial – Venta Telefónica). OCA podrá aceptar que ciertas Empresas
        Adheridas acepten Transacciones con Medios de Pago Electrónico No Presente o Usuarios que apliquen las
        excepciones establecidas en los literales k y l, anteriores. Las Empresas Adheridas interesadas en
        aceptar Transacciones con Medios de Pago Electrónico No Presente o Usuarios que no cumplan con lo
        mencionado, deberán indicar en el Formulario de Adhesión, en el punto de Canales de Venta, la
        modalidad No presencial (venta telefónica), los números de comercio y las terminales utilizadas con
        esta modalidad de venta. La Empresa Adherida comunicará a OCA la totalidad de las ventas efectuadas
        con las excepciones mencionadas en la presente cláusula a través de alguno de los mecanismos
        establecidos en la cláusula 16. La Empresa Adherida asume la total responsabilidad por los daños y
        perjuicios que puedan derivarse, directa o indirectamente, de la falta de realización de los controles
        indicados en la presente cláusula, ya sea de OCA o de terceros, y por los bienes y servicios cuya
        compra u adquisición no fuera reconocida por los Usuarios. Asimismo, la Empresa Adherida se obliga
        desde ya a mantener indemne a OCA, frente a cualquier reclamo, judicial o extrajudicial, derivado de
        la falta de controles mencionados o de los bienes y servicios cuya compra u adquisición no fuera
        reconocida por los Usuarios. En virtud de lo anterior, la Empresa Adherida autoriza expresamente a OCA
        a compensar la totalidad de los importes que se reclamen por los Usuarios o se generen en virtud de lo
        dispuesto en la presente cláusula con cualquier saldo a favor que tenga la Empresa Adherida con OCA.
        En caso de que no existan sumas disponibles para compensar, OCA podrá compensar dichas sumas con
        cualquier importe futuro que la Empresa Adherida tenga derecho a percibir. Sin perjuicio de ello, la
        Empresa Adherida se obliga desde ya a abonar en efectivo las diferencias o saldos pendientes que
        pudieran existir.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>5.12. Impugnación de Transacciones:</span> Impugnada una operación por
        el Usuario, la Empresa Adherida deberá enviar al Adquirente, dentro de los cinco días corridos de
        recibido el pedido, la devolución de la compra o en su defecto las explicaciones y documentación que
        correspondan por escrito, en medio fehaciente y firmado por un Representante de la Empresa Adherida,
        de forma tal que la misma pueda ser entregada al Usuario para su análisis. A dicho escrito deberá
        adjuntar, según el caso, copia del remito, y demás documentación que acredite el perfeccionamiento de
        la Transacción y la entrega de los productos, tales como cupón de pedido (en casos de venta por
        correspondencia), comprobante de conformidad del servicio, etc. La sola presentación de una
        impugnación de la Transacción por el Usuario, habilita a OCA a realizar un análisis y solicitar a la
        Empresa Adherida toda la información probatoria de la misma, pudiendo concluirse con el descuento
        automático a la Empresa Adherida, de los importes acreditados y desconocidos por el Usuario.
      </Typography>
      <Typography variant="h6" component="h3" className={classes.paragraph}>
        6 - PROCEDIMIENTO
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida deberá solicitar autorización previa a OCA para todas las Transacciones que se
        realicen con los Medios de Pago.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.1. Tipo de transacciones:</span> Los tipos de transacción que se
        detallan a continuación aplican a todos los Medios de Pago.
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol type="A" className={classes.list}>
          <li>
            <b>Compra:</b> Una compra es una Transacción por la cual un Usuario cancela, a través de la
            utilización de su Medio de Pago Electrónico, una deuda generada por la adquisición de un bien o
            servicio en una Empresa Adherida.
          </li>
          <li>
            <b>Devolución:</b> Una Devolución es considerada como una transacción nueva y no está ligada a una
            Transacción de compra realizada previamente. El monto de la Transacción es debitado por el
            Adquirente a la Empresa Adherida en el plazo de pago pactado.
          </li>
          <li>
            <b>Anulación:</b> Puede haber Anulaciones con día de negocio diferente al de la Transacción
            original, mientras no exista un cierre de lote entre las mismas. El monto de la Transacción es
            debitado o acreditado a la Empresa Adherida por el Adquirente, en el plazo de pago pactado.
          </li>
          <li>
            <b>Preautorización:</b> Una preautorización es una operación mediante la cual la Empresa Adherida
            efectúa una "Reserva de Fondos" por un periodo en la cuenta del Usuario, hasta que se reciba la
            confirmación de la disponibilidad del importe solicitado a través de otra Transacción. Una
            transacción de preautorización efectúa una validación del importe preautorizado, verificando que
            el mismo sea menor o igual que el disponible de la cuenta del Usuario, en cuyo caso se aprueba la
            Transacción, rechazándola en caso contrario. Sólo podrá realizar estas Transacciones la Empresa
            Adherida que cuente con una autorización previa del Adquirente.
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.2. Formas de operar:</span> El pago de las Transacciones de ventas y
        servicios que las Empresas Adheridas realicen a los Usuarios utilizando Medios de Pago Electrónico
        podrán efectuarse a través de diferentes modalidades:
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol type="a" className={classes.list}>
          <li>Con captura de datos</li>
          <li>En forma manual</li>
          <li>Por débito automático</li>
          <li>Venta telefónica</li>
          <li>Comercio electrónico</li>
          <li>A través del celular en las modalidades que el Adquirente/Emisor habilite</li>
          <li>Otros que el Adquirente autorice en el futuro</li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        Los procedimientos para cada una de estas modalidades están especificados en el Capítulo de
        Condiciones Particulares.
      </Typography>

      <Typography className={classes.paragraph}>
        Según la forma de pago de las Transacciones, cabe distinguir:
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol type="a" className={classes.list}>
          <li>Transacciones de contado</li>
          <li>Transacciones en cuotas con o sin intereses</li>
          <li>
            Transacciones de compra con adelanto de dinero (CASHBACK) (sólo aplica para Medios de Pago tipo
            Débito, no a los Medios de Pago tipo Crédito)
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.3. Operativa en Cuotas:</span>
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.3.1.</span> Las Empresas Adheridas especialmente habilitadas al
        efecto podrán vender sus mercaderías y servicios en cuotas, con o sin recargo de financiación y en los
        planes de venta habilitados por el Adquirente. La autorización conferida podrá ser dejada sin efecto
        en cualquier momento por el Adquirente, aún sin necesidad de causa que lo justifique y sin derecho a
        reclamo alguno al Adquirente por parte de la Empresa Adherida.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.3.2. Planes cuotas con recargo por financiación:</span> El precio de
        venta de las mercaderías y servicios deberá ser el mismo vigente para las operaciones en efectivo y de
        contado, al cual deberá adicionar el importe correspondiente al recargo financiero que se calcula en
        función de un coeficiente, de acuerdo al plazo y moneda de venta y que el Adquirente mantiene
        publicado y actualizado en su página Web. La fórmula a utilizar será única como así también las
        alícuotas a aplicar, que serán las que el Adquirente o las entidades comuniquen a las Empresas
        Adheridas periódicamente.
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol className={classes.list}>
          <li>
            <b>a) Descuento del recargo financiero:</b> Del monto total de la operación, se le descontará en
            los planes de venta que corresponda, el importe correspondiente al cargo financiero, además de los
            detallados en la cláusula 7 de las presentes Condiciones. El importe resultante será abonado a la
            Empresa Adherida según las condiciones que mantenga vigentes a la fecha de presentación.
          </li>
          <li>
            <b>b) Limitaciones a la operativa en cuotas:</b> La operativa en cuotas deberá hacerse, en todos
            los casos, con autorización expresa del Adquirente. El Adquirente podrá excluir algunos medios de
            pago de la operativa en cuotas y/o establecer otras limitaciones, lo cual le será notificado a la
            Empresa Adherida al momento de la autorización.
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.3.3. Venta en cuotas sin recargo por financiación:</span> Las
        Empresas Adheridas podrán operar también con ventas en cuota sin recargo, en todos los casos el precio
        de venta de las mercaderías y servicios deberá ser el mismo vigente para las operaciones en efectivo y
        de contado y los planes de venta serán los acordados y habilitados por el Adquirente.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.4. Operaciones de compra con adelanto de dinero – (CASHBACK):</span>
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.4.1. Ámbito de aplicación:</span> Las disposiciones del presente
        capítulo serán de aplicación a aquellas Transacciones realizadas por los Usuarios que operen con
        Medios de Pago Electrónico habilitados a realizar operaciones de Cashback en las Empresas Adheridas
        también habilitadas para esta operativa. Se entiende por CashBack a la operación que habilita a los
        Usuarios a realizar una compra con su Medio de Pago Electrónico de Débito o Prepago (no aplica para
        PIX, Tarjetas u otros medios en modalidad crédito) a retirar dinero en efectivo en las Empresas
        Adheridas. La extracción de fondos estará limitada a un porcentaje sobre el monto de la compra o a un
        importe predeterminado que es fijado periódicamente por OCA, sin necesidad de notificarlo a la Empresa
        Adherida. Toda transacción que supere dicho importe será rechazada.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.4.2. Formas y modalidades de operación:</span> El Usuario con los
        Medios de Pago habilitados puede, al realizar una compra, retirar cierta cantidad de dinero en las
        líneas de cajas. La “extracción” no es un adelanto en efectivo, dado que el Usuario retira fondos,
        previamente depositados, de una cuenta bancaria. El retiro y la compra se realizan en una misma
        operación, son ingresados por separado, pero ambos se encuentran en el mismo Cupón. Se permiten
        Anulaciones y reversas en Transacciones de compra con CashBack. La devolución del IVA habilitada por
        las leyes vigentes sólo aplicará sobre las compras, no sobre el CashBack. El plazo de pago para el
        CashBack a las Empresas Adheridas es de 48 horas hábiles bancarias a partir de la presentación. La
        Comisión aplicará sobre las Transacciones de compra, no así sobre el monto del CashBack. A efectos de
        poder solicitar el CashBack, no se permiten Transacciones mediante operativa manual ni operaciones en
        cuotas ni débito automático.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.4.3. Forma de instrumentación:</span> En cada Transacción, la
        Terminal de Captura deberá emitir un Cupón, por lo menos por duplicado. El original firmado por el
        Usuario o la representación electrónica deberá quedar archivado en la Empresa Adherida en que la
        Transacción se haya celebrado. Su duplicado será entregado al Usuario y servirá como constancia de la
        operación.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.4.4. Guarda de los Cupones:</span> Las Empresas Adheridas deberán
        guardar los originales de los Cupones correspondientes a esta operativa por el término de doce (12)
        meses y estarán obligadas a presentarlos al Adquirente cuando les sean requeridos, dentro del plazo de
        5 (cinco) días corridos a contar desde la fecha de la solicitud. Si no se presentaren los Cupones
        dentro del plazo fijado, el Adquirente podrá descontar a la Empresa Adherida las sumas abonadas en
        concepto de tales Cupones.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.4.5. Presentación de Transacciones al cobro:</span> Las operaciones
        que se efectúen a través de la operativa CashBack, deberán presentarse del mismo modo detallado en la
        cláusula 6.5.5 del presente.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.4.6. Suspensión, modificación o cancelación:</span> El Adquirente y/o
        la Entidad Emisora pueden, en cualquier momento, suspender, modificar o cancelar, total o
        parcialmente, en forma general para todas las Empresas Adheridas o individual para una Empresa
        Adherida determinada, la operativa de CashBack, sin invocar causa alguna y con el solo requisito de
        notificarlo a la Empresa Adherida.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.4.7. Transacciones irregulares o defectuosas:</span> En caso de
        operaciones irregulares o defectuosas, será de aplicación el procedimiento previsto en la cláusula
        Séptima del presente. Sin perjuicio de lo expuesto, producido un reclamo o desconocimiento de un
        Usuario, la Empresa Adherida se obliga a presentar dentro de los 5 días corridos de recibido el pedido
        por parte del Adquirente, toda la información correspondiente a la Transacción cuestionada.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>
          6.5. Medio de Pago Electrónico de Débito y Prepago (incluye producto Maestro Mastercard):
        </span>
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.5.1. Concepto:</span> Medio de Pago Electrónico de Débito e
        instrumentos de dinero electrónico: son Medios de Pago que permiten la realización de Transacciones en
        las Empresas Adheridas, de acuerdo con los términos y condiciones establecidos en el presente
        capítulo. Todas las Transacciones con Medio de Pago Electrónico de débito e instrumentos de dinero
        electrónico son en línea, se canalizan a través de una Terminal de Captura, (en el caso Maestro
        requiere el ingreso de un número de identificación personal confidencial –PIN– por parte del Usuario),
        y son autorizadas por la Entidad Emisora. En el caso de PIX, las Transacciones se canalizan a través
        de una Terminal de Captura que deberá estar conectada en línea y que generará un código QR específico
        para cada Transacción el que será escaneado por el Usuario y deberá ser autorizado por la Entidad
        Emisora. El monto de la Transacción es debitado automáticamente de la cuenta del Usuario, en bancos o
        emisores de dinero electrónico, en el mismo momento en que ocurre, siendo acreditado por el Emisor a
        la Empresa Adherida en el plazo de pago pactado.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.5.2. Firma electrónica:</span> La clave de identificación personal
        (PIN) es un número confidencial de cada Usuario que, junto con su Medio de Pago lo habilita para
        efectuar Transacciones a través de las Terminales de Captura. El PIN reemplaza la firma ológrafa del
        Usuario, constituyendo su equivalente electrónico. La Empresa Adherida deberá abstenerse en todos los
        casos de requerir a los Usuarios la comunicación de su PIN y, de intentar obtener el PIN por cualquier
        otro medio, debiendo permitir su ingreso en forma confidencial y segura en el teclado numérico o PIN
        PAD.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.5.3. Formas y modalidades de Transacción:</span> No se permiten
        Transacciones mediante operatorio manual, ni Transacciones sin lectura de banda, chip u otros
        dispositivos que tenga el Medio de Pago o fuera de línea. No se admiten Transacciones en cuotas. Para
        operar con ventas del tipo débito automático y Transacciones con Medios de Pago Electrónico No
        Presente, se requiere autorización expresa y por escrito del Adquirente.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.5.4. Transacciones disponibles:</span> Los Medios de Pago
        Electrónicos de débito e instrumentos de dinero electrónico están habilitados para realizar en las
        Empresas Adheridas las transacciones detalladas en la cláusula 6.1 y además las que se detallan a
        continuación. Es obligatoria la aceptación de las transacciones de compra, devolución, reversión y
        anulación, además de consulta de saldos en caso de que el Emisor del Medio de Pago referido y/o el
        Adquirente lo habiliten.
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol className={classes.list}>
          <li>
            <b>6.5.4.1. Compra con retiro de fondos (cashback):</b> Descripta en el punto 6.4.
          </li>
          <li>
            <b>6.5.4.2. Consulta de saldos:</b> Una consulta de saldos es una transacción, por la cual un
            Usuario, mediante la utilización de su Medio de Pago Electrónico, obtiene el saldo de su cuenta en
            el banco o empresa emisora de dinero electrónico. Por esta transacción no se emitirá recibo.
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.5.5. Presentación de operaciones al cobro:</span> Todas las
        Transacciones con Tarjetas Maestro o MASTERCRD DEBIT son realizadas a través de Terminales de Captura
        u otros canales que deben estar conectadas en línea, la Empresa Adherida deberá efectuar
        presentaciones a través del cierre de lote de las Terminales o por corte de operaciones. El momento de
        realización de los cortes de red es determinado periódicamente por el Adquirente, sin necesidad de
        informarlo a las Empresas Adheridas.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.5.6. Liquidación y pago:</span> El proceso de liquidación a la
        Empresa Adherida de las transacciones realizadas con los Medios de Pago indicados en este capítulo
        6.5, se llevará a cabo todos los días hábiles bancarios en la República Oriental del Uruguay, mientras
        que la fecha de pago será determinada por el día de negocio de cada Transacción y el plazo de pago
        pactado al momento de la firma del Formulario de Adhesión.
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        7 - COMISIONES Y PAGO DE OPERACIONES
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>7.1. Presentación de operaciones al cobro:</span> La Empresa Adherida
        deberá presentar al Adquirente las constancias de sus ventas a Usuarios en la forma establecida para
        la operativa de que se trate. Las fechas de presentación de operaciones, así como la fecha para la
        contabilización de dichas operaciones para realizar el pago serán establecidas por el Adquirente y
        comunicadas a la Empresa Adherida en el Formulario de Adhesión u otros canales definidos por OCA. La
        Empresa Adherida deberá abstenerse de presentar al cobro operaciones que sabe o debió haber sabido que
        eran irregulares o defectuosas o no autorizadas por los Usuarios.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>7.2. Pago de operaciones:</span> El pago de las Transacciones
        presentadas al cobro por la Empresa Adherida será realizado por OCA, con las excepciones establecidas
        a lo largo del presente. Las Transacciones presentadas al cobro se abonarán, luego de su
        procesamiento, en los plazos y condiciones vigentes a la fecha de su presentación, que podrán ser
        modificadas por el Adquirente en cualquier momento según lo establecido en la cláusula 7.4 siguiente.
        El pago de operaciones irregulares o defectuosas será condicional y no obstará a su posterior
        descuento. El Adquirente pondrá a disposición de la Empresa Adherida, las liquidaciones
        correspondientes a las Transacciones presentadas y procesadas. Cualquier diferencia y/o controversia
        respecto de Transacciones presentadas y acreditadas o no acreditadas, rechazadas y/o descontadas
        deberá ser planteada por la Empresa Adherida dentro de los 60 días de su presentación. Transcurrido
        dicho plazo, las mismas se tendrán por consentidas, produciéndose la caducidad de todo derecho
        respecto de las mismas.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>7.3. Comisiones y cargos:</span> En oportunidad de realizar el pago a
        la Empresa Adherida por las Transacciones procesadas, OCA descontará, del monto total de los
        Comprobantes de Compra entregados por la Empresa Adherida, en concepto de comisión, los porcentajes
        que surgen del tarifario de OCA el que podrá ser diferente para cada Medio de Pago y aún para cada
        producto y/o Sello, el cual se incluye en el Formulario de Adhesión. Adicionalmente, OCA descontará el
        Impuesto al Valor Agregado (IVA) sobre las Comisiones cobradas, así como cualquier otro tributo o
        gravamen que sea establecido en el futuro, y los cargos, intereses y las retenciones o descuentos que
        imponga la ley o un contrato con condiciones legales (cesiones de crédito y/o embargos). Además, la
        Empresa Adherida deberá abonar los recargos de financiación y cargos administrativos por la prestación
        de determinados servicios que fije el Adquirente.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>7.4. Modificaciones al Tarifario:</span> Salvo que algo distinto se
        hubiera previsto expresamente en la normativa aplicable, OCA podrá modificar en cualquier momento en
        forma unilateral el Tarifario, mediante: (i) notificación enviada a la Empresa Adherida con al menos
        30 (treinta) días corridos de antelación a su entrada en vigencia, (ii) cualquiera de los medios de
        comunicación que se prevén en la cláusula 16. La Empresa Adherida tendrá un plazo de 10 (diez) días
        corridos a contar desde la recepción de la comunicación antes referida para presentar sus objeciones
        por escrito a la modificación notificada por OCA. Si la Empresa Adherida no comunica sus objeciones en
        tal plazo, las modificaciones propuestas por OCA quedarán firmes a todos los efectos. Por el
        contrario, presentadas a OCA tales objeciones en tiempo y forma por parte de la Empresa Adherida, ésta
        tendrá un plazo de 5 (cinco) días corridos a contar desde el día siguiente al que recibió tales
        objeciones por parte de la Empresa Adherida, para aceptarlas o rechazarlas. Si dentro de tal plazo,
        OCA no comunica a la Empresa Adherida su aceptación expresa a tales objeciones, las mismas se tendrán
        por rechazadas. En este caso la Empresa Adherida tendrá un plazo de 5 (cinco) días corridos
        adicionales para rescindir las relaciones contractuales con OCA como respuesta a estas nuevas
        condiciones propuestas por OCA; de lo contrario, las nuevas condiciones comunicadas originalmente por
        OCA quedarán firmes y serán vinculantes a todos los efectos.
      </Typography>
      <Typography variant="h6" component="h3" className={classes.paragraph}>
        8 - RETENCIÓN DE PAGO
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>8.1. Descuento de importes pagados:</span> El Adquirente podrá
        descontar mediante débitos o compensaciones y aún con percepción directa o judicial, todas las sumas
        que hubieren abonado a las Empresas Adheridas con relación a operaciones irregulares o defectuosas sin
        perjuicio del derecho de éstas con respecto a terceros y aún Usuarios. El Adquirente podrá exigir a la
        Empresa Adherida que realice las Transacciones exclusivamente a través de Terminales de Captura y
        demás requisitos de seguridad que considere convenientes, cuando el 5% del volumen mensual de las
        operaciones sean irregulares o defectuosas.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>8.2. Daños y perjuicios:</span> El rechazo o descuento de Transacciones
        no eximirá a la Empresa Adherida de su responsabilidad frente a los Usuarios por los daños y
        perjuicios que dichas Transacciones pudieron haberles inferido.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>8.3. Retención de Medios de Pago Electrónicos:</span> Cuando una
        persona presentare un Medio de Pago Electrónico que pudiere presumirse irregular, no acreditare su
        identidad, cuando el Adquirente requiriere la retención del Medio de Pago Electrónico y en los demás
        casos previstos en el presente, la Empresa Adherida empleará sus mayores esfuerzos, utilizando medios
        razonables y pacíficos, para retener tal Medio de Pago Electrónico y entregarlo al Adquirente, dando
        aviso a la autoridad policial competente, en caso de corresponder. La Empresa Adherida se obliga a
        retener los Medios de Pago Electrónico que se le presenten y que se encuentren incluidos en los
        archivos de Medios de Pago Electrónico inhabilitados, así como a inhabilitar físicamente,
        inmediatamente de rescatados, los Medios de Pago Electrónico retenidos. La Empresa Adherida deberá
        comunicar a OCA que ha retenido el Medio de Pago Electrónico, y seguir las instrucciones para su
        remisión al Adquirente. En todos los casos deberá abstenerse la Empresa Adherida de realizar
        comentarios negativos sobre el tenedor del Medio de Pago Electrónico, debiendo aconsejarle la
        comunicación con su Entidad Emisora para recibir las explicaciones del caso.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>8.4. Indemnidad:</span> El Adquirente no será responsable ni
        intervendrá en controversias que la Empresa Adherida tenga con los Usuarios, motivadas en los
        servicios que el mismo presta, en los bienes que comercializa, en las actividades que desarrolla, en
        el monto, composición y/u oportunidad de los débitos efectuados y/o en el cumplimiento o
        incumplimiento de las obligaciones legales y contractuales a su cargo, sino que éstas se sustanciarán
        exclusivamente con la Empresa Adherida, a quien incumbe íntegramente la responsabilidad pertinente. Ni
        el Adquirente ni las entidades del Sistema serán responsables por los daños y perjuicios, multas,
        costos, costas y honorarios derivados de la falta de cumplimiento por la Empresa Adherida de las
        disposiciones anteriores. Será exclusiva responsabilidad de la Empresa Adherida la atención y
        satisfacción de dichos reclamos y/o consultas, debiendo mantenerlos indemnes de los mismos. El
        incumplimiento de las disposiciones anteriores habilitará a quien haya debido afrontar los importes
        reclamados, a descontarlos directamente a la Empresa Adherida en la forma prevista en la cláusula 8.5
        de las presentes Condiciones.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>
          8.5. Diferimiento de pagos. Retención de fondos. Saldo en garantía:
        </span>
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol className={classes.list}>
          <li>
            <b>8.5.1. Diferimiento de pagos:</b> Es facultad del Adquirente condicionar el pago de
            Transacciones irregulares o defectuosas, a su previa cobranza y a la conformidad del titular del
            Medio de Pago Electrónico. El Adquirente podrá en cualquier momento condicionar el pago de
            Transacciones realizadas en Empresas Adheridas pertenecientes a rubros específicos, a la
            prestación del servicio o entrega de la cosa objeto de la Transacción y aún a su previa cobranza y
            a la conformidad del titular del Medio de Pago Electrónico.
          </li>
          <li>
            <b>8.5.2. Retención de fondos:</b> Respecto de operaciones ya liquidadas a la Empresa Adherida o
            razones de seguridad que razonablemente hagan presumir la ocurrencia de un riesgo de daño para
            cualquiera de los integrantes del Sistema, autorizará al Adquirente a retener a la Empresa
            Adherida de presentaciones futuras y/o pendientes de acreditación y/o de cualquier otra suma que
            tenga el Adquirente de la Empresa Adherida, (i) hasta el equivalente al monto de la(s)
            operación(es) impugnada(s); o bien (ii) el total de dichas presentaciones. El plazo de retención
            no podrá exceder los 120 días contados desde la fecha de su instrumentación.
          </li>
          <li>
            <b>8.5.3. Saldo en garantía:</b> El Adquirente en cualquier momento y a su sola discreción podrá
            retener la totalidad o una porción de las liquidaciones de Transacciones de la Empresa Adherida
            y/o modificar el plazo de pago de éstas, e incluso dar de baja del sistema a la Empresa Adherida,
            cuando se den cualquiera de las siguientes situaciones:
            <ol type="i">
              <li>
                La Empresa Adherida no respete el procedimiento descripto en la cláusula 4 de las presentes
                Condiciones.
              </li>
              <li>
                En el caso de que existan operaciones fraudulentas, o sospechosas de serlo a juicio de OCA, o
                meramente cuestionadas por OCA o por los Usuarios.
              </li>
              <li>
                Cuando se configuren irregularidades que ameriten proceder a una investigación policial y/o
                judicial.
              </li>
              <li>Cuando OCA haya pagado ventas erróneamente.</li>
              <li>Impugnación del/de los Usuario(s).</li>
              <li>Incumplimiento de los Requisitos de Seguridad.</li>
            </ol>
            El saldo retenido será reservado para hacer frente a eventuales descuentos de Transacciones y/o
            penalidades, no pudiendo la Empresa Adherida disponer del mismo. El monto del saldo reservado será
            determinado periódicamente por el Adquirente.
          </li>
        </ol>
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        9 - OBLIGACIONES DE LA EMPRESA ADHERIDA
      </Typography>

      <Typography className={classes.paragraph}>
        Además de las obligaciones de la Empresa Adherida establecidas en el resto de estas Condiciones, la
        Empresa Adherida se obliga a:
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol type="a" className={classes.list}>
          <li>
            Proporcionar al Adquirente, en el plazo que éste determine, toda la información que le sea
            solicitada a la Empresa Adherida en relación con la ejecución de las presentes Condiciones.
          </li>
          <li>
            Cumplir en todo momento con las normas en materia de relaciones de consumo, vigentes o a crearse,
            durante toda la vigencia de las presentes Condiciones, y en particular con la Ley N° 17.250 y su
            Decreto reglamentario N° 244/000, sus modificativas y concordantes.
          </li>
          <li>
            Incluir en el sitio web de la Empresa Adherida un detalle de las políticas de entrega de los
            bienes y/o servicios ofrecidos, así como de las garantías accesorias a tales bienes y/o servicios.
          </li>
          <li>
            Cumplir en todo momento durante la vigencia de las presentes Condiciones con la normativa relativa
            a datos personales, presente y futura, en particular con la Ley Nº 18.331 y su Decreto
            Reglamentario Nº 414/009, sus modificativas y concordantes.
          </li>
        </ol>
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        10 - PUBLICIDAD
      </Typography>

      <Typography className={classes.paragraph}>
        <b>Identificación:</b> Las Empresas Adheridas deberán exhibir en lugar destacado de sus puntos de
        venta (físicos o electrónicos Web, redes, etc.) la aceptación de las marcas correspondientes a los
        Medios de Pago adquiridos por OCA, así como también deberá exhibir en las vidrieras y/o lugares
        visibles de sus locales y su página Web u otros canales electrónicos de venta, los elementos
        publicitarios que OCA le proporcione.
      </Typography>

      <Typography className={classes.paragraph}>
        Todos los formularios, material de publicidad y demás elementos que hayan sido entregados a la Empresa
        Adherida son propiedad de OCA. En caso de desafiliación, la Empresa Adherida se obliga a retirar de
        exhibición todo material identificatorio como adherente al Sistema.
      </Typography>

      <Typography className={classes.paragraph}>
        El Adquirente podrá ordenar a la Empresa Adherida el cese de su uso o exhibición, siendo responsable
        la Empresa Adherida de todos los daños y perjuicios ocasionados, así como único y exclusivo
        responsable del pago de impuestos, tasas y contribuciones que deban abonarse por tal uso o exhibición
        indebida.
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida se compromete a mantener el material identificatorio en buen estado de
        conservación, dando inmediato aviso al Adquirente ante cualquier deterioro que suceda.
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida se obliga a no utilizar las marcas y logotipos de los Medios de Pago emitidos por
        OCA de forma tal que pueda inferirse que el producto o los servicios comercializados por la Empresa
        Adherida son patrocinados, promocionados, producidos, ofrecidos o vendidos por la propietaria de la
        marca, el Adquirente y/o las entidades del Sistema.
      </Typography>

      <Typography className={classes.paragraph}>
        Tampoco podrá usar las marcas ni el isotipo de tales Medios de Pago en su papelería o en la promoción
        o propaganda del producto o servicios prestados, salvo expresa autorización por escrito del
        Adquirente. Asimismo, presta su conformidad para la mención de su nombre social o comercial, marcas,
        logo, domicilio, teléfono y ramo de actividad en cualquier guía o nómina que resuelva comunicar el
        Adquirente.
      </Typography>

      <Typography className={classes.paragraph}>
        Sin perjuicio de lo dispuesto anteriormente, la Empresa Adherida podrá utilizar las marcas o logos de
        los Medios de Pago emitidos por OCA en folletos, catálogos o en su página Web, insertándolos próximos
        al espacio de pago o registro en un tamaño que no será superior a 1 1/4 pulgadas de extensión
        horizontal si se utilizara el logo o en un tipo de letra que no sobrepase el tamaño de letra que se
        utilice en la mayor parte del texto que aparezca en la misma página, si se empleara la marca,
        acompañados en ambos casos por la leyenda "se acepta como pago" en idéntico tamaño. En ningún caso la
        Empresa Adherida utilizará las marcas o logos en la primera página de su solicitud. Podrá aparecer en
        el texto de la solicitud una declaración veraz por parte de la Empresa Adherida al efecto de que el
        mismo dirige o limita su oferta a los Usuarios del Sistema, en otro lugar que no sea al lado del
        espacio correspondiente al pago o inscripción, sujeto a las limitaciones de que: (1) Solamente se
        podrá utilizar la marca (no el logotipo); (2) la marca no podrá (a) aparecer en un tipo de letra mayor
        al utilizado en la misma página; (b) ser de color diferente al tipo de letra que se use en el texto
        (diferenciado de los títulos) que aparezca en la misma página; (c) ser de tamaño equivalente ni mayor
        que el nombre de la Empresa Adherida; (d) ser lo primero que aparezca en cualquier página ni (e) ser
        de ningún otro modo el elemento más visible que aparezca en la página en que se coloque la marca; (3)
        el nombre y/o el logotipo de la Empresa Adherida deberán aparecer de manera visible en la misma página
        en que aparezca la marca; (4) la marca deberá utilizarse de modo tal que no pueda inferirse que las
        entidades del Sistema promocionan y/o garantizan a la Empresa Adherida y/o a los productos ofrecidos.
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida declara conocer y aceptar que las marcas de cada uno de los Sellos y sus
        correspondientes logotipos son propiedad de cada Sello respectivamente.
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida manifiesta conocer y aceptar que en virtud del presente no adquiere derecho,
        título ni interés alguno sobre los logos y marcas ni sobre sus productos y servicios de ninguno de los
        Emisores, los Sellos ni del Adquirente.
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida se abstendrá de realizar a sabiendas cualquier acción que tenga por efecto el
        desprestigio, la dilución o cualquier otra forma que disminuya el valor de los logos, marcas,
        denominaciones, productos o servicios establecidos por el Adquirente, los Sellos o los Emisores,
        obligándose especialmente a no utilizar o causar que tales logos, marcas, denominaciones, productos o
        servicios sean utilizados o explotados en cualquier forma contraria a la moral y las buenas costumbres
        o que puedan tener efectos contrarios hacia el buen nombre, reputación o imagen de los mismos.
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida se obliga en relación a todo y cualquier cartel, aviso, publicación, material
        promocional y/o cualquier anuncio público que incluya o se refiera a los logos o marcas, al
        cumplimiento de las disposiciones legales vigentes, especialmente en materia de derecho de autor,
        propiedad industrial y defensa del consumidor y a mantener indemne a los restantes integrantes del
        Sistema de todo y cualquier reclamo motivado en el incumplimiento de tales disposiciones.
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        11 - RESPONSABILIDAD
      </Typography>

      <Typography className={classes.paragraph}>
        OCA no asume responsabilidad alguna por la calidad, cantidad, marca, devoluciones, servicios no
        prestados, o cualquier otro asunto o reclamo referido a las mercaderías, productos, bienes y/o
        servicios adquiridos por los Usuarios. Asimismo, OCA no será responsable en ningún caso frente a sus
        Usuarios por los errores operativos en que, por cualquier causa, hubiera incurrido la Empresa Adherida
        respecto al monto, moneda y/o planes de adeudos que no hubieran sido enviados en tiempo y forma,
        mediante la utilización de los mecanismos operativos y de seguridad implementados por OCA.
      </Typography>

      <Typography className={classes.paragraph}>
        En caso de que se configure alguna de las siguientes hipótesis, OCA podrá desconocer la Transacción o
        las Transacciones de que se trate, asumiendo la Empresa Adherida la responsabilidad financiera y de
        cualquier otra especie que se genere como consecuencia de la realización de las mismas o de los
        reclamos que surjan como consecuencia del rechazo por los Usuarios, aceptando desde ya la Empresa
        Adherida que OCA no las considere en sus Liquidaciones presentes o futuras, y por tanto no abone a la
        Empresa Adherida los montos correspondientes a las mismas. Asimismo, la Empresa Adherida se obliga a
        mantener a OCA indemne de cualquier perjuicio que pudiere sufrir como consecuencia de las mismas o del
        rechazo de éstas cuando:
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol type="a" className={classes.list}>
          <li>
            La Empresa Adherida no respete el procedimiento descripto en la cláusula 4 de las presentes
            Condiciones.
          </li>
          <li>
            En el caso de que existan operaciones fraudulentas, o sospechosas de serlo a juicio de OCA, o
            meramente cuestionadas por OCA o por los Usuarios.
          </li>
          <li>
            Cuando se configuren irregularidades que ameriten proceder a una investigación policial y/o
            judicial.
          </li>
          <li>Cuando OCA haya pagado Transacciones erróneamente.</li>
          <li>Impugnación de los Usuarios.</li>
          <li>Incumplimiento de los Requisitos de Seguridad.</li>
          <li>
            En los casos en que se reciban Contracargos cuyas condiciones amparen o justifiquen la razón al
            Usuario del Emisor que envía la disputa.
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        En todos estos casos será de aplicación el mecanismo de retención y débito automático de fondos
        descripto en la cláusula 8.5.
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        12 - FACTURA ELECTRÓNICA
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>12.1.</span> La Empresa Adherida y sus Representantes que mantengan la
        calidad de receptores no electrónicos de acuerdo a la Resolución de DGI 798/2012 (artículo 8, inciso
        final) y sus modificativas, dan su autorización expresa y relevan a OCA de la obligación de entregar
        la representación impresa de los Comprobantes Fiscales Electrónicos. La Empresa Adherida reconoce que
        podrá acceder a dichos comprobantes a través del sitio web: oca.com.uy.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>12.2.</span> La Empresa Adherida acepta que, accediendo a la página Web
        de OCA con su usuario y clave, puede obtener los comprobantes fiscales electrónicos que surgen de la
        liquidación de las compras y/o servicios (e-facturas, e-notas de crédito y/o débito, e-resguardos), en
        formato PDF o en una imagen cumpliendo con las condiciones establecidas por la DGI para la
        representación de los comprobantes de factura electrónica.
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        13 - TRIBUTOS
      </Typography>

      <Typography className={classes.paragraph}>
        Los tributos de toda clase (y toda otra prestación pecuniaria de carácter legal) que se aplicarán a
        las operaciones celebradas entre la Empresa Adherida y OCA serán de cargo de la Empresa Adherida.
        Todos los tributos o prestaciones pecuniarias de origen legal que admitan traslado (a modo de ejemplo,
        impuestos indirectos a bienes o servicios, al valor agregado o específicos, a transacciones
        financieras o bancarias, a la circulación jurídica, contratos, tributos de sellos, impuestos a rentas
        especiales, etc.) se entienden trasladados y se trasladarán a la Empresa Adherida a la tasa aplicable
        conforme a la normativa vigente y por ende de su costo y cargo, quedando OCA facultada a debitar el
        importe de los mismos. Asimismo, son de cargo de la Empresa Adherida aquellos tributos o prestaciones
        pecuniarias que la tengan como contribuyente y OCA sea responsable tributario bajo cualquier tipo de
        responsabilidad como ser retención, sustitución, solidaridad, responsable de obligaciones tributarias
        de terceros, etc. En estos casos OCA tendrá derecho de retención, deducción, repetición y
        resarcimiento íntegro.
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        14 - SITIO WEB
      </Typography>

      <Typography className={classes.paragraph}>
        Las Empresas Adheridas que así lo deseen podrán acceder al sitio web de OCA (
        <Link href="https://oca.uy/" target="_blank">
          https://oca.uy/
        </Link>
        ), a través de un usuario y clave, para así acceder a los trámites y servicios habilitados. Asimismo,
        las Empresas Adheridas que así lo deseen podrán acceder al sistema de pagos recurrentes de OCA,
        disponible en el sitio web de OCA. Las personas autorizadas a operar a través del sitio web son las
        declaradas por la Empresa Adherida en el Formulario de Adhesión y las que ellos designen mediante la
        creación de otros usuarios desde el sitio Web, las que se mantendrán vigentes mientras la Empresa
        Adherida no comunique lo contrario. Los usuarios y claves, según corresponda, serán notificados a la
        Empresa Adherida a los correos electrónicos denunciados en el Formulario de Adhesión. La Empresa
        Adherida asume la total responsabilidad por el uso de los servicios prestados a través del sitio web.
        La Empresa Adherida declara conocer que, mediante el uso de la dirección de correo denunciada en el
        Formulario de Adhesión, y mediante el uso de los usuarios y claves provistos, según corresponda, se
        sustituirá la firma manuscrita, reconociendo la autenticidad de las acciones y operaciones que por
        este medio pueda realizar cada uno de los autorizados. La Empresa Adherida acepta que las presentes
        Condiciones serán aplicables a los Representantes y apoderados de la Empresa Adherida, en lo
        pertinente, siendo esta responsable por las instrucciones que sus Representantes y apoderados impartan
        y las obligaciones que asuman (en tanto que estos actúan en nombre y representación de la Empresa
        Adherida), según lo que se establece a continuación, considerándose a todos los efectos como si
        hubiera actuado la Empresa Adherida personalmente. Los usuarios y claves que entrega OCA para utilizar
        el sitio web son de carácter personal y deberán ser mantenidas en el más estricto secreto no debiendo
        ser divulgadas a terceras personas, debiendo tomarse todas las medidas precautorias que impidan el
        acceso y conocimiento de terceros respecto de cada sesión. Por el presente, la Empresa Adherida
        autoriza a OCA a entregar los usuarios y claves correspondientes al o a los apoderados de la Empresa
        Adherida que las solicitaren (y que actúen en su nombre y representación), asumiendo la Empresa
        Adherida la responsabilidad por el uso que ella directamente o sus apoderados o cualquier tercero,
        pudiere hacer de dichos usuarios y claves una vez recibidas de conformidad con lo aquí previsto.
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        15 - CONFIDENCIALIDAD
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>15.1.</span> La Empresa Adherida reconoce que toda información a la que
        pueda acceder respecto de los Usuarios, en relación o en ocasión de las presentes, incluyendo el
        contenido del mismo, se encuentra amparada por el secreto profesional con el alcance previsto en el
        artículo 302 del Código Penal Uruguayo, comprometiéndose a mantener toda información a la que acceda
        en forma estrictamente confidencial en forma indefinida, independientemente de la vigencia o no de las
        presentes. La Empresa Adherida se obliga a guardar la más estricta confidencialidad de toda
        información que OCA le haya suministrado anteriormente a la suscripción del presente o le suministre
        en el futuro o a la que acceda en virtud o en ocasión de las relaciones entabladas entre las partes o
        en virtud del presente. En caso de que la Empresa Adherida sea emplazada judicialmente a dar
        información, deberá notificar previamente de tal circunstancia a OCA.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>15.2.</span> Las obligaciones asumidas en las presentes por parte de la
        Empresa Adherida, incluyendo las obligaciones de confidencialidad, son obligaciones de resultado. La
        Empresa Adherida se compromete a no divulgar la información a la que pueda acceder en cumplimiento o
        en razón del presente, incluso al interno de su organización, con la única excepción de aquellas
        personas que, acumulativamente, (i) son empleados o personas contratadas por la Empresa Adherida
        directamente vinculadas a la prestación de las actividades reguladas en el presente, (ii) tienen
        necesidad de conocer dicha información para poder operar en la modalidad regulada por el presente y
        (iii) están sujetas a obligaciones de confidencialidad al menos tan estrictas como las previstas en
        estas Condiciones (en adelante, los “Sujetos Autorizados”). La Empresa Adherida será directamente
        responsable de cualquier divulgación que ocurra de la información por cualquier causa, incluyendo sin
        limitación cualquier divulgación realizada por parte de los Sujetos Autorizados.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>15.3.</span> La Empresa Adherida se obliga a no utilizar la información
        y los datos personales que por cualquier motivo reciba de OCA y/o de los Usuarios para otra finalidad
        que no sea la del cumplimiento del presente, debiendo actuar en todo momento de acuerdo con lo
        previsto en la Ley N° 18.331 de Protección de Datos Personales, sus decretos reglamentarios, normas
        complementarias y concordantes.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>15.4.</span> La información brindada por OCA será propiedad de OCA,
        pudiendo OCA exigir en cualquier momento a la Empresa Adherida (inclusive con posterioridad a la
        vigencia del presente) la devolución de los medios materiales o soporte de la misma. Ante el
        requerimiento de OCA, la Empresa Adherida deberá proceder en forma inmediata (y en ningún caso en un
        plazo mayor a dos días hábiles) a la inmediata devolución y/o destrucción, según sea solicitado por
        OCA, de toda la información que obre en su poder o en poder de los Sujetos Autorizados, sin retener ni
        realizar ninguna copia, extracto o reproducción del material escrito o cualquier otro documento que
        constituya información confidencial.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>15.5.</span> La Empresa Adherida declara conocer y aceptar que las
        obligaciones de confidencialidad aquí acordadas constituyen un elemento esencial del presente y que
        OCA no habría contratado con la Empresa Adherida si la Empresa Adherida no aceptara las obligaciones
        de confidencialidad aquí pactadas. En virtud de lo antedicho, se pacta como condición resolutoria
        expresa el incumplimiento por parte de la Empresa Adherida de cualquiera de las obligaciones
        estipuladas en el presente artículo rescindiéndose el presente en forma inmediata al incumplimiento,
        siendo para ello necesario únicamente un aviso por escrito a la Empresa Adherida de cinco (5) días
        previos, todo sin perjuicio de los daños y/o perjuicios que OCA pueda reclamar a la Empresa Adherida.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>15.6.</span> La Empresa Adherida se obliga a no hacer, sin la previa
        autorización por escrito de un representante legal autorizado de OCA, ningún tipo de propaganda,
        promoción, avisos de prensa, publicidad o utilización de ningún material publicitario relacionado con
        la ejecución de las presentes Condiciones.
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        16 - COMUNICACIONES
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>16.1. Comunicaciones de la Empresa Adherida a OCA:</span> Todas las
        comunicaciones que deba realizar la Empresa Adherida a OCA deberán realizarse por escrito, salvo en
        aquellos casos en que la normativa vigente imponga otra forma de comunicación que no admita pacto en
        contrario o en caso de que la Empresa Adherida y OCA hubieren pactado una forma de comunicación
        distinta a la aquí prevista.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>16.2. Comunicaciones de OCA a la Empresa Adherida:</span> Toda
        comunicación, aviso, información y/o notificación que deba realizar OCA en forma personal a la Empresa
        Adherida bajo las presentes Condiciones, podrá efectuarse, a opción de OCA, mediante telegrama
        colacionado con acuse de recibo, acta notarial, servicio de mensajería, correo, correo electrónico,
        avisos colocados en circulares, notas especiales o aclaraciones dentro de la liquidación de
        Transacciones o colocando el texto de dicha comunicación en el sitio de Internet cuya página principal
        es{' '}
        <Link href="https://www.oca.com.uy" target="_blank">
          www.oca.com.uy
        </Link>{' '}
        u otro medio idóneo que se instrumente en el futuro. Tales comunicaciones serán consideradas como
        integrantes de las presentes Condiciones y sin que sea necesaria notificación fehaciente alguna, salvo
        cuando fuera expresamente requerido en estas Condiciones o la normativa vigente, desde que le fueren
        comunicadas a la Empresa Adherida. Las comunicaciones se presumen conocidas por las Empresas Adheridas
        desde la fecha en que les fuera comunicada por cualquiera de los medios mencionados anteriormente.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>16.3. Domicilios:</span> La Empresa Adherida designa como domicilio
        válido para comunicaciones los denunciados en el Formulario de Adhesión. OCA constituye domicilio a
        los efectos de las presentes Condiciones en Colonia 1432, Montevideo, Uruguay.
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        17 - MORA
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida caerá en mora de pleno derecho, sin necesidad de interpelación alguna, respecto de
        sus obligaciones para con OCA, por el solo hecho de hacer o no hacer algo contrario a lo aquí
        estipulado.
      </Typography>
      <Typography variant="h6" component="h3" className={classes.paragraph}>
        18 - MODIFICACIONES A LAS PRESENTES CONDICIONES GENERALES Y PARTICULARES
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>18.1.</span> Salvo los casos en que la ley o la reglamentación
        requieran el consentimiento previo y expreso por parte de la Empresa Adherida, las presentes
        Condiciones podrán ser modificadas por OCA mediante notificación a las Empresas Adheridas a través de
        cualquiera de los medios mencionados en la cláusula 16 anterior. La Empresa Adherida tendrá un plazo
        de 10 (diez) días corridos a contar desde la recepción de la comunicación antes referida para
        presentar sus objeciones por escrito a la modificación notificada por OCA.
      </Typography>

      <Typography className={classes.paragraph}>
        Si la Empresa Adherida no comunica sus objeciones en tal plazo, las modificaciones propuestas por OCA
        quedarán firmes a todos los efectos. Por el contrario, presentadas tales objeciones en tiempo y forma
        por parte de la Empresa Adherida a OCA, ésta tendrá un plazo de 5 (cinco) días corridos a contar desde
        el día siguiente al que recibió tales objeciones por parte de la Empresa Adherida, para aceptarlas o
        rechazarlas. Si dentro de tal plazo, OCA no comunica a la Empresa Adherida su aceptación expresa a
        tales objeciones, las mismas se tendrán por rechazadas. En este caso la Empresa Adherida tendrá un
        plazo de 5 (cinco) días corridos adicionales para rescindir las relaciones contractuales con OCA como
        respuesta a estas nuevas condiciones propuestas por OCA; de lo contrario, las nuevas condiciones
        comunicadas originalmente por OCA quedarán firmes y serán vinculantes a todos los efectos.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>18.2.</span> Modificación de los términos y condiciones de acceso a la
        página WEB de OCA. Los términos y condiciones que rigen la adhesión de las Empresas Adheridas al
        servicio de acceso a la página WEB, podrán ser modificados por OCA en cualquier momento mediante
        preaviso de 30 días a la fecha de entrada en vigencia de la modificación. Si dentro de tal plazo las
        Empresas Adheridas no optan por la rescisión, se entenderá que las modificaciones introducidas son
        aceptadas y de cumplimiento obligatorio.
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        19 - PLAZO Y RESCISIÓN
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>19.1.</span> Las presentes Condiciones regirán las relaciones entre OCA
        y la Empresa Adherida por un plazo de dos años a partir de la firma del Formulario de Adhesión por
        parte de la Empresa Adherida y aceptado por OCA, prorrogándose automáticamente por plazos de dos años
        salvo que cualquiera de las partes notifique a la otra su voluntad de dejar sin efecto las
        Condiciones, permaneciendo vigente aun luego de rescindido todas las cláusulas que aplican a la
        vigencia de las Condiciones (por ejemplo, Responsabilidad, Confidencialidad, etc.). Dicho plazo es sin
        perjuicio de la posibilidad de rescisión que se establece en estas Condiciones para las situaciones
        específicas aquí descriptas.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>19.2.</span> Las partes acuerdan que el Adquirente tendrá el derecho de
        rescindir la adhesión en cualquier momento, aún sin notificación previa alguna y en forma automática
        cuando tenga conocimiento de circunstancias o hechos que, a su juicio, afecten la seguridad del
        Sistema o de alguno de sus integrantes, el prestigio, el crédito o el concepto de la Empresa Adherida,
        su declaración de quiebra o presentación en concurso preventivo (cuando las normas lo permitan), o
        bien existan circunstancias o modificaciones en la situación del mercado que afecten las condiciones
        económicas de las partes, sea haciéndolas más gravosas o de cualquier otra forma. Asimismo, el
        Adquirente podrá rescindir en forma inmediata estas Condiciones y dar de baja a la Empresa Adherida en
        caso de incumplimiento de cualquier disposición de estas Condiciones, sin perjuicio de los daños y
        perjuicios que el Adquirente le pueda reclamar.
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        20 - CESIÓN DEL CONTRATO
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida no podrá ceder total o parcialmente los derechos y obligaciones bajo las presentes
        Condiciones, incluyendo su carácter de Empresa Adherida y el número correspondiente, sin conformidad
        previa, expresa y por escrito del Adquirente.
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        21 - ANTICORRUPCIÓN
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>21.1.</span> La Empresa Adherida se obliga a cumplir, o hacer cumplir,
        por sí, sus propietarios, accionistas, funcionarios o eventuales subcontratados, las normas que les
        fueren aplicables y versen sobre actos de corrupción y actos lesivos contra la administración pública,
        en la forma de la Ley 17.060 y su decreto reglamentario N° 30/003 y modificativas, debiendo (i)
        mantener políticas y procedimientos internos que aseguren el cumplimiento integral de tales normas;
        (ii) dar conocimiento pleno de tales normas a todos sus dependientes, previamente al inicio de su
        actuación en el ámbito de estas Condiciones; (iii) abstenerse de practicar actos de corrupción y de
        actuar en forma lesiva a la administración pública, nacional o extranjera, en interés o para
        beneficio, exclusivo o no, de la Empresa Adherida; y (iv) en caso que tenga conocimiento de cualquier
        acto o hecho que viole las normas aludidas, y que se refiera a la relación jurídica mantenida a través
        de las presentes Condiciones comunicar inmediatamente a OCA, la cual podrá tomar todas las
        providencias que considere necesarias.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>21.2.</span> La Empresa Adherida al suscribir el Formulario de Adhesión
        manifiesta y garantiza que:
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol className={classes.list}>
          <li>
            No tiene conocimiento alguno que sus afiliadas, subsidiarias, así como cada uno de los
            accionistas, directores, empleados y demás personas que trabajan en su nombre (incluyendo, pero no
            limitado a sus empleados, Representantes y agentes) han participado en ningún acto de corrupción o
            soborno como los arriba mencionados;
          </li>
          <li>
            Ningún empleado, funcionario, director o propietario directo o indirecto, es funcionario público,
            funcionario de algún partido político o candidato y, en caso contrario, presentará una declaración
            detallada a OCA. En caso de que, en el futuro, ocurriere algún cambio al respecto, la Empresa
            Adherida deberá poner en conocimiento inmediato a OCA de dicha situación.
          </li>
          <li>
            En caso de que algún empleado, funcionario, director o propietario directo o indirecto de la
            Empresa Adherida sea considerado Persona Políticamente Expuesta (PEP) de acuerdo a la normativa
            establecida por el Banco Central del Uruguay sobre Prevención del Lavado de Dinero y
            Financiamiento del terrorismo, deberá dar conocimiento de dicha situación a OCA, para poder dar
            cumplimiento con la normativa vigente.
          </li>
        </ol>
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        22 - DEFENSA DEL CONSUMIDOR
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida declara estar en conocimiento de que los Usuarios, de acuerdo a lo previsto en el
        artículo 16 de la Ley 17.250 y en el artículo 11 de la Ley 19.731, para los casos de oferta de
        productos o servicios que se realicen fuera del local empresarial, por medio postal, telefónico,
        televisivo, informático o similar en locales acondicionados con la finalidad de ofertar, tienen
        derecho a rescindir o resolver, de pleno derecho las respectivas compraventas de bienes o servicios en
        los términos establecidos en dicha norma.
      </Typography>

      <Typography className={classes.paragraph}>
        En tal caso, y en el marco de la citada disposición, OCA no realizará el pago correspondiente a la
        Empresa Adherida, quedando sin efecto las formas de pago de las prestaciones concertadas a través de
        los Medios de Pago.
      </Typography>

      <Typography className={classes.paragraph}>
        En caso de que el pago haya sido realizado, OCA quedará facultado a descontarlo de futuras
        Liquidaciones.
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        23 - PROTECCIÓN DE DATOS (SDP)
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>23.1.</span> La Empresa Adherida se obliga a dar cumplimiento al
        Programa de Protección de Datos -Site Data Protection Program- (el “Programa SDP”) de acuerdo al Nivel
        en el que se encuentre clasificado. El Programa SDP requiere el cumplimiento del Estándar de Seguridad
        de la Industria de Medios de Pago Electrónico (el PCI DSS - Payment Card Industry Data Security
        Standard) disponible en{' '}
        <Link href="https://www.pcisecuritystandards.org/index.htm" target="_blank">
          www.pcisecuritystandards.org/index.htm
        </Link>
        . Clasifica a las Empresas Adheridas en cuatro niveles, de acuerdo al siguiente detalle:
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol type="A" className={classes.list}>
          <li>
            <b>Nivel 1:</b> una Empresa Adherida pertenece al Nivel 1 si:
            <ol type="a">
              <li>
                Ha sufrido alguna intrusión (hacking) y/o ataque que finalizó en un evento de compromiso de
                datos;
              </li>
              <li>
                Realiza más de 6.000.000 de Transacciones anuales con los diferentes Medios de Pago
                Electrónicos con los cuales opere;
              </li>
              <li>
                El Adquirente determina a su exclusivo criterio que la Empresa Adherida presenta un riesgo
                para el Sistema.
              </li>
            </ol>
          </li>
          <li>
            <b>Nivel 2:</b> una Empresa Adherida pertenece al Nivel 2 si:
            <ol type="a">
              <li>
                Realiza más de 1.000.000 y menos de 6.000.000 de Transacciones anuales con los diferentes
                Medios de Pago Electrónicos con los cuales opere;
              </li>
            </ol>
          </li>
          <li>
            <b>Nivel 3:</b> una Empresa Adherida pertenece al Nivel 3 si:
            <ol type="a">
              <li>
                Realiza más de 20.000 y menos de 1.000.000 de Transacciones anuales de comercio electrónico
                con los diferentes Medios de Pago Electrónicos con los cuales opere.
              </li>
            </ol>
          </li>
          <li>
            <b>Nivel 4:</b> una Empresa Adherida pertenece al Nivel 4 si no pertenece a los Niveles 1, 2 o 3.
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>23.2. Cumplimiento del Programa SDP:</span> Cada Empresa Adherida
        deberá dar cumplimiento al Programa SDP de acuerdo al Nivel en el que se encuentre clasificado:
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol className={classes.list}>
          <li>
            Si la Empresa Adherida se encuentra clasificada en el Nivel 1 deberá:
            <ol>
              <li>
                Efectuar una revisión anual del Sitio de procesamiento de las Transacciones de la Empresa
                Adherida a través de un Asesor de Seguridad Calificado. Se entiende por Asesor de Seguridad
                Calificado al auditor que cuente con la certificación al efecto del consorcio PCI, que
                administra dicho programa. Esta revisión anual deberá efectuarse de acuerdo a lo establecido
                en el Manual de Procedimientos de Auditoría de PCI que se encuentra disponible en{' '}
                <Link
                  href="https://www.pcisecuritystandards.org/security_standards/index.php"
                  target="_blank"
                >
                  https://www.pcisecuritystandards.org/security_standards/index.php
                </Link>
                ;
              </li>
              <li>
                Realizar escaneos trimestrales efectuado por alguno de los proveedores homologados por el
                Adquirente de acuerdo a lo establecido en el Manual de Procedimientos y Escaneos de Seguridad
                de PCI disponible en{' '}
                <Link
                  href="https://www.pcisecuritystandards.org/security_standards/index.php"
                  target="_blank"
                >
                  https://www.pcisecuritystandards.org/security_standards/index.php
                </Link>
                . El listado de proveedores homologados por el Adquirente se encuentra disponible en{' '}
                <Link
                  href="https://www.pcisecuritystandards.org/security_standards/index.php"
                  target="_blank"
                >
                  https://www.pcisecuritystandards.org/security_standards/index.php
                </Link>
                .
              </li>
            </ol>
          </li>
          <li>
            Si la Empresa Adherida se encuentra clasificada en el Nivel 2 o en el Nivel 3, la Empresa Adherida
            deberá:
            <ol>
              <li>
                Completar el cuestionario de autoevaluación disponible en{' '}
                <Link
                  href="https://www.pcisecuritystandards.org/security_standards/index.php"
                  target="_blank"
                >
                  https://www.pcisecuritystandards.org/security_standards/index.php
                </Link>{' '}
                en forma anual;
              </li>
              <li>
                Realizar escaneos trimestrales efectuado por alguno de los proveedores homologados por el
                Adquirente de acuerdo a lo establecido en el Manual de Procedimientos y Escaneos de Seguridad
                de PCI disponible en{' '}
                <Link
                  href="https://www.pcisecuritystandards.org/security_standards/index.php"
                  target="_blank"
                >
                  https://www.pcisecuritystandards.org/security_standards/index.php
                </Link>
                . El listado de proveedores homologados por el Adquirente se encuentra disponible en{' '}
                <Link
                  href="https://www.pcisecuritystandards.org/security_standards/index.php"
                  target="_blank"
                >
                  https://www.pcisecuritystandards.org/security_standards/index.php
                </Link>
                .
              </li>
            </ol>
          </li>
          <li>
            Si la Empresa Adherida se encuentra clasificada en el Nivel 4, tendrá la opción de cumplir con los
            requisitos establecidos para las Empresas Adheridas del Nivel 2 y Nivel 3.
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>23.3. Notificación de Niveles a las Empresas Adheridas:</span> OCA
        podrá notificar a cada Empresa Adherida que forme parte del Sistema el encuadramiento en alguna
        categoría específica del Programa SDP y/o modificaciones subsiguientes, sin perjuicio de lo cual las
        Empresas Adheridas deberán realizar su propia evaluación y adoptar las medidas necesarias a efectos de
        cumplir con las normas del Programa SDP.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>23.4. Registración:</span> Las Empresas Adheridas deberán proveer a
        OCA, en los plazos y condiciones que OCA establezca, la información y documentación respaldatoria para
        acreditar el cumplimiento del Programa SDP.
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        24 - INTERCAMBIO DE INFORMACIÓN
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida no podrá vender, comprar, proveer y/o intercambiar información obtenida como
        consecuencia de Transacciones realizadas con los Medios de Pago, incluyendo pero no limitándose a:
        Cupones y sus copias, bases de datos (incluyendo archivos de Medios de Pago Electrónicos habilitadas o
        inhabilitadas), etc., a cualquier persona distinta del Adquirente y/o ante requerimiento formal de
        autoridad competente con facultades suficientes, debiendo en este caso dar aviso previo al Adquirente
        para que éste pueda interponer las medidas que estime pertinentes para evitar su divulgación, salvo
        cuando el requerimiento lo impidiera. En este último caso deberá intentar que la autoridad requirente
        otorgue carácter confidencial a la información.
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida tampoco podrá copiar en cualquier forma y/o medio, los datos grabados en la banda
        magnética, en el chip y/o en otros dispositivos de los Medios de Pago Electrónico, así como de
        incorporar a cualquier base de datos o de cualquier otro modo registrar datos propios del Sistema,
        tales como número de Medio de Pago Electrónico, vigencia, CVV, entre otros, salvo autorización expresa
        del Adquirente.
      </Typography>

      <Typography className={classes.paragraph}>
        La destrucción de los medios que contengan información confidencial que la Empresa Adherida considere
        innecesarios o inapropiado seguir guardando, deberá hacerse de manera tal que los datos queden
        ilegibles.
      </Typography>

      <Typography className={classes.paragraph}>
        El Adquirente podrá intercambiar información sobre la Empresa Adherida con instituciones financieras u
        otras empresas administradoras o procesadoras de Medios de Pago Electrónicos de crédito, débito o
        instrumentos de dinero electrónico, así como cualquier controlante, controlada, afiliada o parte del
        mismo grupo económico del Adquirente. La Empresa Adherida consiente expresamente que el Adquirente, a
        requerimiento de los Sellos, informe a éstos sobre sus volúmenes de Transacciones.
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        25 - MISCELÁNEA
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>25.1. Interpretación y aclaración:</span> Cualquier duda en la
        interpretación de las presentes Condiciones deberá ser formulada al Adquirente y la respuesta de la
        misma será de carácter aclaratorio sólo válida para el caso particular y se considerará, para ese
        caso, como parte integrante de estas Condiciones. Las partes autorizan al Adquirente a realizar
        excepciones a las presentes Condiciones cuando lo estime conveniente.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>25.2. Ley y jurisdicción:</span> Toda disputa o controversia referida a
        las presentes Condiciones será regida por las leyes de la República Oriental del Uruguay, siendo
        competentes los juzgados que correspondan de la ciudad de Montevideo.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>25.3. Derechos acumulativos:</span> Los derechos aquí expuestos son
        acumulativos y no excluyentes de todo otro derecho dispuesto por la ley, pudiendo ser ejercidos en
        forma separada o conjunta.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>25.4. Interpretación del ejercicio de derechos:</span> Salvo cuando se
        disponga lo contrario en estas Condiciones, la falta o demora en el ejercicio por las partes de
        cualquier derecho y/o facultad en virtud de estas Condiciones no se considerará una renuncia al mismo,
        ni tampoco el ejercicio parcial de cualquier derecho y/o facultad impedirá todo otro ejercicio del
        mismo o el ejercicio de todo otro derecho y/o facultad.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>25.5. Divisibilidad:</span> Cada cláusula o provisión de estas
        Condiciones es divisible del resto. En el caso en que cualquier provisión o cláusula de estas
        Condiciones fuere declarada inválida o ineficaz, por decisión de autoridad judicial o administrativa
        con facultades suficientes, no afectará la validez de toda otra cláusula o provisión.
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        26 - PREVENCIÓN DE LAVADO DE ACTIVOS
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida, en nombre propio y de sus empleados que actúan bajo estas Condiciones, declara
        conocer y respetar las leyes uruguayas aplicables que tratan de los delitos de lavado de dinero y de
        lucha contra la financiación del terrorismo, así como las leyes y reglamentos para prevenir el lavado
        de dinero extranjeras que son aplicables a las partes y/o a las presentes Condiciones.
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida es consciente de que OCA es una empresa administradora de créditos sujeta a leyes,
        normas y reglas nacionales e internacionales específicas, y no puede relacionarse ni negociar directa
        o indirectamente con personas o entidades, incluidas las gubernamentales, ni apoyar actividades que
        estén (i) sujetas a las sanciones administradas o impuestas por el Consejo de Seguridad de las
        Naciones Unidas, U.S. Department of the Treasury’s Office of Foreign Assets Control (“OFAC”), la Unión
        Europea y His Majesty’s Treasury (“HMT”) (colectivamente, “Sanciones”), y/o (ii) ubicado, organizado o
        residente en países o territorios sancionados.
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida declara por sí misma, sus empresas controladas, administradores y, hasta el mejor
        de sus conocimientos, por sus accionistas mayoritarios o sus empresas controladoras y empleados que
        actúan bajo estas Condiciones que (i) ninguna de estas partes es directa o indirectamente sancionada,
        ni está ubicada, establecida o residente en países o territorios sancionados; (ii) las actividades
        previstas en estas Condiciones, incluyendo, entre otras, el uso de recursos proporcionados por OCA o
        la prestación de servicios a OCA por la Empresa Adherida, no involucrarán directa o indirectamente a
        ninguna persona o entidad sancionada o ubicada, establecida o residente en países o territorios
        sancionados; y (iii) los montos utilizados por la Empresa Adherida para pagar sus obligaciones o
        realizar pagos en virtud de estas Condiciones no vendrá directa o indirectamente de actividades en o
        con cualquier persona o entidad sancionada o ubicada, establecida o residente en países o territorios
        sancionados.
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida es consciente de que OCA, en virtud de la legislación y reglamentos antes
        mencionados, puede en cualquier momento y sin cualquier responsabilidad negarse a celebrar nuevos
        contratos o realizar transacciones que no cumplan con sus políticas, procedimientos y controles
        internos.
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida se compromete a comunicar a OCA, tan pronto tenga conocimiento de (i) la
        ocurrencia de cualquier violación de las normas, leyes y/o declaraciones aquí establecidas y
        relacionadas con la prevención del lavado de dinero, el combate al financiamiento del terrorismo o las
        Sanciones; (ii) incumplimiento por la Empresa Adherida de cualquiera de las declaraciones aquí
        establecidas; y/o (iii) su inclusión, o la de sus accionistas mayoritarios o controladores, en
        cualquiera de las listas de Sanciones antes mencionadas. Si es sancionada, la Empresa Adherida se
        compromete, si así lo solicita OCA, a proporcionar informaciones y documentos justificativos que
        demuestren y aseguren la regularidad de sus actividades y estatus, así como el cumplimiento de estas
        declaraciones. OCA se reserva el derecho de suspender, terminar anticipadamente o rescindir las
        relaciones comerciales, según sea el caso, si la Empresa Adherida es sancionada, ya sea directamente o
        por Sanciones aplicadas a sus accionistas mayoritarios o controladores, cuando dicho cumplimiento y/o
        mantenimiento de la relación comercial resulte en un incumplimiento o expone a OCA a restricciones de
        cualquier Sanción.
      </Typography>

      <Typography variant="h6" component="h2" className={classes.mainTitle}>
        II. CONDICIONES PARTICULARES
      </Typography>

      <Typography className={classes.paragraph}>
        A continuación, se detallan las Condiciones Particulares aplicables a la relación entre OCA y las
        Empresas Adheridas.
      </Typography>

      <Typography className={classes.paragraph}>
        <b>1 Transacciones CON CAPTURA DE DATOS en el Punto de Venta</b>
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>Ámbito de aplicación:</span> Las disposiciones del presente capítulo
        serán de aplicación a aquellas operaciones realizadas por los Usuarios en las Empresas Adheridas a
        través de una Terminal de Captura, que es utilizada también para la solicitud de Autorización
        electrónica y presentación electrónica de Transacciones al cobro, con lectura de los datos grabados en
        la banda magnética, el chip o cualquier otro dispositivo con el que cuente el Medio de Pago
        Electrónico.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>1.1.</span> Homologación de Terminales de Captura. Las Terminales de
        Captura con que la Empresa Adherida pretenda operar con el Sistema, deberán ser previamente
        homologadas por el Adquirente o terceros designados al efecto, quien controlará que tales Terminales
        de Captura reúnan las condiciones técnicas y de seguridad indispensables para operar con el Sistema.
      </Typography>

      <Typography className={classes.paragraph}>
        La homologación podrá ser otorgada por un período determinado o indeterminado, en forma individual por
        Terminal de Captura o en general para marcas o modelos. En este último caso, el Adquirente podrá en
        cualquier momento exigir la adecuación de la Terminal de Captura a nuevas normas o pautas, con el solo
        requisito de comunicarlo a la Empresa Adherida con una anticipación previa de 30 días corridos.
      </Typography>

      <Typography className={classes.paragraph}>
        La falta de tal homologación hará susceptible de rechazo o descuento de todas las Transacciones
        realizadas a través de tales terminales no autorizadas.
      </Typography>

      <Typography className={classes.paragraph}>
        Bajo ningún concepto podrá entenderse que la homologación implica el otorgamiento de garantía alguna
        por parte del Adquirente o el tercero homologante, no siendo estos responsables de errores o defectos
        en el funcionamiento de las Terminales de Captura homologadas.
      </Typography>

      <Typography className={classes.paragraph}>
        El Adquirente establecerá en el futuro los plazos y condiciones para la adecuación de las Terminales
        de Captura instaladas con anterioridad a la fecha de entrada en vigencia de estas Condiciones.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>1.2.</span> Forma de instrumentación. En cada Transacción la Terminal
        de Captura deberá emitir un Cupón, en una vía o por duplicado. El original firmado por el Usuario o el
        respaldo en medio electrónico para los casos que la firma no sea física o se sustituya por el ingreso
        de un PIN o la lectura de un código QR, deberá quedar archivado en la Empresa Adherida en que la
        Transacción se haya celebrado, por el término de doce (12) meses. Su duplicado será entregado al
        Usuario y servirá como constancia de la Transacción.
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida deberá controlar que los datos impresos en el Cupón coincidan con los impresos o
        grabados en el Medio de Pago Electrónico (frente y dorso), cuando correspondiese, así como los que
        aparezcan en el visor de la Terminal de Captura, si tuviera alguno. Cuando los datos difieran serán de
        aplicación las disposiciones de la cláusula 8.3 de las Condiciones Generales.
      </Typography>

      <Typography className={classes.paragraph}>
        En el caso de Tarjetas, la lectura de la banda magnética, del chip electrónico, u otros dispositivos
        con que cuente la Tarjeta es obligatoria. En caso de que no fuera posible obtener la lectura de la
        banda magnética o del chip electrónico, la Empresa Adherida deberá confeccionar un Cupón en forma
        manual, según lo establecido en el capítulo 2 siguiente.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>1.3.</span> Guarda de los Cupones. Las Empresas Adheridas deberán
        guardar los originales de los Cupones o las representaciones electrónicas correspondientes a esta
        operativa por el término de 12 (doce) meses y estarán obligados a presentarlos al Adquirente cuando
        les sean requeridos, dentro del plazo de 5 (cinco) días corridos a contar desde la fecha de solicitud.
      </Typography>

      <Typography className={classes.paragraph}>
        Si no se presentaren los Cupones dentro del plazo fijado, el Adquirente podrá descontar a la Empresa
        Adherida las sumas abonadas en concepto de tales Cupones.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>1.4.</span> Presentación de Transacciones al cobro. Las Transacciones
        que se efectúen a través de la Terminal de Captura no deben presentarse en OCA. Dicho procedimiento se
        encuentra reemplazado por la operativa de cierre de lote, por medio de la cual la Empresa Adherida
        efectuará la presentación electrónica de sus ventas.
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida deberá efectuar el cierre de lote en forma diaria. OCA podrá, pero no estará
        obligada a configurar las terminales con un cierre diario en forma automática (cierre de oficio).
      </Typography>

      <Typography className={classes.paragraph}>
        Los cierres efectuados los días sábados, domingos y feriados se considerarán presentados el primer día
        hábil siguiente.
      </Typography>

      <Typography className={classes.paragraph}>
        La realización del cierre de lote y aún la emisión de un acuse de recibo del mismo por parte del
        Adquirente o terceros designados al efecto, no implica conformidad con su contenido. Será deber de la
        Empresa Adherida controlar que lo remitido concuerde con lo procesado y acreditado dentro de los 60
        días de realizado el cierre de lote. Transcurrido dicho plazo se tendrá por consentida la Liquidación
        realizada, produciéndose la caducidad del derecho a reclamar diferencias por Transacciones no
        procesadas o no percibidas.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>1.5.</span> Transacciones sin lectura de banda, chip u otros
        dispositivos que tenga el Medio de Pago Electrónico. Salvo autorización previa, expresa y por escrito
        del Adquirente, la Empresa Adherida deberá abstenerse de operar a través de Terminales de Captura si
        no fuera posible obtener la lectura de la banda magnética o chip u otros dispositivos que tenga el
        Medio de Pago Electrónico. En este caso, deberá realizar la operación respetando las disposiciones del
        capítulo 2 siguiente.
      </Typography>

      <Typography className={classes.paragraph}>Bajo esta operación existen dos modalidades:</Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol type="a" className={classes.list}>
          <li>
            <b>Transacción fuera de línea:</b> cuando se realiza la Transacción por el dispositivo, pero no se
            tiene respuesta del Adquirente por esta vía, se puede solicitar vía telefónica, si la respuesta es
            afirmativa y confirma la Transacción, se debe ingresar, digitándolos en el teclado del
            dispositivo, el número de Autorización en la Terminal de Captura.
          </li>
          <li>
            <b>Transacción en modalidad teclado libre:</b> ocurre cuando en la Terminal de Captura no es
            posible obtener la lectura de la banda magnética, chip u otros dispositivos del Medio de Pago
            Electrónico. En este caso se debe digitar en el teclado de la Terminal de Captura el número del
            Medio de Pago Electrónico.
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>1.6.</span> Operaciones especiales. El Adquirente podrá autorizar a
        determinadas Empresas Adheridas a operar con lectura de banda magnética o chip u otros dispositivos
        que tenga el Medio de Pago Electrónico, aunque sin firma del Usuario ni emisión de comprobante
        respaldatorio de la Transacción o emisión de Cupón sólo para el Usuario. La Empresa Adherida asumirá
        exclusivamente el riesgo de la operativa y consecuentemente, el simple desconocimiento de la
        Transacción por el Usuario será causal de rechazo o descuento de la misma.
      </Typography>

      <Typography className={classes.paragraph}>
        El Adquirente podrá limitar el número de Transacciones a realizar por un mismo Usuario en dicha
        Empresa Adherida, según parámetros que establecerá periódicamente, sin necesidad de informarlo a la
        Empresa Adherida.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>1.7.</span> Preautorización. La preautorización es una operación
        mediante la cual la Empresa Adherida efectúa una reserva de fondos sobre la cuenta del Usuario, hasta
        que se reciba la confirmación de la realización de la Transacción, a través de su presentación al
        cobro, o bien expire su plazo de validez, el que coincide con el de una Autorización.
      </Typography>

      <Typography className={classes.paragraph}>
        Salvo los casos expresamente autorizados por el Adquirente, el monto de la operación no deberá exceder
        el de la preautorización, en cuyo caso la misma podrá ser rechazada o descontada total o parcialmente.
      </Typography>

      <Typography className={classes.paragraph}>
        Tampoco deberá la Empresa Adherida entregar mercaderías ni prestar servicios cubiertos por una
        preautorización después de ser notificado de que la preautorización ha sido cancelada o que el Medio
        de Pago Electrónico con que se solicitó la preautorización no se deberá aceptar.
      </Typography>

      <Typography className={classes.paragraph}>
        Las Transacciones confirmadas deberán ser presentadas al cobro dentro del procedimiento y lote de
        presentación habitual de Transacciones de compra.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>1.8.</span> Auditoría. La Empresa Adherida se obliga a permitir el
        acceso a sus instalaciones y equipos, en días y horario hábiles, del Adquirente o terceros designados
        al efecto, para auditar el funcionamiento de las Terminales de Captura y el cumplimiento de las
        disposiciones de estas Condiciones.
      </Typography>

      <Typography className={classes.paragraph}>
        El incumplimiento de esta obligación tornará susceptible de rechazo y/o descuento a todas las
        Transacciones presentadas con posterioridad al mismo.
      </Typography>

      <Typography className={classes.paragraph}>
        <b>2 OPERATIVA EN FORMA MANUAL</b>
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>2.1. Ámbito de aplicación:</span> Las disposiciones del presente
        capítulo serán de aplicación a aquellas Transacciones realizadas por los Usuarios en las Empresas
        Adheridas, instrumentadas en Cupones emitidos en forma manual y donde la Autorización es concedida por
        OCA en forma verbal, mediante una comunicación telefónica.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>2.2. Transacciones manuales:</span> Las Empresas Adheridas que
        documenten las Transacciones de forma manual deberán emitir un cupón manual, en tres vías, en el que
        incluirá el número de afiliado de la Empresa Adherida y razón social, el código de Autorización
        asignado, moneda, importe de la compra, plan de venta, número del Medio de Pago y nombre del Usuario.
      </Typography>

      <Typography className={classes.paragraph}>
        No se admiten transacciones manuales para Medios de Pago que requieren la utilización de PIN o Código
        QR.
      </Typography>

      <Typography className={classes.paragraph}>
        Se rechazarán los Cupones con enmiendas, tachaduras y/o adulterados.
      </Typography>

      <Typography className={classes.paragraph}>
        El triplicado del Cupón deberá ser devuelto al Usuario como constancia.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>2.3.</span> En todos los casos, la Empresa Adherida incluirá en un
        único Comprobante de Compra el monto total de los bienes y/o servicios adquiridos, detallando la
        fecha, el número de factura y el número de Autorización otorgado por OCA. La Empresa Adherida no podrá
        utilizar dos o más Comprobantes de Compra por separado para fraccionar una misma Transacción, debiendo
        entregar al Usuario en el acto de realizar la Transacción una copia del Comprobante de Compra. La
        Empresa Adherida no podrá solicitar a los Usuarios la firma de Comprobantes de Compra en blanco.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>2.4.</span> Depósito y entrega de Comprobantes de Compra. La Empresa
        Adherida deberá depositar en cualquiera de las oficinas de OCA, o las oficinas que OCA habilite a esos
        efectos, los Comprobantes de Compra originales debidamente completados y firmados en un sobre en el
        que constará el nombre y código de la Empresa Adherida, la fecha de presentación, la cantidad de
        Cupones que se adjunta, la suma de sus importes. La presentación se debe realizar dentro de los quince
        (15) días hábiles contados a partir de la fecha de la Transacción. En casos excepcionales este plazo
        podrá extenderse hasta 30 días hábiles contados de la fecha de la Transacción hasta el día del
        depósito inclusive, previa autorización de OCA. Los Comprobantes de Compra depositados por la Empresa
        Adherida se contabilizarán el día de cierre inmediato siguiente a la presentación. OCA pagará a las
        Empresas Adheridas en la opción que esté definida en cada caso.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>2.5.</span> Rechazo de comprobantes. OCA podrá rechazar Comprobantes de
        Compra y las Transacciones en ellos consignadas, y en consecuencia estará legitimada a desconocer y a
        no pagar a la Empresa Adherida el importe de los Comprobantes de Compra depositados, en cualesquiera
        de los casos detallados en la Cláusula 5.11 de las Condiciones Generales.
      </Typography>

      <Typography className={classes.paragraph}>
        No obstante, lo dispuesto en el presente inciso, OCA podrá, a su único y exclusivo juicio, aceptar de
        todas formas los Comprobantes de Compra y las Transacciones en ellos consignadas, sin justificación o
        expresión de causa alguna.
      </Typography>
      <Typography variant="h6" component="h3" className={classes.paragraph}>
        3 - PAGOS RECURRENTES
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>3.1.</span> <b>Ámbito de aplicación:</b> Las disposiciones del presente
        capítulo regulan el procedimiento para el cobro a los Usuarios que adhieran voluntariamente al
        servicio de débito automático, de las facturas periódicas por la contratación de los servicios y/o
        bienes ofrecidos por las Empresas Adheridas al Sistema.
      </Typography>

      <Typography className={classes.paragraph}>
        El débito automático es absolutamente independiente de la operación cuyas facturas se debitan y no
        garantiza la cobrabilidad futura de las mismas sino que en oportunidad del procesamiento de cada una
        de las facturas se consultará con el Emisor del Medio de Pago Electrónico la procedencia o rechazo del
        débito, el Emisor podrá tener en consideración, en general y entre otros, la vigencia del Medio de
        Pago Electrónico, disponibilidad de crédito y, en forma adicional se considerará la inexistencia al
        momento de procesamiento de cada factura, de alguna de las causales descriptas a lo largo de las
        presentes Condiciones que limiten o impidan su procesamiento.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>3.2.</span> <b>Modos de adhesión al servicio:</b> Los Usuarios podrán
        gestionar su adhesión, baja y/o modificación de las condiciones del servicio de débito automático,
        mediante alguno de los siguientes mecanismos, según disponga el Adquirente:
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol type="a" className={classes.list}>
          <li>Solicitando la adhesión al Emisor, cumpliendo los recaudos que se establezcan al efecto;</li>
          <li>
            Solicitándola a la Empresa Adherida, mediante la suscripción de la solicitud correspondiente; o
          </li>
          <li>Solicitándola a través del Adquirente.</li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>3.3.</span> <b>Tope máximo:</b> Si el importe a debitar superara el
        tope máximo del crédito del Medio de Pago, el débito será automáticamente rechazado; no se realizarán
        débitos parciales.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>3.4.</span> <b>Normas de procedimiento.</b>
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol className={classes.list}>
          <li>
            <b>3.4.1. Código especial de Empresa Adherida:</b> El Adquirente asignará a la Empresa Adherida un
            código de Empresa Adherida especial que sólo podrá ser utilizado por éste a efectos de la
            operativa del servicio de débito automático, no pudiendo ser utilizado bajo ninguna circunstancia
            y/o motivo para ninguna otra operativa diferente al débito automático.
          </li>
          <li>
            <b>3.4.2. Adhesiones y/o bajas al servicio de débito automático y débitos mensuales:</b> La
            información relativa a altas, bajas y/o modificaciones de Usuarios, solicitadas ante la Empresa
            Adherida será enviada por ésta al Adquirente en las fechas pactadas y a través del canal
            establecido a estos efectos, siendo el único medio válido para recibir y enviar la información
            vinculada a la operativa del procesamiento de los pagos recurrentes.
            <ol>
              <li>
                La Empresa Adherida remitirá al Adquirente la información utilizando el sistema de pagos
                recurrentes de OCA, disponible en la página web de OCA. A tales efectos la Empresa Adherida
                informará a OCA los Usuarios que hayan optado por el presente sistema de pagos ajustándose al
                formato de archivo que OCA determine, el que se encuentra disponible en la página web para
                Pagos Recurrentes a la que la Empresa Adherida adhiere.
              </li>
              <li>
                La Empresa Adherida enviará periódicamente los débitos que deberá contener los datos
                obligatorios que se detallan en la página web de OCA según el tipo y Sello del Medio de Pago.
                OCA devolverá a la Empresa Adherida, a través de este sistema, la información referente a
                problemas técnicos que surgieren de los formatos enviados, así como los casos de rechazo por
                problemas crediticios.
              </li>
            </ol>
          </li>
          <li>
            <b>3.4.3. Base de datos de Usuarios adheridos al servicio de débito automático:</b> La Empresa
            Adherida llevará una base de datos de Usuarios adheridos al servicio de débito automático.
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida será exclusivamente responsable frente a los Usuarios por los rechazos, falta de
        procesamiento o procesamiento indebido de operaciones, motivados en el incumplimiento de las
        obligaciones a su cargo o error en el manejo de la base de Usuarios adheridos.
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol start={4} className={classes.list}>
          <li>
            <b>3.4.4. Instrucción de débito:</b> La Empresa Adherida, dentro de las fechas de presentación
            elegida por la Empresa Adherida para presentar los débitos, las que son detalladas en la página
            web a la que la Empresa Adherida accede para presentar los archivos, deberá suministrar al
            Adquirente la información, de los Usuarios que se integran al sistema de débito automático, y los
            importes adeudados en el mes; la cantidad de débitos a procesar y el importe total.
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        Ambas partes considerarán la posibilidad de incluir en el presente sistema aquellos Usuarios de la
        Empresa Adherida que tengan deudas anteriores al mes en que solicita el alta al sistema de débito
        automático, siendo en tal caso responsabilidad de la Empresa Adherida los importes y los acuerdos que
        en tal sentido realice con los Usuarios. La desafiliación de un Usuario al presente sistema de débito
        automático ya provenga de decisión de éste o del Emisor, aparejará en forma inmediata su exclusión de
        los débitos que la Empresa Adherida remitirá.
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol start={5} className={classes.list}>
          <li>
            <b>3.4.5. Incumplimiento:</b> El incumplimiento de las condiciones antes referidas en este
            capítulo y las indicadas en la página web de OCA para Pagos Recurrentes, así como la existencia de
            deficiencias en la información brindada, eximirán al Adquirente de toda responsabilidad por la
            falta de procesamiento de las operaciones.
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        El Adquirente no será responsable frente a los Usuarios por los errores operativos en que, por
        cualquier causa, incurra la Empresa Adherida respecto al monto de los adeudos correspondientes al
        presente sistema de débito automático ni de los aportes mensuales a la Empresa Adherida y que no
        hubieran sido enviados en tiempo y forma, mediante la utilización de los mecanismos operativos y de
        seguridad implementados por el Adquirente.
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol start={6} className={classes.list}>
          <li>
            <b>3.4.6. Procesamiento de las operaciones:</b> Una vez enviada la información por la Empresa
            Adherida de acuerdo a lo indicado en la cláusula 3.4.4 anterior, el Adquirente procesará las
            operaciones para su débito a los Usuarios cuyos Medios de Pago Electrónicos se encuentren a ese
            momento en condiciones normales de operar.
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        Las presentaciones serán abonadas a la Empresa Adherida por OCA dentro de los plazos de pago vigentes.
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol start={7} className={classes.list}>
          <li>
            <b>3.4.7. Resultado de los procesos:</b> Dentro de las 24 horas de realizados los procesos, el
            Adquirente pondrá a disposición de la Empresa Adherida el detalle de los débitos efectuados, así
            como de los no realizados.
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        Respecto de estos últimos, la Empresa Adherida informará al Usuario del rechazo y gestionará su cobro
        por otra vía, intentando en todo momento no dejar al Usuario sin cobertura del producto o prestación
        del servicio por el simple rechazo del débito automático.
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol start={8} className={classes.list}>
          <li>
            <b>3.4.8. Información a Usuarios. Recibo:</b> En los casos de Usuarios adheridos al servicio de
            débito automático, la Empresa Adherida incluirá en las facturas y/o liquidaciones que emita la
            información de que se debitará de su Medio de Pago Electrónico.
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        Salvo en los casos previstos en la cláusula 3.5. del presente capítulo, las Empresas Adheridas no
        podrán reclamar a los Usuarios los importes correspondientes a facturas que éstos hubieran abonado a
        través del servicio de débito automático aquí regulado. El resumen de cuenta del Medio de Pago
        Electrónico, con indicación del débito efectuado, será comprobante suficiente del pago de la factura a
        que obedezca dicho débito.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>3.5.</span> <b>Operaciones irregulares o defectuosas:</b> Producido un
        reclamo o desconocimiento de un Usuario, cuando la solicitud correspondiente haya sido presentada ante
        la Empresa Adherida, ésta deberá enviar al Adquirente dicha solicitud dentro de los 5 días corridos de
        recibido el pedido.
      </Typography>

      <Typography className={classes.paragraph}>
        La desafiliación de un Usuario al presente sistema de débito automático ya provenga de decisión de
        éste o de OCA, aparejará en forma inmediata su exclusión de los débitos que la Empresa Adherida
        remita.
      </Typography>

      <Typography className={classes.paragraph}>
        OCA no será responsable frente a los Usuarios por los errores operativos en que, por cualquier causa,
        incurra la Empresa Adherida respecto al monto de los adeudos correspondientes al presente sistema de
        débito automático ni de los aportes mensuales a la Empresa Adherida y que no hubieran sido enviados en
        tiempo y forma, mediante la utilización de los mecanismos operativos y de seguridad implementados por
        OCA.
      </Typography>

      <Typography className={classes.paragraph}>
        El incumplimiento de los plazos como la imposibilidad de probar el consentimiento del Usuario, sea por
        falta de constancia documentada de la autorización de débito, porque la firma estampada difiere de la
        del Usuario o por el incumplimiento de cualquier otro requisito, habilitará al Adquirente a descontar
        tal operación en la forma prevista en la cláusula 8.1 de las presentes Condiciones Generales, así como
        a realizar en forma inmediata la baja del débito automático.
      </Typography>

      <Typography className={classes.paragraph}>
        Las disposiciones del párrafo anterior se aplicarán en todos los demás casos que establezca la
        legislación vigente.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>3.6.</span> <b>Medios de transferencia de información:</b> Todo
        archivo, listado y/o comunicación que deba ser confeccionado y remitido entre OCA y la Empresa
        Adherida, tendrá el formato que el Adquirente establezca, pudiendo ser modificado por éste, quien debe
        notificar todo cambio con una anticipación no menor a 30 días de la fecha en que tendrá efecto.
      </Typography>

      <Typography className={classes.paragraph}>
        En casos de fuerza mayor que no permitan desarrollar la operativa habitual en los tiempos
        establecidos, el Adquirente informará el procedimiento de contingencia.
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        4 - VENTAS ELECTRÓNICAS – A TRAVÉS DEL SITIO WEB DEL COMERCIO
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>4.1.</span> <b>Ámbito de aplicación:</b> El presente capítulo aplica a
        las Empresas Adheridas habilitadas a operar con esta modalidad de venta y regula el procedimiento para
        la realización de Transacciones de Medios de Pago Electrónico No Presente. Se incluyen dentro de esta
        operativa: i) comercios electrónicos, ii) suscripciones electrónicas, y iii) toda otra modalidad que
        implique realizar la venta sin estar presente el Usuario en la Empresa Adherida, incluyendo, pero no
        limitado a, la utilización de links, códigos QR u otros.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>4.2.</span> <b>Código de Empresa Adherida:</b> El Adquirente podrá
        asignar a la Empresa Adherida un código de Empresa Adherida especial que sólo podrá ser utilizado a
        efectos de la modalidad de Transacciones de Medios de Pago Electrónico No Presente; en determinados
        casos el Adquirente podrá autorizar el uso de un mismo código para todos los tipos de venta.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>4.3.</span> <b>Canales de Venta - Integradores:</b> La Empresa Adherida
        acepta que las Transacciones deberán ser cursadas únicamente a través de los canales e integradores de
        pagos homologados por el Adquirente, por lo que, en caso de duda de la homologación de algún
        integrador de pagos, deberá consultar previamente al Adquirente. La Empresa Adherida conoce y acepta
        que el listado de integradores de pagos homologados puede ser modificado, ampliado o reducido en
        cualquier momento, lo cual no justificará en ningún caso la contratación con un integrador de pagos no
        homologado por el Adquirente, debiendo la Empresa Adherida tomar todas las precauciones necesarias
        para evitar procesar Transacciones mediante integradores de pagos no homologados por el Adquirente.
      </Typography>

      <Typography className={classes.paragraph}>
        El incumplimiento de la Empresa Adherida hará pasible de rechazo y/o descuento de todas las
        Transacciones realizadas por la Empresa Adherida bajo esta modalidad.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>4.4.</span>{' '}
        <b>Información que debe requerirse para la Autorización:</b> La Empresa Adherida deberá comunicarse a
        través de un integrador homologado, en forma electrónica y en línea con el Adquirente para obtener la
        Autorización correspondiente, resultando inválida toda Autorización obtenida de modo diferente.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>4.5.</span> <b>Proceso de Conciliación:</b> Para realizar la
        conciliación de las ventas de la Empresa Adherida con las Transacciones Autorizadas por OCA, la
        Empresa Adherida recibirá diariamente vía correo electrónico un reporte enviado por OCA con todas las
        Transacciones realizadas (en adelante, el “Reporte”). La Empresa Adherida se obliga a comunicar a OCA
        en el plazo de cinco (5) días corridos cualquier diferencia entre las Transacciones realizadas por la
        Empresa Adherida y el Reporte. Asimismo, en caso que la Empresa Adherida no hubiese recibido el
        Reporte, deberá comunicar a OCA la no recepción del mismo al día hábil siguiente a la fecha en la que
        debió haberlo recibido. Vencidos los plazos establecidos en la presente cláusula, se considerará que
        la Empresa Adherida acepta el contenido del Reporte, obligándose a no realizar ningún tipo de reclamos
        por este concepto.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>4.6.</span> <b>Riesgos de la operativa:</b> Ni las entidades del
        Sistema ni el Adquirente serán de ningún modo responsables de las consecuencias fraudulentas de este
        tipo de Transacciones, atento el alto potencial de fraude que presentan, el cual es asumido de plena
        responsabilidad por la Empresa Adherida al solicitar su habilitación para operar bajo tal modalidad,
        siendo de responsabilidad de la Empresa Adherida las Transacciones que resultaran fraudulentas por
        medio de esta modalidad.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>4.7.</span> <b>Entrega de los productos:</b> La entrega de los
        productos vendidos a través de esta operativa será efectuada por la Empresa Adherida, por su exclusiva
        cuenta y riesgo.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>4.8.</span> La Empresa Adherida deberá archivar por el lapso mínimo de
        1 (un) año toda documentación que permita certificar el perfeccionamiento de las Transacciones y
        entregarla al Adquirente toda vez que ésta lo requiera.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>4.9.</span> <b>Presentación de operaciones al cobro:</b> Se realiza en
        forma automática para todas las Transacciones confirmadas y con captura electrónica. No aplica en esta
        modalidad de venta el cierre de lote a que refiere el capítulo 1 de estas Condiciones Particulares.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>4.10.</span> <b>Prueba de las Transacciones y monitoreo:</b> Las
        Transacciones realizadas en virtud de la presente cláusula podrán ser probadas por los elementos que
        surgen del sistema informático de OCA, aceptando la Empresa Adherida desde ya que OCA utilice sus
        registros informáticos como medio de prueba, sin perjuicio del error manifiesto y de las observaciones
        fundadas y efectivamente probadas que pueda realizar la Empresa Adherida en las instancias
        correspondientes. La Empresa Adherida llevará a cabo todas las medidas necesarias a efectos de evitar
        adulteraciones o falsificaciones desde su sitio web. A este efecto, la Empresa Adherida faculta a OCA
        a utilizar cualquier sistema idóneo para acreditar la pertinencia de las Transacciones cursadas.
        Asimismo, la Empresa Adherida se obliga a implementar todas las medidas y mecanismos que sean
        necesarios o convenientes para garantizar que la operativa regulada por el presente se lleve a cabo en
        forma correcta y ágil.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>4.11.</span> <b>Impugnación de Transacciones:</b> Sin perjuicio de lo
        dispuesto en la cláusula 5.11 siguiente, la impugnación de Transacciones se regirá por lo establecido
        en la cláusula 5.12 de las Condiciones Generales.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>4.12.</span> <b>Arrepentimiento:</b> Las Empresas Adheridas deberán
        otorgar y permitir a los Usuarios el ejercicio del derecho de arrepentimiento, sin invocación de causa
        alguna, como mínimo hasta el quinto día contado desde la realización de la Transacción, recepción de
        la mercadería o prestación del servicio, lo último que ocurra.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>4.13.</span> <b>Operaciones impugnadas:</b> La sola presentación de una
        impugnación de la operación por el Usuario, habilita a OCA a realizar un análisis y solicitar a la
        Empresa Adherida toda la información probatoria de la misma, pudiendo concluirse con el descuento
        automático, a la Empresa Adherida, de los importes acreditados y desconocidos.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>4.14.</span> <b>Suspensión, modificación o cancelación:</b> El
        Adquirente puede, en cualquier momento, suspender, modificar o cancelar, total o parcialmente, para
        algunos o para todos los códigos de la Empresa Adherida la operativa "Transacciones con Medios de Pago
        Electrónico No Presente", sin invocar causa alguna y con el solo requisito de notificarlo a la Empresa
        Adherida.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>4.15.</span> Si la Empresa Adherida se viera imposibilitada de realizar
        los ajustes necesarios para continuar operando bajo esta modalidad, deberá dar inmediato aviso al
        Adquirente para que éste proceda a la baja de la habilitación otorgada, sin que la Empresa Adherida
        pueda reclamar resarcimiento alguno, atento haber evaluado dicha contingencia al momento de solicitar
        la habilitación para operar.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>4.16.</span> <b>Requisitos de Seguridad</b>
      </Typography>

      <Typography className={classes.paragraph}>
        <b>A. Requisitos.</b>
      </Typography>

      <Typography className={classes.paragraph}>
        La Empresa Adherida asume la obligación de cumplir con los requisitos de seguridad de la información
        que se detallan a continuación (en adelante, los “Requisitos de Seguridad”), los que deberán ser
        aplicados por la Empresa Adherida al equipamiento tecnológico y a las aplicaciones web expuestos a
        Internet (sitio web de la Empresa Adherida) que intervengan en la venta de bienes y/o servicios
        mediante el uso de las Tarjetas:
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol type="a" className={classes.list}>
          <li>
            Contar con licencias originales de sistemas operativos y software de base, y mantenerlos
            actualizados a las últimas versiones disponibles. A los efectos de la presente cláusula, deben
            entenderse por “actualizaciones”: los parches, hotfixes, etc., distribuidas por los fabricantes;
          </li>
          <li>
            Utilizar protocolos de comunicación seguros (https) entre el sitio web de la Empresa Adherida y el
            Usuario (Usuario de OCA), conforme a la norma PCI DSS vigente;
          </li>
          <li>
            Implementar metodologías de desarrollo seguro durante el ciclo de vida completo de las
            aplicaciones expuestas, de acuerdo a mejores prácticas de la industria (por ejemplo, el proyecto
            OWASP);
          </li>
          <li>
            Suscribirse a los boletines de seguridad de los proveedores del software utilizado, con el fin de
            recibir información oportuna sobre vulnerabilidades que surjan, y aplicar los parches/correcciones
            pertinentes;
          </li>
          <li>
            Contar con herramientas de firewall correctamente configuradas, que permitan realizar una adecuada
            segmentación de la red y bloquear tráfico no deseable;
          </li>
          <li>
            Utilizar un software de Antivirus/Antimalware en el equipamiento tecnológico que se conecte con
            OCA y mantenerlo actualizado y monitoreado regularmente, asegurando que las alertas de estos
            productos permitan remediar los riesgos reportados;
          </li>
          <li>
            Contar con un servicio de escaneo de vulnerabilidades trimestral, el cual garantice la detección
            de vulnerabilidades existentes, y un proceso de corrección para las vulnerabilidades detectadas;
          </li>
          <li>
            No almacenar datos de los Usuarios, ni de las Tarjetas (tales como número de tarjeta, código de
            seguridad, contenido de la banda magnética o chip) en ningún equipamiento propio ni de terceros,
            ni divulgarlos por ningún medio.
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        A todos los efectos del presente, debe interpretarse que la referencia a los Requisitos de Seguridad
        incluye a los estipulados al momento de la firma del presente, así como las sucesivas modificaciones
        que se realicen a los mismos por parte de OCA.
      </Typography>

      <Typography className={classes.paragraph}>
        <b>B. Cumplimiento. Modificación.</b>
      </Typography>

      <Typography className={classes.paragraph}>
        En caso de que la Empresa Adherida no cumpliera con los Requisitos de Seguridad, OCA podrá rechazar
        todas las Transacciones realizadas por la Empresa Adherida y no abonar a ésta los importes
        correspondientes a las dichas Transacciones.
      </Typography>

      <Typography className={classes.paragraph}>
        Las partes dejan constancia de que OCA podrá, en cualquier momento, modificar los Requisitos de
        Seguridad, lo cual deberá ser notificado a la Empresa Adherida. Dentro de los cinco (5) días
        siguientes a la emisión de la notificación mencionada, la Empresa Adherida deberá adaptarse a las
        modificaciones de los Requisitos de Seguridad, salvo que OCA establezca un plazo de tolerancia mayor
        en su notificación.
      </Typography>

      <Typography className={classes.paragraph}>
        <b>C. Incidentes de Seguridad.</b>
      </Typography>

      <Typography className={classes.paragraph}>
        Las partes dejan constancia de que los Requisitos de Seguridad son el mínimo exigido a la Empresa
        Adherida, debiendo ésta implementar y aplicar en forma constante todas las medidas que sean necesarias
        o convenientes para evitar la interceptación y/o accesos no autorizados a datos de los Usuarios y/o de
        las Transacciones (en adelante, los “Incidentes de Seguridad”) y todas las consecuencias que puedan
        derivar de aquellos. En caso que la Empresa Adherida sufra un Incidente de Seguridad –aun cuando ésta
        hubiere cumplido con los Requisitos de Seguridad– la Empresa Adherida asume toda la responsabilidad en
        forma expresa e ilimitada por tales Incidentes de Seguridad, así como por el uso no autorizado de
        datos, y por cualquier daño que se produzca a OCA y/o a los Usuarios como consecuencia de todo y
        cualquier Incidente de Seguridad, pudiendo OCA deducir dichos daños del producido de las ventas de la
        Empresa Adherida en todos los casos, lo cual la Empresa Adherida consiente expresamente. En especial,
        la Empresa Adherida asume la responsabilidad por cualquier Transacción realizada como consecuencia de
        todo y cualquier Incidente de Seguridad, ya sea que la misma se realice en su sitio web o en otro.
      </Typography>

      <Typography className={classes.paragraph}>
        Adicionalmente a lo anterior, en caso de la existencia de un Incidente de Seguridad o posible
        existencia del mismo llegue a conocimiento de OCA, OCA podrá, sin responsabilidad de su parte en
        ningún caso, rescindir las relaciones contractuales con la Empresa Adherida o bien, suspender la
        ejecución, total o parcialmente, hasta que la Empresa Adherida demuestre a satisfacción de OCA, que la
        operativa en su sitio web es segura para la realización de Transacciones, así como para la adecuada
        protección de los datos de los Usuarios y de OCA. En caso de que OCA opte por suspender la ejecución
        de las presentes Condiciones, la Empresa Adherida no podrá procesar ninguna Transacción, y en caso de
        procesar alguna Transacción, se considerará una operación no autorizada en todos los casos.
      </Typography>

      <Typography className={classes.paragraph}>
        Si en algún momento, la Empresa Adherida no pudiera cumplir con los Requisitos de Seguridad, deberá
        comunicar a OCA de forma inmediata, quien inhabilitará a la Empresa Adherida para continuar operando
        en esta modalidad y/o rescindirá las relaciones contractuales con la Empresa Adherida, sin que ninguna
        de estas acciones por parte de OCA otorgue derecho alguno de indemnización a favor de la Empresa
        Adherida. Dicha obligación de comunicar inmediatamente alcanza las situaciones en que la Empresa
        Adherida se percate de cualquier evento que pudiera dar lugar a un Incidente de Seguridad.
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        6 - ARRENDAMIENTO Y SERVICIOS POS
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.1.</span> En caso de que la Empresa Adherida haya seleccionado la
        opción de “Arrendamiento de POS” en el Formulario de Adhesión, serán de aplicación las siguientes
        condiciones particulares.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.2.</span> OCA arrienda al Cliente terminales POS (en adelante, los
        “Equipos”), los cuales serán entregados por OCA o por una empresa contratada por OCA a tales efectos,
        sirviendo el remito de entrega a la Empresa Adherida como constancia de recepción en forma
        satisfactoria por la Empresa Adherida. OCA se obliga a proporcionar la información y descripción del
        POS por alguna de las vías de comunicación previstas bajo las Condiciones Generales.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.3.</span> En caso de cambio de Equipo/s, las presentes Condiciones
        Especiales se considerarán aplicables a las nuevas unidades.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.4.</span> Los servicios derivados de estas Condiciones Particulares,
        es decir, entrega de terminales, instalación de las mismas, programaciones y reprogramaciones,
        capacitación, servicio de mantenimiento técnico, cobranzas por los servicios prestados y otros que
        explícitamente surjan del Contrato, estarán a cargo y serán cumplidos por RESONET.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.5.</span> Si OCA modificare la empresa a cargo de estos servicios, lo
        comunicará fehacientemente al Cliente, indicando el día de la sustitución y la nueva empresa que
        prestará el servicio, a lo que el Cliente desde ya presta su consentimiento. El cambio de proveedor de
        servicio por parte de OCA no implicará la firma de un nuevo Formulario de Adhesión o requerirá la
        firma de una adenda a las presentes Condiciones Particulares.
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.6.</span> <b>Plazo.</b>
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol type="a" className={classes.list}>
          <li>
            El plazo del arrendamiento de Equipos será de veinticuatro (24) meses desde la instalación de/los
            Equipo/s.
          </li>
          <li>
            Se establece la prórroga automática a su vencimiento por período de doce (12) meses, salvo
            comunicación de rescindirlo con al menos 30 días de anticipación a su terminación/renovación.
          </li>
          <li>La terminación del contrato generará inmediata desconexión del servicio.</li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.7.</span> <b>Precio</b>
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol type="a" className={classes.list}>
          <li>
            La Empresa Adherida pagará a RESONET el precio del arrendamiento de/los Equipo/s y el servicio
            técnico de mantenimiento, siendo aplicables las tarifas indicadas en el Formulario de Adhesión.
          </li>
          <li>
            Los precios del Formulario de Adhesión no incluyen IVA ni otros impuestos, los cuales son cargo de
            la Empresa Adherida.
          </li>
          <li>
            El precio del arriendo se reajustará el 1 de enero de cada año de acuerdo al precio de la UI.
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.8.</span> <b>Forma de Pago</b>
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol type="a" className={classes.list}>
          <li>
            Los pagos deberán ser realizados dentro de los treinta (30) días corridos de emitida la factura.
          </li>
          <li>El arrendamiento se facturará por trimestre adelantado.</li>
          <li>El hecho de no recibir la factura no exime de responsabilidad en el pago.</li>
          <li>
            La falta de pago en plazo genera recargo por mora a la máxima tasa de interés legal vigente al
            momento del incumplimiento.
          </li>
          <li>
            En caso de falta de pago, OCA podrá bloquear el/los Equipo/s. A los efectos de la reconexión la
            Empresa Adherida deberá cancelar los adeudos más intereses y tasa de reconexión vigente a la fecha
            de restitución del servicio.
          </li>
          <li>
            La Empresa Adherida autoriza expresamente a OCA a descontar del pago de las Transacciones
            presentadas al cobro por la Empresa Adherida para destinarlo al pago del Precio establecido en la
            cláusula 6.7 anterior.
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.9.</span> <b>Servicios Incluidos</b>
      </Typography>

      <Typography>
        RESONET prestará a la Empresa Adherida los servicios que se detallan a continuación:
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol type="a" className={classes.list}>
          <li>
            <b>Instalación:</b> programación de/los Equipo/s incorporando los datos de la Empresa Adherida y
            realización de las pruebas necesarias para verificar su normal funcionamiento. A tales efectos, la
            Empresa Adherida deberá contar con una adecuada instalación eléctrica (toma de “tres patas en
            línea”). Estos requerimientos de infraestructura y su adecuado mantenimiento son por cuenta, cargo
            y costo exclusivo de la Empresa Adherida.
          </li>
          <li>
            <b>Capacitación:</b> capacitación inicial de operación del Equipo para el personal del Cliente.
          </li>
          <li>
            <b>Apoyo telefónico:</b> las 24 horas, los 365 días del año.
          </li>
          <li>
            <b>Mantenimiento de Equipos:</b> RESONET comercializará los insumos necesarios para el
            funcionamiento de/los Equipo/s o indicará a la Empresa Adherida donde puede obtenerlos, no estando
            incluido el precio establecido en la cláusula 6.7 anterior.
          </li>
          <li>
            <b>Instalación personalizada (OPCIONAL):</b> De optar por la instalación personalizada de/los
            Equipo/s, la Empresa Adherida deberá abonar a RESONET el 30% del costo de movilidad considerando a
            tal fin el valor del litro de nafta super, vigente al momento de la visita, por kilómetro
            recorrido (ida y vuelta) desde RESONET al local de la Empresa Adherida, más el valor de los peajes
            (ida y vuelta) en caso de que aplique. No tendrán estos costos las instalaciones personalizadas
            que se realicen en las ciudades de Montevideo, Maldonado y Punta del Este.
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.10.</span> <b>Obligaciones de la Empresa Adherida</b>
      </Typography>

      <Typography>
        Los Equipos son propiedad de OCA, aceptando la Empresa Adherida su calidad de arrendatario, siendo
        obligaciones de la Empresa Adherida:
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol type="a" className={classes.list}>
          <li>Abonar el precio del arriendo.</li>
          <li>
            Usar el/los Equipo/s para la finalidad que se destina según estas Condiciones Particulares y
            mantenerlos en condiciones adecuadas para su funcionamiento según las especificaciones previstas
            por OCA o RESONET.
          </li>
          <li>
            Utilizar los insumos apropiados para los Equipos, siendo el costo de los insumos asumido
            exclusivamente por la Empresa Adherida.
          </li>
          <li>
            Notificar inmediatamente a OCA o a RESONET sobre cualquier falla que impida total o parcialmente
            el funcionamiento del Equipo.
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.11.</span> <b>Rescisión por Incumplimiento</b>
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol type="a" className={classes.list}>
          <li>
            En caso de incumplimiento de cualquiera de las obligaciones establecidas en las presentes
            Condiciones Particulares, la parte afectada por el incumplimiento podrá requerir el cumplimiento
            de la obligación incumplida dentro de las cuarenta y ocho (48) horas hábiles posteriores a su
            notificación, transcurrido el cual si no se hubiere subsanado podrá resolver el arrendamiento y
            servicios de POS, y reclamar los daños y perjuicios a los que el incumplimiento diere lugar. En
            caso de rescisión, todas las sumas adeudadas serán inmediatamente exigibles.
          </li>
          <li>
            En caso de rescisión anticipada por parte de OCA como consecuencia de un incumplimiento de la
            Empresa Adherida, la Empresa Adherida pagará a OCA la totalidad de las cuotas del arriendo
            pendiente, más los restantes daños y perjuicios a que hubiere lugar.
          </li>
          <li>
            Lo anterior es sin perjuicio de lo establecido en la cláusula 19 de las Condiciones Generales.
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.12.</span> <b>Sobre Los Equipos</b>
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol type="a" className={classes.list}>
          <li>
            La Empresa Adherida será responsable por las roturas de/los Equipo/s y por los deterioros como
            consecuencia de un uso descuidado o por culpa o negligencia de la Empresa Adherida o sus
            dependientes, debiendo la Empresa Adherida hacerse cargo de los costos de dichas reparaciones o de
            la sustitución de/los Equipo/s en caso de que no tuviere arreglo. En caso de que el/los Equipo/s
            fueren hurtados o perecieran en un incendio, la Empresa Adherida deberá abonar su valor.
          </li>
          <li>
            <b>Devolución de Equipos:</b> será inmediata luego de extinguidas las presentes Condiciones
            Especiales o la relación comercial con OCA, y dentro de las 48 horas siguientes de producido el
            requerimiento, cumpliendo las instrucciones dictadas por OCA o RESONET. Se fija una multa diaria
            de 30 U$S más IVA por día de atraso por Equipo hasta su devolución.
          </li>
        </ol>
      </Typography>

      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.13.</span> <b>Responsabilidades</b>
      </Typography>

      <Typography component="div" className={classes.paragraph}>
        <ol type="a" className={classes.list}>
          <li>
            OCA no asegura el funcionamiento ininterrumpido de/los Equipo/s ni que aquél se produzca libre de
            errores, asumiendo el compromiso de repararlo o sustituirlo en caso de que el desperfecto fuera
            del propio Equipo. A dichos efectos, el Cliente deberá notificar a RESONET dentro de las 48 horas
            de conocido el desperfecto.
          </li>
          <li>
            OCA se compromete a realizar los mayores esfuerzos para dejar solucionado el desperfecto dentro de
            las 48 horas posteriores al reclamo, si estos fuesen desde Montevideo o Maldonado, o a las 72
            horas si el origen de los reclamos proviene del resto del país (sin considerar los tiempos de
            envío).
          </li>
          <li>
            OCA no tendrá responsabilidad alguna en reparar o sustituir los Equipos en los siguientes casos:
            <ol type="i">
              <li>Equipos utilizados u operados con negligencia;</li>
              <li>Equipos manipulados en contravención a lo dispuesto en el Manual de Usuario;</li>
              <li>
                Equipos manipulados por personas no autorizadas por OCA o RESONET u operados negligentemente.
              </li>
            </ol>
          </li>
          <li>
            OCA queda exonerado de cualquier tipo de responsabilidad por los daños y perjuicios, físicos,
            comerciales, personales o de cualquier otra índole que se pudieran experimentar en el o los
            inmuebles y/o bienes de la Empresa Adherida y/o personas, inmuebles y bienes vecinos durante la
            vigencia de las presentes Condiciones Especiales, cualquiera fuese el origen de los mismos.
          </li>
          <li>
            Es exclusiva responsabilidad de la Empresa Adherida verificar los códigos de comercio que tiene el
            Equipo al momento de la instalación y luego de cada reprogramación. La Empresa Adherida será
            responsable por cualquier error u omisión en la correcta configuración de los mismos, haciéndose
            cargo de todas las gestiones que sean necesarias para regularizar la situación y todos los
            reclamos de pago ante los sellos. OCA y RESONET quedan exonerados de cualquier tipo de
            responsabilidad que se genere por la configuración de los códigos de comercio.
          </li>
        </ol>
      </Typography>

      <Typography variant="h6" component="h3" className={classes.paragraph}>
        7 - INTERPRETACIÓN
      </Typography>

      <Typography className={classes.paragraph}>
        En lo no expresamente previsto en las presentes Condiciones Particulares serán de aplicación las
        Condiciones Generales.
      </Typography>
    </>
  );
}

export default TermsAndConditions;
