import { Button, createStyles, Link, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { FormSelectField } from '../../components/forms/FormSelectField';
import { FormTextField } from '../../components/forms/FormTextField';
import schemas from '../../data/schemas';
import { currencyHelper } from '../../helpers/currencyHelper';
import { Currency, Status } from '../../store/config/enums';
import { CustomSnackbar } from '../CustomSnackbar';
import { QuickLink } from '../Icons/QuickLink';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '1rem 1.5rem',
      position: 'sticky',
      '& > *': {
        margin: '0.5rem 0',
      },
      right: '1rem',
      width: '16rem',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1rem',
    },
    boltIcon: {
      marginRight: '0.5rem',
    },
    formControl: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: theme.spacing(1),
      gap: '1rem',
      '& > *': {
        width: '50%',
      },
    },
    shareQr: {
      whiteSpace: 'nowrap',
      textAlign: 'center',
      display: 'inline-block',
      margin: '15px 0 0',
      width: '100%',
    },
  }),
);

interface QuickLinkCardProps {
  className: string;
  businessName: string;
  openQrDialog: (setOpen: boolean) => void;
}

interface Values {
  currency: number;
  amount: string;
}

function QuickLinkCard({ className, businessName, openQrDialog }: QuickLinkCardProps) {
  const [linkCreated, setLinkCreated] = useState<boolean>(false);
  const classes = useStyles();
  const theme = useTheme();

  const submit = (values: Values, helpers: FormikHelpers<Values>) => {
    setLinkCreated(true);
    navigator.clipboard.writeText(
      `${window.location.origin}/${businessName}/${currencyHelper.getCurrencyName(values.currency, true)}/${
        values.amount
      }`,
    );
  };

  const closeSnack = () => {
    setLinkCreated(false);
  };

  return (
    <>
      <Formik
        initialValues={{
          currency: Currency.PESO,
          amount: '',
        }}
        validationSchema={schemas.QuickLinkSchema}
        onSubmit={submit}
      >
        <Form className={className}>
          <Paper variant="outlined" className={classes.container}>
            <div className={classes.flex}>
              <QuickLink color={theme.palette.secondary.main} className={classes.boltIcon} />
              <Typography variant="h5">Links rápidos</Typography>
            </div>
            <div className={classes.formControl}>
              <Field
                name="currency"
                component={FormSelectField}
                label="Moneda"
                options={currencyHelper.mapCurrency(Currency, (i: Currency) => {
                  return {
                    id: i,
                    value: currencyHelper.getCurrencyToken(i),
                  };
                })}
              />
              <Field name="amount" component={FormTextField} type="text" placeholder="Importe" />
            </div>
            <Button type="submit" fullWidth disableElevation color="primary" variant="contained">
              Compartir
            </Button>
            <Link href="#" color="primary" className={classes.shareQr} onClick={() => openQrDialog(true)}>
              Ver QR para pagos rápidos
            </Link>
          </Paper>
        </Form>
      </Formik>
      <CustomSnackbar
        open={linkCreated}
        message={'Link copiado al portapapeles'}
        handleClose={closeSnack}
        type={Status.SUCCESS}
      />
    </>
  );
}

export default QuickLinkCard;
