import React from 'react';
import { createStyles, Theme, makeStyles, Typography, Paper, Divider } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { numberHelper } from './../../helpers/numberHelper';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '24px 36px',
      borderRadius: '6px',
      boxShadow: '0 1px 4px #15223214',
      border: 'none',
    },
    flexRowDescription: {
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'space-between',
      },
      display: 'flex',
      alignItems: 'center',
      '& *': {
        paddingRight: '5px',
      },
    },
    flexRowAmounts: {
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'space-between',
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
      display: 'flex',
      alignItems: 'center',
    },
    flexRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '6px',
    },
    divider: {
      [theme.breakpoints.up('sm')]: {
        visibility: 'hidden',
      },
      margin: '0.4rem 1rem',
    },
  }),
);

interface ColoredCardWithTotalsProps {
  description: string;
  pesosAmount: number;
  usdAmount: number;
  pesosCount: number;
  usdCount: number;
  color: 'error' | 'success' | 'warning';
  className: string;
}

function ColoredCardWithTotals({
  description,
  pesosAmount,
  usdAmount,
  pesosCount,
  usdCount,
  color,
  className,
}: ColoredCardWithTotalsProps) {
  const theme = useTheme();
  const classes = useStyles();

  const paletteColor = theme.palette[color];
  const txtColor = color === 'warning' ? theme.palette.text.primary : paletteColor.dark;

  pesosAmount = pesosAmount ?? 0;
  usdAmount = usdAmount ?? 0;
  pesosCount = pesosCount ?? 0;
  usdCount = usdCount ?? 0;

  const pesos = numberHelper.getNumberWithDots(pesosAmount);
  const usd = numberHelper.getNumberWithDots(usdAmount);

  return (
    <Paper variant="outlined" elevation={0} className={`${classes.paper} ${className}`}>
      <div className={classes.flexRowDescription}>
        <FiberManualRecordIcon style={{ fontSize: '20px', color: paletteColor?.dark }} />
        <Typography>{description}</Typography>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.flexRowAmounts}>
        <div className={classes.flexRow}>
          <Typography variant="h5" style={{ color: txtColor, fontWeight: 'bold' }}>
            $
          </Typography>
          <Typography variant="h4" style={{ color: txtColor, fontWeight: 'bold' }}>
            {pesos}
          </Typography>
          <Typography variant="body2">({pesosCount})</Typography>
        </div>
        <div style={{ width: '20px' }} />
        <div className={classes.flexRow}>
          <Typography variant="h5" style={{ color: txtColor, fontWeight: 'bold' }}>
            USD
          </Typography>
          <Typography variant="h4" style={{ color: txtColor, fontWeight: 'bold' }}>
            {usd}
          </Typography>
          <Typography variant="body2">({usdCount})</Typography>
        </div>
      </div>
    </Paper>
  );
}

export default ColoredCardWithTotals;
