import React from 'react';
import { AppBar, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import questionCircle from '../../assets/question-circle.svg';

interface PublicHeaderProps {
  isHome?: boolean;
}

function PublicHeader({ isHome = false }: PublicHeaderProps) {
  const history = useHistory();

  const visitFAQs = () => {
    if (history.location.pathname !== '/faqs') localStorage.setItem('prevPage', history.location.pathname);
    history.push('/faqs');
  };

  const redirectTo = (path: string) => {
    history.push(path);
  };

  return (
    <AppBar
      position="static"
      elevation={0}
      className="header"
      style={{ backgroundColor: '#ffffff', boxShadow: '0 1px 4px #15223214' }}
    >
      <div className="header-content">
        <img src={logo} className="public-img" alt="logo" onClick={() => redirectTo('/')} />
        {!isHome ? (
          <Button variant="outlined" className="help" onClick={visitFAQs}>
            <img src={questionCircle} alt="question" className="icon" />
            Ayuda
          </Button>
        ) : (
          <div className="home-header-options">
            <Button variant="contained" onClick={() => redirectTo('/login')} color="primary">
              Ingresar a mi cuenta
            </Button>
            <Button
              id="home-header-register"
              variant="outlined"
              onClick={() => redirectTo('/register')}
              className="contained-home-button"
              disableElevation
            >
              Registrarme
            </Button>
          </div>
        )}
      </div>
    </AppBar>
  );
}

export default PublicHeader;
