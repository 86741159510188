import React, { useEffect } from 'react';
import { makeStyles, createStyles, Theme, Typography, Chip } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState, Business } from './../../store/config/types';
import { fetchBusinesses } from './../../store/action_creators/business.actions';
import { AppActions } from './../../store/config/ActionTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clientsFilter: {
      padding: '0',
      margin: '1.2rem 0',
    },
    clientsChips: {
      margin: '0.6rem 0',
      display: 'flex',
      flexDirection: 'row',
      flexFlow: 'wrap',
    },
    chip: {
      marginRight: '0.4rem',
      marginBottom: '0.2rem',
    },
  }),
);

interface ClientsFilterProps {
  selectedBusiness: Business | null;
  filterByBusiness(business: Business): void;
}

const mapStateToProps = (state: RootState) => ({
  business: state.business,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchBusinesses: () => dispatch(fetchBusinesses()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type PropsType = PropsFromRedux & ClientsFilterProps;

function ClientsFilter({ business, fetchBusinesses, selectedBusiness, filterByBusiness }: PropsType) {
  const classes = useStyles();

  useEffect(() => {
    if (
      !business.loadingBusinesses &&
      business.businesses === null &&
      business.businessesErrorMessage === null
    ) {
      fetchBusinesses();
    }
  }, [business.loadingBusinesses, business.businesses, business.businessesErrorMessage, fetchBusinesses]);

  return (
    <div className={classes.clientsFilter}>
      <Typography variant="h5">Filtrar por cliente</Typography>
      <div className={classes.clientsChips}>
        {business.businesses &&
          business.businesses.map((b) => {
            return (
              <Chip
                key={b.name}
                className={classes.chip}
                color={selectedBusiness && b.name === selectedBusiness.name ? 'primary' : 'default'}
                onClick={() => filterByBusiness(b)}
                label={b.name}
                clickable
              />
            );
          })}
      </div>
    </div>
  );
}

export default connector(ClientsFilter);
