import { get, post } from './base.service';
import {
  FetchPaymentsRequest,
  BeginPaymentRequest,
  FetchPaymentRequest,
  FinalizePaymentRequest,
  BeginPaymentResponse,
  PaymentResponse,
  ConfirmPaymentResponse,
  PaymentInfo,
  BaseResponse,
} from '../store/config/types';
import { endpoints } from './endpoints';
import { filteringHelper } from '../helpers/filteringHelper';

export const paymentsService = {
  fetchPayments,
  beginPayment,
  getPayment,
  confirmPayment,
  cancelPayment,
};

async function fetchPayments(
  request: FetchPaymentsRequest,
  businessId: number,
): Promise<PaymentResponse | undefined> {
  const filterString = filteringHelper.getFiltersForPayments(request);
  return await get<PaymentResponse>(
    `${endpoints['payments']}?${businessId > 0 ? `businessId=${businessId}&` : ''}page=${
      request.page
    }&limit=${request.limit}&filter=${request.term ?? ''}${filterString}`,
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function beginPayment(
  beginPaymentRequest: BeginPaymentRequest,
): Promise<BeginPaymentResponse | undefined> {
  return await post<BeginPaymentResponse>(`${endpoints['beginPayment']}`, beginPaymentRequest)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function getPayment({ paymentId }: FetchPaymentRequest): Promise<PaymentInfo | undefined> {
  return await get<PaymentInfo>(`${endpoints['payments']}/instrument/${paymentId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function confirmPayment(
  finalizePaymentRequest: FinalizePaymentRequest,
): Promise<ConfirmPaymentResponse | undefined> {
  return await post<ConfirmPaymentResponse>(endpoints['payments'], finalizePaymentRequest)
    .then((response) => {
      return response.parsedBody!;
    })
    .catch((error) => {
      throw error;
    });
}

async function cancelPayment(plexoReferenceId: string, businessId: number): Promise<BaseResponse> {
  return await post<BaseResponse>(`${endpoints['payments']}/cancel`, { plexoReferenceId, businessId })
    .then((response) => {
      return response.parsedBody!;
    })
    .catch((error) => {
      throw error;
    });
}
