import { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Field, Form, Formik } from 'formik';
import schemas from '../../../data/schemas';
import { resendConfirmation } from '../../../store/action_creators/auth.actions';
import { AppActions } from '../../../store/config/ActionTypes';
import { Status } from '../../../store/config/enums';
import { RootState } from '../../../store/config/types';
import { CustomSnackbar } from '../../CustomSnackbar';
import { FormTextField } from '../../forms/FormTextField';

interface ResendConfirmationDialogProps {
  email?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  resendConfirmation: (email: string) => dispatch(resendConfirmation(email)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & ResendConfirmationDialogProps;

interface Values {
  email: string;
}

function ResendConfirmationDialog({ auth, resendConfirmation, open, setOpen }: PropsType) {
  const [resendingConfirmation, setResendingConfirmation] = useState<boolean>(false);
  const isOpened: boolean =
    resendingConfirmation && (auth.resendConfirmationSuccess || auth.resendConfirmationErrorMessage !== null);

  const closeDialog = () => setOpen(false);

  const submitResendConfirmation = (values: Values) => {
    setResendingConfirmation(true);
    resendConfirmation(values.email);
  };

  const closeSnack = () => {
    if (resendingConfirmation) setResendingConfirmation(false);
    if (auth.resendConfirmationSuccess) closeDialog();
  };

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="xs" className="dialog bank-dialog">
      <DialogTitle classes={{ root: 'dialog-title' }}>
        <div />
        <Typography variant="h4">Reenviar confirmación</Typography>
        <CloseIcon fontSize="default" className="icon" onClick={closeDialog} />
      </DialogTitle>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={schemas.ResendConfirmationSchema}
        onSubmit={submitResendConfirmation}
      >
        {({ values }) => (
          <Form className="form">
            <DialogContent classes={{ root: 'dialog-content' }}>
              <Field
                className="form-row"
                name="email"
                component={FormTextField}
                type="text"
                placeholder="Correo electrónico"
              />
            </DialogContent>
            <DialogActions classes={{ root: 'dialog-actions email-dialog-actions' }}>
              <Button type="submit" color="primary" variant="contained" fullWidth disabled={!!!values.email}>
                Reenviar
              </Button>
              <Button type="submit" color="primary" variant="contained" fullWidth disabled={!!values.email}>
                Reenviar a todos los no confirmados
              </Button>
            </DialogActions>
            <CustomSnackbar
              open={isOpened}
              message={
                auth.resendConfirmationSuccess
                  ? 'Se ha enviado la confirmación correctamente'
                  : auth.resendConfirmationErrorMessage!
              }
              handleClose={closeSnack}
              type={auth.resendConfirmationSuccess ? Status.SUCCESS : Status.ERROR}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default connector(ResendConfirmationDialog);
