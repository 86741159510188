import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { FormTextField } from '../FormTextField';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { RootState, BusinessRequest } from '../../../store/config/types';
import { createBusiness } from '../../../store/action_creators/business.actions';
import { ThunkDispatch } from 'redux-thunk';
import { AppActions } from '../../../store/config/ActionTypes';
import { connect, ConnectedProps } from 'react-redux';
import { CustomSnackbar } from '../../CustomSnackbar';
import schemas from '../../../data/schemas';
import { Status } from '../../../store/config/enums';
import { FormSelectField } from '../FormSelectField';
import { ivas } from '../../../data/values';
import dummy from '../../../assets/dummy.png';
import { ClientSecret } from '../../ClientSecret';
import generator from 'generate-password';
import upload from '../../../assets/upload.svg';
import trash from '../../../assets/trash.svg';

interface Values {
  name: string;
  legalId: string;
  description: string;
  vat: number;
}

interface BusinessFormProps {
  handleSuccess: () => void;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  business: state.business,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  createBusiness: (
    { name, legalId, userId, softDescriptor, vatRate, clientSecret }: BusinessRequest,
    picture: File,
  ) => dispatch(createBusiness({ name, legalId, userId, softDescriptor, vatRate, clientSecret }, picture)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector> & BusinessFormProps;

function generateSecretText() {
  return generator.generate({ length: 12, numbers: true, uppercase: true });
}

function BusinessForm({ business, createBusiness, auth, handleSuccess }: PropsFromRedux) {
  const history = useHistory();
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const [selectedPhoto, setSelectedPhoto] = useState<File | null>(null);
  const photoInput = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [secret, setSecret] = useState<string>(generateSecretText());

  const onImageError = () => {
    if (imageRef.current) {
      imageRef.current.src = dummy;
    }
  };

  const onPhotoSelected = (files: FileList | null) => {
    setLoadingImage(false);
    setSelectedPhoto(files ? files[0] : null);

    if (FileReader && files && files.length) {
      var fr = new FileReader();
      fr.onload = function () {
        if (imageRef.current) imageRef.current.src = fr.result!.toString();
      };
      fr.readAsDataURL(files[0]);
    }
  };

  const removeImage = () => {
    setSelectedPhoto(null);
  };

  useEffect(() => {
    if (business.createBusinessSuccess && business.createBusinessErrorMessage === null) {
      handleSuccess();
    }
  }, [business.createBusinessSuccess, business.createBusinessErrorMessage, handleSuccess]);

  const submitBusiness = (values: Values, helpers: FormikHelpers<Values>) => {
    if (selectedPhoto)
      createBusiness(
        {
          name: values.name,
          legalId: values.legalId,
          userId: auth.account?.userId!,
          softDescriptor: values.description,
          vatRate: values.vat,
          clientSecret: secret,
        },
        selectedPhoto!,
      );
  };

  const closeSnack = () => {
    if (business.createBusinessSuccess) {
      history.push('/dashboard');
    }
  };

  let allIvas = ivas.map((i) => {
    return {
      id: i,
      value: i.toString(),
    };
  });
  allIvas.unshift({ id: 99, value: 'Selecciona una opción' });

  return (
    <>
      <Formik
        initialValues={{
          name: '',
          legalId: '',
          description: '',
          vat: 99,
        }}
        validationSchema={schemas.BusinessSchema}
        onSubmit={submitBusiness}
      >
        <Form className="form">
          <Field
            className="row-field"
            name="name"
            component={FormTextField}
            type="text"
            placeholder="Nombre para la dirección web"
            startAdornment={`${window.location.origin}/`}
          />
          <div className="row-field texts-row">
            <Typography variant="body2">
              Por ejemplo: <span className="example">cobro-corpo</span>
            </Typography>
            <Typography variant="body2">
              No incluir la palabra "empresa". Ingresar solo letras y guiones "-". El nombre debe tener entre
              3 y 18 caracteres.
            </Typography>
          </div>
          <Field
            className="row-field"
            name="legalId"
            component={FormTextField}
            type="text"
            placeholder={'RUT'}
          />
          <Field className="row-field" name="vat" component={FormSelectField} options={allIvas} label="IVA" />
          <Field
            className="row-field"
            name="description"
            component={FormTextField}
            type="text"
            placeholder={'Razón social'}
          />
          <ClientSecret secret={secret} setSecret={setSecret} creating />
          <div className="upload-btn-wrapper">
            <div className="img-container">
              <img ref={imageRef} src={selectedPhoto?.name ?? dummy} onError={onImageError} alt="img" />
            </div>
            <div className="actions">
              <label className={loadingImage ? 'disabled-label' : ''} htmlFor="photoUpload">
                {loadingImage ? (
                  <CircularProgress size={20} thickness={6} />
                ) : (
                  <span onClick={(e) => e.stopPropagation()} className="action buttonable-span">
                    <img src={upload} alt="upload" /> Cargar imagen
                  </span>
                )}
              </label>
              <label onClick={removeImage}>
                <Button variant="outlined" className="action">
                  <img src={trash} alt="trash" /> Eliminar imagen
                </Button>
              </label>
              <label>
                <span className="required-message">* Debes incluir una imagen</span>
              </label>
            </div>
            <input
              type="file"
              id="photoUpload"
              accept="image/*"
              ref={photoInput}
              onChange={() =>
                onPhotoSelected(photoInput && photoInput.current ? photoInput.current?.files : null)
              }
            />
          </div>
          <div className="form-row">
            <span className="row-field" />
            <div className="button-row-field">
              <Button type="submit" color="primary" variant="contained" disableElevation>
                {business.creatingBusiness ? (
                  <CircularProgress style={{ color: '#FFF' }} size={20} thickness={6} />
                ) : (
                  'Confirmar'
                )}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
      <CustomSnackbar
        open={business.createBusinessSuccess || business.createBusinessErrorMessage !== null}
        message={
          business.createBusinessSuccess
            ? 'Se creó la empresa correctamente'
            : business.createBusinessErrorMessage!
        }
        handleClose={closeSnack}
        type={business.createBusinessSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </>
  );
}

export default connector(BusinessForm);
