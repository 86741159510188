import React, { useEffect, useState } from 'react';
import { ScreenTitle } from '../../components/ScreenTitle';
import { UserDialog } from '../../components/dialogs/UserDialog';
import { DeleteDialog } from '../../components/dialogs/DeleteDialog';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { AppActions } from '../../store/config/ActionTypes';
import { ThunkDispatch } from 'redux-thunk';
import { Collaborator, RootState } from '../../store/config/types';
import { fetchCollaborators, deleteCollaborator } from '../../store/action_creators/collaborators.actions';
import { Status } from '../../store/config/enums';
import editIcon from '../../assets/edit.svg';
import trashIcon from '../../assets/trash.svg';
import userCircle from '../../assets/user-circle.svg';
import btnPlus from '../../assets/btn-plus.svg';

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  collaborators: state.collaborators,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchCollaborators: (businessId: number) => dispatch(fetchCollaborators(businessId)),
  deleteCollaborator: (collaboratorId: string) => dispatch(deleteCollaborator(collaboratorId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Users({ auth, collaborators, fetchCollaborators, deleteCollaborator }: PropsFromRedux) {
  const [open, setOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [deletingCollaborator, setDeletingCollaborator] = useState<boolean>(false);
  const [selectedCollaborator, setSelectedCollaborator] = useState<Collaborator | null>(null);
  const [collaboratorToDelete, setCollaboratorToDelete] = useState<Collaborator | null>(null);

  useEffect(() => {
    if (
      auth.account &&
      !collaborators.loadingCollaborators &&
      collaborators.collaborators === null &&
      collaborators.collaboratorsErrorMessage === null
    ) {
      fetchCollaborators(auth.account.business.id!);
    }
  }, [
    auth.account,
    collaborators.loadingCollaborators,
    collaborators.collaborators,
    collaborators.collaboratorsErrorMessage,
    fetchCollaborators,
  ]);

  const createCollaborator = () => {
    setSelectedCollaborator(null);
    setOpen(true);
  };

  const updateCollaborator = (collaborator: Collaborator) => {
    setSelectedCollaborator(collaborator);
    setOpen(true);
  };

  const beginDeleteCollaborator = (collaborator: Collaborator) => {
    setCollaboratorToDelete(collaborator);
    setDeleteOpen(true);
  };

  const confirmDeleteCollaborator = () => {
    setDeletingCollaborator(true);
    if (collaboratorToDelete) deleteCollaborator(collaboratorToDelete?.userId);
  };

  const closeSnack = () => {
    setDeletingCollaborator(false);

    if (collaborators.deleteCollaboratorSuccess) setDeleteOpen(false);
  };

  return (
    <div className="screen-container users">
      <ScreenTitle
        title="Colaboradores"
        elementsAmount={collaborators.collaborators ? collaborators.collaborators?.length : 0}
      />
      <Grid container spacing={2} className="collaborators-list">
        <Grid item xs={12} sm={6} key="new">
          <div className="new-collaborator">
            <IconButton onClick={createCollaborator} size="small" className="icon">
              <img src={btnPlus} alt="plus" />
            </IconButton>
            <Typography variant="subtitle1">Nuevo colaborador</Typography>
          </div>
        </Grid>
        {collaborators.collaborators &&
          collaborators.collaborators.map((collaborator, index) => {
            return (
              <Grid item xs={12} sm={6} key={index}>
                <div className="collaborator">
                  <div className="names">
                    <img src={userCircle} alt="user" className="icon" />
                    <Typography variant="subtitle1">{`${collaborator.names} ${collaborator.surnames}`}</Typography>
                  </div>
                  <div className="actions">
                    <IconButton
                      onClick={() => updateCollaborator(collaborator)}
                      size="small"
                      className="icon"
                    >
                      <img src={editIcon} alt="edit" />
                    </IconButton>
                    <IconButton
                      onClick={() => beginDeleteCollaborator(collaborator)}
                      size="small"
                      className="icon"
                    >
                      <img src={trashIcon} alt="trash" />
                    </IconButton>
                  </div>
                </div>
              </Grid>
            );
          })}
      </Grid>
      <UserDialog open={open} setOpen={setOpen} collaborator={selectedCollaborator} />
      <DeleteDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        message={`¿Seguro que deseas eliminar el colaborador ${collaboratorToDelete?.names} ${collaboratorToDelete?.surnames}?`}
        deleteAction={confirmDeleteCollaborator}
        deleteDisabled={deletingCollaborator}
      />
      <CustomSnackbar
        open={
          deletingCollaborator &&
          (collaborators.deleteCollaboratorSuccess || collaborators.deleteCollaboratorErrorMessage !== null)
        }
        message={
          collaborators.deleteCollaboratorSuccess
            ? 'Se borró el colaborador correctamente'
            : 'Ocurrió un error al borrar el colaborador'
        }
        handleClose={closeSnack}
        type={collaborators.deleteCollaboratorSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </div>
  );
}

export default connector(Users);
