import { BASE_URL } from '../services/config';

export const urlHelper = {
  parseQueryString,
  isPublicPage,
  isPrivatePage,
  buildImageUrl,
  buildBankImageUrl,
  getHubUrl,
};

function parseQueryString(queryString?: string): any {
  if (!queryString) {
    queryString = window.location.search.substring(1);
  }

  const params: any = {};

  const queries = queryString.split('&');

  queries.forEach((indexQuery: string) => {
    const indexPair = indexQuery.split('=');

    const queryKey = indexPair[0];
    const queryValue = indexPair.length > 1 ? indexPair[1] : '';

    params[queryKey] = queryValue;
  });

  return params;
}

const publicPages = [
  '/confirm',
  '/landing',
  '/loading',
  '/login',
  '/redirect',
  '/register',
  '/reset',
  '/result',
];

const privatePages = [
  '/business',
  '/configuration',
  '/dashboard',
  '/links',
  '/one-use-links',
  '/payments',
  '/profile',
  '/users',
  '/user-faqs',
  '/user-contact',
  '/request-issuers',
  '/banks',
  '/manage-clients',
];

function isPublicPage(url: string): boolean {
  return publicPages.indexOf(url) > -1;
}

function isPrivatePage(url: string): boolean {
  if (url === '/register') {
    let urlVars = urlHelper.parseQueryString();
    return urlVars.step > 1;
  }
  return privatePages.indexOf(url) > -1;
}

function buildImageUrl(businessId: number): string {
  return `${BASE_URL}/business/${businessId}/picture`;
}

function buildBankImageUrl(bankId: string): string {
  return `${BASE_URL}/banks/${bankId}/picture`;
}

function getHubUrl() {
  return `${BASE_URL}/plexoHub`;
}
