export const numberHelper = {
  getNumberWithDots,
  getSubtotal,
  getIVA,
};

function getNumberWithDots(number: number) {
  return number
    .toFixed(2)
    .toString()
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

function getSubtotal(totalAmount: number, iva: number) {
  return (totalAmount / (1 + iva / 100)).toFixed(2).replace('.', ',');
}

function getIVA(totalAmount: number, iva: number) {
  return (totalAmount - totalAmount / (1 + iva / 100)).toFixed(2).replace('.', ',');
}
