import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppBar } from '@material-ui/core';
import LogoutDialog from './LogoutDialog';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';
import CogMenu from './CogMenu';
import UserMenu from './UserMenu';
import logo from '../../assets/logo.svg';
import { RootState } from '../../store/config/types';
import { connect, ConnectedProps } from 'react-redux';
import { UserTypeEnum } from '../../store/config/enums';

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Header({ auth }: PropsFromRedux) {
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const [mobileMenuOpened, setMobileMenuOpened] = useState<boolean>(false);

  const goHome = () => {
    history.push('/dashboard');
  };

  const toggleMobileMenu = (value: boolean) => {
    setMobileMenuOpened(value);
  };

  const hasNavigation = auth.account?.business || auth.account?.type === UserTypeEnum.SUPERADMIN;

  return (
    //TODO: get backgroundColor from somewhere else
    <AppBar
      position="static"
      elevation={0}
      className="header"
      style={{ backgroundColor: '#ffffff', boxShadow: '0px 1px 4px #15223214' }}
    >
      <div className="header-content">
        <img src={logo} alt="logo" className="logo" onClick={goHome} />
        {hasNavigation && <DesktopNavigation role={auth.account?.type} />}
        {hasNavigation && (
          <MobileNavigation
            role={auth.account?.type}
            mobileMenuOpened={mobileMenuOpened}
            toggleMobileMenu={toggleMobileMenu}
          />
        )}
        <div className="configurations">
          {auth.account && auth.account?.type === UserTypeEnum.OWNER && hasNavigation && (
            <>
              <CogMenu />
              <div className="divider" />
            </>
          )}
          <UserMenu setOpen={setOpen} />
        </div>
        <LogoutDialog open={open} setOpen={setOpen} />
      </div>
    </AppBar>
  );
}

export default connector(Header);
