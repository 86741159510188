import React from 'react';
import { useTheme } from '@material-ui/core/styles';

interface QuickLinkProps {
  color?: string;
  className?: string;
}
function QuickLink({ color, className }: QuickLinkProps) {
  const theme = useTheme();
  color = color ?? theme.palette.grey[400];
  className = className ?? '';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="9.572"
      height="15.06"
      viewBox="0 0 9.572 15.06"
    >
      <path
        id="bolt"
        d="M8.465,4.581h-3.3L6.38.865A.69.69,0,0,0,5.716,0H1.593A.688.688,0,0,0,.912.6L0,7.467a.688.688,0,0,0,.681.779h3.4l-1.32,5.569a.686.686,0,0,0,1.263.5l5.039-8.7A.686.686,0,0,0,8.465,4.581ZM4.007,12.509l.959-4.054.266-1.128H.937L1.794.916H5.4L4.29,4.295,3.9,5.5H8.067Z"
        transform="translate(0.212 0.2)"
        fill={color}
        stroke={color}
        stroke-width="0.4"
      />
    </svg>
  );
}

export default QuickLink;
