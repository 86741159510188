import { Page, Text, Document, StyleSheet, Image, View } from '@react-pdf/renderer';
import { currencyHelper } from '../../../helpers/currencyHelper';
import { urlHelper } from '../../../helpers/urlHelper';
import { Amount, Business } from '../../../store/config/types';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 32,
    width: 600,
  },
  title: {
    marginBottom: 25,
    fontSize: 20,
  },
  textContainer: {
    textAlign: 'center',
    width: '50%',
  },
  description: {
    fontSize: 13,
    marginTop: 15,
    color: 'gray',
  },
  value: {
    marginTop: 12,
    fontSize: 20,
    fontWeight: 'bold',
  },
  logo: {
    height: '80pt',
    width: '80pt',
    objectFit: 'contain',
  },
  image: {
    height: '350pt',
    width: '350pt',
    objectFit: 'contain',
  },
});

interface PdfQrProps {
  url: string | null;
  description?: string;
  price?: Amount;
  business?: Business;
}

function PdfQr({ url, description, price, business }: PdfQrProps) {
  const priceValueFormatted: string = `Valor: ${currencyHelper.getCurrencyToken(
    price?.currency!,
  )}${price?.value.toLocaleString('de-DE')}`;

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="portrait">
        <Image src={urlHelper.buildImageUrl(business?.id!)} style={styles.logo} />
        <Text style={styles.title}>{business?.name}</Text>
        <Image src={url!} style={styles.image} />
        <View style={styles.textContainer}>
          {description && <Text style={styles.description}>{description}</Text>}
          {price && <Text style={styles.value}>{priceValueFormatted}</Text>}
        </View>
      </Page>
    </Document>
  );
}

export default PdfQr;
