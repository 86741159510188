import React, { useState } from 'react';
import { Filters } from '../../store/config/types';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  makeStyles,
  Theme,
  createStyles,
  FormControlLabel,
  Tooltip,
} from '@material-ui/core';
import { DateSpan } from '../../store/config/enums';
import moment from 'moment';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DateRangeDialog from './DateRangeDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formLabelRoot: {
      fontSize: '1.3em',
      lineHeight: '1.5',
      color: theme.palette.text.primary,
    },
    dateFilterContainer: {
      marginBottom: '2rem',
    },
    formGroupRoot: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    title: {
      width: '100%',
      textAlign: 'center',
    },
    label: {
      marginRight: '2rem',
      marginBottom: '-0.6rem',
      width: 'calc(33.33333% - 1.3333rem)',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    range: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    infoIcon: {
      color: theme.palette.text.secondary,
      fontSize: '1.6rem',
      paddingLeft: '0.4rem',
    },
    tooltip: {
      fontSize: '0.8rem',
    },
  }),
);

interface DateFiltersProps {
  filters: Filters | null;
  setFilters: (filters: Filters) => void;
}

function DateFilters({ filters, setFilters }: DateFiltersProps) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) !== DateSpan.CUSTOM) {
      setFilters({ ...filters, dateSpan: Number(event.target.value) });
    } else {
      setIsOpen(true);
    }
  };

  return (
    <div className={classes.dateFilterContainer}>
      <FormControl component="fieldset">
        <FormLabel component="legend" classes={{ root: classes.formLabelRoot }} focused={false}>
          Mostrar
        </FormLabel>
        <RadioGroup
          aria-label="dateFilter"
          name="dateFilter"
          value={filters?.dateSpan}
          onChange={handleDateChange}
          classes={{ root: classes.formGroupRoot }}
        >
          <FormControlLabel
            classes={{ root: classes.label }}
            value={DateSpan.TODAY}
            control={<Radio color="secondary" />}
            label="Hoy"
          />
          <FormControlLabel
            classes={{ root: classes.label }}
            value={DateSpan.LAST7DAYS}
            control={<Radio color="secondary" />}
            label="Últimos 7 días"
          />
          <FormControlLabel
            classes={{ root: classes.label }}
            value={DateSpan.LAST15DAYS}
            control={<Radio color="secondary" />}
            label="Últimos 15 días"
          />
          <FormControlLabel
            classes={{ root: classes.label }}
            value={DateSpan.LAST30DAYS}
            control={<Radio color="secondary" />}
            label="Últimos 30 días"
          />
          <FormControlLabel
            classes={{ root: classes.label }}
            value={DateSpan.ALL}
            control={<Radio color="secondary" />}
            label="Todos"
          />
          <FormControlLabel
            classes={{ root: classes.label }}
            value={DateSpan.CUSTOM}
            control={<Radio color="secondary" />}
            label={
              <div className={classes.range}>
                <span>Rango</span>
                {filters && filters.dateSpan === DateSpan.CUSTOM && (
                  <Tooltip
                    placement="right"
                    classes={{ tooltip: classes.tooltip }}
                    title={`Desde ${moment(filters.from).format('DD/MM/YYYY')} hasta ${moment(
                      filters.to,
                    ).format('DD/MM/YYYY')}. Clic para cambiar el rango`}
                  >
                    <HelpOutlineIcon onClick={() => setIsOpen(true)} className={classes.infoIcon} />
                  </Tooltip>
                )}
              </div>
            }
          />
        </RadioGroup>
      </FormControl>
      <DateRangeDialog isOpen={isOpen} setIsOpen={setIsOpen} filters={filters} setFilters={setFilters} />
    </div>
  );
}

export default DateFilters;
