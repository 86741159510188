import React, { useEffect, useState } from 'react';
import { ScreenTitle } from './../../components/ScreenTitle';
import { Divider, makeStyles, createStyles, Theme } from '@material-ui/core';
import { PaymentsTable } from './../../components/PaymentsTable';
import { TotalsAccordion } from './../../components/TotalsAccordion';
import { FiltersAccordion } from '../../components/FiltersAccordion';
import { ClientsFilter } from '../../components/ClientsFilter';
import { ExportPayments } from '../../components/ExportPayments';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState, Business, Filters } from './../../store/config/types';
import { fetchPayments, loadPaymentsFilters } from './../../store/action_creators/payments.actions';
import { fetchCommerces } from '../../store/action_creators/commerces.actions';
import { AppActions } from './../../store/config/ActionTypes';
import { UserTypeEnum } from '../../store/config/enums';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    firstDivider: {
      margin: '2rem 0',
    },
    flexRow: {
      alignItems: 'center',
    },
  }),
);

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  payments: state.payments,
  commerces: state.commerces,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  loadPaymentsFilters: (filters: Filters, page: number, limit: number, businessId: number) =>
    dispatch(loadPaymentsFilters(filters, page, limit, businessId)),
  fetchPayments: (filters: Filters, page: number, limit: number, businessId: number) =>
    dispatch(fetchPayments(filters, page, limit, businessId)),
  fetchCommerces: (businessId: number) => dispatch(fetchCommerces(businessId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

function Payments({
  auth,
  payments,
  commerces,
  fetchPayments,
  fetchCommerces,
  loadPaymentsFilters,
}: PropsFromRedux) {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedBusiness, setSelectedBusiness] = useState<Business | null>(null);

  const businessId =
    auth.account?.type === UserTypeEnum.SUPERADMIN
      ? selectedBusiness === null
        ? 0
        : selectedBusiness.id!
      : auth.account?.business?.id ?? -1;

  useEffect(() => {
    if (
      businessId !== -1 &&
      !payments.loadingPayments &&
      payments.payments === null &&
      payments.paymentsErrorMessage === null
    ) {
      fetchPayments(payments.filters, payments.page, payments.pageSize, businessId);
    }
  }, [
    payments.loadingPayments,
    payments.payments,
    payments.paymentsErrorMessage,
    payments.filters,
    fetchPayments,
    payments.page,
    payments.pageSize,
    auth.account,
    businessId,
  ]);

  useEffect(() => {
    if (
      auth.account &&
      !commerces.loadingCommerces &&
      commerces.commerces === null &&
      commerces.commercesErrorMessage === null
    ) {
      fetchCommerces(businessId);
    }
  }, [
    auth.account,
    commerces.loadingCommerces,
    commerces.commerces,
    commerces.commercesErrorMessage,
    fetchCommerces,
    businessId,
  ]);

  const search = () => {
    if (searchTerm.length > 3 || searchTerm.length === 0) {
      const filters = { ...payments.filters, term: searchTerm };
      loadPaymentsFilters(filters, payments.page, payments.pageSize, businessId);
    }
  };

  const loadFilters = (filters: Filters) => {
    if (auth.account) {
      loadPaymentsFilters(filters, 1, payments.pageSize, businessId);
    }
  };

  const loadNextPage = () => {
    fetchPayments(payments.filters, payments.page + 1, payments.pageSize, businessId);
  };

  const filterByBusiness = (business: Business) => {
    if (selectedBusiness && selectedBusiness.id === business.id) {
      loadPaymentsFilters(payments.filters, 1, payments.pageSize, 0);
      setSelectedBusiness(null);
    } else {
      loadPaymentsFilters(payments.filters, 1, payments.pageSize, business.id!);
      setSelectedBusiness(business);
    }
  };

  return (
    <div className="screen-container">
      <div className={`flex-row ${classes.flexRow}`}>
        <ScreenTitle title="Pagos" elementsAmount={payments.rowCount} />
        <ExportPayments auth={auth} payments={payments} businessId={businessId} />
      </div>
      <Divider className={classes.firstDivider} />
      <FiltersAccordion
        hasDateFilter
        hasIssuerFilter
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filters={payments.filters}
        setFilters={loadFilters}
        triggerSearch={search}
      />
      {auth.account?.type === UserTypeEnum.SUPERADMIN && (
        <ClientsFilter selectedBusiness={selectedBusiness} filterByBusiness={filterByBusiness} />
      )}
      <TotalsAccordion payments={payments} />
      <PaymentsTable loadNextPage={loadNextPage} />
    </div>
  );
}

export default connector(Payments);
