import React from 'react';
import { useTheme } from '@material-ui/core/styles';

interface SingleLinkProps {
  color?: string;
  className?: string;
}
function SingleLink({ color, className }: SingleLinkProps) {
  const theme = useTheme();
  color = color ?? theme.palette.grey[400];
  className = className ?? '';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="17.865"
      height="16.539"
      viewBox="0 0 17.865 16.539"
    >
      <g id="icon-one" transform="translate(-5 -5)">
        <g id="noun-repeat-one-2447137" transform="translate(5 5)">
          <path
            id="Unión_3"
            data-name="Unión 3"
            d="M13.386,11.288a.829.829,0,0,1-.414-.717V5.94A2.644,2.644,0,0,0,10.328,3.3h-7.5l.235.236a.829.829,0,0,1-.394,1.334.837.837,0,0,1-.695-.105H1.953L1.88,4.687.242,3.049a.828.828,0,0,1,0-1.169L1.88.241A.825.825,0,0,1,2.465,0a.845.845,0,0,1,.217.029.83.83,0,0,1,.367,1.389l-.221.221h7.5a4.3,4.3,0,0,1,4.3,4.3v4.631a.832.832,0,0,1-1.244.717Z"
            transform="translate(3.235 0)"
            fill={color}
          />
          <path
            id="Unión_1"
            data-name="Unión 1"
            d="M11.547,11.182a.828.828,0,0,1,.04-1.209l.215-.215H4.3A4.3,4.3,0,0,1,0,5.457V.827A.832.832,0,0,1,.415.109a.845.845,0,0,1,.829,0,.831.831,0,0,1,.414.718V5.457A2.644,2.644,0,0,0,4.3,8.1h7.5l-.239-.241a.83.83,0,0,1,.394-1.334.828.828,0,0,1,.775.166l.014.013,1.638,1.638a.828.828,0,0,1,0,1.169l-.074.074-1.559,1.559a.827.827,0,0,1-.59.281h-.032A.834.834,0,0,1,11.547,11.182Z"
            transform="translate(0 5.113)"
            fill={color}
          />
          <path
            id="Unión_2"
            data-name="Unión 2"
            d="M1.952,6.292a.822.822,0,0,1-.582-.24.832.832,0,0,1-.246-.589V2.731a.841.841,0,0,1-.53.02A.829.829,0,0,1,.2,1.417L.213,1.4,1.37.245A.834.834,0,0,1,1.957,0a.824.824,0,0,1,.589.244.837.837,0,0,1,.241.59v4.63a.83.83,0,0,1-.829.829Z"
            transform="translate(6.975 5.108)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

export default SingleLink;
