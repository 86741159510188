import { useRef } from 'react';
import Lottie from 'lottie-react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import downloadAnimation from '../../assets/plinks-download.json';

interface DownloadingProps {
  startAnimation: boolean | null;
  startDownload: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lottie: {
      cursor: 'pointer',
      width: '4rem',
      height: '4rem',
    },
  }),
);

function Donwloading({ startAnimation, startDownload }: DownloadingProps) {
  const ref: any = useRef();
  const classes = useStyles();

  if (startAnimation) ref.current?.play();
  if (startAnimation === false) ref.current?.setSpeed(4);

  return (
    <Lottie
      onClick={startDownload}
      className={classes.lottie}
      lottieRef={ref}
      animationData={downloadAnimation}
      autoplay={false}
      loop={false}
    />
  );
}

export default Donwloading;
