import React from 'react';
import BurgerToggle from './BurgerToggle';
import { Slide } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { getOptionsFromRole } from './navigationOptions';
import { UserTypeEnum } from '../../store/config/enums';

interface MobileNavigationProps {
  mobileMenuOpened: boolean;
  toggleMobileMenu(value: boolean): void;
  role?: UserTypeEnum;
}

function MobileNavigation({ mobileMenuOpened, toggleMobileMenu, role }: MobileNavigationProps) {
  const history = useHistory();
  const options = getOptionsFromRole(role);
  return (
    <div className="mobile-header">
      <BurgerToggle mobileMenuOpened={mobileMenuOpened} toggleMobileMenu={toggleMobileMenu} />
      <Slide timeout={700} direction="right" in={mobileMenuOpened}>
        <div className="mobile-header-options">
          {options.map((o, index) => {
            return (
              <span
                className={`option ${o.name === 'Hidden' ? 'hidden-option' : ''}`}
                key={index}
                onClick={() => {
                  history.push(o.route);
                  toggleMobileMenu(false);
                }}
              >
                {o.icon}
                {o.name}
              </span>
            );
          })}
        </div>
      </Slide>
    </div>
  );
}

export default MobileNavigation;
