import React, { useState } from 'react';
import {
  createStyles,
  Theme,
  makeStyles,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Hidden,
  PaperProps,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { numberHelper } from './../../helpers/numberHelper';
import { useTheme } from '@material-ui/core/styles';
import { SingleLink } from './../Icons/SingleLink';
import { LinkTypeEnum, PaymentStatusEnum } from './../../store/config/enums';
import { currencyHelper } from '../../helpers/currencyHelper';
import { dateHelper } from '../../helpers/dateHelper';
import { paymentStatusEnumHelper } from '../../helpers/paymentStatusEnumHelper';
import { Payment, RootState } from './../../store/config/types';
import { PaymentDetail } from './../../components/dialogs/PaymentDetail';
import { EmptyMessage } from '../EmptyMessage';
import { Loading } from '../Loading';
import InfiniteScroll from 'react-infinite-scroll-component';
import { UserTypeEnum } from '../../store/config/enums';
import { AppActions } from './../../store/config/ActionTypes';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { cancelPayment } from './../../store/action_creators/payments.actions';
import { resendNotification } from './../../store/action_creators/notifications.actions';
import { PermanentLink } from '../Icons/PermanentLink';
import { QuickLink } from '../Icons/QuickLink';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    currency: {
      paddingRight: '0.3rem',
      fontSize: '0.8rem',
    },
    date: {
      paddingLeft: '0',
    },
    tableRow: {
      transition: 'background-color 500ms',
      '&:hover': {
        backgroundColor: theme.palette.primary.light + '!important',
        '& .mutable-on-hover': {
          color: theme.palette.primary.main,
          fill: theme.palette.primary.main,
        },
        cursor: 'pointer',
      },
    },
    cancelledColor: {
      color: theme.palette.grey[500],
    },
    issuerImg: {
      width: '2rem',
    },
    amountMinHeight: {
      minHeight: '1.5em',
    },
    description: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  }),
);

interface PaymentsTableProps {
  maxItems?: number;
  loadNextPage?: () => void;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  payments: state.payments,
  notifications: state.notifications,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  cancelPayment: (plexoReferenceId: string, businessId: number) =>
    dispatch(cancelPayment(plexoReferenceId, businessId)),
  resendNotification: (notificationId: string) => dispatch(resendNotification(notificationId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type PropsType = PropsFromRedux & PaymentsTableProps;

function PaymentsTable({
  auth,
  payments,
  notifications,
  maxItems,
  loadNextPage,
  cancelPayment,
  resendNotification,
}: PropsType) {
  const vat = auth.account?.business?.vatRate ?? 0;
  const [open, setOpen] = useState<boolean>(false);
  const [selectedPayment, setSelectedPayment] = useState<Payment | null>(null);

  const theme = useTheme();
  const classes = useStyles();

  const isSuperadmin = auth.account?.type === UserTypeEnum.SUPERADMIN;

  const selectIconByType = (linkType: LinkTypeEnum, status: PaymentStatusEnum) => {
    const color = paymentStatusEnumHelper.useIconColorByStatus(status, theme);
    switch (linkType) {
      case LinkTypeEnum.ONETIME:
        return <SingleLink color={color} />;
      case LinkTypeEnum.PERMANENT:
        return <PermanentLink color={color} />;
      case LinkTypeEnum.ONTHEFLY:
        return <QuickLink color={color} />;
      default:
        return <p>{linkType}</p>;
    }
  };

  const handleOnClick = (row: Payment) => {
    setSelectedPayment(row);
    setOpen(true);
  };

  const loadMore = () => {
    if (loadNextPage) {
      loadNextPage();
    }
  };

  const cancelPaymentForBusiness = (plexoReferenceId: string) => {
    if (selectedPayment) cancelPayment(plexoReferenceId, selectedPayment.businessId);
  };

  const hasBillingEnabled =
    auth.account !== null && auth.account.business !== null && auth.account.business.billingEnabled;

  return (
    <div>
      {payments.payments && payments.payments.length === 0 ? (
        <EmptyMessage message="No se encontraron pagos" />
      ) : payments.payments ? (
        <InfiniteScroll
          hasMore={payments.page < payments.pageCount}
          next={loadMore}
          dataLength={payments.payments ? payments.payments.length : 0}
          loader={payments.loadingPayments && loadNextPage && <Loading />}
        >
          <TableContainer>
            <Table
              component={(props: PaperProps) => <Paper {...props} component="table" />}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell className={classes.date}>Fecha</TableCell>
                  <Hidden smDown>
                    <TableCell>Descripción</TableCell>
                  </Hidden>
                  <TableCell align="center">Tipo</TableCell>
                  <Hidden smDown>
                    <TableCell align="center">Medio de pago</TableCell>
                  </Hidden>
                  {isSuperadmin && <TableCell>Empresa</TableCell>}
                  <TableCell align="right">Importe</TableCell>
                  {hasBillingEnabled && <TableCell align="right">N° de factura</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.payments.slice(0, maxItems).map((row) => (
                  <TableRow
                    onClick={() => handleOnClick(row)}
                    key={row.id}
                    hover
                    className={`${classes.tableRow} ${
                      row.status === PaymentStatusEnum.CANCELLED && classes.cancelledColor
                    }`}
                    style={{
                      backgroundColor: paymentStatusEnumHelper.useBackgroundColorByStatus(row.status, theme),
                    }}
                  >
                    <TableCell style={{ maxWidth: '1rem' }}>
                      <FiberManualRecordIcon
                        style={{
                          fontSize: '1rem',
                          verticalAlign: 'middle',
                          color: paymentStatusEnumHelper.useIconColorByStatus(row.status, theme),
                        }}
                      />
                    </TableCell>
                    <TableCell
                      className={`${classes.date} ${'mutable-on-hover'} ${
                        row.status === PaymentStatusEnum.CANCELLED && classes.cancelledColor
                      }`}
                    >
                      <div>
                        <Typography variant="body2">{dateHelper.toNiceString(row.date)}</Typography>
                      </div>
                    </TableCell>
                    <Hidden smDown>
                      <TableCell
                        className={`${'mutable-on-hover'} ${
                          row.status === PaymentStatusEnum.CANCELLED && classes.cancelledColor
                        }`}
                      >
                        {row.description}
                      </TableCell>
                    </Hidden>
                    <TableCell
                      className="mutable-on-hover table-icon"
                      align="center"
                      style={{ verticalAlign: 'bottom' }}
                    >
                      {selectIconByType(row.linkType, row.status)}
                    </TableCell>
                    <Hidden smDown>
                      <TableCell align="center">
                        <img src={row.issuerLogoUrl} className={classes.issuerImg} alt="issuerLogoUrl" />
                      </TableCell>
                    </Hidden>
                    {isSuperadmin && <TableCell>{row.businessName}</TableCell>}
                    <TableCell align="right" className={`${'mutable-on-hover'}`}>
                      <Typography
                        variant="h5"
                        className={`${classes.amountMinHeight} ${
                          row.status === PaymentStatusEnum.CANCELLED && classes.cancelledColor
                        }`}
                      >
                        <span className={classes.currency}>
                          {currencyHelper.getCurrencyToken(
                            row.taxedAmount ? row.taxedAmount.currency : row.amount.currency,
                          )}
                        </span>
                        {numberHelper.getNumberWithDots(
                          row.taxedAmount ? row.taxedAmount.value : row.amount.value,
                        )}
                      </Typography>
                    </TableCell>
                    {hasBillingEnabled && (
                      <TableCell align="right" className={`${'mutable-on-hover'}`}>
                        <Typography
                          variant="h5"
                          className={`${classes.amountMinHeight} ${
                            row.status === PaymentStatusEnum.CANCELLED && classes.cancelledColor
                          }`}
                        >
                          {row.invoiceNumber === null ? '-' : row.invoiceNumber}
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      ) : (
        <Loading />
      )}
      {selectedPayment && (
        <PaymentDetail
          open={open}
          setOpen={setOpen}
          payment={selectedPayment}
          vat={vat}
          billingEnabled={hasBillingEnabled}
          payments={payments}
          notifications={notifications}
          cancelPayment={cancelPaymentForBusiness}
          resendNotification={resendNotification}
        />
      )}
    </div>
  );
}

export default connector(PaymentsTable);
