import React, { useEffect } from 'react';
import { Issuer, RootState } from '../../../store/config/types';
import { connect, ConnectedProps } from 'react-redux';
import { AppActions } from '../../../store/config/ActionTypes';
import { ThunkDispatch } from 'redux-thunk';
import { Grid } from '@material-ui/core';
import { fetchBanks } from '../../../store/action_creators/banks.actions';
import { urlHelper } from '../../../helpers/urlHelper';

interface BanksSelectorProps {
  selectedIssuer: Issuer | null;
  selectedBanks: string[];
  setSelectedBanks(banks: string[]): void;
}

const mapStateToProps = (state: RootState) => ({
  banks: state.banks,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchBanks: () => dispatch(fetchBanks()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & BanksSelectorProps;

function BanksSelector({ banks, fetchBanks, selectedIssuer, selectedBanks, setSelectedBanks }: PropsType) {
  const isSistarbanc = selectedIssuer && selectedIssuer.id === '13';

  useEffect(() => {
    if (!banks.loadingBanks && banks.banks === null && banks.banksErrorMessage === null) {
      fetchBanks();
    }
  }, [banks.loadingBanks, banks.banks, banks.banksErrorMessage, fetchBanks]);

  const clickBank = (bankId: string) => {
    if (isSelectedBank(bankId)) {
      const leftoverBanks = selectedBanks.filter((b) => {
        return b !== bankId;
      });
      setSelectedBanks(leftoverBanks);
    } else {
      setSelectedBanks([...selectedBanks, bankId]);
    }
  };

  const isSelectedBank = (bankId: string) => {
    return selectedBanks.indexOf(bankId) > -1;
  };

  return (
    <Grid item xs={12} container spacing={1}>
      {isSistarbanc &&
        banks.banks &&
        banks.banks.map((b) => {
          return (
            <Grid key={b.id} item xs={12} md={4} onClick={() => clickBank(b.id)}>
              <div className={`bank ${isSelectedBank(b.id.toString()) ? 'selected-bank' : ''}`}>
                <img src={urlHelper.buildBankImageUrl(b.id)} alt={b.name} />
              </div>
            </Grid>
          );
        })}
    </Grid>
  );
}

export default connector(BanksSelector);
