import { Dispatch } from 'redux';
import { notificationsService } from '../../services/notifications.service';
import {
  ResendNotificationActionTypes,
  RESENDING_NOTIFICATION,
  RESEND_NOTIFICATION_SUCCESS,
  RESEND_NOTIFICATION_ERROR,
} from '../config/ActionTypes';

export const resendNotification =
  (notificationId: string) => (dispatch: Dispatch<ResendNotificationActionTypes>) => {
    dispatch(resendingNotification(notificationId));

    return notificationsService.resendNotification(notificationId).then(
      (response) => {
        dispatch(resendNotificationSuccess());
      },
      (error) => {
        dispatch(resendNotificationError(error));
      },
    );
  };

const resendingNotification = (notificationId: string): ResendNotificationActionTypes => ({
  type: RESENDING_NOTIFICATION,
  notificationId,
});

const resendNotificationSuccess = (): ResendNotificationActionTypes => ({
  type: RESEND_NOTIFICATION_SUCCESS,
});

const resendNotificationError = (error: string): ResendNotificationActionTypes => ({
  type: RESEND_NOTIFICATION_ERROR,
  error,
});
