import Lottie from 'lottie-react';
import { Typography } from '@material-ui/core';
import emptyAnimation from '../../assets/plinks-empty.json';

interface EmptyMessageProps {
  message: string;
}

function EmptyMessage({ message }: EmptyMessageProps) {
  return (
    <div className="empty-message">
      <Lottie height="8rem" width="8rem" animationData={emptyAnimation} loop />
      <Typography variant="h6">{message}</Typography>
    </div>
  );
}

export default EmptyMessage;
