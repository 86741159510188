import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { Button, Paper } from '@material-ui/core';
import { FormTextField } from '../../components/forms/FormTextField';
import { ScreenTitle } from '../../components/ScreenTitle';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { Status } from '../../store/config/enums';
import { useHistory } from 'react-router-dom';
import schemas from '../../data/schemas';

interface Values {
  name: string;
  email: string;
  message: string;
}

function Contact() {
  const history = useHistory();

  const [serverState, setServerState] = useState<boolean | null>(null);
  const [serverMessage, setServerMessage] = useState<string | null>(null);
  const [snackOpened, setSnackOpened] = useState<boolean>(false);

  const handleServerResponse = (ok: boolean, msg: string) => {
    setServerState(ok);
    setServerMessage(msg);
    setSnackOpened(true);
  };

  const handleOnSubmit = (values: Values, actions: FormikHelpers<Values>) => {
    axios({
      method: 'POST',
      url: 'https://formspree.io/f/moqpblzg',
      data: values,
    })
      .then((response: any) => {
        actions.setSubmitting(false);
        actions.resetForm();
        handleServerResponse(true, 'Gracias por contactarnos');
      })
      .catch((error: any) => {
        actions.setSubmitting(false);
        handleServerResponse(false, error.response.data.error);
      });
  };

  const closeSnack = () => {
    setSnackOpened(false);

    setTimeout(() => {
      if (serverState) {
        if (history.location.pathname === '/contact') {
          history.push('/faqs');
        } else {
          history.push('/user-faqs');
        }
      }

      setServerState(null);
      setServerMessage(null);
    }, 200);
  };

  return (
    <div className="screen-container contact-form">
      <ScreenTitle title="Contacto" />
      <Paper elevation={2}>
        <Formik
          initialValues={{ name: '', email: '', message: '' }}
          onSubmit={handleOnSubmit}
          validationSchema={schemas.ContactSchema}
        >
          <Form id="contact-form" noValidate>
            <Field id="name" component={FormTextField} type="text" name="name" placeholder="Nombre" />
            <Field id="email" component={FormTextField} type="email" name="email" placeholder="Email" />
            <Field
              id="message"
              component={FormTextField}
              name="message"
              placeholder="Mensaje"
              multiline
              rows={5}
            />
          </Form>
        </Formik>
      </Paper>
      <Button color="primary" variant="contained" type="submit" form="contact-form">
        Enviar solicitud
      </Button>
      <CustomSnackbar
        open={snackOpened}
        message={serverMessage !== null ? serverMessage : ''}
        handleClose={closeSnack}
        type={serverState ? Status.SUCCESS : Status.ERROR}
      />
    </div>
  );
}

export default Contact;
