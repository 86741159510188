import { Dispatch } from 'redux';
import { linksService } from '../../services/links.service';
import {
  Link,
  DeleteLinkRequest,
  ShareLinkRequest,
  Filters,
  CreateLinkRequest,
  LinksResponse,
  UpdateLinkRequest,
  LinkToPay,
} from '../config/types';
import {
  FetchLinksTypes,
  FETCHING_LINKS,
  ADD_LINKS,
  FETCH_LINKS_FAILED,
  CreateLinkTypes,
  CREATING_LINK,
  CREATE_LINK_SUCCESS,
  CREATE_LINK_FAILED,
  DELETING_LINK,
  DELETE_LINK_SUCCESS,
  DELETE_LINK_FAILED,
  DeleteLinkTypes,
  ShareLinkTypes,
  SHARING_LINK,
  SHARE_LINK_SUCCESS,
  SHARE_LINK_FAILED,
  UPDATING_LINK,
  UPDATE_LINK_SUCCESS,
  UPDATE_LINK_FAILED,
  UpdateLinkTypes,
  LOAD_LINK_FILTERS,
  LoadLinksFiltersAction,
  ValidateLinkTypes,
  VALIDATING_LINK,
  VALIDATE_LINK_SUCCESS,
  VALIDATE_LINK_FAILED,
} from '../config/ActionTypes';
import { LinkTypeEnum } from '../config/enums';

//#region Fetch links

export const fetchLinks = (
  filters: Filters,
  page: number,
  count: number,
  businessId: number,
  type: LinkTypeEnum,
  reset: boolean,
) => {
  return (dispatch: Dispatch<FetchLinksTypes>) => {
    dispatch(loadingLinks(type, reset));

    return linksService.fetchLinks({ ...filters, page, count }, businessId, type).then(
      (response) => {
        dispatch(addLinks(response!));
      },
      (error) => {
        dispatch(linksFailed(error));
      },
    );
  };
};

export const loadingLinks = (kind: LinkTypeEnum, reset: boolean): FetchLinksTypes => ({
  type: FETCHING_LINKS,
  kind,
  reset,
});

export const addLinks = (links: LinksResponse): FetchLinksTypes => ({
  type: ADD_LINKS,
  links,
});

export const linksFailed = (error: string): FetchLinksTypes => ({
  type: FETCH_LINKS_FAILED,
  error,
});

//#endregion

//#region Load filters

export const loadLinksFilters = (
  filters: Filters,
  page: number,
  count: number,
  businessId: number,
  type: LinkTypeEnum,
) => {
  return (dispatch: Dispatch<FetchLinksTypes | LoadLinksFiltersAction>) => {
    dispatch(loadFilters(filters));

    return linksService
      .fetchLinks(
        {
          page,
          count,
          ...filters,
        },
        businessId,
        type,
      )
      .then(
        (response) => {
          dispatch(addLinks(response!));
        },
        (error) => {
          dispatch(linksFailed(error));
        },
      );
  };
};

export const loadFilters = (filters: Filters): LoadLinksFiltersAction => ({
  type: LOAD_LINK_FILTERS,
  filters: filters,
});

//#endregion

//#region Create link

export const createLink = (linkRequest: CreateLinkRequest) => {
  return (dispatch: Dispatch<CreateLinkTypes>) => {
    dispatch(creatingLink());

    return linksService.createLink(linkRequest).then(
      (response) => {
        dispatch(createLinkSuccess(response!));
      },
      (error) => {
        dispatch(createLinkFailed(error));
      },
    );
  };
};

export const creatingLink = (): CreateLinkTypes => ({
  type: CREATING_LINK,
});

export const createLinkSuccess = (link: Link): CreateLinkTypes => ({
  type: CREATE_LINK_SUCCESS,
  link,
});

export const createLinkFailed = (error: string): CreateLinkTypes => ({
  type: CREATE_LINK_FAILED,
  error,
});

//#endregion

//#region Update link

export const updateLink = (request: UpdateLinkRequest, linkId: string, businessId: string) => {
  return (dispatch: Dispatch<UpdateLinkTypes>) => {
    dispatch(updatingLink());

    return linksService.updateLink(request, linkId, businessId).then(
      (response) => {
        dispatch(updateLinkSuccess(response!));
      },
      (error) => {
        dispatch(updateLinkFailed(error));
      },
    );
  };
};

export const updatingLink = (): UpdateLinkTypes => ({
  type: UPDATING_LINK,
});

export const updateLinkSuccess = (link: Link): UpdateLinkTypes => ({
  type: UPDATE_LINK_SUCCESS,
  link,
});

export const updateLinkFailed = (error: string): UpdateLinkTypes => ({
  type: UPDATE_LINK_FAILED,
  error,
});

//#endregion

//#region Delete link

export const deleteLink = ({ businessId, linkId }: DeleteLinkRequest) => {
  return (dispatch: Dispatch<DeleteLinkTypes>) => {
    dispatch(deletingLink());
    return linksService.deleteLink({ businessId, linkId }).then(
      (response) => {
        dispatch(deleteLinkSuccess(linkId));
      },
      (error) => {
        dispatch(deleteLinkFailed(error));
      },
    );
  };
};

export const deletingLink = (): DeleteLinkTypes => ({
  type: DELETING_LINK,
});

export const deleteLinkSuccess = (linkId: string): DeleteLinkTypes => ({
  type: DELETE_LINK_SUCCESS,
  linkId,
});

export const deleteLinkFailed = (error: string): DeleteLinkTypes => ({
  type: DELETE_LINK_FAILED,
  error,
});

//#endregion

//#region Share link

export const shareLink = (request: ShareLinkRequest) => {
  return (dispatch: Dispatch<ShareLinkTypes>) => {
    dispatch(sharingLink());

    return linksService.shareLink(request).then(
      (response) => {
        dispatch(shareLinkSuccess());
      },
      (error) => {
        dispatch(shareLinkFailed(error));
      },
    );
  };
};

export const sharingLink = (): ShareLinkTypes => ({
  type: SHARING_LINK,
});

export const shareLinkSuccess = (): ShareLinkTypes => ({
  type: SHARE_LINK_SUCCESS,
});

export const shareLinkFailed = (error: string): ShareLinkTypes => ({
  type: SHARE_LINK_FAILED,
  error,
});

//#endregion

//#region Validate link

export const validateLink = (linkId: string) => {
  return (dispatch: Dispatch<ValidateLinkTypes>) => {
    dispatch(validatingLink());

    return linksService.validateLink(linkId).then(
      (response) => {
        if (response) {
          dispatch(validateLinkSuccess(response!));
        }
      },
      (error) => {
        dispatch(validateLinkFailed(error));
      },
    );
  };
};

export const validatingLink = (): ValidateLinkTypes => ({
  type: VALIDATING_LINK,
});

export const validateLinkSuccess = (link: LinkToPay): ValidateLinkTypes => ({
  type: VALIDATE_LINK_SUCCESS,
  link,
});

export const validateLinkFailed = (error: string): ValidateLinkTypes => ({
  type: VALIDATE_LINK_FAILED,
  error,
});

//#endregion
