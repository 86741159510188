import { get, post, put } from './base.service';
import {
  User,
  LoginRequest,
  ChangeCredentialsRequest,
  RegisterRequest,
  ResetPasswordRequest,
  ConfirmResetPasswordRequest,
  ConfirmEmailRequest,
  Business,
  Account,
  LoggedAccount,
  LoginResponse,
  TokenResponse,
  BaseResponse,
} from '../store/config/types';
import { endpoints } from './endpoints';

export const authService = {
  login,
  logout,
  resetPassword,
  confirmResetPassword,
  registerUser,
  validateRegister,
  registerBusiness,
  getAccount,
  updateAccount,
  modifyCredentials,
  checkEmailAvailability,
  resendConfirmation,
};

async function login({ user }: LoginRequest): Promise<LoginResponse | undefined> {
  return await post<LoginResponse>(`${endpoints['login']}`, user)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function logout(): Promise<BaseResponse | undefined> {
  return await post<BaseResponse>(`${endpoints['logout']}`, {})
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

async function resetPassword(resetPasswordRequest: ResetPasswordRequest): Promise<string> {
  return await post<string>(`${endpoints['resetPassword']}`, resetPasswordRequest)
    .then((response) => {
      return response.stringBody!;
    })
    .catch((error) => {
      throw error;
    });
}

async function confirmResetPassword({ id, token }: ConfirmResetPasswordRequest): Promise<string> {
  return await get<string>(`${endpoints['confirmResetPassword']}?id=${id}&token=${token}`)
    .then((response) => {
      return response.stringBody!;
    })
    .catch((error) => {
      throw error;
    });
}

async function registerUser({ user }: RegisterRequest): Promise<User | undefined> {
  return await post<User>(`${endpoints['registerUser']}`, user)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function validateRegister({ userId, token }: ConfirmEmailRequest): Promise<TokenResponse | undefined> {
  return await get<TokenResponse>(`${endpoints['confirmEmail']}?userId=${userId}&token=${token}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function registerBusiness(business: Business): Promise<Business | undefined> {
  return await post<Business>(`${endpoints['registerBusiness']}`, business)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function getAccount(): Promise<LoggedAccount | undefined> {
  return await get<LoggedAccount>(`${endpoints['account']}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function updateAccount(account: Account): Promise<LoggedAccount | undefined> {
  return await put<LoggedAccount>(`${endpoints['account']}`, account)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function modifyCredentials(changeCredentialsRequest: ChangeCredentialsRequest): Promise<string> {
  return await put<string>(
    `${endpoints['modifyCredentials']}/${changeCredentialsRequest.userId}`,
    changeCredentialsRequest,
  )
    .then((response) => {
      return response.stringBody!;
    })
    .catch((error) => {
      throw error;
    });
}

async function checkEmailAvailability(email: string): Promise<boolean | undefined> {
  return await get<boolean>(`${endpoints['emailVerification']}?email=${email}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function resendConfirmation(email: string): Promise<string | undefined> {
  return await post<string>(`${endpoints['resendConfirmation']}`, { email })
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}
