import { Typography } from '@material-ui/core';
import React from 'react';

import { IssuersForm } from '../../components/forms/IssuersForm';

function RequestIssuers() {
  return (
    <div className="screen-container profile">
      <Typography variant="h5">Solicita tus códigos de comercio</Typography>
      <IssuersForm />
    </div>
  );
}

export default RequestIssuers;
