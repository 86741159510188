import { Component, ErrorInfo, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Lottie from 'lottie-react';
import { Button } from '@material-ui/core';
import errorAnimation from '../../assets/plinks-error.json';
import { urlHelper } from '../../helpers/urlHelper';

interface Props extends RouteComponentProps {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    console.log(_);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <Lottie height="8rem" width="8rem" animationData={errorAnimation} loop />
          <p>Ocurrió un error inesperado en la página de OCA Links.</p>

          <Button
            variant="outlined"
            onClick={() => {
              if (urlHelper.isPublicPage(this.props.history.location.pathname)) {
                this.props.history.push('/');
              } else {
                this.props.history.push('/dashboard');
              }
              window.location.reload();
            }}
          >
            Volver al inicio
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
