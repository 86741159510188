import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Lottie from 'lottie-react';
import errorAnimation from '../../assets/plinks-error.json';
import successAnimation from '../../assets/plinks-success.json';
import { Loading } from '../../components/Loading';
import { urlHelper } from '../../helpers/urlHelper';
import { confirmResetPassword } from '../../store/action_creators/auth.actions';
import { AppActions } from '../../store/config/ActionTypes';
import { ConfirmResetPasswordRequest, RootState } from '../../store/config/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      margin: '1rem auto',
      display: 'block',
    },
    text: {
      textAlign: 'center',
    },
  }),
);

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  confirmResetPassword: (request: ConfirmResetPasswordRequest) => dispatch(confirmResetPassword(request)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

function ConfirmResetPassword({ auth, confirmResetPassword }: PropsFromRedux) {
  const classes = useStyles();

  useEffect(() => {
    if (
      !auth.confirmingResetPassword &&
      !auth.confirmResetPasswordSuccess &&
      auth.confirmResetPasswordErrorMessage === null
    ) {
      let urlVars = urlHelper.parseQueryString();
      if (urlVars.id && urlVars.token) {
        confirmResetPassword({ id: urlVars.id, token: urlVars.token });
      }
    }
  }, [
    confirmResetPassword,
    auth.confirmingResetPassword,
    auth.confirmResetPasswordSuccess,
    auth.confirmResetPasswordErrorMessage,
  ]);

  return (
    <div className="screen-container">
      {auth.confirmingResetPassword ? (
        <Loading />
      ) : !auth.confirmResetPasswordSuccess ? (
        <>
          <Lottie
            className={classes.icon}
            height="10rem"
            width="10rem"
            animationData={errorAnimation}
            loop={false}
          />
          {auth.confirmResetPasswordErrorMessage === null ? (
            <Typography className={classes.text}>
              {'Algo salió mal y no pudimos verificar tu contraseña.'}
            </Typography>
          ) : (
            <Typography className={classes.text}>
              {auth.confirmResetPasswordErrorMessage.toString() + '. No pudimos verificar tu contraseña.'}
            </Typography>
          )}
        </>
      ) : (
        <>
          <Lottie
            className={classes.icon}
            height="10rem"
            width="10rem"
            animationData={successAnimation}
            loop={false}
          />
          <Typography className={classes.text}>
            {'Hemos reseteado tu contraseña y ya mandamos una nueva contraseña a tu correo.'}
          </Typography>
        </>
      )}
    </div>
  );
}

export default connector(ConfirmResetPassword);
