import React, { useState } from 'react';
import {
  createStyles,
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { NotificationsState, Payment, PaymentsState } from './../../../store/config/types';
import { numberHelper } from './../../../helpers/numberHelper';
import { currencyHelper } from '../../../helpers/currencyHelper';
import { dateHelper } from './../../../helpers/dateHelper';
import { linkTypeEnumHelper } from '../../../helpers/linkTypeEnumHelper';
import { DeleteDialog } from '../DeleteDialog';
import { CustomSnackbar } from '../../CustomSnackbar';
import { LinkTypeEnum, PaymentStatusEnum, Status } from '../../../store/config/enums';
import { paymentStatusEnumHelper } from '../../../helpers/paymentStatusEnumHelper';
import { theme } from '../../../styles/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '600px',
      maxWidth: '100%',
    },
    issuerImg: {
      height: '1rem',
    },
    flex: {
      display: 'flex',
      alignItems: 'baseline',
      width: '100%',
      justifyItems: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    halfWidth: {
      width: '50%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginTop: '1rem',
      },
    },
    rightWidth: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      },
    },
    divider: {
      marginTop: '1rem',
      marginBottom: '1rem',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
        display: 'none',
      },
    },
    amount: {
      display: 'flex',
      alignItems: 'baseline',
      flexDirection: 'row',
      '& *': {
        paddingRight: '0.3rem',
      },
    },
    dialogActions: {
      alignItems: 'inherit',
      justifyContent: 'center',
    },
    whiteButton: {
      minWidth: '6rem',
      backgroundColor: '#ffffff',
      borderColor: '#B1C9DE',
    },
    resendButton: {
      minWidth: '6rem',
    },
    deleteButton: {
      minWidth: '6rem',
      backgroundColor: theme.palette.error.main,
      color: '#ffffff',
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
    statusBadge: {
      height: '2rem',
      marginBotton: '2rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#FFFFFF',
    },
  }),
);

interface PaymentDetailProps {
  open: boolean;
  setOpen(value: boolean): void;
  payment: Payment;
  vat: number;
  billingEnabled: boolean;
  payments: PaymentsState;
  notifications: NotificationsState;
  cancelPayment: (plexoReferenceId: string) => void;
  resendNotification: (notificationId: string) => void;
}

function PaymentDetail({
  open,
  setOpen,
  payment,
  vat,
  billingEnabled,
  payments,
  notifications,
  cancelPayment,
  resendNotification,
}: PaymentDetailProps) {
  const classes = useStyles();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deletingPayment, setDeletingPayment] = useState<boolean>(false);
  const [sendingNotification, setSendingNotification] = useState<boolean>(false);

  const closeDialog = () => {
    setOpen(false);
  };

  const cancelTransaction = () => {
    setDeletingPayment(true);
    setDeleteDialogOpen(false);
    cancelPayment(payment.plexoReferenceId);
  };

  const closeSnack = () => {
    setDeletingPayment(false);
    setSendingNotification(false);

    if (payments.cancelPaymentSuccess) {
      setOpen(false);
    }
  };

  const submitResendNotification = () => {
    setSendingNotification(true);
    resendNotification(payment.notificationId!);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={closeDialog}
        fullWidth
        classes={{ paper: classes.root }}
        className="dialog"
      >
        <DialogTitle classes={{ root: 'dialog-title' }}>
          <div />
          <Typography variant="h4">Detalle de pago</Typography>
          <CloseIcon fontSize="default" className="icon" onClick={closeDialog} />
        </DialogTitle>
        <div
          className={classes.statusBadge}
          style={{
            backgroundColor: paymentStatusEnumHelper.useIconColorByStatus(payment.status, theme),
          }}
        >{`Estado del pago: ${paymentStatusEnumHelper.getStatusText(payment.status)}`}</div>
        <DialogContent classes={{ root: 'dialog-content' }} style={{ paddingTop: '2rem' }}>
          {payment.description && (
            <>
              <Typography variant="subtitle2" color="textSecondary">
                Descripción
              </Typography>
              <Typography variant="body1">{payment.description}</Typography>
              <Divider className={classes.divider} />
            </>
          )}
          <div className={classes.flex}>
            <div className={classes.halfWidth}>
              <Typography variant="subtitle2" color="textSecondary">
                Tipo
              </Typography>
              <Typography variant="body1">{linkTypeEnumHelper.toNiceString(payment.linkType)}</Typography>
            </div>
            <div className={`${classes.halfWidth} ${classes.rightWidth}`}>
              <Typography variant="subtitle2" color="textSecondary">
                Fecha
              </Typography>
              <Typography variant="body1">{dateHelper.toNiceString(payment.date)}</Typography>
            </div>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.flex}>
            <div className={classes.halfWidth}>
              <Typography variant="subtitle2" color="textSecondary">
                N° de referencia
              </Typography>
              <Typography variant="body2">{payment.plexoReferenceId}</Typography>
            </div>
            <div className={`${classes.halfWidth} ${classes.rightWidth}`}>
              <Typography variant="subtitle2" color="textSecondary">
                Medio de pago
              </Typography>
              <img src={payment.issuerLogoUrl} className={classes.issuerImg} alt="logo" />
            </div>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.flex}>
            <div className={classes.halfWidth}>
              <Typography variant="subtitle2" color="textSecondary">
                Cuotas
              </Typography>
              <Typography variant="body1">{payment.installments ? payment.installments : 1}</Typography>
            </div>
            <div className={`${classes.halfWidth} ${classes.rightWidth}`}>
              <Typography variant="subtitle2" color="textSecondary">
                Código de autorización
              </Typography>
              <Typography variant="body1">{payment.authorization ? payment.authorization : ''}</Typography>
            </div>
          </div>
          {payment.linkType !== LinkTypeEnum.ONTHEFLY && (
            <>
              <Divider className={classes.divider} />
              <div className={classes.flex}>
                <div className={classes.halfWidth}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Id del link
                  </Typography>
                  <Typography variant="body2">{payment.linkId ? payment.linkId : ''}</Typography>
                </div>
                <div className={`${classes.halfWidth} ${classes.rightWidth}`}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Referencia
                  </Typography>
                  <Typography variant="body1">
                    {payment.referencePayment ? payment.referencePayment : ''}
                  </Typography>
                </div>
              </div>
            </>
          )}
          <Divider className={classes.divider} />
          <div className={classes.flex}>
            <div className={classes.halfWidth}>
              <Typography variant="subtitle2" color="textSecondary">
                Comentarios
              </Typography>
              <Typography variant="body1">{payment.clientComments}</Typography>
            </div>
            <div className={`${classes.halfWidth} ${classes.rightWidth}`}>
              <Typography variant="subtitle2" color="textSecondary">
                Importe
              </Typography>
              <div className={`${classes.amount}`}>
                <Typography variant="body2">
                  {currencyHelper.getCurrencyToken(
                    payment.taxedAmount ? payment.taxedAmount.currency : payment.amount.currency,
                  )}
                </Typography>
                <Typography variant="h5">
                  {numberHelper.getNumberWithDots(
                    payment.taxedAmount ? payment.taxedAmount.value : payment.amount.value,
                  )}
                </Typography>
              </div>
              <Typography variant="subtitle2" color="textSecondary">{`${currencyHelper.getCurrencyToken(
                payment.amount.currency,
              )} ${numberHelper.getSubtotal(
                payment.taxedAmount ? payment.taxedAmount.value : payment.amount.value,
                vat,
              )} + ${currencyHelper.getCurrencyToken(payment.amount.currency)} ${numberHelper.getIVA(
                payment.taxedAmount ? payment.taxedAmount.value : payment.amount.value,
                vat,
              )} (IVA)`}</Typography>
            </div>
          </div>
          <Divider className={classes.divider} />
          {billingEnabled && (
            <>
              <div className={classes.flex}>
                <div className={`${classes.halfWidth}`}>
                  <Typography variant="subtitle2" color="textSecondary">
                    N° de factura
                  </Typography>
                  <div className={`${classes.amount}`}>
                    <Typography variant="body2">
                      {payment.invoiceNumber === null ? '-' : payment.invoiceNumber}
                    </Typography>
                  </div>
                </div>
              </div>
              <Divider className={classes.divider} />
            </>
          )}
        </DialogContent>
        <DialogActions classes={{ root: 'dialog-actions' }} className={classes.dialogActions}>
          <Button variant="outlined" onClick={closeDialog} className={classes.whiteButton}>
            Volver
          </Button>
          {payment.notificationId && (
            <Button
              color="primary"
              variant="contained"
              onClick={submitResendNotification}
              className={classes.resendButton}
            >
              Reenviar notificación
            </Button>
          )}
          {payment.cancellable && payment.status !== PaymentStatusEnum.CANCELLED && (
            <Button
              variant="contained"
              onClick={() => setDeleteDialogOpen(true)}
              className={classes.deleteButton}
            >
              Cancelar transacción
            </Button>
          )}
        </DialogActions>
        <DeleteDialog
          open={deleteDialogOpen}
          setOpen={setDeleteDialogOpen}
          message="¿Está seguro que desea cancelar la transacción?"
          deleteAction={cancelTransaction}
          deleteButtonText="Cancelar transacción"
          deleteDisabled={deletingPayment}
        />
      </Dialog>
      <CustomSnackbar
        open={
          deletingPayment && (payments.cancelPaymentSuccess || payments.cancelPaymentErrorMessage !== null)
        }
        message={
          payments.cancelPaymentSuccess
            ? 'La transacción fue cancelada correctamente'
            : 'Ocurrió un error al cancelar la transacción'
        }
        handleClose={closeSnack}
        type={payments.cancelPaymentSuccess ? Status.SUCCESS : Status.ERROR}
      />
      <CustomSnackbar
        open={
          sendingNotification &&
          (notifications.resendNotificationSuccess || notifications.resendNotificationErrorMessage !== null)
        }
        message={
          notifications.resendNotificationSuccess
            ? 'Se envió la notificación correctamente'
            : 'Ocurrió un error al enviar la notificación'
        }
        handleClose={closeSnack}
        type={notifications.resendNotificationSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </>
  );
}

export default PaymentDetail;
