import { Collaborator, CollaboratorsState } from '../config/types';
import {
  CollaboratorsDataTypes,
  FETCHING_COLLABORATORS,
  ADD_COLLABORATORS,
  FETCH_COLLABORATORS_FAILED,
  DELETE_COLLABORATOR_FAILED,
  DELETE_COLLABORATOR_SUCCESS,
  DELETING_COLLABORATOR,
  CREATE_COLLABORATOR_FAILED,
  CREATE_COLLABORATOR_SUCCESS,
  CREATING_COLLABORATOR,
  LogoutActionTypes,
  LOGOUT,
  UPDATING_COLLABORATOR,
  UPDATE_COLLABORATOR_SUCCESS,
  UPDATE_COLLABORATOR_FAILED,
} from '../config/ActionTypes';

export const initialState: CollaboratorsState = {
  loadingCollaborators: false,
  collaborators: null,
  collaboratorsErrorMessage: null,
  creatingCollaborator: false,
  createCollaboratorSuccess: false,
  createCollaboratorErrorMessage: null,
  updatingCollaborator: false,
  updateCollaboratorSuccess: false,
  updateCollaboratorErrorMessage: null,
  deletingCollaborator: false,
  deleteCollaboratorSuccess: false,
  deleteCollaboratorErrorMessage: null,
};

export function collaboratorsReducer(
  state = initialState,
  action: CollaboratorsDataTypes | LogoutActionTypes,
): CollaboratorsState {
  switch (action.type) {
    case FETCHING_COLLABORATORS:
      return {
        ...state,
        loadingCollaborators: true,
        collaborators: null,
        collaboratorsErrorMessage: null,
      };

    case ADD_COLLABORATORS:
      return {
        ...state,
        loadingCollaborators: false,
        collaborators: action.collaborators,
        collaboratorsErrorMessage: null,
      };

    case FETCH_COLLABORATORS_FAILED:
      return {
        ...state,
        loadingCollaborators: false,
        collaborators: null,
        collaboratorsErrorMessage: action.error,
      };

    case CREATING_COLLABORATOR:
      return {
        ...state,
        creatingCollaborator: true,
        createCollaboratorSuccess: false,
        createCollaboratorErrorMessage: null,
      };

    case CREATE_COLLABORATOR_SUCCESS:
      const newCollaborators = state.collaborators ? [...state.collaborators] : [];
      newCollaborators.push(action.collaborator);

      return {
        ...state,
        collaborators: newCollaborators,
        creatingCollaborator: false,
        createCollaboratorSuccess: true,
        createCollaboratorErrorMessage: null,
      };

    case CREATE_COLLABORATOR_FAILED:
      return {
        ...state,
        creatingCollaborator: false,
        createCollaboratorSuccess: false,
        createCollaboratorErrorMessage: action.error,
      };

    case UPDATING_COLLABORATOR:
      return {
        ...state,
        updatingCollaborator: true,
        updateCollaboratorSuccess: false,
        updateCollaboratorErrorMessage: null,
      };

    case UPDATE_COLLABORATOR_SUCCESS:
      let updatedCollaborators = state.collaborators!.map((c) => {
        if (c.userId === action.collaborator.userId) {
          let returnedCollaborator: Collaborator = {
            ...c,
            names: action.collaborator.names,
            surnames: action.collaborator.surnames,
          };

          return returnedCollaborator;
        }

        return c;
      });

      return {
        ...state,
        updatingCollaborator: false,
        updateCollaboratorSuccess: true,
        collaborators: updatedCollaborators,
        updateCollaboratorErrorMessage: null,
      };

    case UPDATE_COLLABORATOR_FAILED:
      return {
        ...state,
        updatingCollaborator: false,
        updateCollaboratorSuccess: false,
        updateCollaboratorErrorMessage: action.error,
      };

    case DELETING_COLLABORATOR:
      return {
        ...state,
        deletingCollaborator: true,
        deleteCollaboratorSuccess: false,
        deleteCollaboratorErrorMessage: null,
      };

    case DELETE_COLLABORATOR_SUCCESS:
      const nonDeletedCollaborators =
        state.collaborators && state.collaborators.filter((c) => c.userId !== action.collaboratorId);

      return {
        ...state,
        deletingCollaborator: false,
        deleteCollaboratorSuccess: true,
        collaborators: nonDeletedCollaborators,
        deleteCollaboratorErrorMessage: null,
      };

    case DELETE_COLLABORATOR_FAILED:
      return {
        ...state,
        deletingCollaborator: false,
        deleteCollaboratorSuccess: false,
        deleteCollaboratorErrorMessage: action.error,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
