import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { Filters } from '../../store/config/types';
import { OrderFilterEnum } from '../../store/config/enums';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('sm')]: {
        marginTop: '1rem',
      },
    },
    formLabelRoot: {
      fontSize: '1.3em',
      lineHeight: '1.5',
      color: theme.palette.text.primary,
    },
    orderFilterContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    formGroupRoot: {
      display: 'block',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    label: {
      marginBottom: '-0.6rem',
      width: '100%',
    },
    group: {
      width: '50%',
      display: 'inline-block',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }),
);

interface OrderFiltersProps {
  filters: Filters | null;
  setFilters: (filters: Filters) => void;
}

function OrderFilters({ filters, setFilters }: OrderFiltersProps) {
  const classes = useStyles();

  const handleOrderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, order: Number(event.target.value) });
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.formLabelRoot}>Ordenar</Typography>
      <div className={classes.orderFilterContainer}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="order"
            name="order"
            value={filters !== null ? filters.order : OrderFilterEnum.DescendingDate}
            onChange={handleOrderChange}
            classes={{ root: classes.formGroupRoot }}
          >
            <div className={classes.group}>
              <FormControlLabel
                classes={{ root: classes.label }}
                value={OrderFilterEnum.AscendingDate}
                control={<Radio color="secondary" />}
                label="Fecha creciente"
              />
              <FormControlLabel
                classes={{ root: classes.label }}
                value={OrderFilterEnum.DescendingDate}
                control={<Radio color="secondary" />}
                label="Fecha decreciente"
              />
            </div>
            <div className={classes.group}>
              <FormControlLabel
                classes={{ root: classes.label }}
                value={OrderFilterEnum.AscendingAmount}
                control={<Radio color="secondary" />}
                label="Importe creciente"
                disabled={
                  !(
                    (filters?.currency?.pesos || filters?.currency?.dollars) &&
                    !(filters?.currency?.pesos && filters?.currency?.dollars)
                  )
                }
              />
              <FormControlLabel
                classes={{ root: classes.label }}
                value={OrderFilterEnum.DescendingAmount}
                control={<Radio color="secondary" />}
                label="Importe decreciente"
                disabled={
                  !(
                    (filters?.currency?.pesos || filters?.currency?.dollars) &&
                    !(filters?.currency?.pesos && filters?.currency?.dollars)
                  )
                }
              />
            </div>
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
}

export default OrderFilters;
