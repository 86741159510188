import React, { useRef, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { Bank, RootState, UpdateBankImageRequest } from '../../../store/config/types';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Formik, Field } from 'formik';
import { FormTextField } from '../../forms/FormTextField';
import { urlHelper } from '../../../helpers/urlHelper';
import dummy from '../../../assets/dummy.png';
import PublishIcon from '@material-ui/icons/Publish';
import { ThunkDispatch } from 'redux-thunk';
import { createBank, updateBank } from '../../../store/action_creators/banks.actions';
import { AppActions } from '../../../store/config/ActionTypes';
import { connect, ConnectedProps } from 'react-redux';
import { CustomSnackbar } from '../../CustomSnackbar';
import { Status } from '../../../store/config/enums';
import { banksService } from '../../../services/banks.service';
import schemas from '../../../data/schemas';

interface BankDialogProps {
  selectedBank?: Bank;
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface Values {
  name: string;
  id: string;
}

const mapStateToProps = (state: RootState) => ({
  banks: state.banks,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  createBank: (bank: Bank, picture: File) => dispatch(createBank(bank, picture)),
  updateBank: (bank: Bank) => dispatch(updateBank(bank)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & BankDialogProps;

function BankDialog({ selectedBank, open, setOpen, banks, createBank, updateBank }: PropsType) {
  const [creatingBank, setCreatingBank] = useState<boolean>(false);
  const [updatingBank, setUpdatingBank] = useState<boolean>(false);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const [selectedPhoto, setSelectedPhoto] = useState<File | null>(null);
  const [pictureError, setPictureError] = useState<string | null>(null);
  const photoInput = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const closeDialog = () => {
    setOpen(false);
  };

  const submitBank = (values: Values) => {
    const newBank: Bank = { ...values };
    if (selectedBank) {
      setUpdatingBank(true);
      setPictureError(null);

      updateBank(newBank);

      if (selectedPhoto) {
        const updateImageRequest: UpdateBankImageRequest = {
          bankId: newBank.id,
          file: selectedPhoto!,
        };
        banksService.updateBankPicture(updateImageRequest).catch((error) => {
          setPictureError(error.message);
        });
      }
    } else {
      setCreatingBank(true);

      if (selectedPhoto) createBank(newBank, selectedPhoto);
    }
  };

  const closeSnack = () => {
    if (creatingBank) setCreatingBank(false);
    if (updatingBank) setUpdatingBank(false);

    closeDialog();
  };

  const onImageError = () => {
    if (imageRef.current) {
      imageRef.current.src = dummy;
    }
  };

  const onPhotoSelected = (files: FileList | null) => {
    setLoadingImage(false);
    setSelectedPhoto(files ? files[0] : null);

    if (FileReader && files && files.length) {
      var fr = new FileReader();
      fr.onload = function () {
        if (imageRef.current) imageRef.current.src = fr.result!.toString();
      };
      fr.readAsDataURL(files[0]);
    }
  };

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="xs" className="dialog bank-dialog">
      <DialogTitle classes={{ root: 'dialog-title' }}>
        <div />
        <Typography variant="h4">{selectedBank ? 'Editar banco' : 'Agregar banco'}</Typography>
        <CloseIcon fontSize="default" className="icon" onClick={closeDialog} />
      </DialogTitle>

      <Formik
        initialValues={{
          name: selectedBank ? selectedBank.name : '',
          id: selectedBank ? selectedBank.id : '',
        }}
        validationSchema={schemas.BankShema}
        onSubmit={submitBank}
      >
        {({ values }) => {
          return (
            <Form className="form commerce-form">
              <DialogContent classes={{ root: 'dialog-content' }}>
                <div className="upload-btn-wrapper">
                  <div className="img-container">
                    <img
                      ref={imageRef}
                      src={selectedBank ? urlHelper.buildBankImageUrl(selectedBank.id) : dummy}
                      onError={onImageError}
                      alt="img"
                    />
                  </div>
                  <div className="actions">
                    <label className={loadingImage ? 'disabled-label' : ''} htmlFor="photoUpload">
                      {loadingImage ? (
                        <CircularProgress size={20} thickness={6} />
                      ) : (
                        <span className="action">
                          <PublishIcon className="icon" /> Cargar nueva imagen
                        </span>
                      )}
                    </label>
                  </div>
                  <input
                    type="file"
                    id="photoUpload"
                    accept="image/*"
                    ref={photoInput}
                    onChange={() =>
                      onPhotoSelected(photoInput && photoInput.current ? photoInput.current?.files : null)
                    }
                  />
                </div>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field name="name" component={FormTextField} type="text" placeholder="Nombre" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="id"
                      component={FormTextField}
                      type="text"
                      placeholder="ID"
                      disabled={selectedBank}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions classes={{ root: 'dialog-actions' }}>
                <Button type="submit" color="primary" variant="contained" fullWidth>
                  {selectedBank ? 'Guardar cambios' : 'Agregar banco'}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
      <CustomSnackbar
        open={creatingBank && (banks.createBankSuccess || banks.createBankErrorMessage !== null)}
        message={banks.createBankSuccess ? 'Se creó el banco correctamente.' : banks.createBankErrorMessage!}
        handleClose={closeSnack}
        type={banks.createBankSuccess ? Status.SUCCESS : Status.ERROR}
      />
      <CustomSnackbar
        open={updatingBank && (banks.updateBankSuccess || banks.updateBankErrorMessage !== null)}
        message={
          pictureError !== null
            ? pictureError
            : banks.updateBankSuccess
            ? 'Se actualizó el banco correctamente.'
            : banks.updateBankErrorMessage!
        }
        handleClose={closeSnack}
        type={banks.updateBankSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </Dialog>
  );
}

export default connector(BankDialog);
