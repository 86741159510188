import { NotificationsState } from '../config/types';
import {
  RESENDING_NOTIFICATION,
  RESEND_NOTIFICATION_SUCCESS,
  RESEND_NOTIFICATION_ERROR,
  NotificationsDataTypes,
  LogoutActionTypes,
  LOGOUT,
} from '../config/ActionTypes';

export const initialState: NotificationsState = {
  resendingNotification: false,
  notificationId: '',
  resendNotificationSuccess: false,
  resendNotificationErrorMessage: null,
};

export function notificationsReducer(
  state = initialState,
  action: NotificationsDataTypes | LogoutActionTypes,
): NotificationsState {
  switch (action.type) {
    case RESENDING_NOTIFICATION:
      return {
        ...state,
        resendingNotification: true,
        notificationId: action.notificationId,
        resendNotificationSuccess: false,
        resendNotificationErrorMessage: null,
      };

    case RESEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        resendingNotification: false,
        resendNotificationSuccess: true,
        resendNotificationErrorMessage: null,
      };

    case RESEND_NOTIFICATION_ERROR:
      return {
        ...state,
        resendingNotification: false,
        resendNotificationSuccess: false,
        resendNotificationErrorMessage: action.error,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
