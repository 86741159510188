import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  FormControlLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { DesiredIssuer, DesiredIssuersRequest, RootState } from '../../../store/config/types';
import { ThunkDispatch } from 'redux-thunk';
import { AppActions } from '../../../store/config/ActionTypes';
import { sendDesiredIssuers, fetchIssuers } from '../../../store/action_creators/commerces.actions';
import { CustomSnackbar } from '../../CustomSnackbar';
import { Status } from '../../../store/config/enums';
import { Loading } from '../../Loading';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      '& > *': {
        marginTop: '1rem',
      },
    },
    formActions: {
      marginTop: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& button': {
        width: '100px',
      },
    },
  }),
);

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  commerces: state.commerces,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchIssuers: () => dispatch(fetchIssuers()),
  sendDesiredIssuers: (businessId: number, { issuers }: DesiredIssuersRequest) =>
    dispatch(sendDesiredIssuers(businessId, { issuers })),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function IssuersForm({ auth, commerces, sendDesiredIssuers, fetchIssuers }: PropsFromRedux) {
  const classes = useStyles();

  const history = useHistory();
  const [changingDesiredIssuers, setChangingDessiredIssuers] = useState<boolean>(false);
  const [desiredIssuers, setDessiredIssuers] = useState<DesiredIssuer[]>([]);
  const isRegister = window.location.pathname.startsWith('/register');

  useEffect(() => {
    if (!commerces.loadingIssuers && commerces.issuers === null && commerces.issuersErrorMessage === null) {
      fetchIssuers();
    }
  }, [commerces.loadingIssuers, commerces.issuers, commerces.issuersErrorMessage, fetchIssuers]);

  const submit = () => {
    setChangingDessiredIssuers(true);
    sendDesiredIssuers(auth.account?.business.id!, { issuers: desiredIssuers });
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (desiredIssuers.some((i) => i.name === event.target.name)) {
      setDessiredIssuers(
        desiredIssuers.filter((i) => {
          return i.name !== event.target.name;
        }),
      );
    } else {
      setDessiredIssuers(
        desiredIssuers.concat([
          {
            name: event.target.name,
            id: event.target.id,
          },
        ]),
      );
    }
  };

  const closeSnack = () => {
    setChangingDessiredIssuers(false);
    if (commerces.desiredIssuersSuccess) {
      history.push('/dashboard');
    }
  };

  return (
    <div className={classes.container}>
      {isRegister ? (
        <>
          <Typography variant="body1">
            Si ya tienes códigos de comercio, puedes saltearte este paso y agregarlos luego de iniciar sesión.
          </Typography>
          <Typography variant="body1">
            De lo contrario, selecciona los comercios que quieres habilitar.
          </Typography>
        </>
      ) : (
        <Typography variant="body1">Selecciona los comercios que quieres habilitar:</Typography>
      )}
      <div className="form ">
        {commerces.loadingIssuers ? (
          <Loading />
        ) : (
          <div className="issuers-list">
            {commerces.issuers &&
              commerces.issuers.map((issuer) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={desiredIssuers.some((i) => i.name === issuer.issuer)}
                      color="primary"
                      onChange={onChange}
                      name={issuer.issuer}
                      id={issuer.id}
                    />
                  }
                  label={issuer.issuer}
                />
              ))}
          </div>
        )}
        <div className={classes.formActions} style={!isRegister ? { justifyContent: 'flex-end' } : {}}>
          {isRegister && (
            <Button variant="outlined" onClick={() => history.push('/users')}>
              Omitir
            </Button>
          )}
          <Button
            onClick={submit}
            color="primary"
            variant="contained"
            disabled={desiredIssuers.length < 1}
            disableElevation
          >
            {commerces.sendingDesiredIssuers ? (
              <CircularProgress style={{ color: '#FFF' }} size={20} thickness={6} />
            ) : (
              'Confirmar'
            )}
          </Button>
        </div>
      </div>
      <CustomSnackbar
        open={
          changingDesiredIssuers &&
          (commerces.desiredIssuersSuccess || commerces.desiredIssuersErrorMessage !== null)
        }
        message={
          commerces.desiredIssuersSuccess
            ? '¡Gracias! En breve nos comunicaremos contigo para habilitar los comercios seleccionados.'
            : 'Ocurrió un error al solicitar los códigos de comercio indicados.'
        }
        handleClose={closeSnack}
        type={commerces.desiredIssuersSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </div>
  );
}

export default connector(IssuersForm);
