import { DateSpan, LinkTypeEnum } from '../store/config/enums';
import { FetchLinksRequest, FetchPaymentsRequest } from '../store/config/types';
import moment from 'moment';

export const filteringHelper = {
  getFiltersForLinks,
  getFiltersForPayments,
};

function getFiltersForLinks(fetchRequest: FetchLinksRequest, type?: LinkTypeEnum): string {
  let filterString = getFiltersString(fetchRequest, type);

  return filterString;
}

function getFiltersForPayments(fetchRequest: FetchPaymentsRequest, type?: LinkTypeEnum): string {
  let filterString = getFiltersString(fetchRequest, type);
  filterString += getDatesFilterToAntiquity(fetchRequest.dateSpan, fetchRequest.from, fetchRequest.to);

  console.log(filterString);

  return filterString;
}

function getFiltersString(
  fetchLinksRequest: FetchLinksRequest | FetchPaymentsRequest,
  type?: LinkTypeEnum,
): string {
  let filterString = '';

  switch (fetchLinksRequest.order) {
    case 1:
      filterString += `&newerFirst=${false}`;
      break;
    case 2:
      filterString += `&newerFirst=${true}`;
      break;
    case 3:
      filterString += `&largestAmountFirst=${false}`;
      break;
    case 4:
      filterString += `&largestAmountFirst=${true}`;
      break;
  }

  if (fetchLinksRequest.currency !== undefined) {
    let currencyId = 0;

    if (fetchLinksRequest.currency.pesos && !fetchLinksRequest.currency.dollars) {
      currencyId = 1;
    } else if (!fetchLinksRequest.currency.pesos && fetchLinksRequest.currency.dollars) {
      currencyId = 2;
    }

    filterString += `&currency=${currencyId}`;
  }

  if (fetchLinksRequest.term !== undefined) {
    filterString += `&filter=${fetchLinksRequest.term}`;
  }

  if (type) {
    filterString += `&idLinkType=${type}`;
  }

  if (fetchLinksRequest.issuer !== undefined) {
    filterString += `&issuerId=${fetchLinksRequest.issuer}`;
  }

  return filterString;
}

function getDatesFilterToAntiquity(dateSpan?: DateSpan, from?: string, to?: string): string {
  switch (dateSpan) {
    case DateSpan.TODAY:
      return `&from=${moment().startOf('day').toISOString()}`;

    case DateSpan.LAST7DAYS:
      return `&from=${moment().subtract(7, 'days').toISOString()}`;

    case DateSpan.LAST15DAYS:
      return `&from=${moment().subtract(15, 'days').toISOString()}`;

    case DateSpan.LAST30DAYS:
      return `&from=${moment().subtract(30, 'days').toISOString()}`;

    case DateSpan.ALL:
      return '';

    case DateSpan.CUSTOM:
      return `&from=${from}&until=${to}`;

    default:
      return '';
  }
}
