import React, { Dispatch, SetStateAction, useState } from 'react';
import { InputBase, Tooltip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

interface SearchProps {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  triggerSearch: () => void;
}

function Search({ searchTerm, setSearchTerm, triggerSearch }: SearchProps) {
  const [typingTimer, setTypingTimer] = useState<number | undefined>(undefined);

  const search = () => {
    window.clearTimeout(typingTimer);
    setTypingTimer(window.setTimeout(triggerSearch, 1000));
  };

  const searchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <InputBase
      startAdornment={
        <Tooltip title="El término de búsqueda debe tener al menos 3 caracteres">
          <SearchIcon color="disabled" />
        </Tooltip>
      }
      placeholder="Buscar"
      fullWidth
      onChange={searchTermChange}
      onKeyUp={search}
      onClick={(event) => event.stopPropagation()}
      onFocus={(event) => event.stopPropagation()}
      value={searchTerm}
      classes={{
        inputAdornedStart: 'adorned-start',
      }}
    />
  );
}

export default Search;
