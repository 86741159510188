import React, { useState } from 'react';
import { createStyles, IconButton, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { currencyHelper } from '../../helpers/currencyHelper';
import { numberHelper } from '../../helpers/numberHelper';
import { ShareLinkDialog } from '../dialogs/ShareLinkDialog';
import { Link } from '../../store/config/types';
import { dateHelper } from '../../helpers/dateHelper';
import shareIcon from '../../assets/share.svg';
import trashIcon from '../../assets/trash.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      boxShadow: '0px 1px 4px #15223214',
      padding: '1rem',
      '& svg': {
        color: theme.palette.primary.main,
        fontSize: '1.3rem',
      },
    },
    footer: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      '& p': {
        color: theme.palette.primary.main,
        marginBlockEnd: 0,
      },
      '& svg': {
        color: theme.palette.text.secondary,
      },
    },
    amountContainer: {
      display: 'flex',
      alignItems: 'baseline',
      marginRight: 8,
    },
    currency: {
      marginRight: '0.3rem',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    noReference: {
      color: theme.palette.text.secondary,
    },
    dueDate: {
      color: theme.palette.text.secondary,
      fontSize: '12px',
    },
    whiteButton: {
      backgroundColor: '#ffffff',
      border: '1px solid #B1C9DE',
      boxShadow: 'none',
      padding: '0.5rem',
      borderRadius: 6,
      height: 36,
      width: 36,
      marginLeft: 8,
      '& img': {
        height: 16,
      },
    },
  }),
);

interface SmallLinkCardProps {
  link: Link;
  setLinkToDelete(link: Link): void;
}

function SmallLinkCard({ link, setLinkToDelete }: SmallLinkCardProps) {
  const classes = useStyles();
  const [shareOpen, setShareOpen] = useState<boolean>(false);
  return (
    <Paper className={classes.container}>
      <Typography variant="body1" className={link.reference === '' ? classes.noReference : ''}>
        {link.reference !== '' ? link.reference : 'Sin referencia'}
        {link.validUntil && (
          <span className={classes.dueDate}>{` (${dateHelper.toShorterNiceString(link.validUntil)})`}</span>
        )}
      </Typography>
      <div className={classes.flex}>
        <div className={classes.amountContainer}>
          <Typography variant="h6" className={classes.currency}>
            {currencyHelper.getCurrencyToken(link.amount.currency)}
          </Typography>
          <Typography variant="h4">{numberHelper.getNumberWithDots(link.amount.value)} </Typography>
        </div>
        <IconButton
          className={classes.whiteButton}
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            setShareOpen(true);
          }}
        >
          <img src={shareIcon} alt="share" />
        </IconButton>
        <IconButton
          className={classes.whiteButton}
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            setLinkToDelete(link);
          }}
        >
          <img src={trashIcon} alt="trash" />
        </IconButton>
      </div>
      <ShareLinkDialog open={shareOpen} setOpen={setShareOpen} link={link} />
    </Paper>
  );
}

export default SmallLinkCard;
