import { createStyles, Theme, makeStyles } from '@material-ui/core';
import Lottie from 'lottie-react';
import loadAnimation from '../../assets/plinks-loading.json';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

function Loading() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Lottie height="12rem" width="12rem" animationData={loadAnimation} loop />
    </div>
  );
}

export default Loading;
