import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Formik, Field, FormikHelpers } from 'formik';
import schemas from '../../../data/schemas';
import { FormTextField } from '../../forms/FormTextField';
import { connect, ConnectedProps } from 'react-redux';
import { ChangeCredentialsRequest, RootState } from '../../../store/config/types';
import { ThunkDispatch } from 'redux-thunk';
import { AppActions } from '../../../store/config/ActionTypes';
import { changeCredentials } from '../../../store/action_creators/auth.actions';
import { CustomSnackbar } from '../../CustomSnackbar';
import { Status } from '../../../store/config/enums';

interface ChangePasswordDialogProps {
  open: boolean;
  setOpen(value: boolean): void;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  changeCredentials: (changeCredentialsRequest: ChangeCredentialsRequest) =>
    dispatch(changeCredentials(changeCredentialsRequest)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & ChangePasswordDialogProps;

interface Values {
  currentPassword: string;
  password: string;
  repeatPassword: string;
}

function ChangePasswordDialog({ auth, changeCredentials, open, setOpen }: PropsType) {
  const [changingCredentials, setChangingCredentials] = useState<boolean>(false);

  const closeDialog = () => {
    setOpen(false);
  };

  const submitPassword = (values: Values, helpers: FormikHelpers<Values>) => {
    if (auth.account) {
      const changeCredentialsRequest: ChangeCredentialsRequest = {
        userId: auth.account.userId,
        email: auth.account.email,
        oldPassword: values.currentPassword,
        newPassword: values.password,
      };

      setChangingCredentials(true);
      changeCredentials(changeCredentialsRequest);
    }
  };

  const closeSnack = () => {
    setChangingCredentials(false);

    if (auth.changeCredentialsSuccess) {
      closeDialog();
    }
  };

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="xs" className="dialog">
      <DialogTitle classes={{ root: 'dialog-title' }}>
        <div />
        <Typography variant="h4">Cambiar contraseña</Typography>
        <CloseIcon fontSize="default" className="icon" onClick={closeDialog} />
      </DialogTitle>
      <Formik
        initialValues={{ currentPassword: '', password: '', repeatPassword: '' }}
        validationSchema={schemas.ChangePasswordSchema}
        onSubmit={submitPassword}
      >
        <Form className="form">
          <DialogContent classes={{ root: 'dialog-content' }}>
            <Field
              className="form-row"
              name="currentPassword"
              component={FormTextField}
              type="password"
              placeholder="Contraseña actual"
            />
            <Field
              className="form-row"
              name="password"
              component={FormTextField}
              type="password"
              placeholder="Nueva contraseña"
            />
            <Field
              className="form-row"
              name="repeatPassword"
              component={FormTextField}
              type="password"
              placeholder="Repetir nueva contraseña"
            />
          </DialogContent>
          <DialogActions classes={{ root: 'dialog-actions' }}>
            <Button type="submit" color="primary" variant="contained" fullWidth>
              Modificar contraseña
            </Button>
          </DialogActions>
          <CustomSnackbar
            open={
              changingCredentials &&
              (auth.changeCredentialsSuccess || auth.changeCredentialsErrorMessage !== null)
            }
            message={
              auth.changeCredentialsSuccess
                ? 'Se actualizó la contraseña correctamente'
                : auth.changeCredentialsErrorMessage!
            }
            handleClose={closeSnack}
            type={auth.changeCredentialsSuccess ? Status.SUCCESS : Status.ERROR}
          />
        </Form>
      </Formik>
    </Dialog>
  );
}

export default connector(ChangePasswordDialog);
