import { AuthState, LoggedAccount } from '../config/types';
import {
  LOGGING_IN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  RESETING_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CONFIRMING_RESET_PASSWORD,
  CONFIRM_RESET_PASSWORD_SUCCESS,
  CONFIRM_RESET_PASSWORD_ERROR,
  AuthDataTypes,
  REGISTERING_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  VALIDATING_REGISTER,
  VALIDATE_REGISTER_SUCCESS,
  VALIDATE_REGISTER_ERROR,
  REGISTER_BUSINESS_SUCCESS,
  REGISTERING_BUSINESS,
  REGISTER_BUSINESS_ERROR,
  GETTING_ACCOUNT,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_ERROR,
  UPDATING_ACCOUNT,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_ERROR,
  CHANGING_CREDENTIALS,
  CHANGE_CREDENTIALS_SUCCESS,
  CHANGE_CREDENTIALS_ERROR,
  BusinessDataTypes,
  LogoutActionTypes,
  UPDATE_BUSINESS_SUCCESS,
  CommercesDataTypes,
  CREATE_COMMERCE_SUCCESS,
  DELETE_COMMERCE_SUCCESS,
  CREATE_BUSINESS_SUCCESS,
  RESENDING_CONFIRMATION,
  RESEND_CONFIRMATION_SUCCESS,
  RESEND_CONFIRMATION_FAILED,
} from '../config/ActionTypes';
import { UserTypeEnum } from '../config/enums';

export const initialState: AuthState = {
  loggingIn: false,
  loggedIn: false,
  user: null,
  logInErrorMessage: null,
  resetingPassword: false,
  resetPasswordSuccess: false,
  resetPasswordErrorMessage: null,
  confirmingResetPassword: false,
  confirmResetPasswordSuccess: false,
  confirmResetPasswordErrorMessage: null,
  registeringUser: false,
  registeredUser: null,
  registerUserSuccess: false,
  registerUserErrorMessage: null,
  validatingRegister: false,
  validateRegisterSuccess: false,
  validateRegisterErrorMessage: null,
  registeringBusiness: false,
  registeredBusiness: null,
  registerBusinessSuccess: false,
  registerBusinessErrorMessage: null,
  gettingAccount: false,
  account: null,
  getAccountErrorMessage: null,
  updatingAccount: false,
  updateAccountSuccess: false,
  updateAccountErrorMessage: null,
  changingCredentials: false,
  changeCredentialsSuccess: false,
  changeCredentialsErrorMessage: null,
  resendingConfirmation: false,
  resendConfirmationSuccess: false,
  resendConfirmationErrorMessage: null,
};

export function authReducer(
  state = initialState,
  action: AuthDataTypes | BusinessDataTypes | CommercesDataTypes | LogoutActionTypes,
): AuthState {
  switch (action.type) {
    case LOGGING_IN:
      return {
        ...state,
        loggingIn: true,
        loggedIn: false,
        user: null,
        logInErrorMessage: null,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        account: action.account,
        logInErrorMessage: null,
      };

    case LOGIN_ERROR:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        user: null,
        logInErrorMessage: action.error,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    case RESETING_PASSWORD:
      return {
        ...state,
        resetingPassword: true,
        resetPasswordSuccess: false,
        resetPasswordErrorMessage: null,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetingPassword: false,
        resetPasswordSuccess: true,
        resetPasswordErrorMessage: null,
      };

    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetingPassword: false,
        resetPasswordSuccess: false,
        resetPasswordErrorMessage: action.error,
      };

    case CONFIRMING_RESET_PASSWORD:
      return {
        ...state,
        confirmingResetPassword: true,
        confirmResetPasswordSuccess: false,
        confirmResetPasswordErrorMessage: null,
      };

    case CONFIRM_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        confirmingResetPassword: false,
        confirmResetPasswordSuccess: true,
        confirmResetPasswordErrorMessage: null,
      };

    case CONFIRM_RESET_PASSWORD_ERROR:
      return {
        ...state,
        confirmingResetPassword: false,
        confirmResetPasswordSuccess: false,
        confirmResetPasswordErrorMessage: action.error,
      };

    case REGISTERING_USER:
      return {
        ...state,
        registeringUser: true,
        registeredUser: null,
        registerUserSuccess: false,
        registerUserErrorMessage: null,
      };

    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        registeringUser: false,
        registeredUser: action.user,
        registerUserSuccess: true,
        registerUserErrorMessage: null,
      };

    case REGISTER_USER_ERROR:
      return {
        ...state,
        registeringUser: false,
        registeredUser: null,
        registerUserSuccess: false,
        registerUserErrorMessage: action.error,
      };

    case VALIDATING_REGISTER:
      return {
        ...state,
        validatingRegister: true,
        validateRegisterSuccess: false,
        validateRegisterErrorMessage: null,
      };

    case VALIDATE_REGISTER_SUCCESS:
      return {
        ...state,
        validatingRegister: false,
        validateRegisterSuccess: true,
        validateRegisterErrorMessage: null,
      };

    case VALIDATE_REGISTER_ERROR:
      return {
        ...state,
        validatingRegister: false,
        validateRegisterSuccess: false,
        validateRegisterErrorMessage: action.error,
      };

    case REGISTERING_BUSINESS:
      return {
        ...state,
        registeringBusiness: true,
        registeredBusiness: null,
        registerBusinessSuccess: false,
        registerBusinessErrorMessage: null,
      };

    case REGISTER_BUSINESS_SUCCESS:
      return {
        ...state,
        registeringBusiness: false,
        registeredBusiness: action.business,
        registerBusinessSuccess: true,
        registerBusinessErrorMessage: null,
      };

    case REGISTER_BUSINESS_ERROR:
      return {
        ...state,
        registeringBusiness: false,
        registeredBusiness: null,
        registerBusinessSuccess: false,
        registerBusinessErrorMessage: action.error,
      };

    case GETTING_ACCOUNT:
      return {
        ...state,
        loggingIn: true,
        gettingAccount: true,
        account: null,
        getAccountErrorMessage: null,
      };

    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        gettingAccount: false,
        account: action.account,
        getAccountErrorMessage: null,
      };

    case GET_ACCOUNT_ERROR:
      return {
        ...state,
        gettingAccount: false,
        account: null,
        getAccountErrorMessage: action.error,
      };

    case UPDATING_ACCOUNT:
      return {
        ...state,
        updatingAccount: true,
        updateAccountSuccess: false,
        updateAccountErrorMessage: null,
      };

    case UPDATE_ACCOUNT_SUCCESS:
      const newAccount: LoggedAccount = {
        ...action.account,
        business: {
          ...state.account!.business,
        },
      };

      return {
        ...state,
        updatingAccount: false,
        updateAccountSuccess: true,
        account: newAccount,
        updateAccountErrorMessage: null,
      };

    case UPDATE_ACCOUNT_ERROR:
      return {
        ...state,
        updatingAccount: false,
        updateAccountSuccess: false,
        updateAccountErrorMessage: action.error,
      };

    case CHANGING_CREDENTIALS:
      return {
        ...state,
        changingCredentials: true,
        changeCredentialsSuccess: false,
        changeCredentialsErrorMessage: null,
      };

    case CHANGE_CREDENTIALS_SUCCESS:
      return {
        ...state,
        changingCredentials: false,
        changeCredentialsSuccess: true,
        changeCredentialsErrorMessage: null,
      };

    case CHANGE_CREDENTIALS_ERROR:
      return {
        ...state,
        changingCredentials: false,
        changeCredentialsSuccess: false,
        changeCredentialsErrorMessage: action.error,
      };

    case UPDATE_BUSINESS_SUCCESS:
      if (state.account) {
        let newAccount: LoggedAccount = {
          ...state.account,
          business: {
            ...action.business,
            hasCommerces: state.account.business.hasCommerces,
          },
        };

        return {
          ...state,
          account: newAccount,
        };
      } else {
        return { ...state };
      }

    case CREATE_COMMERCE_SUCCESS:
      if (
        state.account &&
        state.account.type !== UserTypeEnum.SUPERADMIN &&
        !state.account.business.hasCommerces
      ) {
        let accountWithCommerce: LoggedAccount = {
          ...state.account,
          business: {
            ...state.account.business,
            hasCommerces: true,
          },
        };

        return {
          ...state,
          account: accountWithCommerce,
        };
      } else {
        return { ...state };
      }

    case DELETE_COMMERCE_SUCCESS:
      if (state.account && state.account.business.hasCommerces && action.isLast) {
        let accountWithoutCommerces: LoggedAccount = {
          ...state.account,
          business: {
            ...state.account.business,
            hasCommerces: false,
          },
        };

        return {
          ...state,
          account: accountWithoutCommerces,
        };
      } else {
        return { ...state };
      }

    case CREATE_BUSINESS_SUCCESS:
      if (state.account) {
        return {
          ...state,
          account: {
            ...state.account,
            business: action.business,
          },
        };
      } else {
        return { ...state };
      }

    case RESENDING_CONFIRMATION: {
      return {
        ...state,
        resendingConfirmation: true,
        resendConfirmationSuccess: false,
        resendConfirmationErrorMessage: null,
      };
    }

    case RESEND_CONFIRMATION_SUCCESS: {
      return {
        ...state,
        resendingConfirmation: false,
        resendConfirmationSuccess: true,
        resendConfirmationErrorMessage: null,
      };
    }

    case RESEND_CONFIRMATION_FAILED: {
      return {
        ...state,
        resendingConfirmation: false,
        resendConfirmationSuccess: false,
        resendConfirmationErrorMessage: action.error,
      };
    }

    default:
      return state;
  }
}
