interface CsvHeader {
  key: string;
  label: string;
}

export const csvHeaders: CsvHeader[] = [
  { label: 'Estado', key: 'status' },
  { label: 'Fecha', key: 'date' },
  { label: 'Descripción', key: 'description' },
  { label: 'Tipo de link', key: 'linkType' },
  { label: 'Medio de pago', key: 'issuerName' },
  { label: 'Monto', key: 'amount' },
  { label: 'Referencia', key: 'referencePayment' },
];
