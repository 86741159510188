import React from 'react';
import { Snackbar, SnackbarContent, Slide, makeStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Status } from '../../store/config/enums';
import { useTheme } from '@material-ui/core/styles';

const useSnackbarStyles = makeStyles({
  snackbar: {
    maxWidth: 600,
  },
  message: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: 15,
  },
});

interface CustomSnackbarProps {
  open: boolean;
  message: string;
  autoHide?: number;
  handleClose(event: React.SyntheticEvent<any>): void;
  type: number;
}

function CustomSnackbar({ open, message, autoHide, handleClose, type }: CustomSnackbarProps) {
  const classes = useSnackbarStyles();
  const theme = useTheme();
  let statusColor;
  switch (type) {
    case Status.SUCCESS:
      statusColor = theme.palette.success;
      break;
    case Status.ERROR:
      statusColor = theme.palette.error;
      break;
    default:
      statusColor = theme.palette.error;
  }
  const statusIcon = type === Status.SUCCESS ? <CheckCircleIcon /> : <ErrorIcon />;

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHide ? autoHide : 2000}
      TransitionComponent={Slide}
      onClose={handleClose}
      classes={{ root: classes.snackbar }}
    >
      <SnackbarContent
        classes={{ message: classes.message }}
        style={{ backgroundColor: statusColor.main }}
        message={
          <>
            <span className={classes.icon}>{statusIcon}</span>
            {message}
          </>
        }
      />
    </Snackbar>
  );
}

export default CustomSnackbar;
