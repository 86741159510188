import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  makeStyles,
  Theme,
  createStyles,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      paddingBottom: 0,
    },
    icon: {
      cursor: 'pointer',
      userSelect: 'none',
      color: theme.palette.grey[500],
    },
    content: {
      padding: '2rem',
      paddingTop: '1rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    message: {
      fontWeight: 'bold',
    },
    actions: {
      marginTop: '1.4rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    button: {
      padding: '0.4rem 4rem',
      backgroundColor: theme.palette.error.main,
      color: '#ffffff',
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
  }),
);

interface DeleteDialogProps {
  open: boolean;
  setOpen(value: boolean): void;
  message: string;
  deleteAction(): void;
  deleteButtonText?: string;
  deleteDisabled?: boolean;
}

function DeleteDialog({
  open,
  setOpen,
  message,
  deleteAction,
  deleteButtonText,
  deleteDisabled,
}: DeleteDialogProps) {
  const classes = useDialogStyles();

  const handleDelete = () => {
    deleteAction();
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <Dialog className="dialog" fullWidth maxWidth="xs" open={open} onClose={closeDialog}>
      <DialogTitle className={classes.title}>
        <CloseIcon fontSize="default" className={classes.icon} onClick={closeDialog} />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="h5" className={classes.message}>
          {message}
        </Typography>
        <div className={classes.actions}>
          <Button
            className={classes.button}
            variant="contained"
            disabled={deleteDisabled}
            onClick={handleDelete}
          >
            {deleteButtonText ?? 'Eliminar'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default DeleteDialog;
