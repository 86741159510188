import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  DialogTitle,
  DialogActions,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { Form, Formik, Field } from 'formik';
import { FormTextField } from '../../forms/FormTextField';
import CloseIcon from '@material-ui/icons/Close';
import schemas from '../../../data/schemas';
import { CustomSnackbar } from '../../CustomSnackbar';
import { Status } from '../../../store/config/enums';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../store/config/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  }),
);

interface ShareValues {
  email: string;
}

interface ShareOptionProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  share: (email: string) => void;
}

const mapStateToProps = (state: RootState) => ({
  links: state.links,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & ShareOptionProps;

function ShareOptions({ links, open, setOpen, share }: PropsType) {
  const classes = useStyles();

  const [sharingLink, setSharingLink] = useState<boolean>(false);
  const closeDialog = () => {
    setOpen(false);
  };
  const submitLink = (values: ShareValues) => {
    setSharingLink(true);
    share(values.email);
  };
  const closeSnack = () => {
    setSharingLink(false);
    closeDialog();
  };
  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      className="dialog"
      maxWidth="xs"
      fullWidth
      classes={{ paper: classes.container }}
    >
      <DialogTitle classes={{ root: 'dialog-title' }}>
        <div />
        <Typography variant="h4" component="p">
          Compartir por email
        </Typography>
        <CloseIcon fontSize="default" className="icon" onClick={closeDialog} />
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            email: '',
          }}
          onSubmit={submitLink}
          validationSchema={schemas.ShareLinkSchema}
        >
          <Form className="form" id="email-form-id">
            <Field
              name="email"
              component={FormTextField}
              type="email"
              placeholder="Email"
              className="form-row"
            />
          </Form>
        </Formik>
      </DialogContent>
      <DialogActions classes={{ root: 'dialog-actions' }}>
        <Button
          type="submit"
          form="email-form-id"
          variant="contained"
          color="primary"
          fullWidth
          disableElevation
        >
          Enviar link
        </Button>
      </DialogActions>
      <CustomSnackbar
        open={sharingLink && (links.shareLinkSuccess || links.shareLinkErrorMessage !== null)}
        message={
          links.shareLinkSuccess
            ? 'Se compartió el link correctamente.'
            : 'Ocurrió un error al compartir el link.'
        }
        handleClose={closeSnack}
        type={links.shareLinkSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </Dialog>
  );
}

export default connector(ShareOptions);
