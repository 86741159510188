import React from 'react';
import { useTheme } from '@material-ui/core/styles';

interface PermanentLinkProps {
  color?: string;
  className?: string;
}
function PermanentLink({ color, className }: PermanentLinkProps) {
  const theme = useTheme();
  color = color ?? theme.palette.grey[400];
  className = className ?? '';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="15.86"
      height="16.473"
      viewBox="0 0 15.86 16.473"
    >
      <g id="icon-multi" transform="translate(-6.07 -5.264)">
        <g id="noun-recycle-148557" transform="translate(6.07 5.264)">
          <path
            id="Trazado_508"
            data-name="Trazado 508"
            d="M333.182,265.3l-1.245-2.141a.923.923,0,1,0-1.592.934l1.245,2.141a.842.842,0,0,1-.732,1.263h-2.892l.6-.6a.919.919,0,0,0-1.3-1.3l-2.2,2.16a.87.87,0,0,0-.275.659.9.9,0,0,0,.275.659l2.2,2.16a.919.919,0,0,0,.641.274.924.924,0,0,0,.659-1.574l-.6-.6h2.892a2.638,2.638,0,0,0,2.324-1.336,2.67,2.67,0,0,0,0-2.69Z"
            transform="translate(-317.686 -255.031)"
            fill={color}
          />
          <path
            id="Trazado_509"
            data-name="Trazado 509"
            d="M112.52,198.6h-2.471a.842.842,0,0,1-.732-1.263l1.428-2.489.2.805a.882.882,0,0,0,1.116.659.937.937,0,0,0,.677-1.116l-.75-3a.943.943,0,0,0-.421-.567.883.883,0,0,0-.714-.092l-3,.787a.928.928,0,0,0,.476,1.794l.86-.22-1.464,2.544a2.561,2.561,0,0,0,0,2.654,2.659,2.659,0,0,0,2.325,1.336h2.471a.915.915,0,1,0,0-1.83Z"
            transform="translate(-107.164 -186.155)"
            fill={color}
          />
          <path
            id="Trazado_510"
            data-name="Trazado 510"
            d="M239.674,32.778a.924.924,0,0,0,1.263-.329l1.263-2.178a.847.847,0,0,1,1.464,0l1.483,2.562-.879-.22a.917.917,0,1,0-.458,1.775l3,.769a.921.921,0,0,0,.22.037.914.914,0,0,0,.9-.7l.732-3.02a.923.923,0,0,0-1.794-.439l-.183.769-1.428-2.453A2.667,2.667,0,0,0,242.914,28a2.638,2.638,0,0,0-2.324,1.336l-1.263,2.178a.906.906,0,0,0,.348,1.263Z"
            transform="translate(-234.886 -28.001)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

export default PermanentLink;
