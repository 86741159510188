function ShareByQr() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.981" height="19.981" viewBox="0 0 19.981 19.981">
      <path
        id="qrcode"
        d="M0,40.563H8.563V32H0Zm1.427-7.136H7.136v5.709H1.427ZM11.418,32v8.563h8.563V32Zm7.136,7.136H12.845V33.427h5.709ZM0,51.981H8.563V43.418H0Zm1.427-7.136H7.136v5.709H1.427Zm1.427-9.99H5.709v2.854H2.854Zm14.272,2.854H14.272V34.854h2.854ZM2.854,46.272H5.709v2.854H2.854Zm15.7-2.854h1.427v5.709H14.272V47.7H12.845v4.282H11.418V43.418H15.7v1.427h2.854Zm0,7.136h1.427v1.427H18.554Zm-2.854,0h1.427v1.427H15.7Z"
        transform="translate(0 -32)"
        fill="#fff"
      />
    </svg>
  );
}

export default ShareByQr;
