function ShareByWhatsapp() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.981" height="19.981" viewBox="0 0 19.981 19.981">
      <path
        id="whatsapp"
        d="M16.988,34.9A9.9,9.9,0,0,0,1.4,46.852L0,51.981,5.249,50.6a9.871,9.871,0,0,0,4.732,1.2h0a9.838,9.838,0,0,0,7-16.9Zm-7,15.235a8.215,8.215,0,0,1-4.192-1.146l-.3-.178-3.113.816.83-3.037-.2-.312a8.245,8.245,0,1,1,15.293-4.375A8.321,8.321,0,0,1,9.986,50.139ZM14.5,43.975c-.245-.125-1.463-.723-1.69-.8s-.392-.125-.557.125-.638.8-.785.972-.29.187-.535.062a6.734,6.734,0,0,1-3.367-2.944c-.254-.437.254-.406.727-1.351a.458.458,0,0,0-.022-.433c-.062-.125-.557-1.342-.763-1.838s-.406-.415-.557-.424-.308-.009-.473-.009a.916.916,0,0,0-.66.308,2.78,2.78,0,0,0-.865,2.065,4.847,4.847,0,0,0,1.008,2.56A11.055,11.055,0,0,0,10.187,46a4.841,4.841,0,0,0,2.97.62,2.534,2.534,0,0,0,1.668-1.177,2.069,2.069,0,0,0,.143-1.177C14.91,44.158,14.745,44.1,14.5,43.975Z"
        transform="translate(0 -32)"
        fill="#fff"
      />
    </svg>
  );
}

export default ShareByWhatsapp;
