import { Commerce, Field as PlexoField } from '../../../store/config/types';
import { Typography, Grid, Link, makeStyles, createStyles } from '@material-ui/core';
import { FormTextField } from '../../forms/FormTextField';
import { Field } from 'formik';

const useStyles = makeStyles(() =>
  createStyles({
    helperText: {
      paddingTop: '8px',
    },
  }),
);

interface PlexoFieldsProps {
  fields: PlexoField[] | undefined;
  commerce: Commerce | null;
}

function PlexoFields({ fields, commerce }: PlexoFieldsProps) {
  const classes = useStyles();

  const getPlexoFieldData = (type: number) => {
    switch (type) {
      case 2049:
        return {
          name: 'Código de comercio',
          value: 'commerceNumber',
        };

      case 2051:
        return {
          name: 'Número de terminal',
          value: 'terminalNumber',
        };

      case 270:
        return {
          name: 'Número de cuenta',
          value: 'accountNumber',
        };

      default:
        return { name: '', value: '', setValue: null };
    }
  };

  return (
    <>
      {fields &&
        fields.map((f) => {
          const fieldData = getPlexoFieldData(f.fieldType);

          if (fieldData.name !== '') {
            return (
              <Grid key={f.fieldType} item xs={12}>
                <Field
                  name={fieldData.value}
                  component={FormTextField}
                  type="text"
                  placeholder={fieldData.name}
                  disabled={commerce !== null}
                />
                {f.fieldType === 2049 && !commerce && (
                  <Typography variant="subtitle2" component="p" className={classes.helperText}>
                    ¿Necesitas un código de comercio? <Link href="/request-issuers">Solicítalo aquí</Link>
                  </Typography>
                )}
              </Grid>
            );
          } else {
            return null;
          }
        })}
    </>
  );
}

export default PlexoFields;
