import { Dispatch } from 'redux';
import { paymentsService } from '../../services/payments.service';
import {
  FETCHING_PAYMENTS,
  ADD_PAYMENTS,
  FETCH_PAYMENTS_ERROR,
  FetchPaymentsTypes,
  BEGIN_PAYMENT_SUCCESS,
  BEGIN_PAYMENT_ERROR,
  BeginPaymentTypes,
  GETTING_PAYMENT,
  GET_PAYMENT_SUCCESS,
  GET_PAYMENT_ERROR,
  GetPaymentTypes,
  CONFIRMING_PAYMENT,
  CONFIRM_PAYMENT_SUCCESS,
  CONFIRM_PAYMENT_ERROR,
  ConfirmPaymentTypes,
  ClearInstrumentTypes,
  CLEAR_INSTRUMENT,
  BEGINNING_PAYMENT,
  LOAD_PAYMENT_FILTERS,
  LoadPaymentsFiltersAction,
  VISA_REDIRECTED,
  TRANSACTION_CALLBACK,
  TransactionCallbackType,
  VisaRedirectedType,
  REGISTER_CALLBACK_FAILED,
  RegisterCallbackFailedType,
  CancelPaymentTypes,
  CANCELLING_PAYMENT,
  CANCEL_PAYMENT_SUCCESS,
  CANCEL_PAYMENT_ERROR,
} from '../config/ActionTypes';
import {
  BeginPaymentRequest,
  BeginPaymentResponse,
  PaymentResponse,
  FinalizePaymentRequest,
  PaymentInfo,
  ConfirmPaymentResponse,
  TransactionStatus,
  Filters,
} from '../config/types';

//#region Fetch payments

export const fetchPayments =
  (filters: Filters, page: number, limit: number, businessId: number) =>
  (dispatch: Dispatch<FetchPaymentsTypes>) => {
    dispatch(fetchingPayments());

    return paymentsService.fetchPayments({ ...filters, page, limit }, businessId).then(
      (response) => {
        dispatch(addPayments(response!));
      },
      (error) => {
        dispatch(fetchPaymentsError(error));
      },
    );
  };

const fetchingPayments = (): FetchPaymentsTypes => ({
  type: FETCHING_PAYMENTS,
});

export const loadPaymentsFilters = (filters: Filters, page: number, limit: number, businessId: number) => {
  return (dispatch: Dispatch<FetchPaymentsTypes | LoadPaymentsFiltersAction>) => {
    dispatch(loadFilters(filters));

    return paymentsService
      .fetchPayments(
        {
          ...filters,
          page,
          limit,
        },
        businessId,
      )
      .then(
        (response) => {
          dispatch(addPayments(response!));
        },
        (error) => {
          dispatch(fetchPaymentsError(error));
        },
      );
  };
};

export const loadFilters = (filters: Filters): LoadPaymentsFiltersAction => ({
  type: LOAD_PAYMENT_FILTERS,
  filters: filters,
});

const addPayments = (paymentResponse: PaymentResponse): FetchPaymentsTypes => ({
  type: ADD_PAYMENTS,
  paymentResponse,
});

const fetchPaymentsError = (error: string): FetchPaymentsTypes => ({
  type: FETCH_PAYMENTS_ERROR,
  error,
});

//#endregion

//#region Begin payment

export const beginPayment =
  (beginPaymentRequest: BeginPaymentRequest) => (dispatch: Dispatch<BeginPaymentTypes>) => {
    dispatch(beginningPayment());

    return paymentsService.beginPayment(beginPaymentRequest).then(
      (response) => {
        console.log(response);
        dispatch(beginPaymentSuccess(response!));
      },
      (error) => {
        dispatch(beginPaymentError(error));
      },
    );
  };

const beginningPayment = (): BeginPaymentTypes => ({
  type: BEGINNING_PAYMENT,
});

const beginPaymentSuccess = (confirmPaymentResponse: BeginPaymentResponse): BeginPaymentTypes => ({
  type: BEGIN_PAYMENT_SUCCESS,
  confirmPaymentResponse,
});

const beginPaymentError = (error: string): BeginPaymentTypes => ({
  type: BEGIN_PAYMENT_ERROR,
  error,
});

//#endregion

//#region  Get payment

export const getPayment = (paymentId: string) => (dispatch: Dispatch<GetPaymentTypes>) => {
  dispatch(gettingPayment());

  return paymentsService.getPayment({ paymentId }).then(
    (response) => {
      dispatch(getPaymentSuccess(response!));
    },
    (error) => {
      dispatch(getPaymentError(error));
    },
  );
};

const gettingPayment = (): GetPaymentTypes => ({
  type: GETTING_PAYMENT,
});

const getPaymentSuccess = (paymentResponse: PaymentInfo): GetPaymentTypes => ({
  type: GET_PAYMENT_SUCCESS,
  paymentResponse,
});

const getPaymentError = (error: string): GetPaymentTypes => ({
  type: GET_PAYMENT_ERROR,
  error,
});

//#endregion

//#region Clear instrument

export const clearInstrument = (): ClearInstrumentTypes => ({
  type: CLEAR_INSTRUMENT,
});

//#endregion

//#region Confirm payment

export const confirmPayment =
  (finalizePaymentRequest: FinalizePaymentRequest) => (dispatch: Dispatch<ConfirmPaymentTypes>) => {
    dispatch(confirmingPayment());

    return paymentsService.confirmPayment(finalizePaymentRequest).then(
      (response) => {
        dispatch(confirmPaymentSuccess(response!));
      },
      (error) => {
        dispatch(confirmPaymentError(error));
      },
    );
  };

const confirmingPayment = (): ConfirmPaymentTypes => ({
  type: CONFIRMING_PAYMENT,
});

const confirmPaymentSuccess = (response: ConfirmPaymentResponse): ConfirmPaymentTypes => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  response,
});

const confirmPaymentError = (error: string): ConfirmPaymentTypes => ({
  type: CONFIRM_PAYMENT_ERROR,
  error,
});

//#endregion

//#region Cancel payment

export const cancelPayment =
  (plexoReferenceId: string, businessId: number) => (dispatch: Dispatch<CancelPaymentTypes>) => {
    dispatch(cancellingPayment());

    return paymentsService.cancelPayment(plexoReferenceId, businessId).then(
      (response) => {
        dispatch(cancelPaymentSuccess(plexoReferenceId));
      },
      (error) => {
        dispatch(cancelPaymentError(error));
      },
    );
  };

const cancellingPayment = (): CancelPaymentTypes => ({
  type: CANCELLING_PAYMENT,
});

const cancelPaymentSuccess = (plexoReferenceId: string): CancelPaymentTypes => ({
  type: CANCEL_PAYMENT_SUCCESS,
  plexoReferenceId,
});

const cancelPaymentError = (error: string): CancelPaymentTypes => ({
  type: CANCEL_PAYMENT_ERROR,
  error,
});

//#endregion

//#region Visa Redirect

export const visaRedirect = (paymentId: string): VisaRedirectedType => ({
  type: VISA_REDIRECTED,
  paymentId,
});

//#endregion

//#region TransactionCallback

export const transactionCallback = (response: TransactionStatus): TransactionCallbackType => ({
  type: TRANSACTION_CALLBACK,
  response,
});

//#endregion

//#region RegisterCallbackFailed

export const registerCallbackFailed = (response: boolean): RegisterCallbackFailedType => ({
  type: REGISTER_CALLBACK_FAILED,
  response,
});

//#endregion
