import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { UserTypeEnum } from '../../store/config/enums';
import Dashboard from '../Icons/Dashboard/Dashboard';
import { SingleLink } from '../Icons/SingleLink';

export const options = [
  {
    name: 'Tablero',
    icon: <Dashboard className="icon" />,
    route: '/dashboard',
    value: 0,
    roles: [UserTypeEnum.OWNER, UserTypeEnum.COLLABORATOR],
  },
  // TODO: Uncomment or delete this option when confirmed
  // {
  //   name: 'Links multiuso',
  //   icon: <PermanentLink className="icon" />,
  //   route: '/links',
  //   value: 1,
  //   roles: [UserTypeEnum.OWNER, UserTypeEnum.COLLABORATOR],
  // },
  {
    name: 'Links de pago',
    icon: <SingleLink className="icon" />,
    route: '/one-use-links',
    value: 2,
    roles: [UserTypeEnum.OWNER, UserTypeEnum.COLLABORATOR],
  },
  {
    name: 'Pagos',
    icon: <MonetizationOnIcon className="icon" />,
    route: '/payments',
    value: 3,
    roles: [UserTypeEnum.SUPERADMIN, UserTypeEnum.OWNER, UserTypeEnum.COLLABORATOR],
  },
  {
    name: 'Bancos',
    icon: <AccountBalanceIcon className="icon" />,
    route: '/banks',
    value: 4,
    roles: [UserTypeEnum.SUPERADMIN],
  },
  {
    name: 'Clientes',
    icon: <BusinessCenterIcon className="icon" />,
    route: '/manage-clients',
    value: 5,
    roles: [UserTypeEnum.SUPERADMIN],
  },
  {
    name: 'Hidden',
    icon: <MonetizationOnIcon className="icon" />,
    route: '/hidden',
    value: 99,
    roles: [UserTypeEnum.SUPERADMIN, UserTypeEnum.OWNER, UserTypeEnum.COLLABORATOR],
  },
];

export const getOptionsFromRole = (role?: UserTypeEnum) => {
  return options.filter((o) => {
    if (role && o.roles?.includes(role)) {
      return o;
    }
    return null;
  });
};
