import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Amount, Bank, BusinessIssuer, RootState } from '../../../store/config/types';
import { FormSelectField } from '../../forms/FormSelectField';
import { FormTextField } from '../../forms/FormTextField';
import { Button, Divider, Paper, Typography } from '@material-ui/core';
import { currencyHelper } from '../../../helpers/currencyHelper';
import { numberHelper } from '../../../helpers/numberHelper';
import { ThunkDispatch } from 'redux-thunk';
import { AppActions } from '../../../store/config/ActionTypes';
import { fetchInstallments } from '../../../store/action_creators/commerces.actions';
import { connect, ConnectedProps } from 'react-redux';
import { Loading } from '../../Loading';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { urlHelper } from '../../../helpers/urlHelper';
import dummy from '../../../assets/no-image.jpg';

const mapStateToProps = (state: RootState) => ({
  commerces: state.commerces,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchInstallments: () => dispatch(fetchInstallments()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface InstallmentProps {
  submit: (values: InstallmentValues) => void;
  issuer: BusinessIssuer;
  bank: Bank | null;
  beginningPayment: boolean;
  amountToPay: Amount;
  businessName: string;
  businessId: number;
  setStep: (step: number) => void;
}

type PropsType = PropsFromRedux & InstallmentProps;

interface InstallmentValues {
  installments: number;
  comment: string;
}

function ConsumerInstallments({
  submit,
  issuer,
  bank,
  beginningPayment,
  amountToPay,
  businessName,
  businessId,
  commerces,
  fetchInstallments,
  setStep,
}: PropsType) {
  const [src, setSrc] = useState(urlHelper.buildImageUrl(businessId));

  const onImageError = () => {
    setSrc(dummy);
  };

  useEffect(() => {
    if (
      !commerces.fetchingPossibleInstallments &&
      commerces.possibleInstallments === null &&
      commerces.fetchInstallmentsError === null
    ) {
      fetchInstallments();
    }
  }, [
    commerces.fetchingPossibleInstallments,
    commerces.possibleInstallments,
    commerces.fetchInstallmentsError,
    fetchInstallments,
  ]);

  return (
    <Paper className="consumer-card">
      <div className="heading installments-heading">
        <div className="go-back-div" onClick={() => setStep(1)}>
          <ArrowBackIosIcon />
        </div>
        <Typography variant="h4">Cuotas</Typography>
        <Typography variant="h6">Por favor, ingresa la cantidad de cuotas.</Typography>
      </div>
      <div className="card-body">
        <img className="card-image" src={src} onError={onImageError} alt="logo" />
        <Typography variant="subtitle2">
          Destinatario <span className="destination">{businessName}</span>
        </Typography>

        <Divider className="divider" />
        <div className="installments-card-body">
          <div className="amount-div">
            <Typography variant="subtitle2">
              Pago en {currencyHelper.getCurrencyName(amountToPay!.currency).toLowerCase()}
            </Typography>
            <Typography variant="h3" className="amount amount-alone">
              <span className="currency">{currencyHelper.getCurrencyToken(amountToPay!.currency)}</span>
              {numberHelper.getNumberWithDots(amountToPay!.value)}
              <span className="iva">IVA inc.</span>
            </Typography>
          </div>
          {commerces.possibleInstallments ? (
            <Formik
              initialValues={{
                installments: issuer.installments[0],
                comment: '',
              }}
              onSubmit={submit}
            >
              <Form className="form" key="installments">
                <div className="installments-row">
                  <div className="issuer-card">
                    <img
                      className="issuer-image"
                      src={bank ? urlHelper.buildBankImageUrl(bank.id) : issuer.issuerImageUrl}
                      alt={issuer.name}
                    />
                  </div>
                  <Field
                    name="installments"
                    component={FormSelectField}
                    label="Cuotas"
                    options={issuer.installments
                      .sort((a, b) => a - b)
                      .map((i) => {
                        return {
                          id: i,
                          value: i,
                        };
                      })}
                  />
                </div>
                <Field
                  component={FormTextField}
                  type="text"
                  name="comment"
                  placeholder="Puedes agregar un comentario (opcional)"
                  multiline
                  rows={3}
                />
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={beginningPayment}
                  classes={{ root: 'continue-button' }}
                  className="installments-button"
                >
                  Pagar
                </Button>
              </Form>
            </Formik>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </Paper>
  );
}

export default connector(ConsumerInstallments);
