import React, { useState } from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { FormTextField } from '../../components/forms/FormTextField';
import schemas from '../../data/schemas';
import { ScreenTitle } from '../../components/ScreenTitle';
import { ChangePasswordDialog } from '../../components/dialogs/ChangePasswordDialog';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { Loading } from '../../components/Loading';
import { Button, Link, Paper } from '@material-ui/core';
import { Account, RootState } from '../../store/config/types';
import { ThunkDispatch } from 'redux-thunk';
import { AppActions } from '../../store/config/ActionTypes';
import { connect, ConnectedProps } from 'react-redux';
import { updateAccount } from '../../store/action_creators/auth.actions';
import { Status } from '../../store/config/enums';

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  updateAccount: (account: Account) => dispatch(updateAccount(account)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Values {
  names: string;
  surnames: string;
  email: string;
}

function Profile({ auth, updateAccount }: PropsFromRedux) {
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false);
  const [updatingAccount, setUpdatingAccount] = useState<boolean>(false);

  const submitProfile = (values: Values, helpers: FormikHelpers<Values>) => {
    const account: Account = {
      userId: auth.account!.userId,
      businessId: auth.account!.business.id!,
      names: values.names,
      surnames: values.surnames,
      telephoneNo: auth.account!.telephoneNo,
      type: auth.account!.type,
    };

    setUpdatingAccount(true);
    updateAccount(account);
  };

  const closeSnack = () => {
    setUpdatingAccount(false);
  };

  return (
    <div className="screen-container profile">
      <ScreenTitle title="Perfil" />
      <Paper elevation={2} className="profile-paper">
        {auth.account ? (
          <Formik
            initialValues={{
              names: auth.account.names,
              surnames: auth.account.surnames,
              email: auth.account.email,
            }}
            validationSchema={schemas.ProfileSchema}
            onSubmit={submitProfile}
          >
            <Form id="profileForm" className="form">
              <Field
                className="form-row"
                name="names"
                component={FormTextField}
                type="text"
                placeholder="Nombres"
              />
              <Field
                className="form-row"
                name="surnames"
                component={FormTextField}
                type="text"
                placeholder="Apellidos"
              />
              <Field
                className="form-row"
                name="email"
                component={FormTextField}
                type="text"
                placeholder="Correo electrónico"
                disabled
              />
              <div className="links">
                <Link onClick={() => setPasswordOpen(true)}>Cambiar contraseña</Link>
              </div>
            </Form>
          </Formik>
        ) : (
          <Loading />
        )}
      </Paper>
      <Button form="profileForm" type="submit" color="primary" variant="contained" fullWidth>
        Guardar cambios
      </Button>
      <ChangePasswordDialog open={passwordOpen} setOpen={setPasswordOpen} />
      <CustomSnackbar
        open={updatingAccount && (auth.updateAccountSuccess || auth.updateAccountErrorMessage !== null)}
        message={
          auth.updateAccountSuccess
            ? 'Se actualizaron los datos de perfil correctamente'
            : auth.updateAccountErrorMessage!
        }
        handleClose={closeSnack}
        type={auth.updateAccountSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </div>
  );
}

export default connector(Profile);
