import { Typography } from '@material-ui/core';
import phone from '../../assets/phone.png';
import network from '../../assets/network@2x.png';
import Cookies from 'universal-cookie';

function Home() {
  const cookies = new Cookies();

  if (cookies.get('token') !== undefined) {
    cookies.remove('token', {
      path: '/',
    });
    window.location.reload();
  }

  return (
    <div className="home-content">
      <div className="texts">
        <Typography className="title" variant="h1">
          <span className="blue-text">Generá y gestioná</span>
          <br /> links de un uso o <br />
          de múltiples usos.
        </Typography>
        <Typography className="subtitle" variant="h3" component="h2">
          El link de pago es la herramienta más adaptable <br/>y rápida para gestionar tus cobranzas online.
        </Typography>
        <img src={network} alt="network" />
      </div>
      <div className="image">
        <img src={phone} alt="phone" />
      </div>
    </div>
  );
}

export default Home;
