import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  makeStyles,
  Theme,
  createStyles,
  DialogTitle,
  Typography,
  Link,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      paddingBottom: 0,
    },
    icon: {
      cursor: 'pointer',
      userSelect: 'none',
      color: theme.palette.grey[500],
    },
    content: {
      padding: '2rem 3rem',
      paddingTop: '1rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    actions: {
      marginTop: '1.4rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    button: {
      marginRight: '1rem',
    },
  }),
);

interface EmailConfirmationDialogProps {
  open: boolean;
  setOpen(value: boolean): void;
  message: string;
  action(): void;
}

function EmailConfirmationDialog({ open, setOpen, message, action }: EmailConfirmationDialogProps) {
  const classes = useDialogStyles();

  const handleOk = () => {
    action();
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <Dialog className="dialog" fullWidth maxWidth="xs" open={open} onClose={closeDialog}>
      <DialogTitle className={classes.title}>
        <CloseIcon fontSize="default" className={classes.icon} onClick={closeDialog} />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="h5">{message}</Typography>
        <div className={classes.actions}>
          <Button
            form="businessForm"
            type="submit"
            color="primary"
            className={classes.button}
            variant="contained"
            onClick={handleOk}
          >
            Aceptar
          </Button>
          <Link onClick={closeDialog}>Cancelar</Link>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default EmailConfirmationDialog;
