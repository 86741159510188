import { Button, Divider, Link, Paper, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ScreenTitle } from '../../components/ScreenTitle';

function FAQs() {
  const history = useHistory();
  const prevPage = localStorage.getItem('prevPage');

  // TODO: Uncomment or delete commented code
  // const goToContact = () => {
  //   if (history.location.pathname === '/faqs') {
  //     history.push('/contact');
  //   } else {
  //     history.push('/user-contact');
  //   }
  // };

  const userFAQs = (
    <div className="faqs-list">
      <div className="faq">
        <Typography variant="subtitle2" className="question">
          ¿Qué son los links de pago?
        </Typography>
        <Typography variant="subtitle2" className="answer">
          Son la manera más fácil de recibir dinero. Los links expiran o se inactivan cuando un cliente lo
          utiliza para pagarte. Es decir que si compartes el link con tu cliente, a partir de que el mismo lo
          use y te pague, no podrás compartirlo con otro cliente. Con estos links solo recibirás un pago.
        </Typography>
        <Divider className="divider" />
      </div>
      <div className="faq">
        <Typography variant="subtitle2" className="question">
          ¿Cómo comienzo a operar con los links de pago?
        </Typography>
        <Typography variant="subtitle2" className="answer">
          Consulta a{' '}
          <Link href="mailto:comercios@oca.com.uy" target="_blank">
            comercios@oca.com.uy
          </Link>
          .
        </Typography>
        <Divider className="divider" />
      </div>
      <div className="faq">
        <Typography variant="subtitle2" className="question">
          ¿Cómo funciona un link de pago?
        </Typography>
        <Typography variant="subtitle2" className="answer">
          Es sencillo. Solo te registras en OCA y abres una cuenta gratis. Generas tus links, los compartes,
          te pagan, y recibes los pagos en tu cuenta bancaria.
        </Typography>
        <Divider className="divider" />
      </div>
      <div className="faq">
        <Typography variant="subtitle2" className="question">
          ¿Qué beneficios tengo con los links de pago?
        </Typography>
        <Typography variant="subtitle2" className="answer">
          <ol type="A">
            <li>
              Cierras ventas en tiempo real, con solo compartir el link a tus usuarios por Whatsapp, email,
              SMS o redes sociales, incluso en la misma conversación online con tu cliente.
            </li>
            <li>Puedes personalizar tu link para identificar el pago, fácilmente.</li>
            <li>
              No necesitas compartir tu información bancaria. Tu link se encarga de depositar el dinero en tu
              cuenta.
            </li>
            <li>
              Tus clientes solo tendrán que hacer clic el link y seguir simples instrucciones para realizar el
              pago, recibiendo la confirmación del mismo en tiempo real.
            </li>
            <li>No precisas ninguna solución de POS físico.</li>
            <li>
              No tienes que visitar a ninguno de tus clientes para cobrarle. Puedes enviarle la solicitud de
              cobro por los medios anteriormente citados.
            </li>
            <li>
              Puedes mantener tu cuenta de links de pago activa, sin costos mensuales de ningún tipo y generar
              los links que precises, cuando los precises.
            </li>
          </ol>
        </Typography>
        <Divider className="divider" />
      </div>
      <div className="faq">
        <Typography variant="subtitle2" className="question">
          ¿Quiénes pueden usar los links de pago?
        </Typography>
        <Typography variant="subtitle2" className="answer">
          Cualquier empresa o persona que quiera tener una solución rápida y sencilla para efectuar sus
          cobros. Especialmente diseñado para trabajadores independientes, vendedores en redes sociales,
          proveedores, agencias de viaje, vendedores, etc.
        </Typography>
        <Divider className="divider" />
      </div>
      <div className="faq">
        <Typography variant="subtitle2" className="question">
          ¿Qué costos tengo para implementar la solución del link de pago?
        </Typography>
        <Typography variant="subtitle2" className="answer">
          El costo por utilizar el servicio es el mismo que el comercio tiene cerrado en sus condiciones
          comerciales de transacciones físicas/web con Oca Comercios.
        </Typography>
        <Divider className="divider" />
      </div>
      <div className="faq">
        <Typography variant="subtitle2" className="question">
          ¿Es seguro cobrar y pagar por link de pago?
        </Typography>
        <Typography variant="subtitle2" className="answer">
          Es totalmente seguro. Tus datos nunca estarán expuestos y tus clientes manejan su propios datos
          ingresándolos en una plataforma segura auditada por las normas PCI. De hecho es mucho más seguro
          pagar con un link de pago que entregarle la tarjeta a un desconocido para que te cobre.
        </Typography>
        <Divider className="divider" />
      </div>
      <div className="faq">
        <Typography variant="subtitle2" className="question">
          ¿En cuánto tiempo recibo el dinero por mis ventas?
        </Typography>
        <Typography variant="subtitle2" className="answer">
          Los plazos de acreditación son los mismos que manejas según condiciones comerciales con OCA
          Comercios dependiendo si es débito o crédito. Esto depende de la característica de tu código de
          comercio.
        </Typography>
        <Divider className="divider" />
      </div>
      <div className="faq">
        <Typography variant="subtitle2" className="question">
          ¿Cuál es la validez de un link de pago?
        </Typography>
        <Typography variant="subtitle2" className="answer">
          Salvo que sea un link de un solo uso, todos los links mantienen validez hasta que tu cliente los
          utilice.
        </Typography>
        <Divider className="divider" />
      </div>
      <div className="faq">
        <Typography variant="subtitle2" className="question">
          ¿Qué es el campo "soft descriptor" y para qué sirve?
        </Typography>
        <Typography variant="subtitle2" className="answer">
          Es un valor que solicitamos en el registro de la empresa y que puede editarse luego. Algunos medios
          de pago usan el texto allí ingresado como descripción del movimiento / transacción en los estados de
          cuenta.Sugerimos usar algo simple e identificable como el nombre de fantasía de la empresa.
        </Typography>
        <Divider className="divider" />
      </div>
    </div>
  );

  const clientFAQs = (
    <div className="faqs-list">
      <div className="faq">
        <Typography variant="subtitle2" className="question">
          ¿Es seguro pagar por link de pago?
        </Typography>
        <Typography variant="subtitle2" className="answer">
          Es totalmente seguro. Tus datos nunca estarán expuestos y tu mismo los controlas ingresandolos en
          Plexo que es una plataforma de pagos seguros auditada por las normas internacionales PCI. De hecho,
          es mucho más seguro pagar con un link de pago que entregarle la tarjeta a un desconocido para que te
          cobre.
        </Typography>
        <Divider className="divider" />
      </div>
      <div className="faq">
        <Typography variant="subtitle2" className="question">
          ¿Qué debo hacer cuando recibo un link de pago?
        </Typography>
        <Typography variant="subtitle2" className="answer">
          Seguramente serás notificado con anticipación que te enviaran al cobro una cuota o un servicio por
          este medio. Simplemente deberás seguir los pasos indicados en el email o por el medio que te lo
          hayan enviado.
        </Typography>
        <Divider className="divider" />
      </div>
      <div className="faq">
        <Typography variant="subtitle2" className="question">
          ¿Recibo una constancia de pago luego de haber pagado?
        </Typography>
        <Typography variant="subtitle2" className="answer">
          Sí, podrás descargar un recibo con un número de referencia el cual indica el pago realizado.
        </Typography>
        <Divider className="divider" />
      </div>
      <div className="faq">
        <Typography variant="subtitle2" className="question">
          ¿Es válido fiscalmente el recibo del link de pago?
        </Typography>
        <Typography variant="subtitle2" className="answer">
          No. Debes exigirle a tu proveedor o empresa que te haya enviado el link de pago, el comprobante
          fiscal correspondiente.
        </Typography>
        <Divider className="divider" />
      </div>
      <div className="faq">
        <Typography variant="subtitle2" className="question">
          ¿Se puede perder mi pago?
        </Typography>
        <Typography variant="subtitle2" className="answer">
          No. Es imposible que esto suceda. Todas las transacciones están trackeadas y debidamente almacenadas
          bajo estándares de seguridad internacionales.
        </Typography>
        <Divider className="divider" />
      </div>
      <div className="faq">
        <Typography variant="subtitle2" className="question">
          Si recibo un link para efectuar un pago, ¿cuánto tiempo tengo para hacerlo?
        </Typography>
        <Typography variant="subtitle2" className="answer">
          Los links no vencen por tiempo, así que lo puedes usar para pagar cuando dispongas de tiempo
        </Typography>
        <Divider className="divider" />
      </div>
      <div className="faq">
        <Typography variant="subtitle2" className="question">
          Si recibo un link por un monto o una moneda que no es correcto, ¿cómo procedo?
        </Typography>
        <Typography variant="subtitle2" className="answer">
          Comunicate con tu proveedor o quien te haya enviado el link y dile que tienes dudas respecto al
          monto a pagar. El proveedor o empresa que te haya enviado el link también puede haberse equivocado
          al generarlo.
        </Typography>
        <Divider className="divider" />
      </div>
    </div>
  );

  return (
    <div className="screen-container faqs">
      <div className="screen-header">
        <ScreenTitle title="Preguntas frecuentes" />
        {prevPage && (
          <Button variant="outlined" onClick={() => history.push(prevPage)} className="white-button">
            Volver
          </Button>
        )}
      </div>
      <Paper elevation={2}>{history.location.pathname === '/faqs' ? clientFAQs : userFAQs}</Paper>
      <div className="contact">
        <Typography variant="subtitle2">¿Aún no encuentras lo que buscabas?</Typography>
        <Button
          component="a"
          href="mailto:comercios@oca.com.uy"
          target="_blank"
          rel="noreferrer"
          color="primary"
          variant="contained"
          fullWidth
        >
          Contáctanos
        </Button>
      </div>
    </div>
  );
}

export default FAQs;
