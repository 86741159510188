import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import schemas from '../../../data/schemas';
import { currencyHelper } from '../../../helpers/currencyHelper';
import { dateHelper } from '../../../helpers/dateHelper';
import { numberHelper } from '../../../helpers/numberHelper';
import { createLink, updateLink } from '../../../store/action_creators/links.actions';
import { AppActions } from '../../../store/config/ActionTypes';
import { Currency, LinkTypeEnum, Status, ValidUntil } from '../../../store/config/enums';
import { CreateLinkRequest, Link, RootState, UpdateLinkRequest } from '../../../store/config/types';
import { theme } from '../../../styles/theme';
import { CustomSnackbar } from '../../CustomSnackbar';
import { FormDateField } from '../../forms/FormDateField';
import { FormSelectField } from '../../forms/FormSelectField';
import { FormTextField } from '../../forms/FormTextField';
import { PermanentLink } from '../../Icons/PermanentLink';
import { SingleLink } from '../../Icons/SingleLink';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    valueFieldsContainer: {
      display: 'flex',
    },
    subtotalsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    subtotal: {
      marginRight: '0.6rem',
    },
    currencySelect: {
      width: '40%',
      marginRight: '1rem',
    },
    valueField: {
      fontSize: '1.5rem',
    },
    title: {
      display: 'flex',
      alignItems: 'baseline',
      '& svg': {
        color: theme.palette.secondary.main,
        marginRight: '0.5rem',
      },
    },
    whiteButton: {
      backgroundColor: '#ffffff',
      borderColor: '#B1C9DE',
      marginLeft: '0 !important',
      marginTop: '1rem',
    },
  }),
);

interface LinkDetailDialogProps {
  open: boolean;
  setOpen(value: boolean): void;
  link: Link | null;
  linkType?: LinkTypeEnum;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  links: state.links,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  createLink: (createRequest: CreateLinkRequest) => dispatch(createLink(createRequest)),
  updateLink: (updateRequest: UpdateLinkRequest, linkId: string, businessId: string) =>
    dispatch(updateLink(updateRequest, linkId, businessId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & LinkDetailDialogProps;

interface LinkValues {
  currency: Currency;
  value: number;
  description: string | undefined;
  reference: string | undefined;
  validUntil: ValidUntil;
  validUntilDate: Date;
}

function LinkDetailDialog({ open, setOpen, link, linkType, auth, links, createLink, updateLink }: PropsType) {
  const classes = useStyles();

  const [creatingLink, setCreatingLink] = useState<boolean>(false);
  const [updatingLink, setUpdatingLink] = useState<boolean>(false);
  const [copyLink, setCopyLink] = useState<boolean>(false);

  useEffect(() => {
    if (copyLink && links.createLinkSuccess && links.createLinkErrorMessage === null && links.latestLink) {
      navigator.clipboard.writeText(links.latestLink.url ?? '');
    }
  }, [copyLink, links.createLinkSuccess, links.createLinkErrorMessage, links.latestLink]);

  const closeDialog = () => {
    setOpen(false);
  };

  const closeSnack = () => {
    if (link) {
      setUpdatingLink(false);
    } else {
      setCreatingLink(false);
    }

    if (links.createLinkSuccess || links.updateLinkSuccess) {
      setOpen(false);
    }
  };

  const sendLinkRequest = (values: LinkValues) => {
    if (link) {
      setUpdatingLink(true);

      const request: UpdateLinkRequest = {
        amount: {
          value: values.value,
          currency: values.currency,
        },
        description: values.description !== undefined ? values.description : '',
        status: link.status,
        linkType: link.linkType,
        validUntil:
          link.validUntil !== null
            ? dateHelper.getDateFromEnum(ValidUntil.CUSTOM, values.validUntilDate)
            : null,
      };

      if (linkType === LinkTypeEnum.ONETIME) request.reference = values.reference;

      updateLink(request, link.id, auth.account!.business.id!.toString());
    } else {
      setCreatingLink(true);

      const request: CreateLinkRequest = {
        businessId: auth.account?.business.id !== undefined ? auth.account?.business.id! : 0,
        amount: {
          value: values.value,
          currency: values.currency,
        },
        linkType: linkType ? linkType : LinkTypeEnum.PERMANENT,
        userId: auth.account?.userId!,
        description: values.description !== undefined ? values.description : '',
        reference: values.reference,
        validUntil: dateHelper.getDateFromEnum(values.validUntil, values.validUntilDate),
      };

      if (linkType === LinkTypeEnum.ONETIME) request.reference = values.reference;
      createLink(request);
    }
  };

  const createAndCopy = (values: LinkValues) => {
    setCopyLink(true);
    sendLinkRequest(values);
  };

  const submitLink = (values: LinkValues) => {
    setCopyLink(false);
    sendLinkRequest(values);
  };

  const vat: number = auth.account?.business?.vatRate ?? 0;

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="xs" className="dialog">
      <DialogTitle classes={{ root: 'dialog-title' }}>
        <div />
        <div className={classes.title}>
          {linkType === LinkTypeEnum.PERMANENT && (
            <PermanentLink color={theme.palette.secondary.main.toString()} />
          )}
          {linkType === LinkTypeEnum.ONETIME && (
            <SingleLink color={theme.palette.secondary.main.toString()} />
          )}
          {/* TODO: Uncomment or delete */}
          {/* <Typography variant="h4" component="p">{`${link ? 'Editar' : 'Nuevo'} link ${
            linkType === LinkTypeEnum.PERMANENT
              ? 'multiuso'
              : linkType === LinkTypeEnum.ONETIME
              ? 'de 1 uso'
              : ''
          }`}</Typography> */}
          <Typography variant="h4" component="p">{`${link ? 'Editar' : 'Nuevo'} link`}</Typography>
        </div>
        <CloseIcon fontSize="default" className="icon" onClick={closeDialog} />
      </DialogTitle>
      <Formik
        initialValues={{
          currency: link ? link.amount.currency : Currency.PESO,
          value: link ? link.amount.value : 0,
          description: link ? link.description : '',
          reference: link ? link.reference : '',
          validUntil: ValidUntil.NONE,
          validUntilDate: link && link.validUntil ? new Date(link.validUntil) : new Date(),
        }}
        validationSchema={schemas.LinkSchema}
        onSubmit={submitLink}
      >
        {({ values }) => {
          return (
            <div>
              <Form className="form">
                <DialogContent classes={{ root: 'dialog-content' }}>
                  <div className={classes.valueFieldsContainer}>
                    <Field
                      className={classes.currencySelect}
                      name="currency"
                      component={FormSelectField}
                      type="text"
                      label="Moneda"
                      options={[
                        {
                          id: Currency.PESO,
                          value: currencyHelper.getCurrencyToken(Currency.PESO),
                        },
                        {
                          id: Currency.DOLAR,
                          value: currencyHelper.getCurrencyToken(Currency.DOLAR),
                        },
                      ]}
                    />
                    <Field
                      name="value"
                      component={FormTextField}
                      type="number"
                      placeholder="Importe total"
                      className={classes.valueField}
                    />
                  </div>
                  <div className={classes.subtotalsContainer}>
                    <Typography
                      className={classes.subtotal}
                      variant="subtitle2"
                    >{`Subtotal: ${currencyHelper.getCurrencyToken(
                      values.currency,
                    )} ${numberHelper.getSubtotal(values.value, vat)}`}</Typography>
                    {vat > 0 && (
                      <Typography variant="subtitle2">{`IVA: ${currencyHelper.getCurrencyToken(
                        values.currency,
                      )} ${numberHelper.getIVA(values.value, vat)}`}</Typography>
                    )}
                  </div>
                  <Field
                    className="form-row"
                    name="description"
                    component={FormTextField}
                    type="text"
                    placeholder="Descripción (opcional)"
                  />
                  {link === null && (
                    <Field
                      name="validUntil"
                      component={FormSelectField}
                      type="text"
                      label="Vencimiento"
                      options={[
                        {
                          id: ValidUntil.NONE,
                          value: dateHelper.getValidUntilName(ValidUntil.NONE),
                        },
                        {
                          id: ValidUntil.HALFHOUR,
                          value: dateHelper.getValidUntilName(ValidUntil.HALFHOUR),
                        },
                        {
                          id: ValidUntil.ONEHOUR,
                          value: dateHelper.getValidUntilName(ValidUntil.ONEHOUR),
                        },
                        {
                          id: ValidUntil.TWOHOURS,
                          value: dateHelper.getValidUntilName(ValidUntil.TWOHOURS),
                        },
                        {
                          id: ValidUntil.DAYEND,
                          value: dateHelper.getValidUntilName(ValidUntil.DAYEND),
                        },
                        {
                          id: ValidUntil.MONTHEND,
                          value: dateHelper.getValidUntilName(ValidUntil.MONTHEND),
                        },
                        {
                          id: ValidUntil.CUSTOM,
                          value: dateHelper.getValidUntilName(ValidUntil.CUSTOM),
                        },
                      ]}
                    />
                  )}
                  {(values.validUntil === ValidUntil.CUSTOM ||
                    (link !== null && link.validUntil !== null)) && (
                    <Field
                      label="validUntilDate"
                      name="validUntilDate"
                      component={FormDateField}
                      placeholder="Fecha vencimiento"
                      type="text"
                    />
                  )}
                  {linkType === LinkTypeEnum.ONETIME && (
                    <Field
                      className="form-row"
                      name="reference"
                      component={FormTextField}
                      type="text"
                      placeholder="Referencia o destinatario (opcional)"
                    />
                  )}
                </DialogContent>
                <DialogActions classes={{ root: 'dialog-actions' }} style={{ flexDirection: 'column' }}>
                  <Button type="submit" color="primary" variant="contained" fullWidth>
                    {`${link ? 'Editar' : 'Crear'} link`}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => createAndCopy(values)}
                    className={classes.whiteButton}
                    fullWidth
                  >
                    {`${link ? 'Editar' : 'Crear'} y copiar link`}
                  </Button>
                </DialogActions>
                <CustomSnackbar
                  open={creatingLink && (links.createLinkSuccess || links.createLinkErrorMessage !== null)}
                  message={
                    links.createLinkSuccess ? 'Se creó el link correctamente' : links.createLinkErrorMessage!
                  }
                  handleClose={closeSnack}
                  type={links.createLinkSuccess ? Status.SUCCESS : Status.ERROR}
                />
                <CustomSnackbar
                  open={updatingLink && (links.updateLinkSuccess || links.updateLinkErrorMessage !== null)}
                  message={
                    links.updateLinkSuccess
                      ? 'Se actualizó el link correctamente'
                      : links.updateLinkErrorMessage!
                  }
                  handleClose={closeSnack}
                  type={links.updateLinkSuccess ? Status.SUCCESS : Status.ERROR}
                />
              </Form>
            </div>
          );
        }}
      </Formik>
    </Dialog>
  );
}

export default connector(LinkDetailDialog);
