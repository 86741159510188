import React from 'react';
import { urlHelper } from '../../helpers/urlHelper';
import { BusinessIssuer, Bank } from '../../store/config/types';

interface IssuersListProps {
  businessIssuers: BusinessIssuer[];
  businessBanks: Bank[] | null;
  selectedIssuer: BusinessIssuer | null;
  selectedBank: Bank | null;
  disabled: boolean;
  clickAction: (issuer: BusinessIssuer, bank: Bank | null) => void;
}

function IssuersCardList({
  businessIssuers,
  businessBanks,
  selectedIssuer,
  selectedBank,
  disabled,
  clickAction,
}: IssuersListProps) {
  return (
    <div className="issuers-list">
      {businessIssuers.map((issuer) => {
        if (issuer.name !== 'Sistarbanc') {
          return (
            <div
              key={issuer.idPlexo}
              className={`issuer-card ${disabled && 'disabled-issuer-card'} ${
                selectedIssuer?.idPlexo === issuer.idPlexo && 'selected-issuer-card'
              }`}
              onClick={() => {
                clickAction(issuer, null);
              }}
            >
              <img className="issuer-image" src={issuer.issuerImageUrl} alt={issuer.name} />
            </div>
          );
        } else {
          return null;
        }
      })}
      {businessBanks &&
        businessBanks.map((bank) => {
          return (
            <div
              key={bank.id}
              className={`issuer-card ${disabled && 'disabled-issuer-card'} ${
                selectedBank?.id === bank.id && 'selected-issuer-card'
              }`}
              onClick={() => {
                clickAction(businessIssuers.filter((bi) => bi.name === 'Sistarbanc')[0], bank);
              }}
            >
              <img className="issuer-image" src={urlHelper.buildBankImageUrl(bank.id)} alt={bank.name} />
            </div>
          );
        })}
    </div>
  );
}

export default IssuersCardList;
