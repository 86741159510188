import React, { SyntheticEvent, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Link,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Formik, Field, FormikHelpers } from 'formik';
import schemas from '../../../data/schemas';
import { FormTextField } from '../../forms/FormTextField';
import { connect, ConnectedProps } from 'react-redux';
import {
  Collaborator,
  CreateCollaboratorRequest,
  ResetPasswordRequest,
  RootState,
  UpdateCollaboratorRequest,
} from '../../../store/config/types';
import { ThunkDispatch } from 'redux-thunk';
import { AppActions } from '../../../store/config/ActionTypes';
import { createCollaborator, updateCollaborator } from '../../../store/action_creators/collaborators.actions';
import { resetPassword } from '../../../store/action_creators/auth.actions';
import { CustomSnackbar } from '../../CustomSnackbar';
import { Status } from '../../../store/config/enums';

interface UserDialogProps {
  open: boolean;
  setOpen(value: boolean): void;
  collaborator: Collaborator | null;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  collaborators: state.collaborators,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  createCollaborator: (createCollaboratorRequest: CreateCollaboratorRequest) =>
    dispatch(createCollaborator(createCollaboratorRequest)),
  updateCollaborator: (updateCollaboratorRequest: UpdateCollaboratorRequest) =>
    dispatch(updateCollaborator(updateCollaboratorRequest)),
  resetPassword: (resetPasswordRequest: ResetPasswordRequest) =>
    dispatch(resetPassword(resetPasswordRequest)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & UserDialogProps;

interface Values {
  names: string;
  surnames: string;
  email: string;
  password: string;
  repeatPassword: string;
}

function UserDialog({
  auth,
  collaborators,
  createCollaborator,
  updateCollaborator,
  resetPassword,
  open,
  setOpen,
  collaborator,
}: PropsType) {
  const [creatingCollaborator, setCreatingCollaborator] = useState<boolean>(false);
  const [updatingCollaborator, setUpdatingCollaborator] = useState<boolean>(false);
  const [resetingPassword, setResetingPassword] = useState<boolean>(false);

  const closeDialog = () => {
    setOpen(false);
  };

  const submitCollaborator = (values: Values, helpers: FormikHelpers<Values>) => {
    if (auth.account) {
      if (collaborator) {
        setUpdatingCollaborator(true);

        const updateCollaboratorRequest: UpdateCollaboratorRequest = {
          userId: collaborator.userId,
          businessId: auth.account?.business.id!,
          names: values.names,
          surnames: values.surnames,
          telephoneNo: collaborator.telephoneNo,
          type: 2,
        };

        updateCollaborator(updateCollaboratorRequest);
      } else {
        setCreatingCollaborator(true);

        const createCollaboratorRequest: CreateCollaboratorRequest = {
          businessId: auth.account?.business.id!,
          names: values.names,
          surnames: values.surnames,
          email: values.email,
          password: values.password,
          telephoneNo: '1',
        };

        createCollaborator(createCollaboratorRequest);
      }
    }
  };

  const resetCollaboratorPassword = (event: SyntheticEvent) => {
    event.preventDefault();

    if (collaborator) {
      const request: ResetPasswordRequest = {
        email: collaborator?.email,
      };

      setResetingPassword(true);
      resetPassword(request);
    }
  };

  const closeSnack = () => {
    if (collaborator) {
      setUpdatingCollaborator(false);
      setResetingPassword(false);
    } else {
      setCreatingCollaborator(false);
    }

    if (
      collaborators.createCollaboratorSuccess ||
      collaborators.updateCollaboratorSuccess ||
      auth.resetPasswordSuccess
    ) {
      closeDialog();
    }
  };

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="xs" className="dialog">
      <DialogTitle classes={{ root: 'dialog-title' }}>
        <div />
        <Typography variant="h4">{collaborator ? 'Editar colaborador' : 'Nuevo colaborador'}</Typography>
        <CloseIcon fontSize="default" className="icon" onClick={closeDialog} />
      </DialogTitle>
      <Formik
        initialValues={{
          names: collaborator ? collaborator.names : '',
          surnames: collaborator ? collaborator.surnames : '',
          email: collaborator ? collaborator.email : '',
          password: collaborator ? 'Password1234!' : '',
          repeatPassword: collaborator ? 'Password1234!' : '',
        }}
        validationSchema={schemas.UserSchema}
        onSubmit={submitCollaborator}
      >
        <Form className="form">
          <DialogContent classes={{ root: 'dialog-content' }}>
            <Field
              className="form-row"
              name="names"
              component={FormTextField}
              type="text"
              placeholder="Nombres"
            />
            <Field
              className="form-row"
              name="surnames"
              component={FormTextField}
              type="text"
              placeholder="Apellidos"
            />
            <Field
              className="form-row"
              name="email"
              component={FormTextField}
              type="text"
              placeholder="Correo electrónico"
              disabled={collaborator !== null}
            />
            {collaborator ? (
              <div className="link">
                <Link onClick={resetCollaboratorPassword}>Reiniciar contraseña</Link>
              </div>
            ) : (
              <>
                <Field
                  className="form-row"
                  name="password"
                  component={FormTextField}
                  type="password"
                  placeholder="Contraseña"
                />
                <Field
                  className="form-row"
                  name="repeatPassword"
                  component={FormTextField}
                  type="password"
                  placeholder="Repetir contraseña"
                />
              </>
            )}
          </DialogContent>
          <DialogActions classes={{ root: 'dialog-actions' }}>
            <Button type="submit" color="primary" variant="contained" fullWidth>
              {collaborator ? 'Guardar cambios' : 'Crear colaborador'}
            </Button>
          </DialogActions>
          <CustomSnackbar
            open={
              creatingCollaborator &&
              (collaborators.createCollaboratorSuccess ||
                collaborators.createCollaboratorErrorMessage !== null)
            }
            message={
              collaborators.createCollaboratorSuccess
                ? 'Se creó el colaborador correctamente.'
                : collaborators.createCollaboratorErrorMessage!
            }
            handleClose={closeSnack}
            type={collaborators.createCollaboratorSuccess ? Status.SUCCESS : Status.ERROR}
          />
          <CustomSnackbar
            open={
              updatingCollaborator &&
              (collaborators.updateCollaboratorSuccess ||
                collaborators.updateCollaboratorErrorMessage !== null)
            }
            message={
              collaborators.updateCollaboratorSuccess
                ? 'Se actualizó el colaborador correctamente.'
                : collaborators.updateCollaboratorErrorMessage!
            }
            handleClose={closeSnack}
            type={collaborators.updateCollaboratorSuccess ? Status.SUCCESS : Status.ERROR}
          />
          <CustomSnackbar
            open={resetingPassword && (auth.resetPasswordSuccess || auth.resetPasswordErrorMessage !== null)}
            message={
              auth.resetPasswordSuccess
                ? 'Se reinició la contraseña del colaborador correctamente.'
                : 'Ocurrió un error al reiniciar la contraseña del colaborador.'
            }
            handleClose={closeSnack}
            type={auth.resetPasswordSuccess ? Status.SUCCESS : Status.ERROR}
          />
        </Form>
      </Formik>
    </Dialog>
  );
}

export default connector(UserDialog);
