import { Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserTypeEnum } from '../../store/config/enums';
import { getOptionsFromRole } from './navigationOptions';

function getTabOfPath(path: string) {
  switch (path) {
    case '/dashboard':
      return 0;

    case '/links':
      return 1;

    case '/one-use-links':
      return 2;

    case '/payments':
      return 3;

    case '/banks':
      return 4;

    case '/manage-clients':
      return 5;

    case '/hidden':
      return 99;

    default:
      return 99;
  }
}
interface DesktopNavigatonProps {
  role?: UserTypeEnum;
}

function DesktopNavigation({ role }: DesktopNavigatonProps) {
  const history = useHistory();
  const [value, setValue] = useState<number>(getTabOfPath(history.location.pathname));
  const options = getOptionsFromRole(role);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setValue(newValue);
  };

  history.listen((location: any) => {
    setValue(getTabOfPath(location.pathname));
  });

  return (
    <div className="header-options">
      <Tabs value={value} onChange={handleChange} classes={{ indicator: 'indicator' }}>
        {options &&
          options.map((o, index) => {
            return (
              <Tab
                key={index}
                disableRipple={true}
                component="span"
                label={
                  <span className="option">
                    {o.icon}
                    {o.name}
                  </span>
                }
                onClick={() => history.push(o.route)}
                value={o.value}
                classes={{
                  selected: 'selected-option',
                  root: o.name === 'Hidden' ? 'hidden-option' : 'tab-option',
                }}
              />
            );
          })}
      </Tabs>
    </div>
  );
}

export default DesktopNavigation;
