import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme, Paper } from '@material-ui/core';
import Lottie from 'lottie-react';
import { urlHelper } from './../../helpers/urlHelper';
import successAnimation from '../../assets/plinks-success.json';
import { Loading } from '../../components/Loading';
import { getAccount, validateRegister } from '../../store/action_creators/auth.actions';
import { AppActions } from '../../store/config/ActionTypes';
import { UserTypeEnum } from '../../store/config/enums';
import { ConfirmEmailRequest, RootState } from '../../store/config/types';
import { ErrorCard } from '../../components/ErrorCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      margin: '1rem auto',
      display: 'block',
    },
    text: {
      textAlign: 'center',
    },
    buttonDiv: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '2rem',
    },
  }),
);

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  validateRegister: (request: ConfirmEmailRequest) => dispatch(validateRegister(request)),
  getAccount: () => dispatch(getAccount()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

function ConfirmEmail({ auth, validateRegister, getAccount }: PropsFromRedux) {
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (
      !auth.validatingRegister &&
      !auth.validateRegisterSuccess &&
      auth.validateRegisterErrorMessage === null
    ) {
      let urlVars = urlHelper.parseQueryString();
      if (urlVars.id && urlVars.token) {
        validateRegister({ userId: urlVars.id, token: urlVars.token });
      }
    }
  }, [
    validateRegister,
    auth.validatingRegister,
    auth.validateRegisterSuccess,
    auth.validateRegisterErrorMessage,
  ]);

  useEffect(() => {
    if (auth.validateRegisterSuccess && !auth.account && !auth.loggingIn && !auth.loggedIn) {
      console.log('get');
      getAccount();
    }
  }, [auth.validateRegisterSuccess, auth.account, auth.loggingIn, auth.loggedIn, getAccount]);

  return (
    <div className="screen-container">
      {auth.validatingRegister ? (
        <Loading />
      ) : !auth.validateRegisterSuccess ? (
        <ErrorCard
          message={
            auth.validateRegisterErrorMessage ||
            'Algo salió mal y no pudimos verificar tu correo para verificar tu usuario.'
          }
        />
      ) : (
        <Paper elevation={2} className="result-card">
          <Lottie
            className={classes.icon}
            style={{ height: 300, width: 300 }}
            animationData={successAnimation}
            loop={false}
          />
          <Typography className={classes.text} variant="h4">
            {'Hemos verificado tu correo y ya puedes comenzar a usar la plataforma.'}
          </Typography>
          {auth.account && (
            <div className={classes.buttonDiv}>
              <Button
                onClick={() =>
                  history.push(auth.account!.type === UserTypeEnum.OWNER ? '/register?step=2' : '/login')
                }
                color="primary"
                variant="contained"
                disableElevation
              >
                {auth.account.type === UserTypeEnum.OWNER ? 'Continuar con el registro' : 'Ir al login'}
              </Button>
            </div>
          )}
        </Paper>
      )}
    </div>
  );
}

export default connector(ConfirmEmail);
