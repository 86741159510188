import { Dispatch } from 'redux';
import { businessService } from '../../services/business.service';
import {
  CreateBusinessTypes,
  CREATING_BUSINESS,
  CREATE_BUSINESS_SUCCESS,
  CREATE_BUSINESS_FAILED,
  UpdateBusinessTypes,
  UPDATING_BUSINESS,
  UPDATE_BUSINESS_SUCCESS,
  UPDATE_BUSINESS_FAILED,
  GetBusinessTypes,
  GET_BUSINESS_FAILED,
  GET_BUSINESS_SUCCESS,
  GETTING_BUSINESS,
  FetchBusinessesTypes,
  FETCHING_BUSINESSES,
  ADD_BUSINESSES,
  FETCH_BUSINESSES_FAILED,
  UpdateSecretTypes,
  UPDATING_SECRET,
  UPDATE_SECRET_SUCCESS,
  UPDATE_SECRET_FAILED,
} from '../config/ActionTypes';
import { Business, BusinessRequest, ClientSecretRequest, UpdateBusinessRequest } from '../config/types';

//#region Fetch businesses

export const fetchBusinesses = () => {
  return (dispatch: Dispatch<FetchBusinessesTypes>) => {
    dispatch(loadingBusinesses());

    return businessService.fetchBusinesses().then(
      (response) => {
        dispatch(addBusinesses(response!));
      },
      (error) => {
        dispatch(fetchBusinessesFailed(error));
      },
    );
  };
};

export const loadingBusinesses = (): FetchBusinessesTypes => ({
  type: FETCHING_BUSINESSES,
});

export const addBusinesses = (businesses: Business[]): FetchBusinessesTypes => ({
  type: ADD_BUSINESSES,
  businesses,
});

export const fetchBusinessesFailed = (error: string): FetchBusinessesTypes => ({
  type: FETCH_BUSINESSES_FAILED,
  error,
});

//#endregion

//#region Create business

export const createBusiness = (business: BusinessRequest, picture: File) => {
  return (dispatch: Dispatch<CreateBusinessTypes>) => {
    dispatch(creatingBusiness());

    return businessService.createBusiness(business, picture).then(
      (response) => {
        dispatch(createBusinessSuccess(response!));
      },
      (error) => {
        dispatch(createBusinessFailed(error));
      },
    );
  };
};

export const creatingBusiness = (): CreateBusinessTypes => ({
  type: CREATING_BUSINESS,
});

export const createBusinessSuccess = (business: Business): CreateBusinessTypes => ({
  type: CREATE_BUSINESS_SUCCESS,
  business,
});

export const createBusinessFailed = (error: string): CreateBusinessTypes => ({
  type: CREATE_BUSINESS_FAILED,
  error,
});

//#endregion

//#region Update business

export const updateBusiness = (updateBusinessRequest: UpdateBusinessRequest) => {
  return (dispatch: Dispatch<UpdateBusinessTypes>) => {
    dispatch(updatingBusiness());

    return businessService.updateBusiness(updateBusinessRequest).then(
      (response) => {
        dispatch(updateBusinessSuccess(response!));
      },
      (error) => {
        dispatch(updateBusinessFailed(error));
      },
    );
  };
};

export const updatingBusiness = (): UpdateBusinessTypes => ({
  type: UPDATING_BUSINESS,
});

export const updateBusinessSuccess = (business: Business): UpdateBusinessTypes => ({
  type: UPDATE_BUSINESS_SUCCESS,
  business,
});

export const updateBusinessFailed = (error: string): UpdateBusinessTypes => ({
  type: UPDATE_BUSINESS_FAILED,
  error,
});

//#endregion

//#region Get business

export const getBusiness = (businessName: string) => {
  return (dispatch: Dispatch<GetBusinessTypes>) => {
    dispatch(gettingBusiness());

    return businessService.getBusiness(businessName).then(
      (response) => {
        dispatch(getBusinessSuccess(response!));
      },
      (error) => {
        dispatch(getBusinessFailed(error));
      },
    );
  };
};

export const gettingBusiness = (): GetBusinessTypes => ({
  type: GETTING_BUSINESS,
});

export const getBusinessSuccess = (business: Business): GetBusinessTypes => ({
  type: GET_BUSINESS_SUCCESS,
  business,
});

export const getBusinessFailed = (error: string): GetBusinessTypes => ({
  type: GET_BUSINESS_FAILED,
  error,
});

//#endregion

//#region Update client secret

export const updateClientSecret = (clientSecretRequest: ClientSecretRequest) => {
  return (dispatch: Dispatch<UpdateSecretTypes>) => {
    dispatch(updatingSecret());

    return businessService.updateClientSecret(clientSecretRequest).then(
      (response) => {
        dispatch(updateSecretSuccess());
      },
      (error) => {
        dispatch(updateSecretFailed(error));
      },
    );
  };
};

export const updatingSecret = (): UpdateSecretTypes => ({
  type: UPDATING_SECRET,
});

export const updateSecretSuccess = (): UpdateSecretTypes => ({
  type: UPDATE_SECRET_SUCCESS,
});

export const updateSecretFailed = (error: string): UpdateSecretTypes => ({
  type: UPDATE_SECRET_FAILED,
  error,
});

//#endregion
