import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Paper, Typography } from '@material-ui/core';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Lottie from 'lottie-react';
import successAnimation from '../../assets/plinks-success.json';
import { ErrorCard } from '../../components/ErrorCard';
import { Loading } from '../../components/Loading';
import { PDFReceipt } from '../../components/PDFReceipt';
import { urlHelper } from '../../helpers/urlHelper';
import { RootState } from '../../store/config/types';

const mapStateToProps = (state: RootState) => ({
  payments: state.payments,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Result({ payments }: PropsFromRedux) {
  const [ready, setReady] = useState<boolean>(false);
  const history = useHistory();
  let urlVars = urlHelper.parseQueryString();

  const isAbitabPayment = payments.selectedPayment?.instrument?.issuerName === 'Abitab';

  useEffect(() => {
    if (
      !payments.confirmingPayment &&
      !payments.confirmPaymentSuccess &&
      payments.confirmPaymentErrorMessage === null &&
      !urlVars.beginPaymentId
    ) {
      history.push('/');
    }
  }, [
    payments.confirmingPayment,
    payments.confirmPaymentSuccess,
    payments.confirmPaymentErrorMessage,
    history,
    urlVars.beginPaymentId,
  ]);

  useEffect(() => {
    if (!ready) {
      setTimeout(() => {
        setReady(true);
      }, 500);
    }
  }, [ready, setReady]);

  return payments.confirmingPayment ? (
    <Loading />
  ) : payments.confirmPaymentErrorMessage !== null ? (
    <ErrorCard
      title="Ocurrió un error al procesar tu pago"
      subtitle="Vuelve a intentarlo mas tarde"
      message={payments.confirmPaymentErrorMessage!}
    />
  ) : (
    <div className="screen-container">
      <Paper elevation={2} className="result-card">
        {payments.confirmPaymentSuccess ? (
          <div className="card-body">
            <Lottie animationData={successAnimation} loop={false} style={{ height: 300, width: 300 }} />
            <Typography variant="h4">{`¡Tu pago ha sido ${
              isAbitabPayment ? 'iniciado' : 'realizado'
            } con éxito!`}</Typography>
            {isAbitabPayment ? (
              <>
                <Typography className="reference" variant="h6">
                  {`El código a presentar en Abitab es ${payments.selectedPayment?.abitabCode}`}
                </Typography>
                <Typography className="reference" variant="body2">
                  {`También puede realizar el pago con su número de CI ${payments.selectedPayment?.instrument?.name}`}
                </Typography>
              </>
            ) : (
              <>
                <p className="reference">La referencia del pago es la siguiente</p>
                <Typography variant="body2">{payments.confirmedPayment?.plexoReferenceId}</Typography>
              </>
            )}
            {payments.selectedPayment && payments.confirmedPayment && ready && (
              <PDFDownloadLink
                document={
                  <PDFReceipt
                    selectedPayment={payments.selectedPayment}
                    confirmedPayment={payments.confirmedPayment}
                  />
                }
                fileName={`Pago ${payments.confirmedPayment.plexoReferenceId}`}
                style={{ textDecoration: 'none' }}
              >
                {({ loading }) =>
                  loading ? (
                    'Loading...'
                  ) : (
                    <Button variant="outlined" className="white-button">
                      Descargá el comprobante
                    </Button>
                  )
                }
              </PDFDownloadLink>
            )}
          </div>
        ) : (
          <Loading />
        )}
      </Paper>
    </div>
  );
}

export default connector(Result);
