import React, { useState } from 'react';
import { LinkTypeEnum } from '../../store/config/enums';
import { createStyles, Divider, makeStyles, Paper, Theme, Typography, IconButton } from '@material-ui/core';
import { currencyHelper } from '../../helpers/currencyHelper';
import { numberHelper } from '../../helpers/numberHelper';
import { ShareLinkDialog } from '../dialogs/ShareLinkDialog';
import { LinkDetailDialog } from '../dialogs/LinkDetailDialog';
import { Link } from '../../store/config/types';
import { SingleLink } from '../Icons/SingleLink';
import { theme } from '../../styles/theme';
import { dateHelper } from '../../helpers/dateHelper';
import { PermanentLink } from '../Icons/PermanentLink';
import editIcon from '../../assets/edit.svg';
import trashIcon from '../../assets/trash.svg';
import shareIcon from '../../assets/share.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '1rem',
      boxShadow: '0px 1px 4px #15223214',
    },
    header: {
      color: theme.palette.secondary.main,
    },
    body: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1rem',
      '& svg': {
        color: theme.palette.primary.main,
        fontSize: '1.3rem',
      },
    },
    footer: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      marginTop: '0.5rem',
      '& p': {
        color: '#7e84a3',
        marginBlockEnd: 0,
        marginBlockStart: 0,
      },
      '& svg': {
        color: theme.palette.text.secondary,
      },
      '& span': {
        color: theme.palette.grey[500],
      },
    },
    amountContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'center',
      '& p': {
        marginBlockStart: 0,
        marginBlockEnd: 0,
      },
    },
    currency: {
      marginRight: '1rem',
    },
    amount: {
      fontSize: '2.5rem',
    },
    smallIconButton: {
      padding: '0.5em',
      fontSize: '1rem',
    },
    whiteButton: {
      backgroundColor: '#ffffff',
      border: '1px solid #B1C9DE',
      boxShadow: 'none',
      padding: '0.5rem',
      borderRadius: 6,
      height: 36,
      width: 36,
      '& img': {
        height: 16,
      },
    },
    deleteIconContainer: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'flex-end',
      gap: 8,
    },
  }),
);

interface LinkCardProps {
  link: Link;
  setLinkToDelete(link: Link): void;
}

function LinkCard({ link, setLinkToDelete }: LinkCardProps) {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [shareOpen, setShareOpen] = useState<boolean>(false);

  return (
    <>
      <Paper className={classes.container}>
        <div className={classes.header}>
          {link.linkType && link.linkType === LinkTypeEnum.PERMANENT ? (
            <PermanentLink color={theme.palette.secondary.main.toString()} />
          ) : (
            <SingleLink color={theme.palette.secondary.main.toString()} />
          )}
        </div>
        <div className={classes.body}>
          <div className={classes.amountContainer}>
            <Typography variant="h4" component="p" className={classes.currency}>
              {currencyHelper.getCurrencyToken(link.amount.currency)}
            </Typography>
            <Typography variant="h3" component="p" className={classes.amount}>
              {numberHelper.getNumberWithDots(link.amount.value)}{' '}
            </Typography>
          </div>
          <IconButton
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.stopPropagation();
              setShareOpen(true);
            }}
            size="medium"
            className={classes.whiteButton}
          >
            <img src={shareIcon} alt="share" />
          </IconButton>
        </div>
        <Divider />
        <div className={classes.footer}>
          {(link.description || link.validUntil) && (
            <p>
              {link.description}
              {link.validUntil && (
                <span>{` (Vence ${dateHelper.toShorterNiceString(link.validUntil)})`}</span>
              )}
            </p>
          )}
          <div className={classes.deleteIconContainer}>
            <IconButton
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                setOpen(true);
              }}
              size="small"
              className={classes.whiteButton}
            >
              <img src={editIcon} alt="edit" />
            </IconButton>
            <IconButton
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                setLinkToDelete(link);
              }}
              size="small"
              className={classes.whiteButton}
            >
              <img src={trashIcon} alt="trash" />
            </IconButton>
          </div>
        </div>
      </Paper>
      <LinkDetailDialog open={open} setOpen={setOpen} link={link} linkType={link.linkType} />
      <ShareLinkDialog open={shareOpen} setOpen={setShareOpen} link={link} />
    </>
  );
}

export default LinkCard;
