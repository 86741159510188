import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { authReducer } from '../reducers/auth';
import { banksReducer } from '../reducers/banks';
import { businessReducer } from '../reducers/business';
import { collaboratorsReducer } from '../reducers/collaborators';
import { commercesReducer } from '../reducers/commerces';
import { paymentsReducer } from '../reducers/payments';
import { linksReducer } from '../reducers/links';
import { clientsReducer } from '../reducers/clients';
import { notificationsReducer } from '../reducers/notifications';
import signalRMiddleware from '../middlewares/signalRMiddleware';
import { AppActions } from './ActionTypes';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const rootReducer = combineReducers({
  auth: authReducer,
  banks: banksReducer,
  business: businessReducer,
  collaborators: collaboratorsReducer,
  commerces: commercesReducer,
  links: linksReducer,
  payments: paymentsReducer,
  clients: clientsReducer,
  notifications: notificationsReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>, signalRMiddleware)),
);
