import { useRef, useState, useEffect, useCallback } from 'react';
import { CSVLink } from 'react-csv';
import { makeStyles, createStyles, Theme, Button } from '@material-ui/core';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import {
  Payment,
  CSVPayment,
  PaymentsState,
  AuthState,
  FetchPaymentsRequest,
} from './../../store/config/types';
import { paymentStatusEnumHelper } from '../../helpers/paymentStatusEnumHelper';
import { dateHelper } from '../../helpers/dateHelper';
import { linkTypeEnumHelper } from '../../helpers/linkTypeEnumHelper';
import { numberHelper } from '../../helpers/numberHelper';
import { currencyHelper } from '../../helpers/currencyHelper';
import { paymentsService } from '../../services/payments.service';
import { csvHeaders } from '../../data/csv';
import { PDFPayments } from './../PDFPayments';
import fileCSV from '../../assets/file-csv.svg';
import filePDF from '../../assets/file-pdf.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
    },
    whiteButton: {
      whiteSpace: 'nowrap',
      backgroundColor: '#ffffff',
      borderColor: '#B1C9DE',
      '& img': {
        marginRight: '0.4rem',
      },
    },
    csvLink: {
      textDecoration: 'none',
      margin: '0.3rem',
      marginRight: '0.6rem',
    },
  }),
);

interface ExportPaymentsProps {
  auth: AuthState;
  payments: PaymentsState;
  businessId: number;
}

function ExportPayments({ auth, payments, businessId }: ExportPaymentsProps) {
  const classes = useStyles();
  const [loadingPayments, setLoadingPayments] = useState<boolean | null>(null);
  const [allLocalPayments, setAllLocalPayments] = useState<Payment[]>([]);
  const [hasClickedCSV, setHasClickedCSV] = useState<boolean>(false);
  const [hasClickedPDF, setHasClickedPDF] = useState<boolean>(false);
  const csvRef = useRef<any>();

  const fetchAllPayments = useCallback(() => {
    const request: FetchPaymentsRequest = {
      page: 1,
      limit: 1000,
      ...payments.filters,
    };

    paymentsService
      .fetchPayments(request, businessId)
      .then(
        (transactions) => {
          if (transactions) setAllLocalPayments(transactions.payments.results!);
        },
        (error) => setAllLocalPayments([]),
      )
      .finally(() => setLoadingPayments(false));
  }, [businessId, payments.filters]);

  const onClickHandler = (fileType: 'CSV' | 'PDF') => {
    setLoadingPayments(true);
    if (fileType === 'CSV') setHasClickedCSV(true);
    else if (fileType === 'PDF') setHasClickedPDF(true);
  };

  const downloadPDF = useCallback(async () => {
    const blob = await pdf(
      <PDFPayments data={allLocalPayments} business={auth.account ? auth.account.business : null} />,
    ).toBlob();
    saveAs(blob, 'Pagos');
  }, [allLocalPayments, auth.account]);

  useEffect(() => {
    if (loadingPayments) {
      fetchAllPayments();
    }
  }, [fetchAllPayments, loadingPayments]);

  useEffect(() => {
    if (allLocalPayments.length > 0 && loadingPayments === false) {
      if (hasClickedCSV) {
        csvRef.current.link.click();
        setHasClickedCSV(false);
      }
      if (hasClickedPDF) {
        downloadPDF();
        setHasClickedPDF(false);
      }
    }
  }, [allLocalPayments.length, downloadPDF, hasClickedCSV, hasClickedPDF, loadingPayments]);

  const buildCSVPayments = (payments: Payment[]): CSVPayment[] => {
    return payments.map((payment) => {
      return {
        status: paymentStatusEnumHelper.getStatusText(payment.status),
        date: dateHelper.toShorterNiceString(payment.date),
        description: payment?.description || '-',
        linkType: linkTypeEnumHelper.toNiceString(payment.linkType),
        issuerName: payment.issuerName,
        amount: `${currencyHelper.getCurrencyToken(
          payment.taxedAmount ? payment.taxedAmount.currency : payment.amount.currency,
        )} ${numberHelper.getNumberWithDots(
          payment.taxedAmount ? payment.taxedAmount.value : payment.amount.value,
        )}`,
        referencePayment: payment?.referencePayment || '-',
      };
    });
  };

  return (
    <div className={classes.container}>
      <Button variant="outlined" className={classes.whiteButton} onClick={() => onClickHandler('CSV')}>
        <img src={fileCSV} alt="csv" />
        {hasClickedCSV ? 'Cargando...' : 'Exportar a CSV'}
      </Button>
      <CSVLink
        data={buildCSVPayments(allLocalPayments)}
        filename="Pagos.csv"
        headers={csvHeaders}
        className={classes.csvLink}
        ref={csvRef}
      />
      <Button variant="outlined" className={classes.whiteButton} onClick={() => onClickHandler('PDF')}>
        <img src={filePDF} alt="pdf" />
        {hasClickedPDF ? 'Cargando...' : 'Exportar a PDF'}
      </Button>
    </div>
  );
}

export default ExportPayments;
