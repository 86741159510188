import { LinkTypeEnum } from '../store/config/enums';

export const linkTypeEnumHelper = {
  toNiceString,
};

function toNiceString(linkType: LinkTypeEnum): string {
  switch (linkType) {
    case LinkTypeEnum.ONETIME:
      return 'Link de 1 solo uso';
    case LinkTypeEnum.PERMANENT:
      return 'Link multiuso';
    case LinkTypeEnum.ONTHEFLY:
      return 'Link rápido';
    default:
      return '';
  }
}
