import React from 'react';
import { makeStyles, TextField, Tooltip, InputAdornment, TextFieldProps } from '@material-ui/core';
import { FieldProps } from 'formik';
import ErrorIcon from '@material-ui/icons/Error';

const useTextFieldStyles = makeStyles({
  root: {
    width: '100%',
  },
  icon: {
    cursor: 'pointer',
  },
});

interface CustomProps {
  startAdornment?: string;
}

type FormTextFieldProps = CustomProps & FieldProps & React.HTMLProps<HTMLInputElement> & TextFieldProps;

function FormTextField({ startAdornment, field, form, ...props }: FormTextFieldProps) {
  const classes = useTextFieldStyles();
  const hasError = (form.touched[field.name] && form.errors[field.name]) !== undefined;

  return (
    <TextField
      margin={props.margin ?? 'normal'}
      variant="outlined"
      className={props.className}
      error={hasError}
      classes={{ root: classes.root }}
      InputProps={{
        startAdornment: startAdornment ? (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ) : null,
        endAdornment: hasError ? (
          <Tooltip title={form.errors[field.name]!.toString()}>
            <InputAdornment position="end">
              <ErrorIcon color="error" classes={{ root: classes.icon }} />
            </InputAdornment>
          </Tooltip>
        ) : null,
      }}
      {...field}
      type={props.type}
      label={props.placeholder}
      disabled={props.disabled}
      multiline={props.multiline}
      rows={props.rows}
      style={props.style}
    />
  );
}

export default FormTextField;
