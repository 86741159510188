import moment from 'moment';
import { ValidUntil } from '../store/config/enums';

export const dateHelper = {
  toNiceString,
  toShorterNiceString,
  toEvenShorterNiceString,
  getValidUntilName,
  getDateFromEnum,
};

const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };

function toNiceString(date: Date): string {
  return new Date(date).toLocaleTimeString('es-ES', options);
}

function toShorterNiceString(date: Date): string {
  const theDate = moment(date);

  return theDate.format('DD/MM/YY HH:mm');
}

function toEvenShorterNiceString(date: Date): string {
  const theDate = moment(date);

  return theDate.format('DD/MM/YY');
}

function toSendableString(date: moment.Moment): string {
  return date.utc().format('MM/DD/YYYY HH:mm');
}

function getValidUntilName(validUntil: ValidUntil): string {
  switch (validUntil) {
    case ValidUntil.NONE:
      return 'Sin vencimiento';

    case ValidUntil.HALFHOUR:
      return 'En media hora';

    case ValidUntil.ONEHOUR:
      return 'En una hora';

    case ValidUntil.TWOHOURS:
      return 'En dos horas';

    case ValidUntil.DAYEND:
      return 'Al final del día';

    case ValidUntil.MONTHEND:
      return 'Al final del mes';

    case ValidUntil.CUSTOM:
      return 'Personalizado';
  }
}

function getDateFromEnum(validUntil: ValidUntil, validUntilDate: Date): string | null {
  switch (validUntil) {
    case ValidUntil.NONE:
      return null;

    case ValidUntil.HALFHOUR:
      return toSendableString(moment().add(30, 'minutes'));

    case ValidUntil.ONEHOUR:
      return toSendableString(moment().add(1, 'hour'));

    case ValidUntil.TWOHOURS:
      return toSendableString(moment().add(2, 'hours'));

    case ValidUntil.DAYEND:
      return toSendableString(moment().endOf('day'));

    case ValidUntil.MONTHEND:
      return toSendableString(moment().endOf('month'));

    case ValidUntil.CUSTOM:
      return toSendableString(moment(validUntilDate));
  }
}
