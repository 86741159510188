import React, { useEffect, useState } from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';
import { Bank, RootState } from '../../store/config/types';
import { urlHelper } from '../../helpers/urlHelper';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { ScreenTitle } from '../../components/ScreenTitle';
import { BankDialog } from '../../components/dialogs/BankDialog';
import { deleteBank, fetchBanks } from '../../store/action_creators/banks.actions';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppActions } from '../../store/config/ActionTypes';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { Status } from '../../store/config/enums';

const mapStateToProps = (state: RootState) => ({
  banks: state.banks,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => {
  return {
    fetchBanks: () => dispatch(fetchBanks()),
    deleteBank: (bankId: string) => dispatch(deleteBank(bankId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

function Banks({ banks, fetchBanks, deleteBank }: PropsFromRedux) {
  const [selectedBank, setSelectedBank] = useState<Bank | null>(null);
  const [openBankDialog, setOpenBankDialog] = useState<boolean>(false);
  const [deletingBank, setDeletingBank] = useState<boolean>(false);

  const updateBank = (bank: Bank) => {
    setSelectedBank(bank);
    setOpenBankDialog(true);
  };

  const createBank = () => {
    setSelectedBank(null);
    setOpenBankDialog(true);
  };

  const triggerDeleteBank = (bank: Bank) => {
    setDeletingBank(true);
    deleteBank(bank.id);
  };

  const closeSnack = () => {
    if (deletingBank) setDeletingBank(false);
  };

  useEffect(() => {
    if (!banks.loadingBanks && banks.banks === null && banks.banksErrorMessage === null) {
      fetchBanks();
    }
  }, [banks.loadingBanks, banks.banks, banks.banksErrorMessage, fetchBanks]);

  return (
    <div className="banks">
      <ScreenTitle title="Bancos" />
      <Divider className="divider" />
      <Grid container spacing={2} className="banks-list">
        <Grid item xs={12} sm={4} key="new">
          <div className="new-bank" onClick={createBank}>
            <AddIcon color="primary" fontSize="large" className="icon" />
            <Typography variant="h6" component="h6">
              Nuevo banco
            </Typography>
          </div>
        </Grid>
        {banks.banks &&
          banks.banks.map((b, i) => {
            return (
              <Grid item xs={12} sm={4} key={i}>
                <div className="bank" onClick={() => updateBank(b)}>
                  <div className="trash">
                    <DeleteIcon
                      className="icon"
                      onClick={(e: React.SyntheticEvent<any>) => {
                        e.stopPropagation();
                        triggerDeleteBank(b);
                      }}
                    />
                  </div>
                  <img src={urlHelper.buildBankImageUrl(b.id)} alt="logo" />
                  <div className="number">
                    <Typography variant="subtitle2">{b.name}</Typography>
                  </div>
                </div>
              </Grid>
            );
          })}
      </Grid>
      <BankDialog
        open={openBankDialog}
        setOpen={setOpenBankDialog}
        selectedBank={selectedBank ?? undefined}
      />
      <CustomSnackbar
        open={deletingBank && (banks.deleteBankSuccess || banks.deleteBankErrorMessage !== null)}
        message={banks.deleteBankSuccess ? 'Se borró el banco correctamente' : banks.deleteBankErrorMessage!}
        handleClose={closeSnack}
        type={banks.deleteBankSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </div>
  );
}

export default connector(Banks);
