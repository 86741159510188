import React from 'react';
import { useTheme } from '@material-ui/core/styles';

interface CogProps {
  color?: string;
  className?: string;
  onClick?: (event: any) => void;
}
function Cog({ color, className, onClick }: CogProps) {
  const theme = useTheme();
  color = color ?? theme.palette.grey[400];
  className = className ?? '';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
      width="16"
      height="16.622"
      viewBox="0 0 16 16.622"
    >
      <path
        id="cog"
        d="M32.873,17.761,31.78,17.13a6.54,6.54,0,0,0,0-1.639l1.093-.631a.8.8,0,0,0,.37-.922A8.3,8.3,0,0,0,31.315,10.6a.8.8,0,0,0-.986-.143l-1.091.63a6.505,6.505,0,0,0-1.419-.82V9.007a.8.8,0,0,0-.619-.783,8.369,8.369,0,0,0-3.846,0,.8.8,0,0,0-.619.783v1.26a6.5,6.5,0,0,0-1.419.82l-1.091-.63a.8.8,0,0,0-.986.143,8.3,8.3,0,0,0-1.928,3.338.8.8,0,0,0,.37.922l1.093.631a6.54,6.54,0,0,0,0,1.639l-1.093.631a.8.8,0,0,0-.37.922,8.3,8.3,0,0,0,1.928,3.338.8.8,0,0,0,.986.144l1.091-.63a6.5,6.5,0,0,0,1.419.82v1.261a.8.8,0,0,0,.619.783,8.369,8.369,0,0,0,3.846,0,.8.8,0,0,0,.619-.783V22.354a6.506,6.506,0,0,0,1.419-.82l1.091.63a.8.8,0,0,0,.986-.144,8.3,8.3,0,0,0,1.928-3.338.8.8,0,0,0-.37-.922Zm-2.194,3.367-1.552-.9a5.79,5.79,0,0,1-2.381,1.376V23.4a7.277,7.277,0,0,1-2.94,0V21.607a5.791,5.791,0,0,1-2.381-1.376l-1.552.9A7.239,7.239,0,0,1,18.4,18.582l1.553-.9a5.839,5.839,0,0,1,0-2.751l-1.553-.9a7.244,7.244,0,0,1,1.472-2.546l1.552.9a5.782,5.782,0,0,1,2.381-1.376V9.222a7.265,7.265,0,0,1,2.94,0v1.792a5.782,5.782,0,0,1,2.381,1.376l1.552-.9a7.243,7.243,0,0,1,1.472,2.546l-1.553.9a5.838,5.838,0,0,1,0,2.751l1.553.9A7.238,7.238,0,0,1,30.678,21.128Zm-5.4-8.034a3.217,3.217,0,1,0,3.217,3.217A3.221,3.221,0,0,0,25.276,13.094Zm0,5.362a2.145,2.145,0,1,1,2.145-2.145A2.147,2.147,0,0,1,25.276,18.455Z"
        transform="translate(-17.276 -8)"
        fill={color}
      />
    </svg>
  );
}

export default Cog;
