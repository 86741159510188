import { BASE_URL } from './config';

export const endpoints = {
  login: `${BASE_URL}/auth/login`,
  logout: `${BASE_URL}/auth/logout`,
  resetPassword: `${BASE_URL}/auth/forgetPassword`,
  confirmResetPassword: `${BASE_URL}/auth/validateRecoverPasswordToken`,
  registerUser: `${BASE_URL}/user`,
  confirmEmail: `${BASE_URL}/user/confirmation`,
  registerBusiness: `${BASE_URL}/auth/business`,
  account: `${BASE_URL}/user`,
  modifyCredentials: `${BASE_URL}/user/credentials`,
  banks: `${BASE_URL}/banks`,
  resendConfirmation: `${BASE_URL}/user/unconfirmed`,
  collaborators: `${BASE_URL}/user`,
  business: `${BASE_URL}/business`,
  commerces: `${BASE_URL}/commerces`,
  issuers: `${BASE_URL}/issuers`,
  links: `${BASE_URL}/links`,
  payments: `${BASE_URL}/payments`,
  beginPayment: `${BASE_URL}/payments/beginPay`,
  emailVerification: `${BASE_URL}/user/verification`,
  installments: `${BASE_URL}/installments`,
  clients: `${BASE_URL}/business`,
  billing: `${BASE_URL}/electronicbilling`,
  notifications: `${BASE_URL}/notification`,
};
