import { useHistory } from 'react-router-dom';
import Lottie from 'lottie-react';
import { Paper, Typography, Button, Link } from '@material-ui/core';
import errorAnimation from '../../assets/plinks-error.json';

interface ErrorCardProps {
  message: string;
  title?: string;
  subtitle?: string;
  onClick?(): void;
  hasLoginRedirect?: boolean;
}

function ErrorCard({ message, title, subtitle, onClick, hasLoginRedirect }: ErrorCardProps) {
  const history = useHistory();

  return (
    <>
      <Paper elevation={2} className="result-card">
        <div className="error-body">
          <Lottie style={{ height: 300, width: 300 }} animationData={errorAnimation} loop={false} />
          <Typography variant="h4">{title ? title : 'Ha ocurrido un error'}</Typography>
          {subtitle && (
            <Typography variant="h6" className="reference">
              {subtitle}
            </Typography>
          )}
          <Typography variant="body2">{message}</Typography>
          {onClick && (
            <Button onClick={onClick} color="primary" variant="contained">
              Volver a ingresar medio de pago
            </Button>
          )}
        </div>
      </Paper>
      {hasLoginRedirect && (
        <Link classes={{ root: 'login-redirection' }} onClick={() => history.push('/login')}>
          Ir al login
        </Link>
      )}
    </>
  );
}

export default ErrorCard;
