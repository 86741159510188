import React, { useState } from 'react';
import { makeStyles, Theme, createStyles, Menu, MenuItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../store/config/types';
import { connect, ConnectedProps } from 'react-redux';
import { UserTypeEnum } from '../../store/config/enums';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: '#7e84a3',
      '&:hover': {
        color: '#0cd782',
        cursor: 'pointer',
        userSelect: 'none',
      },
    },
    selected: {
      color: '#0cd782',
    },
    menu: {
      marginTop: 8,
    },
  }),
);

interface UserMenuProps {
  setOpen(value: boolean): void;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & UserMenuProps;

function UserMenu({ auth, setOpen }: PropsType) {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectOption = (option: string) => {
    if (option === 'logout') {
      setOpen(true);
    } else if (option === '/user-faqs') {
      if (history.location.pathname !== '/faqs') localStorage.setItem('prevPage', history.location.pathname);
      history.push(option);
    } else {
      history.push(option);
    }
    setAnchorEl(null);
  };

  return (
    <div className="user-menu">
      <span className="name">{auth && auth.account && auth.account.names}</span>
      <ExpandMoreIcon
        onClick={handleClick}
        className={`${classes.icon} ${anchorEl !== null ? classes.selected : null}`}
      />
      <Menu
        keepMounted
        anchorEl={anchorEl}
        className={classes.menu}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {auth.account?.type === UserTypeEnum.OWNER && (
          <>
            <MenuItem onClick={() => selectOption('/profile')}>Editar perfil</MenuItem>
            <MenuItem onClick={() => selectOption('/user-faqs')}>FAQs</MenuItem>
          </>
        )}
        <MenuItem onClick={() => selectOption('logout')}>Salir</MenuItem>
      </Menu>
    </div>
  );
}

export default connector(UserMenu);
