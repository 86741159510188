import { get, put, httpDelete, postForm, putForm } from './base.service';
import { endpoints } from './endpoints';
import { Bank, UpdateBankImageRequest } from '../store/config/types';

export const banksService = {
  fetchBanks,
  fetchBusinessBanks,
  createBank,
  updateBank,
  deleteBank,
  updateBankPicture,
};

async function fetchBanks(): Promise<Bank[] | undefined> {
  return await get<Bank[]>(`${endpoints['banks']}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function fetchBusinessBanks(businessId: number): Promise<Bank[] | undefined> {
  return await get<Bank[]>(`${endpoints['banks']}/${businessId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function createBank(bank: Bank, picture: File): Promise<Bank | undefined> {
  let formData = new FormData();
  formData.append('file', picture);
  formData.append('request', JSON.stringify(bank));
  return await postForm<Bank>(`${endpoints['banks']}`, formData)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function updateBank(bank: Bank): Promise<Bank | undefined> {
  return await put<Bank>(`${endpoints['banks']}`, bank)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function deleteBank(bankId: string): Promise<boolean | undefined> {
  return await httpDelete<boolean>(`${endpoints['banks']}/${bankId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function updateBankPicture(updateImageRequest: UpdateBankImageRequest): Promise<string | undefined> {
  let formData = new FormData();
  formData.append('file', updateImageRequest.file);

  return await putForm<string>(`${endpoints['banks']}/${updateImageRequest.bankId}/picture`, formData)
    .then((response) => {
      return response.stringBody;
    })
    .catch((error) => {
      throw error;
    });
}
