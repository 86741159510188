export enum Currency {
  PESO = 1,
  DOLAR = 2,
}

export enum Status {
  SUCCESS = 0,
  ERROR = 1,
}

export enum DateSpan {
  TODAY = 0,
  LAST7DAYS = 1,
  LAST15DAYS = 2,
  LAST30DAYS = 3,
  ALL = 4,
  CUSTOM = 5,
}

export enum LinkTypeEnum {
  ONETIME = 1,
  PERMANENT = 2,
  ONTHEFLY = 3,
}

export enum LinkStatusEnum {
  Active = 1,
  Inactive = 2,
}

export enum OrderFilterEnum {
  AscendingDate = 1,
  DescendingDate = 2,
  AscendingAmount = 3,
  DescendingAmount = 4,
}

export enum PaymentStatusEnum {
  PENDING = 1,
  APPROVED = 2,
  REJECTED = 3,
  CANCELLED = 4,
}

export enum UserTypeEnum {
  OWNER = 1,
  COLLABORATOR = 2,
  SUPERADMIN = 3,
}

export enum ValidUntil {
  NONE = 0,
  HALFHOUR = 1,
  ONEHOUR = 2,
  TWOHOURS = 3,
  DAYEND = 4,
  MONTHEND = 5,
  CUSTOM = 6,
}
