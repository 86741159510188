import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import { Filters } from '../../store/config/types';
import { OrderFilterEnum } from '../../store/config/enums';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('sm')]: {
        marginTop: '1rem',
      },
    },
    formLabelRoot: {
      fontSize: '1.3em',
      lineHeight: '1.5',
      color: theme.palette.text.primary,
    },
    currencyFilterContainer: {
      marginBottom: '2rem',
    },
    label: {
      marginRight: '2rem',
      marginBottom: '-0.6rem',
    },
    group: {
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
  }),
);

interface CurrencyFiltersProps {
  filters: Filters | null;
  setFilters: (filters: Filters) => void;
  hasDateFilters?: boolean;
}

function CurrencyFilters({ filters, setFilters, hasDateFilters }: CurrencyFiltersProps) {
  const classes = useStyles();

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currency = {
      pesos: filters?.currency?.pesos === undefined ? false : filters?.currency?.pesos,
      dollars: filters?.currency?.dollars === undefined ? false : filters?.currency?.dollars,
    };

    if (
      (filters?.currency?.pesos || filters?.currency?.dollars) &&
      !(filters.currency?.pesos && filters.currency?.dollars)
    ) {
      filters.order = OrderFilterEnum.DescendingDate;
    }

    let newLinksFilters: Filters = {
      ...filters,
      currency: { ...currency, [event.target.name]: event.target.checked },
    };

    setFilters(newLinksFilters);
  };

  return (
    <div className={`${classes.currencyFilterContainer} ${hasDateFilters ? classes.container : ''}`}>
      <FormControl component="fieldset">
        <FormLabel component="legend" classes={{ root: classes.formLabelRoot }} focused={false}>
          Filtrar por moneda
        </FormLabel>
        <FormGroup classes={{ root: classes.group }}>
          <FormControlLabel
            classes={{ root: classes.label }}
            control={
              <Checkbox
                checked={filters?.currency?.pesos}
                color="secondary"
                onChange={handleCurrencyChange}
                name="pesos"
              />
            }
            label="Pesos"
          />
          <FormControlLabel
            classes={{ root: classes.label }}
            control={
              <Checkbox
                checked={filters?.currency?.dollars}
                color="secondary"
                onChange={handleCurrencyChange}
                name="dollars"
              />
            }
            label="Dólares"
          />
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default CurrencyFilters;
