import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { urlHelper } from '../../helpers/urlHelper';
import { BusinessForm } from '../../components/forms/BusinessForm';
import { RootState } from '../../store/config/types';
import { connect, ConnectedProps } from 'react-redux';
import { UserForm } from '../../components/forms/UserForm';
import { Dialog, DialogContent, Typography, makeStyles, createStyles, Theme, Paper } from '@material-ui/core';
import logo from '../../assets/logo.svg';
import { ScreenTitle } from '../../components/ScreenTitle';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      display: 'flex',
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    dialogContent: {
      textAlign: 'center',
      '& > *': {
        marginTop: '1rem',
      },
    },
    whiteButton: {
      minWidth: '6rem',
      backgroundColor: '#ffffff',
      borderColor: '#B1C9DE',
    },
  }),
);

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  business: state.business,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Register({ auth, business }: PropsFromRedux) {
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState<string>('');
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (step === 0) {
      let urlVars = urlHelper.parseQueryString();

      if (urlVars.step) {
        setStep(Number(urlVars.step));
      } else {
        setStep(1);
      }
    }
  }, [step, setStep]);

  const goBack = () => {
    history.push('/login');
  };

  const firstStep = (
    <>
      <Typography variant="h5">Información de acceso</Typography>
      <UserForm setEmail={setEmail} />
    </>
  );

  const secondStep = (
    <>
      <Typography variant="h5">Tu empresa</Typography>
      <BusinessForm
        handleSuccess={() => {
          history.push('/dashboard');
        }}
      />
    </>
  );

  // const thirdStep = (
  //   <>
  //     <Typography variant="h5">Códigos de comercio</Typography>
  //     <IssuersForm />
  //   </>
  // );

  return (
    <div className="screen-container profile">
      <div className={classes.flex}>
        <div>
          <ScreenTitle title="Crea tu cuenta" />
          <Typography>{`Paso ${step} de 2`}</Typography>
        </div>
        {step === 1 && (
          <Button variant="outlined" onClick={goBack} className={classes.whiteButton}>
            Volver
          </Button>
        )}
      </div>
      <Paper elevation={2}>
        {step === 1 ? firstStep : null}
        {step === 1 && auth.registerUserSuccess ? (
          <Dialog open={true} fullWidth maxWidth="xs" className="dialog">
            <DialogContent className={classes.dialogContent} classes={{ root: 'dialog-content' }}>
              <img src={logo} alt="logo" />
              <Typography variant="h6">
                Te enviamos un email de verificación a <span className="email">{email}</span>
              </Typography>
              <p>Allí encontrarás las instrucciones para ingresar a tu cuenta.</p>
              <p>Si no recibiste este correo después de unos minutos, revisa tu casilla de spam.</p>
            </DialogContent>
          </Dialog>
        ) : null}
        {step === 2 ? secondStep : null}
        {/* {step === 3 ? thirdStep : null} */}
      </Paper>
    </div>
  );
}

export default connector(Register);
