import React, { useEffect, useRef } from 'react';
import { Typography } from '@material-ui/core';
import { RootState } from '../../store/config/types';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';

declare global {
  interface Window {
    attachEvent: any;
  }
}

window.attachEvent = window.attachEvent || {};

const mapStateToProps = (state: RootState) => ({
  payments: state.payments,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function PlexoPayment({ payments }: PropsFromRedux) {
  const history = useHistory();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    window.addEventListener('message', receiveMessage)

    return () => {
      window.removeEventListener('message', receiveMessage)
    }
  })

  function receiveMessage(e: MessageEventInit) {
    console.log(e.data)
    console.log(e.data.message)
    if (e.data.message === 'redirectError') {
      history.push(`/result?errorCode=${e.data.payload}`)
    } else if (e.data.message === 'redirectSuccess') {
      history.push(`/result?beginPaymentId=${e.data.payload}`)
    }
  }

  function onMessage(event: MessageEventInit) {
    var data = event.data;
    if (data && data.plexo && iframeRef && iframeRef.current) {
      iframeRef.current.height = data.plexo.height;
    }
  }

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener('message', onMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent('onmessage', onMessage, false);
    }

    return () => {
      window.removeEventListener('message', onMessage, false);
    };
  });

  useEffect(() => {
    if (payments.confirmPaymentSuccess) {
      history.push(`/result`);
    } else if (payments.confirmPaymentErrorMessage !== null) {
      history.push(`/result?errorCode=`);
    }
  });

  return (
    <div className="screen-container">
      <div className="consumer-card">
        <div className="heading">
          <Typography variant="h4">Ingresá tu medio de pago</Typography>
        </div>
        <iframe
          id="plexoIframe"
          ref={iframeRef}
          height={600}
          width={498}
          src={payments.plexoUrl ? payments.plexoUrl : 'https://google.com'}
          title="plexo"
        />
      </div>
    </div>
  );
}

export default connector(PlexoPayment);
