import { Link, LinksState } from '../config/types';
import {
  LinksDataTypes,
  FETCHING_LINKS,
  ADD_LINKS,
  FETCH_LINKS_FAILED,
  SHARE_LINK_FAILED,
  SHARE_LINK_SUCCESS,
  SHARING_LINK,
  DELETE_LINK_FAILED,
  DELETE_LINK_SUCCESS,
  DELETING_LINK,
  CREATE_LINK_FAILED,
  CREATE_LINK_SUCCESS,
  CREATING_LINK,
  LogoutActionTypes,
  LOGOUT,
  UPDATING_LINK,
  UPDATE_LINK_SUCCESS,
  UPDATE_LINK_FAILED,
  LOAD_LINK_FILTERS,
  VALIDATING_LINK,
  VALIDATE_LINK_SUCCESS,
  VALIDATE_LINK_FAILED,
} from '../config/ActionTypes';
import { DateSpan, LinkTypeEnum, OrderFilterEnum } from '../config/enums';

export const initialState: LinksState = {
  loadingLinks: false,
  links: null,
  currentPage: 0,
  pageCount: 0,
  linksType: LinkTypeEnum.ONTHEFLY,
  linksFilters: {
    currency: { pesos: false, dollars: false },
    order: OrderFilterEnum.DescendingDate,
    dateSpan: DateSpan.TODAY,
  },
  linksErrorMessage: null,
  creatingLink: false,
  createLinkSuccess: false,
  latestLink: null,
  createLinkErrorMessage: null,
  updatingLink: false,
  updateLinkSuccess: false,
  updateLinkErrorMessage: null,
  deletingLink: false,
  deleteLinkSuccess: false,
  deleteLinkErrorMessage: null,
  sharingLink: false,
  shareLinkSuccess: false,
  shareLinkErrorMessage: null,
  validatingLink: false,
  validateLinkSuccess: false,
  linkToPay: null,
  validateLinkErrorMessage: null,
};

export function linksReducer(state = initialState, action: LinksDataTypes | LogoutActionTypes): LinksState {
  switch (action.type) {
    case LOAD_LINK_FILTERS:
      return {
        ...state,
        linksFilters: action.filters,
        loadingLinks: true,
        links: null,
      };

    case FETCHING_LINKS:
      const leftoverLinks = action.reset ? null : state.links;

      return {
        ...state,
        loadingLinks: true,
        links: leftoverLinks,
        linksType: action.kind,
        linksErrorMessage: null,
      };

    case ADD_LINKS:
      let linksToAdd = state.links;

      if (linksToAdd) {
        linksToAdd = linksToAdd.concat(action.links.results);
      } else {
        linksToAdd = action.links ? action.links.results : [];
      }
      return {
        ...state,
        loadingLinks: false,
        links: linksToAdd,
        currentPage: action.links ? action.links.currentPage : state.currentPage,
        pageCount: action.links ? action.links.pageCount : state.pageCount,
        linksErrorMessage: null,
      };

    case FETCH_LINKS_FAILED:
      return {
        ...state,
        loadingLinks: false,
        // links: null,
        linksErrorMessage: action.error,
      };

    case CREATING_LINK:
      return {
        ...state,
        creatingLink: true,
        createLinkSuccess: false,
        createLinkErrorMessage: null,
      };

    case CREATE_LINK_SUCCESS:
      const newLinks = state.links ? [...state.links] : [];

      if (action.link.linkType === state.linksType) {
        newLinks.unshift(action.link);
      }

      return {
        ...state,
        links: newLinks,
        creatingLink: false,
        latestLink: action.link,
        createLinkSuccess: true,
        createLinkErrorMessage: null,
      };

    case CREATE_LINK_FAILED:
      return {
        ...state,
        creatingLink: false,
        createLinkSuccess: false,
        latestLink: null,
        createLinkErrorMessage: action.error,
      };

    case UPDATING_LINK:
      return {
        ...state,
        updatingLink: true,
        updateLinkSuccess: false,
        updateLinkErrorMessage: null,
      };

    case UPDATE_LINK_SUCCESS:
      const updatedLinks = state.links?.map((l) => {
        if (l.id === action.link.id) {
          const updatedLink: Link = {
            ...action.link,
          };
          if (action.link.description) updatedLink.description = action.link.description;
          if (action.link.reference) updatedLink.reference = action.link.reference;
          return updatedLink;
        }
        return l;
      });
      return {
        ...state,
        links: updatedLinks ? updatedLinks : null,
        updatingLink: false,
        updateLinkSuccess: true,
        updateLinkErrorMessage: null,
      };

    case UPDATE_LINK_FAILED:
      return {
        ...state,
        updatingLink: false,
        updateLinkSuccess: false,
        updateLinkErrorMessage: action.error,
      };

    case DELETING_LINK:
      return {
        ...state,
        deletingLink: true,
        deleteLinkSuccess: false,
        deleteLinkErrorMessage: null,
      };

    case DELETE_LINK_SUCCESS:
      const survivingLinks = state.links?.filter((l) => l.id !== action.linkId);

      return {
        ...state,
        links: survivingLinks ? survivingLinks : null,
        deletingLink: false,
        deleteLinkSuccess: true,
        deleteLinkErrorMessage: null,
      };

    case DELETE_LINK_FAILED:
      return {
        ...state,
        deletingLink: false,
        deleteLinkSuccess: false,
        deleteLinkErrorMessage: action.error,
      };

    case SHARING_LINK:
      return {
        ...state,
        sharingLink: true,
        shareLinkSuccess: false,
        shareLinkErrorMessage: null,
      };

    case SHARE_LINK_SUCCESS:
      return {
        ...state,
        sharingLink: false,
        shareLinkSuccess: true,
        shareLinkErrorMessage: null,
      };

    case SHARE_LINK_FAILED:
      return {
        ...state,
        sharingLink: false,
        shareLinkSuccess: false,
        shareLinkErrorMessage: action.error,
      };

    case VALIDATING_LINK: {
      return {
        ...state,
        validatingLink: true,
        validateLinkSuccess: false,
        linkToPay: null,
        validateLinkErrorMessage: null,
      };
    }

    case VALIDATE_LINK_SUCCESS: {
      return {
        ...state,
        validatingLink: false,
        validateLinkSuccess: true,
        linkToPay: action.link,
        validateLinkErrorMessage: null,
      };
    }

    case VALIDATE_LINK_FAILED: {
      return {
        ...state,
        validatingLink: false,
        validateLinkSuccess: false,
        linkToPay: null,
        validateLinkErrorMessage: action.error,
      };
    }

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
