import React, { useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import schemas from '../../../data/schemas';
import { currencyHelper } from '../../../helpers/currencyHelper';
import { urlHelper } from '../../../helpers/urlHelper';
import { Currency } from '../../../store/config/enums';
import { LandingParams, BusinessIssuer, Amount, Bank } from '../../../store/config/types';
import { FormSelectField } from '../../forms/FormSelectField';
import { FormTextField } from '../../forms/FormTextField';
import { IssuersCardList } from '../../IssuersCardList';
import dummy from '../../../assets/no-image.jpg';

interface OnTheFlyLandingProps {
  params: LandingParams;
  businessIssuers: BusinessIssuer[] | null;
  businessBanks: Bank[] | null;
  selectedIssuer: BusinessIssuer | null;
  setSelectedIssuer: (business: BusinessIssuer) => void;
  selectedBank: Bank | null;
  setSelectedBank: (bank: Bank | null) => void;
  businessId: number;
  beginningPayment: boolean;
  setAmount: (amount: Amount) => void;
  selectedAmount: Amount | null;
  setStep: (step: number) => void;
}

interface Values {
  currency: Currency;
  amount: number;
  issuer: number;
}

function OnTheFlyLanding({
  params,
  businessIssuers,
  businessBanks,
  selectedIssuer,
  setSelectedIssuer,
  selectedBank,
  setSelectedBank,
  businessId,
  beginningPayment,
  setAmount,
  selectedAmount,
  setStep,
}: OnTheFlyLandingProps) {
  const [src, setSrc] = useState(urlHelper.buildImageUrl(businessId));

  const onImageError = () => {
    setSrc(dummy);
  };

  const submit = (values: Values) => {
    setAmount({ value: values.amount, currency: values.currency });
    setStep(2);
  };

  return (
    <div className="card-body">
      <img className="card-image" src={src} onError={onImageError} alt="destination" />
      <Typography variant="subtitle2">
        Destinatario <span className="destination">{params.companyId}</span>
      </Typography>
      <Formik
        initialValues={{
          currency: params.currency
            ? currencyHelper.isValidCurrency(Number(params.currency))
              ? Number(params.currency)
              : Currency.PESO
            : selectedAmount?.currency ?? Currency.PESO,
          amount: params.amount ? Number(params.amount) : selectedAmount?.value ?? 0,
          issuer: businessIssuers !== null ? businessIssuers[0].idPlexo : 1,
        }}
        validationSchema={schemas.OnTheFlySchema}
        onSubmit={submit}
      >
        {({ values }) => (
          <Form className="form amount-form" key="amount">
            <Field
              name="currency"
              component={FormSelectField}
              label="Moneda"
              options={currencyHelper.mapCurrency(Currency, (i: Currency) => {
                return {
                  id: i,
                  value: currencyHelper.getCurrencyToken(i),
                };
              })}
            />
            <Field name="amount" component={FormTextField} type="number" placeholder="Monto" />
            <Typography variant="subtitle2" className="pay-text">
              Pagar con
            </Typography>
            {businessIssuers && (
              <IssuersCardList
                businessIssuers={businessIssuers}
                businessBanks={businessBanks}
                selectedIssuer={selectedIssuer}
                selectedBank={selectedBank}
                disabled={false}
                clickAction={(issuer, bank) => {
                  setSelectedIssuer(issuer);
                  setSelectedBank(bank);
                }}
              />
            )}
            <Button
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              disabled={beginningPayment || values.amount <= 0 || selectedIssuer === null}
              classes={{ root: 'continue-button' }}
            >
              Continuar con el pago
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default OnTheFlyLanding;
