import React, { useState } from 'react';
import { Divider, Typography } from '@material-ui/core';
import { IssuersCardList } from '../../IssuersCardList';
import { urlHelper } from '../../../helpers/urlHelper';
import { currencyHelper } from '../../../helpers/currencyHelper';
import { numberHelper } from '../../../helpers/numberHelper';
import { BusinessIssuer, LinkToPay, Bank } from '../../../store/config/types';
import dummy from '../../../assets/no-image.jpg';

interface PermanentLinkLandingProps {
  linkToPay: LinkToPay;
  businessIssuers: BusinessIssuer[] | null;
  businessBanks: Bank[] | null;
  setStep: (step: number) => void;
  selectedIssuer: BusinessIssuer | null;
  setSelectedIssuer: (issuer: BusinessIssuer) => void;
  selectedBank: Bank | null;
  setSelectedBank: (bank: Bank | null) => void;
}

function PermanentLinkLanding({
  linkToPay,
  businessIssuers,
  businessBanks,
  setStep,
  selectedIssuer,
  setSelectedIssuer,
  selectedBank,
  setSelectedBank,
}: PermanentLinkLandingProps) {
  const [src, setSrc] = useState(urlHelper.buildImageUrl(linkToPay.businessId));

  const onImageError = () => {
    setSrc(dummy);
  };

  return (
    <div className="card-body">
      <img className="card-image" src={src} onError={onImageError} alt="logo" />
      <Typography variant="subtitle2">
        Destinatario <span className="destination">{linkToPay.businessName}</span>
      </Typography>
      <Divider className="divider" />
      <Typography variant="subtitle2">{linkToPay.description}</Typography>
      <Typography variant="h3" className={`amount ${!linkToPay.reference ? 'amount-alone' : ''}`}>
        <span className="currency">{currencyHelper.getCurrencyToken(linkToPay.amount.currency)}</span>
        {numberHelper.getNumberWithDots(linkToPay.amount.value)}
        <span className="iva">IVA inc.</span>
      </Typography>
      {linkToPay.reference && (
        <>
          <Divider className="divider" />
          <Typography
            variant="subtitle2"
            className="reference-text"
          >{`Referencia: ${linkToPay.reference}`}</Typography>
        </>
      )}
      <Typography variant="subtitle2">Pagar con</Typography>
      {businessIssuers && (
        <IssuersCardList
          businessIssuers={businessIssuers}
          businessBanks={businessBanks}
          selectedIssuer={selectedIssuer}
          selectedBank={selectedBank}
          disabled={false}
          clickAction={(issuer, bank) => {
            setSelectedIssuer(issuer);
            setSelectedBank(bank);
            setStep(2);
          }}
        />
      )}
    </div>
  );
}

export default PermanentLinkLanding;
