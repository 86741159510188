import React, { useEffect, useState } from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { fetchCommerces, deleteCommerce } from '../../store/action_creators/commerces.actions';
import { AppActions } from '../../store/config/ActionTypes';
import { ThunkDispatch } from 'redux-thunk';
import { Commerce, RootState } from '../../store/config/types';
import { CommerceDialog } from '../dialogs/CommerceDialog';
import { DeleteDialog } from '../dialogs/DeleteDialog';
import { Status } from '../../store/config/enums';
import trashIcon from '../../assets/trash.svg';
import plusIcon from '../../assets/plus.svg';
import { CustomSnackbar } from '../CustomSnackbar';

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  commerces: state.commerces,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchCommerces: (businessId: number) => dispatch(fetchCommerces(businessId)),
  deleteCommerce: (businessId: number, commerceId: string, isLast: boolean) =>
    dispatch(deleteCommerce(businessId, commerceId, isLast)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Commerces({ auth, commerces, fetchCommerces, deleteCommerce }: PropsFromRedux) {
  const [open, setOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [deletingCommerce, setDeletingCommerce] = useState<boolean>(false);
  const [selectedCommerce, setSelectedCommerce] = useState<Commerce | null>(null);
  const [commerceToDelete, setCommerceToDelete] = useState<Commerce | null>(null);

  useEffect(() => {
    if (
      auth.account &&
      !commerces.loadingCommerces &&
      commerces.commerces === null &&
      commerces.commercesErrorMessage === null
    ) {
      fetchCommerces(auth.account.business.id!);
    }
  }, [
    auth.account,
    commerces.loadingCommerces,
    commerces.commerces,
    commerces.commercesErrorMessage,
    fetchCommerces,
  ]);

  const createCommerce = () => {
    setSelectedCommerce(null);
    setOpen(true);
  };

  const updateCommerce = (commerce: Commerce) => {
    setSelectedCommerce(commerce);
    setOpen(true);
  };

  const beginDeleteCommerce = (commerce: Commerce) => {
    setCommerceToDelete(commerce);
    setDeleteOpen(true);
  };

  const confirmDeleteCommerce = () => {
    setDeletingCommerce(true);

    if (commerceToDelete && auth.account && commerces.commerces)
      deleteCommerce(auth.account.business.id!, commerceToDelete?.id, commerces.commerces.length === 1);
  };

  const closeSnack = () => {
    setDeletingCommerce(false);

    if (commerces.deleteCommerceSuccess) setDeleteOpen(false);
  };

  return (
    <>
      <Grid container spacing={2} className="commerces-list">
        <Grid item xs={12} sm={4} key="new">
          <div className="new-commerce" onClick={createCommerce}>
            <img src={plusIcon} alt="plus" className="icon" />
            <Typography variant="subtitle2">Nuevo comercio</Typography>
          </div>
        </Grid>
        {commerces.commerces &&
          commerces.commerces.map((commerce, index) => {
            return (
              <Grid item xs={12} sm={4} key={index}>
                <div className="commerce" onClick={() => updateCommerce(commerce)}>
                  <div className="trash">
                    <IconButton
                      onClick={(e: React.SyntheticEvent<any>) => {
                        e.stopPropagation();
                        beginDeleteCommerce(commerce);
                      }}
                      className="icon"
                    >
                      <img src={trashIcon} alt="edit" />
                    </IconButton>
                  </div>
                  <img src={commerce.issuer?.issuerImageUrl} alt="logo" />
                  <div className="number">
                    <Typography variant="subtitle2">
                      {commerce.number ? commerce.number : commerce.issuer?.name}
                    </Typography>
                  </div>
                </div>
              </Grid>
            );
          })}
      </Grid>
      <CommerceDialog open={open} setOpen={setOpen} commerce={selectedCommerce} />
      <DeleteDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        message={`¿Seguro que deseas eliminar el comercio ${commerceToDelete?.number}?`}
        deleteAction={confirmDeleteCommerce}
        deleteDisabled={deletingCommerce}
      />
      <CustomSnackbar
        open={
          deletingCommerce &&
          (commerces.deleteCommerceSuccess || commerces.deleteCommerceErrorMessage !== null)
        }
        message={
          commerces.deleteCommerceSuccess
            ? 'Se borró el comercio correctamente'
            : 'Ocurrió un error al borrar el comercio'
        }
        handleClose={closeSnack}
        type={commerces.deleteCommerceSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </>
  );
}

export default connector(Commerces);
