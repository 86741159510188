import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { createStyles, Divider, Grid, makeStyles, Switch, Typography } from '@material-ui/core';
import { Client, Commerce } from '../../store/config/types';

const useStyles = makeStyles(() =>
  createStyles({
    settingsContainer: {
      padding: '1rem 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);

interface ClientSettingProps {
  selectedClient: Client;
  handleBillingChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  createCommerce: () => void;
  updateCommerce: (commerce: Commerce) => void;
  beginDeleteCommerce: (commerce: Commerce) => void;
}

function ClientSettings({
  selectedClient,
  handleBillingChange,
  createCommerce,
  updateCommerce,
  beginDeleteCommerce,
}: ClientSettingProps) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.settingsContainer}>
        <Typography>Habilitar facturación electrónica</Typography>
        <Switch
          checked={selectedClient!.billingEnabled}
          onChange={handleBillingChange}
          color="primary"
          name="checkedB"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </div>
      <Typography variant="h5">Comercios</Typography>
      <Divider className="divider" />
      <Grid container spacing={2} className="commerces-list">
        <Grid item xs={12} sm={4} key="new">
          <div className="new-commerce" onClick={createCommerce}>
            <AddIcon color="primary" fontSize="large" className="icon" />
            <Typography variant="subtitle2">Nuevo comercio</Typography>
          </div>
        </Grid>
        {selectedClient.commerces &&
          selectedClient.commerces.map((commerce, index) => {
            return (
              <Grid item xs={12} sm={4} key={index}>
                <div className="commerce" onClick={() => updateCommerce(commerce)}>
                  <div className="trash">
                    <DeleteIcon
                      className="icon"
                      onClick={(e: React.SyntheticEvent<any>) => {
                        e.stopPropagation();
                        beginDeleteCommerce(commerce);
                      }}
                    />
                  </div>
                  <img src={commerce.issuer?.issuerImageUrl} alt="logo" />
                  <div className="number">
                    <Typography variant="subtitle2">
                      {commerce.number ? commerce.number : commerce.issuer?.name}
                    </Typography>
                  </div>
                </div>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
}

export default ClientSettings;
