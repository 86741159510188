import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core';
import { Filters, RootState } from '../../store/config/types';
import { connect, ConnectedProps } from 'react-redux';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('sm')]: {
        marginTop: '1rem',
      },
    },
    formLabelRoot: {
      fontSize: '1.3em',
      lineHeight: '1.5',
      color: theme.palette.text.primary,
    },
    currencyFilterContainer: {
      marginBottom: '2rem',
    },
    label: {
      marginRight: '1.6rem',
      marginBottom: '-0.6rem',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    formGroupRoot: {
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
  }),
);

const mapStateToProps = (state: RootState) => ({
  commerces: state.commerces.commerces,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface IssuerFiltersProps {
  filters: Filters | null;
  setFilters: (filters: Filters) => void;
  hasDateFilters?: boolean;
}

type PropsType = IssuerFiltersProps & PropsFromRedux;

function IssuerFilters({ filters, commerces, setFilters, hasDateFilters }: PropsType) {
  const classes = useStyles();

  const handleIssuerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedIssuer = event.target.value ? parseInt(event.target.value) : undefined;
    var filterIssuer;

    filterIssuer = selectedIssuer === filters?.issuer ? undefined : selectedIssuer;

    let newPaymentsFilters: Filters = {
      ...filters,
      issuer: filterIssuer,
    };

    setFilters(newPaymentsFilters);
  };

  return (
    <div className={`${classes.currencyFilterContainer} ${hasDateFilters ? classes.container : ''}`}>
      <FormControl component="fieldset">
        <FormLabel component="legend" classes={{ root: classes.formLabelRoot }} focused={false}>
          Filtrar por medio de pago
        </FormLabel>
        <RadioGroup
          aria-label="issuerFilter"
          name="issuerFilter"
          value={filters?.issuer?.toString()}
          onChange={handleIssuerChange}
          classes={{ root: classes.formGroupRoot }}
        >
          <FormControlLabel
            key={'all'}
            classes={{ root: classes.label }}
            value={undefined}
            control={<Radio color="secondary" />}
            label={'Todos'}
          />
          {commerces &&
            commerces.map((commerce, index) => {
              return (
                <FormControlLabel
                  key={commerce.issuer?.id.toString()}
                  classes={{ root: classes.label }}
                  value={commerce.issuer?.id.toString()}
                  control={<Radio color="secondary" />}
                  label={commerce.issuer?.name}
                />
              );
            })}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export default connector(IssuerFilters);
