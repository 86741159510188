import React, { useState } from 'react';
import { makeStyles, Theme, createStyles, Menu, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Cog from '../Icons/Cog/Cog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      cursor: 'pointer',
      userSelect: 'none',
      height: 20,
      width: 20,
    },
    selected: {
      color: '#0cd782',
    },
    menu: {
      marginTop: 8,
    },
  }),
);

function CogMenu() {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectOption = (option: string) => {
    history.push(option);
    setAnchorEl(null);
  };

  return (
    <>
      <Cog onClick={handleClick} className={classes.icon} color={anchorEl !== null ? '#0cd782' : '#7e84a3'} />
      <Menu
        keepMounted
        anchorEl={anchorEl}
        className={classes.menu}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => selectOption('/business')}>Empresa</MenuItem>
        <MenuItem onClick={() => selectOption('/configuration')}>Configuración</MenuItem>
        <MenuItem onClick={() => selectOption('/users')}>Colaboradores</MenuItem>
      </Menu>
    </>
  );
}

export default CogMenu;
