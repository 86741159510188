import { BanksState } from '../config/types';
import {
  FETCHING_BANKS,
  ADD_BANKS,
  FETCH_BANKS_FAILED,
  FETCH_BUSINESS_BANKS_FAILED,
  ADD_BUSINESS_BANKS,
  FETCHING_BUSINESS_BANKS,
  CREATING_BANK,
  CREATE_BANK_SUCCESS,
  CREATE_BANK_FAILED,
  UPDATING_BANK,
  UPDATE_BANK_SUCCESS,
  UPDATE_BANK_FAILED,
  DELETING_BANK,
  DELETE_BANK_SUCCESS,
  DELETE_BANK_FAILED,
  BanksDataTypes,
  LogoutActionTypes,
  LOGOUT,
} from '../config/ActionTypes';

export const initialState: BanksState = {
  loadingBanks: false,
  banks: null,
  banksErrorMessage: null,
  loadingBusinessBanks: false,
  businessBanks: null,
  businessBanksErrorMessage: null,
  creatingBank: false,
  createBankSuccess: false,
  createBankErrorMessage: null,
  updatingBank: false,
  updateBankSuccess: false,
  updateBankErrorMessage: null,
  deletingBank: false,
  deleteBankSuccess: false,
  deleteBankErrorMessage: null,
};

export function banksReducer(state = initialState, action: BanksDataTypes | LogoutActionTypes): BanksState {
  switch (action.type) {
    case FETCHING_BANKS: {
      return {
        ...state,
        loadingBanks: true,
        banks: null,
        banksErrorMessage: null,
      };
    }

    case ADD_BANKS: {
      return {
        ...state,
        loadingBanks: false,
        banks: action.banks,
        banksErrorMessage: null,
      };
    }

    case FETCH_BANKS_FAILED: {
      return {
        ...state,
        loadingBanks: false,
        banks: null,
        banksErrorMessage: action.error,
      };
    }

    case FETCHING_BUSINESS_BANKS: {
      return {
        ...state,
        loadingBusinessBanks: true,
        businessBanks: null,
        businessBanksErrorMessage: null,
      };
    }

    case ADD_BUSINESS_BANKS: {
      return {
        ...state,
        loadingBusinessBanks: false,
        businessBanks: action.banks,
        businessBanksErrorMessage: null,
      };
    }

    case FETCH_BUSINESS_BANKS_FAILED: {
      return {
        ...state,
        loadingBusinessBanks: false,
        businessBanks: null,
        businessBanksErrorMessage: action.error,
      };
    }

    case CREATING_BANK: {
      return {
        ...state,
        creatingBank: true,
        createBankSuccess: false,
        createBankErrorMessage: null,
      };
    }

    case CREATE_BANK_SUCCESS: {
      const banksWithNew = state.banks ? [...state.banks] : [];
      banksWithNew?.push(action.bank);
      return {
        ...state,
        creatingBank: false,
        createBankSuccess: true,
        createBankErrorMessage: null,
        banks: banksWithNew,
      };
    }

    case CREATE_BANK_FAILED: {
      return {
        ...state,
        creatingBank: false,
        createBankSuccess: false,
        createBankErrorMessage: action.error,
      };
    }

    case UPDATING_BANK: {
      return {
        ...state,
        updatingBank: true,
        updateBankSuccess: false,
        updateBankErrorMessage: null,
      };
    }

    case UPDATE_BANK_SUCCESS: {
      const banksWithUpdated =
        state.banks &&
        state.banks.map((b) => {
          if (b.id === action.bank.id) {
            return action.bank;
          } else return b;
        });
      return {
        ...state,
        updatingBank: false,
        updateBankSuccess: true,
        updateBankErrorMessage: null,
        banks: banksWithUpdated,
      };
    }

    case UPDATE_BANK_FAILED: {
      return {
        ...state,
        updatingBank: false,
        updateBankSuccess: false,
        updateBankErrorMessage: action.error,
      };
    }

    case DELETING_BANK: {
      return {
        ...state,
        deletingBank: true,
        deleteBankSuccess: false,
        deleteBankErrorMessage: null,
      };
    }

    case DELETE_BANK_SUCCESS: {
      const banksWithoutDeleted = state.banks && state.banks.filter((b) => b.id !== action.bankId);
      return {
        ...state,
        deletingBank: false,
        deleteBankSuccess: true,
        deleteBankErrorMessage: null,
        banks: banksWithoutDeleted,
      };
    }

    case DELETE_BANK_FAILED: {
      return {
        ...state,
        deletingBank: false,
        deleteBankSuccess: false,
        deleteBankErrorMessage: action.error,
      };
    }

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
