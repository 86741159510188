import React, { useEffect, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { connect, ConnectedProps } from 'react-redux';
import { Button, createStyles, Divider, Link, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ScreenTitle } from '../../components/ScreenTitle';
import { ClientSettings } from '../../components/ClientSettings';
import { Client, Commerce, RootState, ToggleBillingRequest } from '../../store/config/types';
import { CommerceDialog } from '../../components/dialogs/CommerceDialog';
import { DeleteDialog } from '../../components/dialogs/DeleteDialog';
import { ResendConfirmationDialog } from '../../components/dialogs/ResendConfirmationDialog';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { Status } from '../../store/config/enums';
import { AppActions } from '../../store/config/ActionTypes';
import { fetchClients, toggleBilling } from '../../store/action_creators/clients.actions';
import { deleteBusinessCommerce } from '../../store/action_creators/commerces.actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '2rem 0',
      maxWidth: '600px',
      margin: '0 auto',
    },
    divider: {
      margin: '0.8rem 0',
      width: '100%',
    },
    screenTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      width: '100%',
    },
    paperRoot: {
      margin: '1rem 0',
      padding: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
);

const mapStateToProps = (state: RootState) => ({
  clients: state.clients,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => {
  return {
    fetchClients: () => dispatch(fetchClients()),
    toggleBilling: (request: ToggleBillingRequest) => dispatch(toggleBilling(request)),
    deleteBusinessCommerce: (businessId: number, commerceId: string) =>
      dispatch(deleteBusinessCommerce(businessId, commerceId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

function ManageClients({ clients, fetchClients, toggleBilling, deleteBusinessCommerce }: PropsFromRedux) {
  const classes = useStyles();
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [selectedCommerce, setSelectedCommerce] = useState<Commerce | null>(null);
  const [deletingCommerce, setDeletingCommerce] = useState<boolean>(false);
  const [commerceToDelete, setCommerceToDelete] = useState<Commerce | null>(null);
  const [openResendConfirmationDialog, setOpenResendConfirmationDialog] = useState<boolean>(false);

  const showClientDetails = (client: Client) => {
    setSelectedClient(client);
  };

  const handleBillingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleBilling({ id: selectedClient!.id, enabled: !selectedClient!.billingEnabled });
  };

  const createCommerce = () => {
    setSelectedCommerce(null);
    setOpen(true);
  };

  const updateCommerce = (commerce: Commerce) => {
    setSelectedCommerce(commerce);
    setOpen(true);
  };

  const beginDeleteCommerce = (commerce: Commerce) => {
    setCommerceToDelete(commerce);
    setDeleteOpen(true);
  };

  const confirmDeleteCommerce = () => {
    setDeletingCommerce(true);

    if (commerceToDelete) deleteBusinessCommerce(selectedClient!.id, commerceToDelete.id);
  };

  const closeSnack = () => {
    setDeletingCommerce(false);

    setDeleteOpen(false);
  };

  useEffect(() => {
    if (!clients.loadingClients && clients.clients === null && clients.loadingClientsErrorMessage === null) {
      fetchClients();
    }
  });

  return (
    <div className={classes.container}>
      <div className={classes.screenTitle}>
        <ScreenTitle title={selectedClient === null ? 'Clientes' : selectedClient.name}>
          {selectedClient === null ? (
            <Button variant="contained" color="primary" onClick={() => setOpenResendConfirmationDialog(true)}>
              Reenviar confirmación
            </Button>
          ) : (
            <Link onClick={() => setSelectedClient(null)}>Volver</Link>
          )}
        </ScreenTitle>
      </div>
      <Divider className={classes.divider} />

      {selectedClient === null &&
        clients.clients &&
        clients.clients.map((client) => {
          return (
            <Paper
              key={client.id}
              elevation={0}
              variant="outlined"
              classes={{ root: classes.paperRoot }}
              onClick={() => showClientDetails(client)}
            >
              <Typography variant="body1">{client.name}</Typography>
              <ArrowForwardIosIcon fontSize="small" />
            </Paper>
          );
        })}
      {selectedClient !== null && (
        <ClientSettings
          selectedClient={selectedClient!}
          handleBillingChange={handleBillingChange}
          createCommerce={createCommerce}
          updateCommerce={updateCommerce}
          beginDeleteCommerce={beginDeleteCommerce}
        />
      )}
      <CommerceDialog
        open={open}
        setOpen={setOpen}
        commerce={selectedCommerce}
        client={selectedClient ?? undefined}
      />
      <DeleteDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        message={`¿Seguro que deseas eliminar el comercio ${commerceToDelete?.number}?`}
        deleteAction={confirmDeleteCommerce}
        deleteDisabled={deletingCommerce}
      />
      <ResendConfirmationDialog
        open={openResendConfirmationDialog}
        setOpen={setOpenResendConfirmationDialog}
      />
      <CustomSnackbar
        open={
          deletingCommerce &&
          (clients.deletingCommercesSuccess || clients.deletingCommercesErrorMessage !== null)
        }
        message={
          clients.deletingCommercesSuccess
            ? 'Se eliminó el comercio correctamente'
            : clients.deletingCommercesErrorMessage!
        }
        handleClose={closeSnack}
        type={clients.deletingCommercesSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </div>
  );
}

export default connector(ManageClients);
