import { Dispatch } from 'redux';
import { collaboratorsService } from '../../services/collaborators.service';
import {
  FetchCollaboratorsTypes,
  FETCHING_COLLABORATORS,
  ADD_COLLABORATORS,
  FETCH_COLLABORATORS_FAILED,
  CreateCollaboratorTypes,
  CREATING_COLLABORATOR,
  CREATE_COLLABORATOR_SUCCESS,
  CREATE_COLLABORATOR_FAILED,
  UpdateCollaboratorTypes,
  UPDATING_COLLABORATOR,
  UPDATE_COLLABORATOR_SUCCESS,
  UPDATE_COLLABORATOR_FAILED,
  DeleteCollaboratorTypes,
  DELETING_COLLABORATOR,
  DELETE_COLLABORATOR_SUCCESS,
  DELETE_COLLABORATOR_FAILED,
} from '../config/ActionTypes';
import { Collaborator, CreateCollaboratorRequest, UpdateCollaboratorRequest } from '../config/types';

//#region Fetch collaborators

export const fetchCollaborators = (businessId: number) => {
  return (dispatch: Dispatch<FetchCollaboratorsTypes>) => {
    dispatch(loadingCollaborators());

    return collaboratorsService.fetchCollaborators(businessId).then(
      (response) => {
        dispatch(addCollaborators(response!));
      },
      (error) => {
        dispatch(collaboratorsFailed(error));
      },
    );
  };
};

export const loadingCollaborators = (): FetchCollaboratorsTypes => ({
  type: FETCHING_COLLABORATORS,
});

export const addCollaborators = (collaborators: Collaborator[]): FetchCollaboratorsTypes => ({
  type: ADD_COLLABORATORS,
  collaborators,
});

export const collaboratorsFailed = (error: string): FetchCollaboratorsTypes => ({
  type: FETCH_COLLABORATORS_FAILED,
  error,
});

//#endregion

//#region Create collaborator

export const createCollaborator = (createCollaboratorRequest: CreateCollaboratorRequest) => {
  return (dispatch: Dispatch<CreateCollaboratorTypes>) => {
    dispatch(creatingCollaborator());

    return collaboratorsService.createCollaborator(createCollaboratorRequest).then(
      (response) => {
        dispatch(createCollaboratorSuccess(response!));
      },
      (error) => {
        dispatch(createCollaboratorFailed(error));
      },
    );
  };
};

export const creatingCollaborator = (): CreateCollaboratorTypes => ({
  type: CREATING_COLLABORATOR,
});

export const createCollaboratorSuccess = (collaborator: Collaborator): CreateCollaboratorTypes => ({
  type: CREATE_COLLABORATOR_SUCCESS,
  collaborator,
});

export const createCollaboratorFailed = (error: string): CreateCollaboratorTypes => ({
  type: CREATE_COLLABORATOR_FAILED,
  error,
});

//#endregion

//#region Update collaborator

export const updateCollaborator = (updateCollaboratorRequest: UpdateCollaboratorRequest) => {
  return (dispatch: Dispatch<UpdateCollaboratorTypes>) => {
    dispatch(updatingCollaborator());

    return collaboratorsService.updateCollaborator(updateCollaboratorRequest).then(
      (response) => {
        dispatch(updateCollaboratorSuccess(response!));
      },
      (error) => {
        dispatch(updateCollaboratorFailed(error));
      },
    );
  };
};

export const updatingCollaborator = (): UpdateCollaboratorTypes => ({
  type: UPDATING_COLLABORATOR,
});

export const updateCollaboratorSuccess = (collaborator: Collaborator): UpdateCollaboratorTypes => ({
  type: UPDATE_COLLABORATOR_SUCCESS,
  collaborator,
});

export const updateCollaboratorFailed = (error: string): UpdateCollaboratorTypes => ({
  type: UPDATE_COLLABORATOR_FAILED,
  error,
});

//#endregion

//#region Delete collaborator

export const deleteCollaborator = (collaboratorId: string) => {
  return (dispatch: Dispatch<DeleteCollaboratorTypes>) => {
    dispatch(deletingCollaborator());

    return collaboratorsService.deleteCollaborator(collaboratorId).then(
      (response) => {
        dispatch(deleteCollaboratorSuccess(collaboratorId));
      },
      (error) => {
        dispatch(deleteCollaboratorFailed(error));
      },
    );
  };
};

export const deletingCollaborator = (): DeleteCollaboratorTypes => ({
  type: DELETING_COLLABORATOR,
});

export const deleteCollaboratorSuccess = (collaboratorId: string): DeleteCollaboratorTypes => ({
  type: DELETE_COLLABORATOR_SUCCESS,
  collaboratorId,
});

export const deleteCollaboratorFailed = (error: string): DeleteCollaboratorTypes => ({
  type: DELETE_COLLABORATOR_FAILED,
  error,
});

//#endregion
