import React, { useState } from 'react';
import { ScreenTitle } from '../../components/ScreenTitle';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { Button, Paper, createStyles, Theme, makeStyles } from '@material-ui/core';
import { FormTextField } from '../../components/forms/FormTextField';
import schemas from '../../data/schemas';
import { ThunkDispatch } from 'redux-thunk';
import { AppActions } from '../../store/config/ActionTypes';
import { connect, ConnectedProps } from 'react-redux';
import { resetPassword } from '../../store/action_creators/auth.actions';
import { RootState, ResetPasswordRequest } from '../../store/config/types';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { Status } from '../../store/config/enums';
import { useHistory } from 'react-router-dom';

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  resetPassword: (resetPasswordRequest: ResetPasswordRequest) =>
    dispatch(resetPassword(resetPasswordRequest)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Values {
  email: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    whiteButton: {
      minWidth: '6rem',
      backgroundColor: '#ffffff',
      borderColor: '#B1C9DE',
    },
  }),
);

function ResetPassword({ auth, resetPassword }: PropsFromRedux) {
  const history = useHistory();
  const classes = useStyles();

  const [resetingPassword, setResetingPassword] = useState<boolean>(false);

  const submitLogin = (values: Values, helpers: FormikHelpers<Values>) => {
    const request: ResetPasswordRequest = {
      email: values.email,
    };

    setResetingPassword(true);
    resetPassword(request);
  };

  const closeSnack = () => {
    setResetingPassword(false);
  };

  return (
    <div className="screen-container profile">
      <Paper elevation={2}>
        <ScreenTitle title="Reiniciar contraseña" />
        <Formik
          initialValues={{ email: '' }}
          validationSchema={schemas.ResetPasswordSchema}
          onSubmit={submitLogin}
        >
          <Form className="form">
            <Field
              className="form-row"
              name="email"
              component={FormTextField}
              type="text"
              placeholder="Correo electrónico"
            />
            <div className="many-actions-row">
              <Button
                variant="outlined"
                onClick={() => history.push('login')}
                className={classes.whiteButton}
              >
                Volver
              </Button>
              <Button type="submit" color="primary" variant="contained">
                Reiniciar
              </Button>
            </div>
          </Form>
        </Formik>
        <CustomSnackbar
          open={resetingPassword && (auth.resetPasswordSuccess || auth.resetPasswordErrorMessage !== null)}
          message={
            auth.resetPasswordSuccess
              ? 'Se ha enviado un mail con los pasos para reiniciar tu contraseña'
              : 'Ocurrió un error al reiniciar tu contraseña'
          }
          handleClose={closeSnack}
          type={auth.resetPasswordSuccess ? Status.SUCCESS : Status.ERROR}
        />
      </Paper>
    </div>
  );
}

export default connector(ResetPassword);
