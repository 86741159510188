import { useEffect } from 'react';
import { Commerce, RootState } from '../../../store/config/types';
import { connect, ConnectedProps } from 'react-redux';
import { AppActions } from '../../../store/config/ActionTypes';
import { ThunkDispatch } from 'redux-thunk';
import { fetchInstallments } from '../../../store/action_creators/commerces.actions';
import { Grid, makeStyles, createStyles, Chip, Typography } from '@material-ui/core';
import { useState } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(() =>
  createStyles({
    helperText: {
      paddingTop: '8px',
    },
    subtitle: {
      marginBottom: '0.6rem',
    },
    chip: {
      marginRight: 8,
      marginBottom: 8,
      paddingLeft: 4,
      paddingRight: 4,
      width: '50px',
    },
    selectedChip: {
      color: '#ffffff',
    },
  }),
);

interface InstallmentsFieldProps {
  commerce: Commerce | null;
  selectedInstallments: number[];
  setSelectedInstallments(installments: number[]): void;
}

const mapStateToProps = (state: RootState) => ({
  commerces: state.commerces,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchInstallments: () => dispatch(fetchInstallments()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & InstallmentsFieldProps;

function InstallmentsField({
  commerces,
  fetchInstallments,
  commerce,
  selectedInstallments,
  setSelectedInstallments,
}: PropsType) {
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      setSelectedInstallments(commerce ? commerce.installments : []);
    }
  }, [loaded, setLoaded, setSelectedInstallments, commerce]);

  const classes = useStyles();

  useEffect(() => {
    if (
      !commerces.fetchingPossibleInstallments &&
      commerces.possibleInstallments === null &&
      commerces.fetchInstallmentsError === null
    ) {
      fetchInstallments();
    }
  }, [
    commerces.fetchingPossibleInstallments,
    commerces.possibleInstallments,
    commerces.fetchInstallmentsError,
    fetchInstallments,
  ]);

  const checkInstallment = (installment: number) => {
    const existingInstallment = selectedInstallments.find((i) => i === installment);

    if (existingInstallment) {
      setSelectedInstallments(selectedInstallments.filter((i) => i !== installment));
    } else {
      setSelectedInstallments([...selectedInstallments, installment]);
    }
  };

  return (
    <>
      {commerces.possibleInstallments && (
        <Grid item xs={12}>
          <Typography variant="subtitle2" className={classes.subtitle}>
            Cuotas disponibles
          </Typography>
          {commerces.possibleInstallments.map((i, index) => {
            const isSelected = selectedInstallments.find((ins) => ins === i) !== undefined;

            return (
              <Chip
                key={index}
                label={i}
                clickable
                className={clsx(classes.chip, isSelected && classes.selectedChip)}
                color={isSelected ? 'secondary' : 'default'}
                onClick={() => checkInstallment(i)}
              />
            );
          })}
        </Grid>
      )}
    </>
  );
}

export default connector(InstallmentsField);
