import { ReactNode } from 'react';
import { createStyles, Theme, makeStyles, Typography, Tooltip } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    screenTitle: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      '& *': {
        paddingRight: '0.6em',
      },
    },
    infoIcon: {
      color: theme.palette.text.secondary,
      fontSize: '2.3rem',
      paddingRight: '1rem',
    },
    titleWithChild: {
      justifyContent: 'space-between',
    },
  }),
);

interface ScreenTitleProps {
  title: string;
  elementsAmount?: number;
  tooltip?: string;
  children?: ReactNode;
}

function ScreenTitle({ title, elementsAmount, tooltip, children }: ScreenTitleProps) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.screenTitle, children && classes.titleWithChild)}>
      <Typography variant="h4">{title}</Typography>
      {tooltip && (
        <Tooltip title={tooltip} disableFocusListener>
          <HelpOutlineIcon className={classes.infoIcon} />
        </Tooltip>
      )}
      {elementsAmount !== null && elementsAmount !== undefined && (
        <>
          <Typography variant="subtitle2">|</Typography>
          <Typography variant="subtitle2">
            {elementsAmount} elemento{elementsAmount !== 1 ? 's' : ''}
          </Typography>
        </>
      )}
      {children}
    </div>
  );
}

export default ScreenTitle;
