import { Dispatch } from 'redux';
import { Bank } from '../config/types';
import {
  FetchBanksTypes,
  FETCHING_BANKS,
  ADD_BANKS,
  FETCH_BANKS_FAILED,
  FetchBusinessBanksTypes,
  FETCH_BUSINESS_BANKS_FAILED,
  ADD_BUSINESS_BANKS,
  FETCHING_BUSINESS_BANKS,
  CREATING_BANK,
  CREATE_BANK_SUCCESS,
  CREATE_BANK_FAILED,
  CreateBankTypes,
  UpdateBankTypes,
  UPDATING_BANK,
  UPDATE_BANK_SUCCESS,
  UPDATE_BANK_FAILED,
  DeleteBankTypes,
  DELETING_BANK,
  DELETE_BANK_SUCCESS,
  DELETE_BANK_FAILED,
} from '../config/ActionTypes';
import { banksService } from '../../services/banks.service';

//#region Fetch banks

export const fetchBanks = () => {
  return (dispatch: Dispatch<FetchBanksTypes>) => {
    dispatch(loadingBanks());

    return banksService.fetchBanks().then(
      (response) => {
        dispatch(addBanks(response!));
      },
      (error) => {
        dispatch(banksFailed(error));
      },
    );
  };
};

export const loadingBanks = (): FetchBanksTypes => ({
  type: FETCHING_BANKS,
});

export const addBanks = (banks: Bank[]): FetchBanksTypes => ({
  type: ADD_BANKS,
  banks,
});

export const banksFailed = (error: string): FetchBanksTypes => ({
  type: FETCH_BANKS_FAILED,
  error,
});

//#endregion

//#region Fetch business banks

export const fetchBusinessBanks = (businessId: number) => {
  return (dispatch: Dispatch<FetchBusinessBanksTypes>) => {
    dispatch(loadingBusinessBanksBanks());

    return banksService.fetchBusinessBanks(businessId).then(
      (response) => {
        dispatch(addBusinessBanks(response!));
      },
      (error) => {
        dispatch(businessBanksFailed(error));
      },
    );
  };
};

export const loadingBusinessBanksBanks = (): FetchBusinessBanksTypes => ({
  type: FETCHING_BUSINESS_BANKS,
});

export const addBusinessBanks = (banks: Bank[]): FetchBusinessBanksTypes => ({
  type: ADD_BUSINESS_BANKS,
  banks,
});

export const businessBanksFailed = (error: string): FetchBusinessBanksTypes => ({
  type: FETCH_BUSINESS_BANKS_FAILED,
  error,
});

//#endregion

//#region Create bank

export const createBank = (bank: Bank, picture: File) => {
  return (dispatch: Dispatch<CreateBankTypes>) => {
    dispatch(creatingBank());

    return banksService.createBank(bank, picture).then(
      (response) => {
        dispatch(createBankSuccess(response!));
      },
      (error) => {
        dispatch(createBankError(error));
      },
    );
  };
};

export const creatingBank = (): CreateBankTypes => ({
  type: CREATING_BANK,
});

export const createBankSuccess = (bank: Bank): CreateBankTypes => ({
  type: CREATE_BANK_SUCCESS,
  bank,
});

export const createBankError = (error: string): CreateBankTypes => ({
  type: CREATE_BANK_FAILED,
  error,
});

//#endregion

//#region Update bank

export const updateBank = (bank: Bank) => {
  return (dispatch: Dispatch<UpdateBankTypes>) => {
    dispatch(updatingBank());

    return banksService.updateBank(bank).then(
      (response) => {
        dispatch(updateBankSuccess(response!));
      },
      (error) => {
        dispatch(updateBankError(error));
      },
    );
  };
};

export const updatingBank = (): UpdateBankTypes => ({
  type: UPDATING_BANK,
});

export const updateBankSuccess = (bank: Bank): UpdateBankTypes => ({
  type: UPDATE_BANK_SUCCESS,
  bank,
});

export const updateBankError = (error: string): UpdateBankTypes => ({
  type: UPDATE_BANK_FAILED,
  error,
});

//#endregion

//#region Delete bank

export const deleteBank = (bankId: string) => {
  return (dispatch: Dispatch<DeleteBankTypes>) => {
    dispatch(deletingBank());

    return banksService.deleteBank(bankId).then(
      (response) => {
        dispatch(deleteBankSuccess(bankId));
      },
      (error) => {
        dispatch(deleteBankError(error));
      },
    );
  };
};

export const deletingBank = (): DeleteBankTypes => ({
  type: DELETING_BANK,
});

export const deleteBankSuccess = (bankId: string): DeleteBankTypes => ({
  type: DELETE_BANK_SUCCESS,
  bankId,
});

export const deleteBankError = (error: string): DeleteBankTypes => ({
  type: DELETE_BANK_FAILED,
  error,
});

//#endregion
