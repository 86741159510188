import { PaymentStatusEnum } from '../store/config/enums';
import { Theme } from '@material-ui/core/styles';

export const paymentStatusEnumHelper = {
  useBackgroundColorByStatus,
  useIconColorByStatus,
  getStatusText,
};

function useBackgroundColorByStatus(paymentStatus: PaymentStatusEnum, theme: Theme): string {
  switch (paymentStatus) {
    case PaymentStatusEnum.PENDING:
      return theme.palette.warning.light;
    case PaymentStatusEnum.APPROVED:
      return '';
    case PaymentStatusEnum.REJECTED:
      return theme.palette.error.light;
    case PaymentStatusEnum.CANCELLED:
      return theme.palette.grey[200];
    default:
      return '';
  }
}

function useIconColorByStatus(paymentStatus: PaymentStatusEnum, theme: Theme) {
  switch (paymentStatus) {
    case PaymentStatusEnum.PENDING:
      return theme.palette.warning.dark;
    case PaymentStatusEnum.APPROVED:
      return theme.palette.secondary.main;
    case PaymentStatusEnum.REJECTED:
      return theme.palette.error.dark;
    case PaymentStatusEnum.CANCELLED:
      return theme.palette.grey[500];
    default:
      return '';
  }
}

function getStatusText(paymentStatus: PaymentStatusEnum): string {
  switch (paymentStatus) {
    case PaymentStatusEnum.PENDING:
      return 'Pendiente';
    case PaymentStatusEnum.APPROVED:
      return 'Aprobado';
    case PaymentStatusEnum.REJECTED:
      return 'Rechazado';
    case PaymentStatusEnum.CANCELLED:
      return 'Cancelado';
    default:
      return '';
  }
}
