import { Collaborator, CreateCollaboratorRequest, UpdateCollaboratorRequest } from '../store/config/types';
import { get, post, httpDelete, put } from './base.service';
import { endpoints } from './endpoints';

export const collaboratorsService = {
  fetchCollaborators,
  createCollaborator,
  updateCollaborator,
  deleteCollaborator,
};

async function fetchCollaborators(businessId: number): Promise<Collaborator[] | undefined> {
  return await get<Collaborator[]>(`${endpoints['collaborators']}/${businessId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function createCollaborator(
  createCollaboratorRequest: CreateCollaboratorRequest,
): Promise<Collaborator | undefined> {
  return await post<Collaborator>(`${endpoints['collaborators']}/collaborator`, createCollaboratorRequest)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function updateCollaborator(
  updateCollaboratorRequest: UpdateCollaboratorRequest,
): Promise<Collaborator | undefined> {
  return await put<Collaborator>(`${endpoints['collaborators']}`, updateCollaboratorRequest)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function deleteCollaborator(collaboratorId: string): Promise<boolean | undefined> {
  return await httpDelete<boolean>(`${endpoints['collaborators']}/${collaboratorId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}
