import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { ConfirmPaymentResponse, PaymentInfo } from '../../store/config/types';
import moment from 'moment';
import { theme } from '../../styles/theme';
import { currencyHelper } from '../../helpers/currencyHelper';
import image from '../../assets/footer-logo@2x.png';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px 15px',
  },
  footerContainer: {
    width: '500px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    paddingTop: 32,
    paddingBottom: 32,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  footerImage: {
    height: 40,
    width: 'auto',
  },
  content: {
    margin: '0',
    marginTop: '30px',
    width: '500px',
    height: '500px',
    fontSize: 14,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  details: {
    display: 'flex',
    flexGrow: 1,
    border: '1 solid #666',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomColor: '#FFF',
    flexDirection: 'column',
    padding: 15,
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
  },
  separator: {
    margin: '15px',
    borderTop: '1 solid #666',
  },
  horizontalChunk: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  amountChunk: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  verticalChunk: {
    display: 'flex',
    flexDirection: 'column',
  },
  verticalLastChunk: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  smallText: {
    fontSize: 12,
    color: '#666',
    marginTop: 4,
    marginBottom: 4,
  },
  date: {
    marginLeft: 7,
  },
  currency: {
    fontSize: 20,
    marginBottom: 2,
  },
  bigNumber: {
    marginLeft: 5,
    marginRight: 5,
    fontSize: 24,
  },
  issuerName: {
    marginRight: 6,
  },
  cardNumber: {
    marginLeft: 6,
  },
});

interface PDFReceiptProps {
  selectedPayment: PaymentInfo;
  confirmedPayment: ConfirmPaymentResponse;
}

function PDFReceipt({ selectedPayment, confirmedPayment }: PDFReceiptProps) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <View style={styles.content}>
            <View style={styles.details}>
              <View style={styles.line}>
                <Text>{'Pago realizado'}</Text>
                <Text>{confirmedPayment.plexoReferenceId}</Text>
              </View>
              <View style={styles.separator} />
              <View style={styles.line}>
                <View style={styles.horizontalChunk}>
                  <Text style={styles.issuerName}>{selectedPayment.instrument.issuerName}</Text>
                  <Text style={styles.smallText}>terminada en</Text>
                  <Text style={styles.cardNumber}>{` ${selectedPayment.instrument.name.substr(
                    selectedPayment.instrument.name.length - 4,
                  )}`}</Text>
                </View>
                <View style={styles.horizontalChunk}>
                  <Text style={styles.smallText}>Fecha</Text>
                  <Text style={styles.date}>{moment(selectedPayment.createdAt).format('DD/MM/YYYY')}</Text>
                </View>
              </View>
              <View style={styles.separator} />
              <View style={styles.line}>
                <View style={styles.verticalChunk}>
                  <Text style={styles.smallText}>Moneda</Text>
                  <Text>{currencyHelper.getCurrencyName(confirmedPayment.currency)}</Text>
                </View>
                <View style={styles.verticalLastChunk}>
                  <View style={styles.amountChunk}>
                    <Text style={styles.currency}>
                      {currencyHelper.getCurrencyToken(confirmedPayment.currency)}
                    </Text>
                    <Text style={styles.bigNumber}>{confirmedPayment.amountValue}</Text>
                    <Text style={styles.currency}>IVA inc.</Text>
                  </View>
                  <Text style={styles.smallText}>{`(${confirmedPayment.installments} cuota${
                    confirmedPayment.installments > 1 ? 's' : ''
                  })`}</Text>
                </View>
              </View>
              <View style={styles.separator} />
              {selectedPayment.linkDescription && selectedPayment.linkDescription !== '' ? (
                <View style={styles.line}>
                  <View style={styles.verticalChunk}>
                    <Text style={styles.smallText}>Concepto</Text>
                    <Text>{selectedPayment.linkDescription}</Text>
                  </View>
                </View>
              ) : null}
            </View>
          </View>
          <View style={styles.footerContainer}>
            <Image style={styles.footerImage} source={image} />
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default PDFReceipt;
