import React, { useState } from 'react';
import ColoredCardWithTotals from './ColoredCardWithTotals';
import {
  Typography,
  Divider,
  Accordion,
  AccordionDetails,
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  Hidden,
} from '@material-ui/core';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { PaymentsState } from '../../store/config/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      backgroundColor: 'transparent',
      '&:before': {
        display: 'none',
      },
    },
    accordionSummary: {
      padding: '0',
    },
    accordionDetails: {
      flexDirection: 'column',
      padding: '0',
    },
    collapseButton: {
      display: 'flex',
    },
    paddingRight: {
      paddingRight: '0.6em',
    },
    coloredCard: {
      marginBottom: '1rem',
    },
    secondDivider: {
      margin: '1rem 0',
    },
  }),
);

const AccordionSummary = withStyles({
  content: {
    display: 'block',
  },
})(MuiAccordionSummary);

interface TotalsAccordionProps {
  payments: PaymentsState;
}

function TotalsAccordion({ payments }: TotalsAccordionProps) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string | false>('totals');

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Accordion
      square
      expanded={expanded === 'totals'}
      onChange={handleChange('totals')}
      elevation={0}
      className={classes.accordion}
    >
      <AccordionSummary className={classes.accordionSummary}>
        <div className="flex-row">
          <Typography variant="h5">Totales</Typography>
          <div className={classes.collapseButton}>
            <Typography variant="subtitle2" className={classes.paddingRight}>
              {expanded === 'totals' ? 'Contraer' : 'Ampliar'}
            </Typography>
            {expanded === 'totals' ? (
              <RemoveIcon fontSize="small" color="action" />
            ) : (
              <AddIcon fontSize="small" color="action" />
            )}
          </div>
        </div>
        <Divider className={classes.secondDivider} />
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Hidden xsUp>
          <ColoredCardWithTotals
            className={classes.coloredCard}
            color="warning"
            description="Links de 1 uso pendientes"
            pesosAmount={
              payments.paymentsTotals.oneTimeOnlyTotals
                ? payments.paymentsTotals.oneTimeOnlyTotals.uruguayanPesoAmount
                : 0
            }
            usdAmount={
              payments.paymentsTotals.oneTimeOnlyTotals
                ? payments.paymentsTotals.oneTimeOnlyTotals.dollarAmount
                : 0
            }
            pesosCount={
              payments.paymentsTotals.oneTimeOnlyTotals
                ? payments.paymentsTotals.oneTimeOnlyTotals.uruguayanPesoCount
                : 0
            }
            usdCount={
              payments.paymentsTotals.oneTimeOnlyTotals
                ? payments.paymentsTotals.oneTimeOnlyTotals.dollarCount
                : 0
            }
          />
        </Hidden>
        <ColoredCardWithTotals
          className={classes.coloredCard}
          color="success"
          description="Pagos aprobados"
          pesosAmount={
            payments.paymentsTotals.approvedTotals
              ? payments.paymentsTotals.approvedTotals.uruguayanPesoAmount
              : 0
          }
          usdAmount={
            payments.paymentsTotals.approvedTotals ? payments.paymentsTotals.approvedTotals.dollarAmount : 0
          }
          pesosCount={
            payments.paymentsTotals.approvedTotals
              ? payments.paymentsTotals.approvedTotals.uruguayanPesoCount
              : 0
          }
          usdCount={
            payments.paymentsTotals.approvedTotals ? payments.paymentsTotals.approvedTotals.dollarCount : 0
          }
        />
        <ColoredCardWithTotals
          className={classes.coloredCard}
          color="error"
          description="Pagos rechazados"
          pesosAmount={
            payments.paymentsTotals.rejectedTotals
              ? payments.paymentsTotals.rejectedTotals.uruguayanPesoAmount
              : 0
          }
          usdAmount={
            payments.paymentsTotals.rejectedTotals ? payments.paymentsTotals.rejectedTotals.dollarAmount : 0
          }
          pesosCount={
            payments.paymentsTotals.rejectedTotals
              ? payments.paymentsTotals.rejectedTotals.uruguayanPesoCount
              : 0
          }
          usdCount={
            payments.paymentsTotals.rejectedTotals ? payments.paymentsTotals.rejectedTotals.dollarCount : 0
          }
        />
      </AccordionDetails>
    </Accordion>
  );
}

export default TotalsAccordion;
