import React, { Dispatch, SetStateAction } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  makeStyles,
  Theme,
  Divider,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Filters } from '../../store/config/types';
import Search from './Search';
import DateFilters from './DateFilters';
import CurrencyFilters from './CurrencyFilters';
import OrderFilters from './OrderFilters';
import IssuerFilters from './IssuerFilters';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outlinedRoot: {
      borderRadius: '5px',
      backgroundColor: theme.palette.background.paper,
    },
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    accordionRoot: {
      // border: `1px solid ${theme.palette.divider}`,
      boxShadow: '0px 1px 4px #15223214',
    },
    accordionRounded: {
      '&:last-child': {
        borderRadius: '5px',
      },
    },
    accordionSummaryRoot: {
      '& .Mui-expanded': {
        margin: 0,
      },
    },
    accordionSummaryContent: {
      margin: 0,
    },
    accordionDetailsRoot: {
      flexDirection: 'column',
      padding: '1.5rem',
      paddingTop: '0',
      '& hr': {
        marginBottom: '1.5rem',
      },
    },
  }),
);

interface FiltersAccordionProps {
  hasDateFilter?: boolean;
  hasIssuerFilter?: boolean;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  filters: Filters | null;
  setFilters: (filters: Filters) => void;
  triggerSearch: () => void;
  triggerTime?: number;
}

function FiltersAccordion({
  hasDateFilter = false,
  hasIssuerFilter = false,
  searchTerm,
  setSearchTerm,
  filters,
  setFilters,
  triggerSearch,
}: FiltersAccordionProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion elevation={0} classes={{ root: classes.accordionRoot, rounded: classes.accordionRounded }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{
            root: classes.accordionSummaryRoot,
            content: classes.accordionSummaryContent,
          }}
        >
          <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} triggerSearch={triggerSearch} />
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
          <Divider />
          <div>
            {hasDateFilter && <DateFilters filters={filters} setFilters={setFilters} />}
            <CurrencyFilters filters={filters} setFilters={setFilters} hasDateFilters />
            {hasIssuerFilter && <IssuerFilters filters={filters} setFilters={setFilters} />}
            <OrderFilters filters={filters} setFilters={setFilters} />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default FiltersAccordion;
