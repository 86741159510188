import { useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import QRCode from 'qrcode.react';
import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { currencyHelper } from '../../../helpers/currencyHelper';
import useWindowDimensions from '../../../helpers/windowDimensionsHelper';
import { Amount, RootState } from '../../../store/config/types';
import { Downloading } from '../../Downloading';
import PdfQr from './PdfQr';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    title: {
      paddingLeft: '10px',
    },
    descriptionText: {
      maxWidth: '480px',
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
  }),
);

const calculateQrWidth = (windowWidth: number): number => {
  // 64 for margins, 48 for paddings
  return windowWidth - 64 - 48;
};

interface QrCodeDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  url: string;
  description?: string;
  price?: Amount;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  links: state.links,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & QrCodeDialogProps;

function QrCodeDialog({ auth, open, setOpen, url, description, price }: PropsType) {
  const [hasClicked, setHasClicked] = useState<boolean | null>(null);

  const classes = useStyles();
  const closeDialog = () => {
    setOpen(false);
  };
  const { width } = useWindowDimensions();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const downloadPDF = useCallback(async () => {
    const base64Image: string = (document.querySelector('canvas') as HTMLCanvasElement).toDataURL();
    const blob = await pdf(
      <PdfQr url={base64Image} description={description} price={price} business={auth.account?.business} />,
    ).toBlob();
    saveAs(blob, `${auth.account?.business.name}_QR`);
    setHasClicked(false);
  }, [auth, description, price]);

  useEffect(() => {
    if (hasClicked) {
      downloadPDF();
    }
  }, [downloadPDF, hasClicked]);

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      className="dialog"
      maxWidth="sm"
      classes={{ paper: classes.container }}
    >
      <DialogTitle classes={{ root: 'dialog-title special-title' }}>
        <Typography variant="h4" className={classes.title}>
          {description
            ? description
            : price
            ? `${price.value} ${currencyHelper.getCurrencyName(price.currency)}`
            : 'Código QR'}
        </Typography>
        {url && <Downloading startAnimation={hasClicked} startDownload={() => setHasClicked(true)} />}
        <CloseIcon fontSize="default" className="icon" onClick={closeDialog} />
      </DialogTitle>
      <DialogContent>
        {url && (
          <div>
            <p className={classes.descriptionText}>
              Mostrá este QR a quién te pagará.
              <br />
              Al capturarlo con tu teléfono móvil podrás navegar hacia la página de pago.
            </p>
            <div className="qr-code">
              <QRCode includeMargin={true} value={url} size={isMobile ? calculateQrWidth(width) : 450} />
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default connector(QrCodeDialog);
