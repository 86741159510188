import { Paper, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { uuid } from 'uuidv4';
import { ErrorCard } from '../../components/ErrorCard';
import { ConsumerInstallments } from '../../components/LandingComponents/ConsumerInstallments';
import { OnTheFlyLanding } from '../../components/LandingComponents/OnTheFlyLanding';
import { PermanentLinkLanding } from '../../components/LandingComponents/PermanentLinkLanding';
import { Loading } from '../../components/Loading';
import { currencyHelper } from '../../helpers/currencyHelper';
import { fetchBusinessBanks } from '../../store/action_creators/banks.actions';
import { getBusiness } from '../../store/action_creators/business.actions';
import { fetchBusinessIssuers } from '../../store/action_creators/commerces.actions';
import { validateLink } from '../../store/action_creators/links.actions';
import { beginPayment } from '../../store/action_creators/payments.actions';
import { AppActions } from '../../store/config/ActionTypes';
import {
  Amount,
  Bank,
  BeginPaymentRequest,
  BusinessIssuer,
  LandingParams,
  RootState,
} from '../../store/config/types';

const mapStateToProps = (state: RootState) => ({
  banks: state.banks,
  business: state.business,
  links: state.links,
  payments: state.payments,
  commerces: state.commerces,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  validateLink: (linkId: string) => dispatch(validateLink(linkId)),
  beginPayment: (beginPaymentRequest: BeginPaymentRequest) => dispatch(beginPayment(beginPaymentRequest)),
  getBusiness: (businessName: string) => dispatch(getBusiness(businessName)),
  fetchBusinessIssuers: (businessId: number) => dispatch(fetchBusinessIssuers(businessId)),
  fetchBusinessBanks: (businessId: number) => dispatch(fetchBusinessBanks(businessId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface InstallmentValues {
  installments: number;
  comment: string;
}

function Landing({
  banks,
  business,
  links,
  payments,
  commerces,
  validateLink,
  beginPayment,
  getBusiness,
  fetchBusinessIssuers,
  fetchBusinessBanks,
}: PropsFromRedux) {
  const history = useHistory();
  const params: LandingParams = useParams();
  const isLink = params.companyId && params.currency && params.amount && params.linkId;
  const isOnTheFly = params.companyId && params.linkId === undefined;

  const [malformed, setMalformed] = useState<boolean>(false);
  const [warnings, setWarnings] = useState<string | null>(null);
  const [step, setStep] = useState<number>(1);
  const [selectedIssuer, setSelectedIssuer] = useState<BusinessIssuer | null>(null);
  const [selectedBank, setSelectedBank] = useState<Bank | null>(null);
  const [amountToPay, setAmountToPay] = useState<Amount | null>(null);

  useEffect(() => {
    if (
      isLink &&
      !links.validatingLink &&
      links.linkToPay === null &&
      links.validateLinkErrorMessage === null
    ) {
      validateLink(params.linkId);
    }

    if (
      !business.gettingBusiness &&
      business.business === null &&
      business.getBusinessErrorMessage === null
    ) {
      getBusiness(params.companyId);
    }

    if (!params.companyId) {
      setMalformed(true);
    }
  }, [
    params,
    links.validatingLink,
    links.linkToPay,
    links.validateLinkErrorMessage,
    validateLink,
    business.gettingBusiness,
    business.business,
    business.getBusinessErrorMessage,
    getBusiness,
    isLink,
    isOnTheFly,
  ]);

  useEffect(() => {
    if (payments.plexoUrl) {
      history.push('/plexo-payment');
    }
  }, [payments.plexoUrl, history]);

  useEffect(() => {
    if (
      !commerces.loadingBusinessIssuers &&
      commerces.businessIssuers === null &&
      commerces.businessIssuersErrorMessage === null &&
      business.business?.id
    ) {
      fetchBusinessIssuers(business.business!.id);
    }
  }, [
    commerces.loadingBusinessIssuers,
    commerces.businessIssuers,
    commerces.businessIssuersErrorMessage,
    fetchBusinessIssuers,
    business.business,
  ]);

  useEffect(() => {
    if (
      !banks.loadingBusinessBanks &&
      banks.businessBanks === null &&
      banks.businessBanksErrorMessage === null &&
      business.business?.id
    ) {
      fetchBusinessBanks(business.business!.id);
    }
  }, [
    banks.loadingBusinessBanks,
    banks.businessBanks,
    banks.businessBanksErrorMessage,
    fetchBusinessBanks,
    business.business,
  ]);

  useEffect(() => {
    if (!links.validatingLink && links.linkToPay !== null) {
      setAmountToPay(links.linkToPay.amount);
    }
  }, [links.validatingLink, links.linkToPay, setAmountToPay]);

  useEffect(() => {
    if (warnings === null && links.validateLinkSuccess && links.linkToPay) {
      const amountFetched = links.linkToPay.amount.value.toString();
      const businessFetched = links.linkToPay.businessName;

      if (amountFetched !== params.amount) {
        setWarnings('El monto que hemos encontrado difiere del monto que está ingresado en la URL.');
      }

      if (businessFetched !== params.companyId) {
        setWarnings('La empresa que hemos encontrado difiere de la que está ingresada en la URL.');
      }

      if (amountFetched !== params.amount && businessFetched !== params.companyId) {
        setWarnings(
          'Los datos de monto y empresa que hemos encontrado difieren de los que están ingresados en la URL.',
        );
      }

      if (amountFetched === params.amount && businessFetched === params.companyId) {
        setWarnings('');
      }
    }

    if (params.currency) {
      params.currency = currencyHelper.formatCurrencyToNumber(params.currency);
    }

    if (warnings === null && params.currency && !currencyHelper.isValidCurrency(Number(params.currency))) {
      setWarnings('La moneda que está ingresada en la URL no existe en el sistema.');
    }
  }, [
    params,
    warnings,
    setWarnings,
    links.validateLinkSuccess,
    links.linkToPay,
    params.currency,
    params.amount,
    params.companyId,
  ]);

  const isTest =
    window.location.host === 'localhost:3000' ||
    window.location.host === 'px-links.netlify.app' ||
    window.location.host === 'plinks.handsoft.com.uy';
  const environmentId = isTest ? '1snn5n9w' : 'k8vif92e';
  const commerceId = isTest ? '123' : 'visanetuy_px_2048256716';
  const fingerprintId = uuid();
  const visaFingerprint = commerceId + fingerprintId;
  let fingerprint = isTest ? 'd1cab7e203194d09972b7fb3d4657fc8' : fingerprintId;

  const loadVisaDeviceFingerprint = (callback: any) => {
    const existingScript = document.getElementById('visaDeviceFingerprint');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `https://h.online-metrix.net/fp/tags.js?org_id=${environmentId}&session_id=${visaFingerprint}`;
      script.id = 'visaDeviceFingerprint';
      document.body.appendChild(script);

      script.onload = () => {
        if (callback) callback();
      };
    }

    if (existingScript && callback) callback();
  };

  useEffect(() => {
    loadVisaDeviceFingerprint(() => console.log('fingerprint loaded'));
  });

  const submitPayment = (values: InstallmentValues) => {
    if (links.linkToPay) {
      const linkToPayRequest: BeginPaymentRequest = {
        linkId: links.linkToPay.id,
        businessName: links.linkToPay.businessName,
        currency: links.linkToPay.amount.currency,
        value: links.linkToPay.amount.value,
        redirectUrl: `${window.location.origin}/redirect`,
        installments: values.installments,
        issuerPlexoId: selectedIssuer!.idPlexo,
        deviceFingerprint: fingerprint,
        clientComment: values.comment,
      };
      beginPayment(linkToPayRequest);
    } else if (amountToPay) {
      const onTheFlyRequest: BeginPaymentRequest = {
        businessName: params.companyId,
        currency: amountToPay.currency,
        value: amountToPay.value,
        redirectUrl: `${window.location.origin}/redirect`,
        installments: values.installments,
        issuerPlexoId: selectedIssuer!.idPlexo,
        deviceFingerprint: fingerprint,
        clientComment: values.comment,
      };
      beginPayment(onTheFlyRequest);
    }
  };

  if (links.validateLinkErrorMessage) {
    return <ErrorCard message="No se pudo validar el link que ingresaste." />;
  } else if (business.getBusinessErrorMessage) {
    return <ErrorCard message="No pudimos encontrar la empresa a la que deseas pagar" />;
  } else if (malformed) {
    return (
      <ErrorCard
        subtitle="URL debe tener información de la empresa"
        message="La URL ingresada no tiene el formato correcto"
        hasLoginRedirect
      />
    );
  } else if (step === 1) {
    return (
      <div className="screen-container">
        {warnings && (
          <div className="consumer-warnings">
            <WarningIcon className="icon" fontSize="large" />
            <div className="warning-texts">
              <Typography variant="subtitle2">{`${warnings} Antes de efectivizar el pago, revisa que la información del pago que se muestra es la deseada`}</Typography>
            </div>
          </div>
        )}
        <Paper className="consumer-card">
          <div className="heading">
            <Typography variant="h4">Bienvenido</Typography>
            <Typography variant="h6">Selecciona el medio con el cual quieres pagar</Typography>
          </div>
          {links.linkToPay ? (
            <PermanentLinkLanding
              linkToPay={links.linkToPay}
              businessIssuers={commerces.businessIssuers}
              businessBanks={banks.businessBanks}
              setStep={setStep}
              selectedIssuer={selectedIssuer}
              setSelectedIssuer={setSelectedIssuer}
              selectedBank={selectedBank}
              setSelectedBank={setSelectedBank}
            />
          ) : business.business ? (
            <OnTheFlyLanding
              params={params}
              businessIssuers={commerces.businessIssuers}
              businessBanks={banks.businessBanks}
              selectedIssuer={selectedIssuer}
              setSelectedIssuer={setSelectedIssuer}
              selectedBank={selectedBank}
              setSelectedBank={setSelectedBank}
              businessId={business.business.id!}
              beginningPayment={payments.beginningPayment}
              setAmount={setAmountToPay}
              selectedAmount={amountToPay}
              setStep={setStep}
            />
          ) : (
            <Loading />
          )}
        </Paper>
      </div>
    );
  } else if (step === 2) {
    return (
      <ConsumerInstallments
        submit={submitPayment}
        issuer={selectedIssuer!}
        bank={selectedBank}
        beginningPayment={payments.beginningPayment}
        amountToPay={amountToPay!}
        businessName={links.linkToPay?.businessName ?? params.companyId}
        businessId={links.linkToPay?.businessId ?? business.business!.id!}
        setStep={setStep}
      />
    );
  } else {
    return <Loading />;
  }
}

export default connector(Landing);
