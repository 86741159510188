import React, { useState, useRef, useEffect } from 'react';
import { ScreenTitle } from '../../components/ScreenTitle';
import { Button, Grid, Typography, CircularProgress, Paper } from '@material-ui/core';
import { Form, Formik, Field, FormikHelpers } from 'formik';
import schemas from '../../data/schemas';
import { FormTextField } from '../../components/forms/FormTextField';
import { Commerces } from '../../components/Commerces';
import { Loading } from '../../components/Loading';
import dummy from '../../assets/dummy.png';
import { RootState, UpdateBusinessRequest, UpdateImageRequest } from '../../store/config/types';
import { ThunkDispatch } from 'redux-thunk';
import { AppActions } from '../../store/config/ActionTypes';
import { updateBusiness } from '../../store/action_creators/business.actions';
import { connect, ConnectedProps } from 'react-redux';
import { businessService } from '../../services/business.service';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { urlHelper } from '../../helpers/urlHelper';
import upload from '../../assets/upload.svg';
import trash from '../../assets/trash.svg';

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  business: state.business,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  updateBusiness: (updateBusinessRequest: UpdateBusinessRequest) =>
    dispatch(updateBusiness(updateBusinessRequest)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Values {
  name: string;
  legalId: string;
  description: string;
  vat: number;
}

function Business({ auth, business, updateBusiness }: PropsFromRedux) {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const [selectedPhoto, setSelectedPhoto] = useState<File | null>(null);
  const [pictureError, setPictureError] = useState<string | null>(null);
  const photoInput = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imageRef.current && imageRef.current.src === '') {
      imageRef.current.src = dummy;
    }
  });

  const onImageError = () => {
    if (imageRef.current) {
      imageRef.current.src = dummy;
    }
  };

  useEffect(() => {
    if (!initialized && auth.account && imageRef.current !== null) {
      setInitialized(true);

      if (auth.account.business.logoImage) {
        imageRef.current.src = `data:${auth.account.business.logoImage.contentType};base64,${auth.account.business.logoImage.data}`;
      }
    }
  }, [initialized, auth.account]);

  const submitBusiness = (values: Values, helpers: FormikHelpers<Values>) => {
    if (auth.account) {
      setUpdating(true);
      setPictureError(null);

      const updatedBusiness: UpdateBusinessRequest = {
        businessId: auth.account.business.id!,
        name: values.name,
        legalId: values.legalId,
        acceptsOnTheFly: auth.account.business.acceptsOnTheFly!,
        softDescriptor: values.description,
        vatRate: auth.account.business.vatRate!,
        sendNotification: auth.account.business.mailNotificationsEnabled!,
        mailForNotifications: auth.account.business.mailForNotifications,
        urlForSystemNotifications: auth.account.business.urlForSystemNotifications,
        clientId: auth.account.business.clientId,
        clientSecret: auth.account.business.clientSecret,
      };

      updateBusiness(updatedBusiness);

      if (selectedPhoto) {
        const updateImageRequest: UpdateImageRequest = {
          businessId: auth.account.business.id!,
          file: selectedPhoto!,
        };

        businessService.updateBusinessPicture(updateImageRequest).catch((error) => {
          setPictureError(error.message);
        });
      }
    }
  };

  const onPhotoSelected = (files: FileList | null) => {
    setLoadingImage(false);
    setSelectedPhoto(files ? files[0] : null);

    if (FileReader && files && files.length) {
      var fr = new FileReader();
      fr.onload = function () {
        if (imageRef.current) imageRef.current.src = fr.result!.toString();
      };
      fr.readAsDataURL(files[0]);
    }
  };

  const removeImage = () => {
    if (auth.account) {
      businessService
        .removeBusinessPicture(auth.account.business.id!)
        .then((response) => {
          setSelectedPhoto(null);
          if (imageRef.current) imageRef.current.src = dummy;
        })
        .catch((error) => {
          setPictureError(error.message);
        });
    }
  };

  const closeSnack = () => {
    setUpdating(false);
  };

  return (
    <div className="screen-container configuration">
      <ScreenTitle title="Empresa" />
      <Paper elevation={2}>
        <div className="upload-btn-wrapper" style={{ paddingTop: 0 }}>
          <div className="img-container">
            <img
              ref={imageRef}
              src={auth.account ? urlHelper.buildImageUrl(auth.account.business.id!) : dummy}
              onError={onImageError}
              alt="img"
            />
          </div>
          <div className="actions">
            <label className={loadingImage ? 'disabled-label' : ''} htmlFor="photoUpload">
              {loadingImage ? (
                <CircularProgress size={20} thickness={6} />
              ) : (
                <span onClick={(e) => e.stopPropagation()} className="action buttonable-span">
                  <img src={upload} alt="upload" /> Cargar imagen
                </span>
              )}
            </label>
            <label onClick={removeImage}>
              <Button variant="outlined" className="action">
                <img src={trash} alt="trash" /> Eliminar imagen
              </Button>
            </label>
          </div>
          <input
            type="file"
            id="photoUpload"
            accept="image/*"
            ref={photoInput}
            onChange={() =>
              onPhotoSelected(photoInput && photoInput.current ? photoInput.current?.files : null)
            }
          />
        </div>
        {auth.account ? (
          <Formik
            initialValues={{
              name: auth.account.business.name,
              legalId: auth.account.business.legalId,
              description: auth.account.business.softDescriptor,
              vat: auth.account.business.vatRate ?? 0,
            }}
            validationSchema={schemas.BusinessSchema}
            onSubmit={submitBusiness}
          >
            <Form id="businessForm" className="form">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field name="name" component={FormTextField} type="text" placeholder="Nombre" disabled />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="vat" component={FormTextField} type="text" placeholder="IVA" disabled />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="legalId" component={FormTextField} type="text" placeholder="RUT" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="description" component={FormTextField} type="text" placeholder="Descripción" />
                </Grid>
              </Grid>
            </Form>
          </Formik>
        ) : (
          <Loading />
        )}
      </Paper>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        form="businessForm"
        fullWidth
        style={{ marginTop: '1.4rem' }}
      >
        Guardar cambios
      </Button>
      <Typography variant="h5">Comercios</Typography>
      <Commerces />
      <CustomSnackbar
        open={updating && (business.updateBusinessSuccess || business.updateBusinessErrorMessage !== null)}
        message={
          pictureError !== null
            ? pictureError
            : business.updateBusinessSuccess
            ? 'Se actualizó la empresa correctamente'
            : business.updateBusinessErrorMessage!
        }
        handleClose={closeSnack}
        type={!business.updateBusinessSuccess || pictureError !== null ? 1 : 0}
      />
    </div>
  );
}

export default connector(Business);
