const paymentPageLimit = 10;
const linksPageLimit = 10;

export const possibleInstallments = [1, 2, 3, 4, 5, 6, 10, 12, 18];
export const ivas = [0, 10, 22];

const values = {
  paymentPageLimit,
  linksPageLimit,
};

export default values;
