import React from 'react';
import { Business, Payment } from '../../store/config/types';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { dateHelper } from '../../helpers/dateHelper';
import { currencyHelper } from '../../helpers/currencyHelper';
import { numberHelper } from '../../helpers/numberHelper';
import { paymentStatusEnumHelper } from '../../helpers/paymentStatusEnumHelper';
import { linkTypeEnumHelper } from '../../helpers/linkTypeEnumHelper';
import { useTheme } from '@material-ui/core';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 32,
    paddingBottom: 32,
    width: 600,
  },
  title: {
    marginBottom: 16,
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 14,
    fontWeight: 'bold',
    width: 600,
    paddingTop: 6,
    paddingBottom: 6,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 12,
    width: 600,
    paddingTop: 6,
    paddingBottom: 6,
  },
  column: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    paddingLeft: 4,
    paddingRight: 4,
  },
  divider: {
    height: 1,
    width: 600,
    backgroundColor: '#999',
    marginTop: 2,
    marginBottom: 2,
  },
  date: {
    width: 100,
    textAlign: 'center',
  },
  description: {
    width: 100,
    textAlign: 'center',
  },
  type: {
    width: 72,
    textAlign: 'center',
  },
  issuer: {
    width: 70,
    textAlign: 'center',
  },
  amount: {
    width: 56,
    textAlign: 'right',
  },
  referencePayment: {
    width: 100,
    textAlign: 'center',
  },
});

interface PDFPaymentsProps {
  data: Payment[] | null;
  business: Business | null;
}

function PDFPayments({ data, business }: PDFPaymentsProps) {
  const theme = useTheme();

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        <View>
          <Text style={styles.title}>{`Listado de pagos ${business ? 'de' : ''} ${
            business ? business.name : ''
          }`}</Text>
          <View key="header">
            <View style={styles.headerRow}>
              <View style={styles.column}>
                <Text style={{ width: 60 }}>Estado</Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.date}>Fecha</Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.description}>Descripción</Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.type}>Tipo de link</Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.issuer}>Medio de pago</Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.amount}>Monto</Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.referencePayment}>Referencia</Text>
              </View>
            </View>
            <View style={styles.divider}>
              <Text></Text>
            </View>
          </View>
          {data &&
            data.map((payment, index) => {
              return (
                <View key={index}>
                  <View style={styles.row}>
                    <View style={styles.column}>
                      <Text
                        style={{
                          width: 62,
                          color: paymentStatusEnumHelper.useIconColorByStatus(payment.status, theme),
                        }}
                      >
                        {paymentStatusEnumHelper.getStatusText(payment.status)}
                      </Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={styles.date}>{dateHelper.toShorterNiceString(payment.date)}</Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={styles.description}>{payment?.description || '-'}</Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={styles.type}>{linkTypeEnumHelper.toNiceString(payment.linkType)}</Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={styles.issuer}>{payment.issuerName}</Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={styles.amount}>{`${currencyHelper.getCurrencyToken(
                        payment.taxedAmount ? payment.taxedAmount.currency : payment.amount.currency,
                      )} ${numberHelper.getNumberWithDots(
                        payment.taxedAmount ? payment.taxedAmount.value : payment.amount.value,
                      )}`}</Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={styles.referencePayment}>{payment?.referencePayment || '-'}</Text>
                    </View>
                  </View>
                  <View style={styles.divider}>
                    <Text></Text>
                  </View>
                </View>
              );
            })}
        </View>
      </Page>
    </Document>
  );
}

export default PDFPayments;
